import { NgModule } from '@angular/core';

import { PowerBiComponent } from './powerbi.component';
import { PowerBiService } from './powerbi.service';

@NgModule({
  imports: [],
  declarations: [PowerBiComponent],
  exports: [PowerBiComponent],
  // providers: [PowerBiService]
})
export class PowerBiModule {}
