import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  startWith,
  catchError,
  filter,
  take,
  shareReplay,
  finalize,
  switchMap,
} from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, BehaviorSubject, Observable, combineLatest, concat, defer, OperatorFunction } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { QuestionBase, ProviderService } from 'api-clinician-app';
import { ConfigService } from '@app/core/services/config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpeechRecognitionService } from '@app/core/services/speech-recognition.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AppInsightTags, ApplicationInsightsService } from '@app/core/services/application-insights.service';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'shared-dynamic-form-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss'],
})
export class DynamicFormQuestionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() question: QuestionBase;
  @Input() form: FormGroup;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('textArea') textAreaElement: ElementRef;

  @ViewChild('notesField') notesField: ElementRef;

  get isValid() {
    const control = this.form.controls[this.question.QuestionKey];
    return !control || !control.touched || !control.dirty || control.valid;
  }
  get required() {
    return this.question.Required;
  }

  get thisFieldControl() {
    return this.form.get(this.question.QuestionKey);
  }

  visibleQuestion$ = of(false);
  timeErrorMessage = 'Start time must be less than end time';
  showNote$ = new BehaviorSubject(false);

  hasFormNoteValue$: Observable<boolean>;

  enableSpeech$: Observable<boolean>;

  // speedServiceStarted$ = this.speechService.started$.pipe(
  //   distinctUntilChanged(),
  //   untilDestroyed(this),
  //   shareReplay(1)
  // );

  speechServiceStarted$ = new BehaviorSubject(false);

  showTranscriptionQualityMenu = false;

  constructor(
    private providerService: ProviderService,
    private configService: ConfigService,
    private snackBar: MatSnackBar,
    private speechRecognitionService: SpeechRecognitionService,
    private appInsightsService: ApplicationInsightsService,
  ) { }

  ngOnInit() {
    this.visibleQuestion$ = this.form.valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      debounceTime(10),
      startWith(false),
      map(t => {
        if (!this.question.Dependency) {
          return true;
        } // no dependency, show question

        const existingValue = t[this.question.QuestionKey];
        if (!!existingValue) {
          return true;
        } // we have a captured value, show question

        const dependentFieldValue = t[this.question.Dependency.DependencyKeyName];

        // ranges for numbers

        // ranges for timespan
        if (this.question.Dependency.DependencyKeyValueMin || this.question.Dependency.DependencyKeyValueMax) {
          if (!dependentFieldValue) {
            return false;
          }

          const duration = moment.duration(dependentFieldValue);
          if (!moment.isDuration(duration)) {
            return false;
          }

          if (this.question.Dependency.DependencyKeyValueMin) {
            const min = moment.duration(this.question.Dependency.DependencyKeyValueMin);
            if (duration.asMinutes() < min.asMinutes()) {
              return false;
            }
          }

          if (this.question.Dependency.DependencyKeyValueMax) {
            const max = moment.duration(this.question.Dependency.DependencyKeyValueMax);
            if (duration.asMinutes() > max.asMinutes()) {
              return false;
            }
          }

          return true;
        }

        // exact value
        if (dependentFieldValue && dependentFieldValue.includes(',') && dependentFieldValue.split(',') instanceof Array) {
          const resArray = String(dependentFieldValue).split(',');
          return (
            resArray.includes(this.question.Dependency.DependencyKeyValue) ||
            resArray.filter(a => this.question.Dependency.DependencyKeyValue.replace(/ /g, '').split(',').includes(a)).length > 0
          );
        } else if (
          dependentFieldValue &&
          this.question.Dependency.DependencyKeyValue &&
          this.question.Dependency.DependencyKeyValue.includes(',')
        ) {
          return (
            this.question.Dependency.DependencyKeyValue.split(', ').filter(f => dependentFieldValue.split(', ').includes(f)).length > 0
          );
        } else {
          return dependentFieldValue === this.question.Dependency.DependencyKeyValue;
        }
      })
    );

    this.hasFormNoteValue$ = this.form.valueChanges.pipe(
      map(formVals => formVals[this.question.QuestionKey + '-notes']),
      startWith(this.form.value[this.question.QuestionKey + '-notes']), // assumption: form already has values
      map(formVal => !!formVal && formVal.length > 0),
      distinctUntilChanged(),
      untilDestroyed(this)
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.hasFormNoteValue$.subscribe(formVal => this.showNote$.next(formVal)), 2000);

    this.enableSpeech$ = combineLatest([
      of(this.configService.config.settings?.voiceToTextNotesTranscribeEnabled || false), // (1) enabled for environment
      of(this.question.QuestionKey?.includes('section-notes')), // (2) is this a field that should use it?
      of(this.speechApiSupported()), // (3) does the browser support it?
      this.providerService.providerOptions$.pipe( // (4) is the provider enabled?

        take(1),
        map(options => options?.IsEnabledForWebSpeechAPI?.toLowerCase() === 'true')
      ),
    ]).pipe(
      map(options => options.every(option => option)), // all of them must be truthy
      distinctUntilChanged(),
      untilDestroyed(this),
      shareReplay(1), // multiple subscriptions
    );
  }

  isChecked(): boolean {
    return Boolean(this.question.Answer);
  }

  toggleCheck(event: MatCheckbox) {
    this.form.patchValue({
      [this.question.QuestionKey]: event.checked
    }, { emitEvent: true });
    this.form.markAsDirty();
  }

  getDurationUnitType(duration: moment.Duration): 'hours' | 'days' | 'weeks' | 'months' | 'years' {
    if (duration.years() > 0) {
      return 'years';
    }
    if (duration.months() > 0) {
      return 'months';
    }
    if (duration.weeks() > 0) {
      return 'weeks';
    }
    if (duration.days() > 0) {
      return 'days';
    }
    if (duration.hours() > 0) {
      return 'hours';
    }
    return null;
  }

  getStringOptions() {
    return this.question.Options.map(o => o.AnswerValue);
  }

  timespanToNumber() { }

  toggleNote() {
    // this.showNote = !this.showNote;
    this.showNote$.next(true);

    setTimeout(() => {
      if (!!this.notesField) {
        this.notesField.nativeElement.focus();
      }
    }, 50);
  }

  get isLongQuestion() {
    return this.question.Label && this.question.Label.length >= 25;
  }

  startListening() {
    this.showTranscriptionQualityMenu = true;

    this.appInsightsService.trackEvent(AppInsightTags.STARTED_SPEECH_RECOGNITION, {
      recognitionType: 'web-speech-api'
    });

    this.speechRecognitionService.options.interimResults = true;
    this.speechRecognitionService.options.continuous = false;

    this.speechRecognitionService.notification = {
      text: 'Now transcribing your speech',
      disableText: 'Stop'
    };

    let fieldValue: string = this.thisFieldControl.value || '';

    // formatting logic for transcript taking the current value into account
    const formatTranscript = (currentFieldValue: string, transcript: string) => {
      const prefix = currentFieldValue?.length > 0
        && currentFieldValue.trimEnd().slice(-1) !== '.' ? '. ' : ' ';

      const formattedTranscript = transcript?.length > 2 ?
        `${prefix}` + // preceeding space
        `${transcript?.charAt(0).toLocaleUpperCase() || ''}${transcript?.slice(1) || ''}` + // capitalised first character
        `.` : // add a fullstop at the end
        transcript;
      return formattedTranscript;
    };

    const startListening$ = this.speechRecognitionService.askForPermission().pipe(
      catchError(() => of(false)),
      tap(success => !success && this.snackBar.open('You have not granted permission for us to use your microphone', 'OK')),
      // at this point we have an observable that emits true or false for success in asking for permission
      filter(success => success), // stop processing the rest for errors...
      tap(() => this.speechServiceStarted$.next(true)),
      switchMap(() => this.speechRecognitionService.start()),

      tap(event => {
        if (event.type === 'result') {

          const newValue = `${fieldValue?.trimEnd()}${formatTranscript(fieldValue, event.results[0][0].transcript)}`;

          // if it's final, lock it in!
          if (event.results[0].isFinal) {
            fieldValue = newValue;
          }

          this.updateTextAreaValue(newValue);
        }
      }), // weirdness here with types

      // TODO add something that counts the no-speech events and then cancel based on time

      // delay(5000),
      finalize(() => {
        this.speechRecognitionService.stop();
        this.speechServiceStarted$.next(false);
      }),
      untilDestroyed(this),
    );


    startListening$.subscribe();
  }

  updateTextAreaValue(newValue: string) {
    this.thisFieldControl.patchValue(newValue);

    const element = this.textAreaElement?.nativeElement;

    element?.focus();
    element?.setSelectionRange(element.value.length, element.value.length);

    this.autosize.resizeToFitContent();
  }

  stopListening() {
    // TODO not supported by the package directly
    // this.speechService.abort();
    this.speechRecognitionService.stop();
    this.speechServiceStarted$.next(false);
  }


  private speechApiSupported() {
    // return 'SpeechRecognition' in window;
    return this.speechRecognitionService.isEnabled();
  }

  goodQualityTranscription() {
    this.logTranscriptionFeedback('Good');
  }

  badQualityTranscription() {
    this.logTranscriptionFeedback('Good');
  }

  private logTranscriptionFeedback(transcriptionQuality: string) {
    this.showTranscriptionQualityMenu = false;
    this.appInsightsService.trackEvent(AppInsightTags.REVIEW_SPEECH_RECOGNITION, {
      recognitionType: 'web-speech-api',
      transcriptionQuality
    });
  }

  ngOnDestroy() { }
}

export function withLastAs<T, R>(getLast: () => R): OperatorFunction<T, R> {
  return (source: Observable<T>) => source.lift.call(
    concat(source, defer(() => of(getLast())))
  );
}
