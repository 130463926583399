import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  Injector,
  ɵcreateInjector as createInjector,
  AfterViewInit,
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, filter, map, switchMap, share, startWith, tap, shareReplay, debounceTime, withLatestFrom } from 'rxjs/operators';
import { AuthService, ProviderService, UiStateService } from '@app/core/services';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-web-layout',
  templateUrl: './web-layout.component.html',
  styleUrls: ['./web-layout.component.scss']
})
export class WebLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sidebar', { read: ViewContainerRef, static: true }) public sidebar: ViewContainerRef;
  loadedSidebar = false;

  authenticated$ = combineLatest([this.authService.authenticated$, this.providerService.provider$]).pipe(
    map(([provider, auth]) => !!provider && !!auth),
    distinctUntilChanged(),
    debounceTime(500),
    tap(auth => {
      if (auth && !this.loadedSidebar) {
        this.loadSidebar();
      }
    }),
    shareReplay(1),
    untilDestroyed(this),
  );
  sidebarFlyout$ = this.uiStateService.sidebarFlyout$;
  displayProgressSpinner$ = this.uiStateService.displayProgressSpinner$.pipe(
    distinctUntilChanged(_.isEqual),
    untilDestroyed(this)
  );

  guidedTourInstantiated$ = this.uiStateService.activeGuidedTour$.pipe(
    withLatestFrom(this.authService.authenticated$),
    map(([tour, authenticated]) => !!tour && authenticated  ),
    distinctUntilChanged(_.isEqual),
    untilDestroyed(this)
  );

  constructor(
    private authService: AuthService,
    private providerService: ProviderService,
    private uiStateService: UiStateService,
    private injector: Injector,
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  loadSidebar() {
    if (!this.loadedSidebar) {
      import('../../../modules/sidebar/sidebar.module').then(({ SidebarModule }) => {
        const injector = createInjector(SidebarModule, this.injector);
        const sidebarModule = injector.get(SidebarModule);
        sidebarModule.renderSidebar(this.sidebar);

        this.loadedSidebar = true;
      });
    }
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnDestroy(): void { }

}
