import { Pipe, PipeTransform, SecurityContext, Sanitizer } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from '@app/core/services';
import { AvatarViewModel } from '@app/core/services/state/mypatientfiles/avatars.store';
import { ConfigService } from '@app/core/services/config.service';

@Pipe({
  name: 'mypatientfilesUrl',
  pure: false
})
export class MyPatientFilesUrlPipe implements PipeTransform {
  constructor(
    private authService: AuthService,
    private configService: ConfigService,
    private sanitizer: Sanitizer) { }

  transform(value: AvatarViewModel): string {
    if (!!value) { return this.fileUrl(value.PracticeId, value.AvatarUrl); }
    return '';
  }

  fileUrl(tenantId: string, documentUrl: string) {
    if (!this.authService.token) { return null; }
    const accessToken = this.authService.token;
    const url =
      // tslint:disable-next-line: max-line-length
      `${this.configService.config.apiUrl}/api/v1/document?url=${documentUrl}&practiceId=${tenantId}&access_token=${accessToken}&r=picture.png`;
    return this.sanitizer.sanitize(SecurityContext.URL, url);
  }
}
