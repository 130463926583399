import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as semver from 'semver';
import { retry, map, switchMap, tap } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { WINDOW } from './window.provider';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { VERSION } from '@env/version';
import * as moment from 'moment';
import { AuthService } from './auth.service';
import { NavigationService } from './navigation.service';

export type UpdateRecommendation = 'required' | 'recommended' | 'none';

export interface IVersion {
  version: string;
  date: string;
  gitInfo: { [key: string]: string | boolean };
}

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  versionSnackbar: MatSnackBarRef<SimpleSnackBar>;
  versionFile = 'assets/config/version.json';
  currentVersion: IVersion = VERSION;

  get serverVersion(): Observable<{ version: IVersion; comparison: string; updateRecommendation: UpdateRecommendation }> {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    });

    return this.http
      .get<IVersion>(this.versionFile, { headers })
      .pipe(
        retry(2),
        map(serverVer => {
          const comparison = semver.diff(this.currentVersion.version, serverVer.version);
          const updateRecommendation: UpdateRecommendation =
            comparison === 'major' || comparison === 'premajor' ? 'required' : comparison !== null ? 'recommended' : 'none';
          return { version: serverVer, comparison, updateRecommendation, current: this.currentVersion };
        })
      );
  }

  constructor(
    private http: HttpClient, @Inject(WINDOW)
    private window: Window,
    private navService: NavigationService,
    private authService: AuthService,
    private snackbar: MatSnackBar) {
    this.setupTimer();
  }

  setupTimer(initialCheck = 5000, subsequentChecks = 1000 * 60 * 5) {
    timer(initialCheck, subsequentChecks)
      .pipe(
        untilDestroyed(this, 'destroy'),
        switchMap(() => this.serverVersion)
      )
      .subscribe(ver => {
        if (ver.updateRecommendation === 'recommended' || ver.updateRecommendation === 'required') {
          const versionDate = moment(ver.version.date).format('LL');
          const buttonActionName = ver.comparison === 'major' ? 'Logout & Update' : 'Update';
          this.versionSnackbar = this.snackbar.open(`New version available ${ver.version.version} (${versionDate})`, buttonActionName, {
            duration: subsequentChecks, // match the check timer, so essentially it'll remain open and naggy
            politeness: 'assertive',
            horizontalPosition: 'left'
          });
          this.versionSnackbar
            .onAction()
            .pipe(
              untilDestroyed(this, 'destroy'),
              tap(s => {
                if (ver.comparison === 'major') {
                  this.authService.logout();
                  this.navService.navigate(['/auth/login']).then(() => {
                    // disable warning, but we need this!
                    // tslint:disable-next-line: deprecation
                    setTimeout(() => {
                      this.window.location.reload(true);
                    });
                  });
                } else {
                  // disable warning, but we need this!
                  // tslint:disable-next-line: deprecation
                  this.window.location.reload(true);
                }
              })
            ).subscribe();
        }
      });
  }

  destroy() { }
}
