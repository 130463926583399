import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EncounterDiagramVo, PatientDocumentVo, PatientsService, ProviderService } from '@app/core/services';
import { ClinicalEncounterService } from '@app/core/services/clinical-encounter.service';
import { ConfirmDialogComponent, ConfirmDialogModel, defaultDialogActions, DialogResponse } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { b64toBlob2 } from '@app/shared/functions/b64ToBlob';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, forkJoin, iif, Observable, of } from 'rxjs';
import { switchMap, take, map, withLatestFrom, tap, mapTo, catchError } from 'rxjs/operators';
import { PatientWhiteboardComponent } from '../patient-whiteboard.component';
import * as uuidV4 from 'uuid/v4';
import { AppInsightTags, ApplicationInsightsService } from '@app/core/services/application-insights.service';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * This component should never be called directly. Rather, call using the shared-whiteboard-icon.component.
 */
@Component({
  selector: 'app-patient-whiteboard-dialog',
  templateUrl: './patient-whiteboard-dialog.component.html',
  styleUrls: ['./patient-whiteboard-dialog.component.scss']
})
export class PatientWhiteboardDialogComponent implements OnInit, OnDestroy {

  public static readonly RESULT = {
    DONE: 'DONE',
    UPLOADED: 'UPLOADED'
  };

  public static readonly PatientWhiteboardCategory = 'Clinical Notes';

  @ViewChild('whiteboardComponent', { static: false })
  whiteboardComponent: PatientWhiteboardComponent;

  shouldUploadOnComplete = false;
  type: string;
  uploadOnSave = false;

  uploading$ = new BehaviorSubject(false);

  params$ = new BehaviorSubject({ tenantId: null, patientId: null });

  saveDiagrams$ = new BehaviorSubject<EncounterDiagramVo[]>([]);
  otherDiagrams$ = this.params$.pipe(
    take(1),
    switchMap(p => this.clinicalEncounterService.encounterInProgress$(p.tenantId, p.patientId)),
    map(encounter => {
      return encounter.EncounterDiagrams?.filter(diagram => diagram.Type !== this.type) || [];
    }),
  );

  diagramImages$ = this.params$.pipe(
    take(1),
    switchMap(p => this.clinicalEncounterService.encounterInProgress$(p.tenantId, p.patientId)),
    map(encounter => encounter.EncounterDiagrams?.filter(diagram => diagram.Type === this.type) || null),
    map(e => e ? e.map(ed => ed.Data) : []),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private clinicalEncounterService: ClinicalEncounterService,
    private patientsService: PatientsService,
    private dialogRef: MatDialogRef<PatientWhiteboardDialogComponent>,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private appInsightsService: ApplicationInsightsService,
  ) {
    if (!data?.type) {
      throw new Error(`No type provided for: ${this.constructor.name}`);
    }
    this.type = data?.type;
    this.uploadOnSave = data?.uploadOnSave || this.uploadOnSave;
    this.shouldUploadOnComplete = data?.shouldUploadOnComplete || false;
    this.params$.next({ tenantId: data.tenantId, patientId: data.patientId });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  isUnsavedChanges(): boolean {
    return this.whiteboardComponent.hasUnsavedChanges();
  }

  saveAndClose() {
    if (!this.isUnsavedChanges()) {
      this.dialogRef.close();
      return;
    }
    this.saveDiagrams$.next([]);
    this.uploading$.next(true);
    this.whiteboardComponent.saveCanvasToBlob((blobs) => {
      of(blobs as Blob[]).pipe(
        switchMap(inputBlobs => forkJoin(inputBlobs.map(inputBlob => this.blobToDiagram$(inputBlob)))),
        switchMap(diagrams => forkJoin(diagrams.map(diagram => this.uploadDiagram$(diagram))).pipe(
          map(uploadResult => [uploadResult, diagrams] as [PatientDocumentVo[], EncounterDiagramVo[]])
        )),
        map(([uploadResult, diagrams]) => {
          for (let index = 0; index < uploadResult.length; index++) {
            diagrams[index].Data = uploadResult[index].DocumentUrl;
          }
          return diagrams;
        }),
        switchMap(persistDiagrams => {
          if (this.uploadOnSave === false) {
            return forkJoin(
              persistDiagrams.map(diagram => this.saveDiagramLocally$(diagram))
            );
          } else {
            return of(null);
          }
        }),
        mapTo(this.shouldUploadOnComplete ? PatientWhiteboardDialogComponent.RESULT.UPLOADED : PatientWhiteboardDialogComponent.RESULT.DONE),
        untilDestroyed(this),
        catchError(err => {
          this.uploading$.next(false);
          console.error(err);
          this.snackbar.open('Failed to save drawing: ' + err, 'OK', { duration: 5000 });
          return of(null);
        }),
      ).subscribe(result => {
        if (result != null) {
          this.uploading$.next(false);
          this.dialogRef.close({ event: this.shouldUploadOnComplete ? PatientWhiteboardDialogComponent.RESULT.UPLOADED : PatientWhiteboardDialogComponent.RESULT.DONE });
        }
      });
    });
  }

  saveDiagramLocally$(diagram: EncounterDiagramVo) {
    return of(diagram).pipe(
      take(1),
      withLatestFrom(this.otherDiagrams$, this.saveDiagrams$),
      // Combine other diagrams with the diagram to save or remove it if it is now null
      map(([innerDiagram, otherDiagrams, saveDiagrams]) => {
        if (innerDiagram !== null) {
          saveDiagrams.push(innerDiagram);
        }
        return [...otherDiagrams, ...saveDiagrams] as EncounterDiagramVo[];
      }),
      withLatestFrom(this.params$),
      tap(([diagrams, params]) => {
        this.clinicalEncounterService.updateDiagramsToEncounter(params.patientId, diagrams)
      }),
      mapTo(PatientWhiteboardDialogComponent.RESULT.DONE),
    );
  }

  uploadDiagram$(diagram: EncounterDiagramVo): Observable<PatientDocumentVo> {
    return this.params$.pipe(
      take(1),
      switchMap(params => {
        const file = b64toBlob2(diagram.Data, 'image/png');
        const documentVo: PatientDocumentVo = {
          PracticeId: params.tenantId,
          PatientId: params.patientId,
          Description: diagram.Description,
          Category: diagram.Category,
          FileName: `${diagram.Description}.png`,
          CapturedDate: moment().toDate(),
          DocumentDate: moment().toDate(),
          FileSize: file.size,
          DocumentId: uuidV4(),
        };
        return this.patientsService.uploadPatientFile(params.tenantId, params.patientId, documentVo, file).pipe(
          map(response => response.document)
        );
      }),
      tap(() => this.appInsightsService.trackEvent(
        AppInsightTags.EVENT_DIAGRAM_UPLOADED, { referrer: this.type })
      ),
    );
  }

  checkCloseWithoutSave() {
    if (!this.isUnsavedChanges()) {
      this.dialogRef.close();
      return;
    }
    this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: {
        title: 'Clear diagram?',
        message: 'Are you sure you would like to close without saving?',
        actions: [defaultDialogActions.YES, defaultDialogActions.CANCEL]
      } as ConfirmDialogModel
    }).afterClosed().subscribe(result => result.key === DialogResponse.YES && this.dialogRef.close());
  }

  checkDeleteDiagram() {
    this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: {
        title: 'Delete diagram?',
        message: 'Are you sure you would like to delete this diagram?',
        actions: [defaultDialogActions.YES, defaultDialogActions.CANCEL]
      } as ConfirmDialogModel
    }).afterClosed().pipe(
      switchMap(result => result.key === DialogResponse.YES ? this.deleteDiagramAndClose$() : of(result)),
      untilDestroyed(this),
    ).subscribe();
  }

  deleteDiagramAndClose$(): Observable<any> {
    return this.params$.pipe(
      withLatestFrom(this.otherDiagrams$),
      tap(([params, diagrams]) => this.clinicalEncounterService.updateDiagramsToEncounter(params.patientId, diagrams)),
      tap(_ => this.dialogRef.close()),
    );
  }

  blobToDiagram$(blob: Blob): Observable<EncounterDiagramVo> {
    return this.blobToBase64$(blob).pipe(
      map(base64 => ({
        Description: 'Handwritten ' + this.type?.toLowerCase() + ' notes',
        Category: PatientWhiteboardDialogComponent.PatientWhiteboardCategory,
        Type: this.type,
        Data: base64
      } as EncounterDiagramVo)
      ));
  }

  blobToBase64$(blob: Blob): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = event => {
          observer.next((event.target as any).result);
          observer.complete();
          reader = null;
        };
      }
    });
  }
}
