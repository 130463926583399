<form [formGroup]="addFileForm" (submit)="saveFiles()">
  <h2 mat-dialog-title>Add myPatientFiles<mat-icon mat-dialog-close>clear</mat-icon>
  </h2>
  <mat-dialog-content>
    <h4>Details</h4>

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <input matInput placeholder="Description" formControlName="Description">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Category</mat-label>
      <!-- <input matInput placeholder="Category"> -->

      <mat-select formControlName="Category">
        <mat-option *ngFor="let category of patientCategories$ | async" [value]="category">
          {{ category }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Date</mat-label>
      <!-- <input matInput placeholder="Date" formControlName="DocumentDate"> -->
      <input matInput [matDatepicker]="picker" placeholder="Date" formControlName="DocumentDate" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <h4>Files</h4>

    <app-form-file-selection formControlName="Files">Add files</app-form-file-selection>

    <mat-list *ngIf="files$ | async as files">
      <mat-list-item *ngFor="let file of files">
        <mat-icon mat-list-icon>image</mat-icon>
        <h4>{{ file.name }}</h4>
        {{ file.size | fileSize }}
        {{ file.type }}
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>


  <mat-dialog-actions>
    <button mat-stroked-button color="primary" type="submit" [disabled]="!(validForm$ | async)">
      Save
    </button>

    <button mat-button color="warn" type="reset" mat-dialog-close>
      Cancel
    </button>
  </mat-dialog-actions>
</form>
