<div class="form-input" [ngStyle]="{ 'width': fieldWidth || '100%' }">
  <label>
    <div class="label" *ngIf="label" [ngStyle]="{ 'width': fieldWidth || '100%' }">{{ label }}<br /></div>
    <ng-content></ng-content>
  </label>
  <div class="optional" *ngIf="suppressOptional">Optional</div>
  <div class="errorMessage" *ngIf="showError" [@validationErrorAnimation] [ngStyle]="{ 'width': fieldWidth || '100%' }">
    {{ errorMessage }}
  </div>
</div>
