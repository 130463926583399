import { Observable, Subscriber, empty, throwError, timer, from, EMPTY } from 'rxjs';
import { finalize, filter, debounceTime, repeatWhen, retryWhen, mergeMap } from 'rxjs/operators';
import whenDomReady from 'when-dom-ready';
import { Injectable } from '@angular/core';

export enum SpeechControlErrors {
  NoSpeechRecognition = 'no-speech-recognition',
  Disabled = 'disabled'
}

export interface IOptions {
  recLanguage?: string;
  continuous?: boolean;
  interimResults?: boolean;
}

const Css = `
  #arly-ms {
    position: fixed;
    top: 0.75rem;
    left: 0.75rem;
    right: 0.75rem;
    z-index: 100000;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 1rem;
    background: rgba(0,0,0,0.7);
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.7);
    color: #fff;
    transition: opacity 0.3s;
    text-align: left;
  }
  #arly-ms.hidden {
    opacity: 0;
  }
  #arly-ms.gone {
    z-index: -1;
  }
  .arly-ms-text {
    flex: 1;
  }
  .arly-ms-rec {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    border-radius: 50%;
    background: #C91C2E;
    box-shadow: 0 0 5px 0 rgba(201,28,46,0.7);
  }
  .arly-ms-disable {
    color: #bbb;
    text
  }
`;

export const Html = (notificationText: string, disableText: string) => `
  <style>
    ${Css}
  </style>
  <div id="arly-ms">
    <span class="arly-ms-rec"></span>
    <span class="arly-ms-text">${notificationText}</span>
    <span class="arly-ms-disable">${disableText}</span>
  </div>
`;

export const append: (notificationOptions?: INotification) => Promise<INotificationResult> = (
  notificationOptions: INotification = {}
) => {
  return new Promise(resolve => {
    const notification = getNotification();
    if (!notification) {
      whenDomReady().then(() => {
        const language = (navigator as any).language || (navigator as any).userLanguage;
        const container: any = notificationOptions.container || document.body;
        const notificationText =
          notificationOptions.text || `I am listening for your search. Your language is ${language}`;
        const disableText = notificationOptions.disableText || `Disable`;
        container.insertAdjacentHTML('beforeend', Html(notificationText, disableText));
        resolve(notificationEvents(getNotification()));
      });
    } else {
      notification.classList.remove('hidden', 'gone');
      resolve(notificationEvents(notification));
    }
  });
};

export const remove = () => {
  const notification = getNotification();
  if (notification) {
    notification.classList.add('hidden');
    setTimeout(() => notification.classList.add('gone'), 500);
  }
};

@Injectable({
  providedIn: 'root',
})
export class SpeechRecognitionService {

  _recognition?: SpeechRecognition;
  _observable?: Observable<SpeechRecognitionEvent>;
  _stopped = false;
  _notificationShown = false;
  notification?: INotification;
  options: Partial<IOptions> = {
    interimResults: true,
    continuous: false,
    recLanguage: undefined,
  };

  constructor() {
  }

  _record(subscriber: Subscriber<SpeechRecognitionEvent>) {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    this._recognition = new SpeechRecognition();

    if (this._recognition) {
      this._recognition.interimResults = this.options.interimResults;
      this._recognition.continuous = this.options.continuous;
      this._recognition.maxAlternatives = 0;

      if (this.options?.recLanguage) {
        this._recognition.lang = this.options?.recLanguage;
      }

      this._recognition.onresult = subscriber.next.bind(subscriber);
      this._recognition.onspeechend = subscriber.next.bind(subscriber); // interim
      this._recognition.onend = subscriber.complete.bind(subscriber);
      this._recognition.onerror = subscriber.error.bind(subscriber);

      this._recognition.start();
    }
  }

  _disableRec() {
    window.sessionStorage.setItem('ARLY_DISABLE_REC', 'true');
    this.stop();
  }

  public askForPermission(): Observable<boolean> {
    return from(
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        // stop it immediately, its just used to trigger the permission
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        return true;
      }).catch(() => false)
    );
  }

  public whenPermissionGranted(): Observable<boolean> {
    if (!(navigator as any).permissions) {
      console.warn('SPEECH CONTROL: PERMISSIONS API IS NOT AVAILABLE, USING getUserMedia HERE');
      return this.askForPermission();
    }

    const handleState = (subscriber: Subscriber<boolean>, status: PermissionStatus) => {
      if (status.state === 'granted') {
        subscriber.next(true);
        subscriber.complete();
      } else if (status.state === 'prompt') {
        status.addEventListener('change', ({ target }) => {
          handleState(subscriber, target as PermissionStatus);
        });
      } else {
        subscriber.next(false);
      }
    };

    return new Observable(subscriber => {
      (navigator as any).permissions
        .query({ name: 'microphone' })
        .then((status: PermissionStatus) => handleState(subscriber, status));
    });
  }

  public isEnabled() {
    // check if not disabled and speech _recognition available
    return (
      !window.sessionStorage.getItem('ARLY_DISABLE_REC') &&
      (window.hasOwnProperty('SpeechRecognition') ||
        window.hasOwnProperty('webkitSpeechRecognition'))
    );
  }

  public setNotification(notification: INotification) {
    this.notification = notification;
  }

  public on(term: string): Observable<SpeechRecognitionEvent> {
    if (!this._observable) {
      this._observable = this.start().pipe(finalize(() => (this._observable = undefined)));
    }

    return this._observable.pipe(
      filter(event => {
        const item = event.results
          .item(event.results.length - 1)[0]
          .transcript.trim()
          .toLowerCase()
          .replace(/\s/g, ', ');

        return item.includes(term);
      })
    );
  }

  public start(notificationOptions?: INotification): Observable<SpeechRecognitionEvent> {
    this._stopped = false;
    return new Observable<SpeechRecognitionEvent>(subscriber => {
      if (this.isEnabled()) {
        this._record(subscriber);

        this.whenPermissionGranted().subscribe(() => {
          if (!this._notificationShown) {
            const notification = append(notificationOptions || this.notification);
            notification.then((nr: INotificationResult) =>
              nr.disable.then(() => {
                this._disableRec();
                subscriber.error(SpeechControlErrors.Disabled);
              })
            );

            setTimeout(remove, 3000);
            this._notificationShown = true;
          }
        });
      } else {
        subscriber.error(SpeechControlErrors.NoSpeechRecognition);
      }
    }).pipe(
      debounceTime(500),
      repeatWhen((complete: Observable<any>) => {
        return complete.pipe(
          mergeMap(() => {
            // repeat because continouse does not work on all mobile devices
            if (this._stopped) {
              return EMPTY;
            }
            return timer(500);
          })
        );
      }),
      retryWhen((error: Observable<any>) => {
        return error.pipe(
          mergeMap((e: any) => {
            console.log(e);
            // retry if noting said
            if (e?.error === 'no-speech') {
              return timer(500);
            }
            return throwError(error);
          })
        );
      })
    );
  }

  public stop() {
    remove();
    this._stopped = true;
    if (this._recognition) {
      this._recognition.stop();
    }
  }
}

function getNotification(): HTMLDivElement | null {
  return document.querySelector('#arly-ms');
}

function notificationEvents(notification: HTMLDivElement | null): INotificationResult {
  const disable = new Promise(resolve => {
    const disableSpan = notification && notification.querySelector('.arly-ms-disable');
    disableSpan?.addEventListener('click', resolve);
  });

  return {
    disable
  } as INotificationResult;
}

export interface INotificationResult {
  disable: Promise<any>;
}

export interface INotification {
  container?: HTMLElement | null;
  text?: string;
  disableText?: string;
}
