import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel, defaultDialogActions, DialogResponse } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { CanvasWhiteboardComponent } from 'ng2-canvas-whiteboard';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ColorSelection } from './color-picker-basic/color-picker-basic.component';
import { LineThicknessSelection } from './line-thickness-picker/line-thickness-picker.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as _ from 'lodash';
import { map, tap } from 'rxjs/operators';
import { DrawingComponentComponent } from './drawing-component/drawing-component.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DrawTemplateSelectorComponent } from './draw-template-selector/draw-template-selector.component';
import { AppInsightTags, ApplicationInsightsService } from '@app/core/services/application-insights.service';
import { ProviderService } from '@app/core/services';

/**
 * This component should never be called directly. Rather, call using the app-patient-whiteboard-dialog.component.
 */
@Component({
  selector: 'app-patient-whiteboard',
  templateUrl: './patient-whiteboard.component.html',
  styleUrls: ['./patient-whiteboard.component.scss'],
  viewProviders: [CanvasWhiteboardComponent],
})
export class PatientWhiteboardComponent implements OnInit, OnDestroy {
  @ViewChild('drawTemplateSelector') drawTemplateSelector: DrawTemplateSelectorComponent;
  @ViewChildren('drawingComponent') drawingComponents: QueryList<DrawingComponentComponent>

  @Output() save: EventEmitter<boolean> = new EventEmitter();
  @Output() delete: EventEmitter<boolean> = new EventEmitter();
  @Input() uploadOnSave = false;
  @Input() preloadedImages: string[];

  savingImage: boolean = false;

  isLite$ = this.providerService.isLite$;
  currentTenantId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  textAnnotationEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedDrawColor$: BehaviorSubject<ColorSelection> = new BehaviorSubject<ColorSelection>(null);
  selectedLineWidth$: BehaviorSubject<LineThicknessSelection> = new BehaviorSubject<LineThicknessSelection>(null);
  selectedTabIndex$: BehaviorSubject<Number> = new BehaviorSubject<Number>(0);
  selectedTemplates$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  previousSelectedTemplateCount: Number = 0;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PatientWhiteboardComponent>,
    private appInsightsService: ApplicationInsightsService,
    private providerService: ProviderService,
  ) { }

  ngOnInit() {
    combineLatest([this.selectedTemplates$, this.selectedTabIndex$]).pipe(
      tap(([selectedTemplates, index]) => {
        const drawingComponent: DrawingComponentComponent = this.getDrawingComponent(index);
        drawingComponent?.resizeCanvas();
      }),
      untilDestroyed(this)
    ).subscribe();
    this.providerService.provider$.pipe(
      tap(provider => this.currentTenantId$.next(provider.PracticeId))
    ).subscribe();
  }

  ngOnDestroy(): void { }

  ngAfterViewInit() {
    // Set the first blank tab component size
    this.selectedTabIndex$.next(0);
  }

  hasUnsavedChanges(): boolean {
    let hasUnsavedChanges: boolean = false;
    this.drawingComponents.forEach(item => {
      hasUnsavedChanges = hasUnsavedChanges || item.hasChanges();
    });
    return hasUnsavedChanges;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (this.savingImage === false) {
      this.goToTabIndex(tabChangeEvent.index);
    }
  }

  goToTabIndex(index) {
    if (this.selectedTabIndex$.value !== index) {
      this.getCurrentDrawingComponent()?.finaliseDrawing();
    }
    this.selectedTabIndex$.next(index);
  }

  undoDraw() {
    this.getCurrentDrawingComponent()?.undoDraw();
  }

  redoDraw() {
    this.getCurrentDrawingComponent()?.redoDraw();
  }

  selectPenDrawing() {
    this.textAnnotationEnabled$.next(false);
  }

  selectTextDrawing() {
    this.textAnnotationEnabled$.next(true);
  }

  onColorChange(colorSelection: ColorSelection) {
    this.selectedDrawColor$.next(colorSelection);
  }

  onLineThicknessChanged(selectedLineThickness: LineThicknessSelection) {
    this.selectedLineWidth$.next(selectedLineThickness);
  }

  deleteDrawTemplate(templateId: string) {
    const drawComponent = this.getDrawingComponentByTemplateId(templateId);
    if (!!drawComponent) {
      this.deSelectDrawComponentTemplate(drawComponent);
    }
  }

  deleteDrawComponent() {
    const drawComponent = this.getCurrentDrawingComponent();
    if (!!drawComponent) {
      this.deSelectDrawComponentTemplate(drawComponent);
    }
  }

  saveDiagram() {
    this.textAnnotationEnabled$.next(false);
    this.save.emit(true);
  }

  clearCanvas() {
    const hasChanges = this.getCurrentDrawingComponent()?.hasChanges() ?? false;
    if (hasChanges === true) {
      this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: {
          title: 'Clear diagram?',
          message: 'Are you sure you would like to clear your diagram?'
        } as ConfirmDialogModel
      }).afterClosed().subscribe(result => {
        if (result.key === DialogResponse.YES) {
          this.getCurrentDrawingComponent().clearCanvas();
        }
      });
    }
  }

  onSelectedTemplatesChanged($event) {
    this.selectedTemplates$.next($event.DrawTemplates);
  }

  saveCanvasToBlob(saveBlobCallBack) {
    const blobImages: Blob[] = new Array<Blob>();
    this.savingImage = true;
    this.saveDrawComponentToImage(0, (blob: Blob) => blobImages.push(blob), () => saveBlobCallBack(blobImages));
  }

  saveDrawComponentToImage(index, addBlobCallback: any, allComponentSavedCallback) {
    if (this.getDrawingComponent(index).hasChanges()) {
      if (this.selectedTabIndex$.value !== index) {
        this.goToTabIndex(index);
      }
      setTimeout(() => {
        const drawComponent = this.getCurrentDrawingComponent();
        drawComponent.saveCanvasToBlob((blob: Blob) => {

          addBlobCallback(blob);

          this.appInsightsService.trackEvent(AppInsightTags.STYLUS_TEMPLATE_ASSIGN, {
            TemplateName: drawComponent.drawTemplate.TemplateName,
            Size: `${blob.size} bytes`,
            TemplateId: drawComponent.drawTemplate.TemplateId,
            tenantId: this.currentTenantId$.value,
          });

          if ((index + 1) < this.drawingComponents.toArray().length) {
            this.saveDrawComponentToImage(index + 1, addBlobCallback, allComponentSavedCallback);
          }
          else {
            allComponentSavedCallback();
          }
        })
      }, 500);
    }
    else {
      if ((index + 1) < this.drawingComponents.toArray().length) {
        this.saveDrawComponentToImage(index + 1, addBlobCallback, allComponentSavedCallback);
      }
      else {
        allComponentSavedCallback();
      }
    }
  }

  deSelectDrawComponentTemplate(drawComponent: DrawingComponentComponent) {
    if (drawComponent.hasChanges() === true) {
      this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: {
          title: 'Remove diagram?',
          message: 'Are you sure you want to remove this diagram? All changes will be lost.',
          actions: [
            { key: DialogResponse.CANCEL, value: 'Cancel', color: 'secondary' },
            { key: DialogResponse.YES, value: 'Remove', color: 'primary' }
          ],
        } as ConfirmDialogModel
      }).afterClosed().subscribe(result => {
        if (result.key === DialogResponse.YES) {
          this.drawTemplateSelector.removeTemplateFromSelectedTemplates(drawComponent.drawTemplate.TemplateId);
        }
      });
    }
    else {
      this.drawTemplateSelector.removeTemplateFromSelectedTemplates(drawComponent.drawTemplate.TemplateId);
    }
  }

  cancelClick() {
    if (this.hasUnsavedChanges()) {
      this.dialog.open(ConfirmDialogComponent, {
        width: '450px',
        data: {
          title: 'Exit diagram?',
          message: 'Are you sure you want to cancel the diagram?'
        } as ConfirmDialogModel
      }).afterClosed().subscribe(result => {
        if (result.key === DialogResponse.YES) {
          this.dialogRef.close(null);
        }
      });
    }
    else {
      this.dialogRef.close(null);
    }
  }

  getCurrentDrawingComponent(): DrawingComponentComponent {
    const index = this.selectedTabIndex$.value;
    return this.getDrawingComponent(index);
  }

  getDrawingComponent(index): DrawingComponentComponent {
    const drawingComponent: DrawingComponentComponent = this.drawingComponents?.toArray()[index];
    return drawingComponent;
  }

  getDrawingComponentByTemplateId(templateId: string): DrawingComponentComponent {
    const component = this.drawingComponents?.toArray().find(c => c.drawTemplate?.TemplateId === templateId) ?? null;
    return component;
  }

  toggleTemplateVisibility() {
    this.drawTemplateSelector.toggleDisplay();
  }

}
