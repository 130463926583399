import { Component, OnInit, Inject, AfterViewChecked, OnDestroy, Optional, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ReferenceDataService } from '@app/core/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { distinctUntilChanged, map, tap, startWith, debounceTime } from 'rxjs/operators';
import { distinctUntilArrayItemChanged } from '@datorama/akita';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, Observable, combineLatest } from 'rxjs';
import { formatDate } from '@angular/common';

export interface AddFilesDialogData {
  Files: File[];
  Blobs: Blob[];
}

export interface AddFilesReturnData {
  Files: File[];
  Description: string;
  Category: string;
  Name: string;
  CapturedDate: Date;
  DocumentDate?: Date;
  CreatedBy?: string;
  Notes: string;
  AdditionalData?: { [key: string]: string; };
}

@Component({
selector: 'app-add-file-dialog',
  templateUrl: './add-file-dialog.component.html',
  styleUrls: ['./add-file-dialog.component.scss']
})
export class AddFileDialogComponent implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit {

  patientCategories$: Observable<string[]> = this.referenceData.myPatientFilesCategories$.pipe(
    map(categories => categories.patientCategories)
  );

  addFileForm = this.fb.group({
    Files: [this.data.Files],
    Description: ['', Validators.required],
    Category: ['General', Validators.required],
    Name: [''],
    CapturedDate: [new Date()],
    DocumentDate: [new Date()],
    CreatedBy: [''],
    Notes: [''],
    AdditionalData: [''],
  });

  files$ = this.addFileForm.get('Files').valueChanges
  .pipe(
    map(f => f as File[]),
    startWith(this.data.Files),
    distinctUntilChanged(),
    untilDestroyed(this),
  );

  validForm$ =
    combineLatest([
      this.addFileForm.statusChanges,
      this.files$
    ])
  .pipe(
    debounceTime(50),
    map(([ formState, files ]) => {
      // return formState
      return formState === 'VALID' && files && files.length >= 0;
    }),
  );

  constructor(
    private fb: FormBuilder,
    private referenceData: ReferenceDataService,
    private dialogRef: MatDialogRef<AddFileDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: AddFilesDialogData,
    ) {
    }

  ngOnInit() {
  }

  uniqueName() {

  }

  ngAfterViewChecked(): void {
  }

  ngAfterViewInit() {
    if (this.data) {
      const blobsToFiles = (this.data.Blobs || [])
        .map(b => b as any)
        .map(f => {
          f.lastModifiedDate = new Date();
          f.name = `Picture ${formatDate(new Date(), 'yyyy/MM/dd', 'en')}`;
          return f as File;
        });
      const allFiles = [ ...(this.data.Files || []), ...blobsToFiles];

      setTimeout(() =>
        this.addFileForm.patchValue({
          Files: allFiles
        })
      );
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveFiles() {
    this.dialogRef.close(this.addFileForm.value as AddFilesReturnData);
  }

  ngOnDestroy(): void { }

}
