import { ReferenceDataStore } from './state/reference-data/reference-data.store';
import { Injectable } from '@angular/core';
import { ReferenceDataQuery } from './state/reference-data/reference-data.query';
import { shareReplay, switchMap, map } from 'rxjs/operators';
import {
  PatientClient,
  SymptomQuestion,
  QuestionBase,
  PatientFilterCriteria,
  EncounterPlanOutcomeVo,
  AllergenVo,
} from './api-client.service';
import { AuthQuery } from './state/auth/auth.query';
import { HttpClient } from '@angular/common/http';
import { AlgoliaRestService } from './algolia-rest.service';
import { LimitAvailable } from './view-models/patient-financials-view-model';
import { Observable } from 'rxjs';
import {
  ChartDefaultData,
} from '@app/modules/patient/patient-vitals/vitals-growth-chart/vitals-growth-chart.component';
import { VERSION } from '@env/version';
import { MerakiClientService } from './meraki-client.service';
import { GenericMedicine } from './view-models/generic-medicine-view-model.utils';
import * as _ from 'lodash';

export const ProviderOptionEnabledForHBClinical = 'IsHealthbridgeClinicalEnabled';
export const ProviderOptionEnabledForHBClinicalLite = 'IsHealthbridgeClinicalLiteEnabled';

export const PatientIdentityNoRegex = /^([0-9][0-9])([0][1-9]|[1][0-2])(0[1-9]|[12][0-9]|3[01])([0-9])([0-9]{3})([0|1])([0-9])([0-9])$/;
export const AccountNoRegex = /^[a-zA-Z0-9\\W\s]+[\\s_/\\-a-zA-Z0-9]*$/;
export const ContactNoRegex = /^[0-9]*$/;
export const MedDepCodeRegex = /^[0-9]*$/;

export const WaitingRoomStatusEnum: { [key: number]: string } = {
  0: 'Scheduled',
  1: 'Checked In',
  2: 'In Progress',
  3: 'Completed',
  4: 'Canceled',
  5: 'Deleted',
};

export interface MyPatientFilesCategories {
  patientCategories: string[];
  practiceCategories: string[];
}

export interface IClinicalMetricInfo {
  name: string;
  type: string;
  vital: boolean;
}

export interface ISurgery {
  specialities: string[] | null;
  specialitiesExcluded?: string[] | null;
  name: string;
  common: number | 0;
}

export interface Language {
  key: string;
  language: string;
}

export interface IClinicalMetricValue extends IClinicalMetricInfo {
  value: string;
  valueUnit: string;
  valueFlag: string;
  date: Date;
}

export interface ISeverityMetric {
  name: string;
  severity?: string[] | null;
}

export interface IMetricSchema {
  groupName: string;
  groupTitle: string;
  isCollapsible: boolean;
  isCollapsed: boolean;
  className: string;
  items: IMetricSchemaItem[];
}

export interface IMetricSchemaItem {
  vital?: boolean;
  type: string;
  uom: string;
  name: string;
  id: string;
  min: number;
  max: number;
  maxlength: number;
  mask: string;
  options: string[];
  required: boolean;
  validationMessage: any;
  value: string;
  oldValue: string;
  valueFlag: string;
  oldValueFlag: string;
  oldValueDate: Date;
  placeholder: string;
  step: number;
  checked: boolean;
  readonly?: boolean;
  gender?: string;
  usage?: string[];
  captured?: boolean;
}

export interface PlaceOfService {
  name: string;
  code: string;
}

export enum EnounterLineType {
  Procedure = 'Procedure',
  Consumable = 'Consumable',
  Medicine = 'Medicine',
  Modifier = 'Modifier',
}

export enum BenefitCheckStatus {
  FullyCovered = 'Fully covered',
  PartiallyCovered = 'Partially covered',
  NotCovered = 'Not covered',
  Rejected = 'Rejected',
  AwaitingResponse = 'Awaiting response',
  // Rejected = 'Rejected',
}

export enum PatientGenericInformationCategories {
  Gynae = 'Gynae',
}

export interface Lifestyle {
  category: string;
  name: string;
  label: string;
  ordinal: number;
  control: Control;
  severity?: Control;
}

export interface Control {
  type: string;
  placeholder: string;
  options: string[];
}

export interface UiColor {
  Name: string;
  Hex: string;
  RGB?: string;
  ClassName: string;
}

export enum MetricUsages {
  all = '*',
  metric = 'metric',
  screening = 'screening',
  commonScreening = 'commonScreening',
  customMetrics = 'customMetrics',
}

export interface NavigationItem {
  id: string;
  title: string;
  url?: string;
  openInNewTab?: boolean;
  fragment?: string;
  type: 'item' | 'group';
  classes?: string;
  hidden?: boolean;
  componentRef?: any;
  function?: any;
  children?: NavigationItem[];
}

export interface SymptomConfiguration {
  SymptomId?: string | null;
  ExcludedConsultSections?: string[];
  ExcludedFromConsultSection?: string[];
  Filter?: PatientFilterCriteria;
  Container?: string;
}


export enum EncounterTypes {
  MedicalInsurance = 'MedicalInsurance',
  MedicalAidInvoice = 'MedicalAidInvoice',
  CashInvoice = 'CashInvoice',
  NoChargeInvoice = 'NoChargeInvoice'
}

export enum MedicineTypes {
  Prescribe = 'Prescribe',
  Dispense = 'Dispense',
  Consumable = 'Consumable',
}

export enum PlanOutcomeTypes {
  PathRequest = 'path',
  FollowUp = 'follow-up',
  Referred = 'referred',
  Ultrasound = 'ultrasound',
  Radiology = 'radiology',
  AdmitToHospital = 'hospital-admit',
  DischargeFromHospital = 'hospital-discharge',
}

export enum ConsultationQuestionStore {
  Consultation = 'Consultation',
  PatientGenericInformation = 'PatientGenericInformation'
}

export enum CommunicationTemplateType {
  CustomLetter = 'custom-letter',
  PlanSMS = 'plan-sms',
  PathologySMS = 'pathology-sms'
}

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataService {
  public readonly allergyCategories = [
    { Category: 'Medication', Order: 0 },
    { Category: 'Non-Medication', Order: 1 },
    { Category: 'Food', Order: 2 },
  ];

  public readonly compositeMetrics = ['Blood glucose test', 'Total Cholesterol test'];

  public readonly surgeryStatus = {
    default: 'SurgeryUnderwent',
  };

  // Changing the order of these codes will require refactoring components that reference this array.
  covidCodes = ['U07.1', 'U07.2'];
  allTrackingConditionCodes = [...this.covidCodes];
  availableHomeTabs = [
    {
      Index: 0,
      Screen: 'schedule',
    },
    {
      Index: 1,
      Screen: 'tracker',
    },
    {
      Index: 2,
      Screen: 'history',
    },
    {
      Index: 3,
      Screen: 'path',
    }];
  availableConsultationScreens = [
    {
      Index: 0,
      Screens: ['notes'],
    },
    {
      Index: 1,
      Screens: ['symptoms'],
    },
    {
      Index: 2,
      Screens: ['examination'],
    },
    {
      Index: 3,
      Screens: ['templates', 'diagnosis', 'medicine', 'procedure', 'consumable', 'review'],
    },
    {
      Index: 4,
      Screens: ['plan'],
    },
  ];

  metricPattern = '^(\\+{1,4}|\\d+)((\\.|\\,)\\d{1,4})?$';

  readonly symptomQuestions$ = this.httpClient
    .get<SymptomQuestion[]>(`/assets/reference-data/symptoms/symptom-questions.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  readonly symptoms$ = this.merakiClient.getVisitReasons().pipe(shareReplay(1));

  //TODO: Firestore migration moved this into single document. Find a way to do this in a better
  readonly symptomsConfig$: Observable<SymptomConfiguration[]> = this.merakiClient.getVisitReasons().pipe(shareReplay(1));

  readonly questions$ = this.httpClient
    .get<QuestionBase[]>(`/assets/reference-data/symptoms/questions.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  /**************** MEDICAL AIDS & HEALTHID ****************/

  readonly medicalAids$ = this.authQuery.currentTenantId$.pipe(
    switchMap(currentTenantId => this.patientClient.getAllMedicalAids(currentTenantId)),
    map(medicalAids => _.chain(medicalAids).orderBy(s => s.Name).value()),
    shareReplay(1),
  );

  private readonly flattenedMedicalAids$ = this.medicalAids$.pipe(
    map(medicalAids =>
      medicalAids.flatMap(scheme =>
        scheme.Plans.flatMap(plan =>
          plan.Options.flatMap(option => ({
            SchemeName: scheme.Name,
            SchemeCode: scheme.SchemeCode,

            PlanName: plan.Name,
            PlanCode: plan.PlanCode,

            OptionName: option.Name,
            OptionCode: option.OptionCode,
            RoutingCode: option.RoutingCode,
            PVEnabled: option.RoutingCode,
          })),
        ),
      ),
    ),
    shareReplay(1),
  );

  readonly healthIDSSupportedSchemes$ = this.httpClient
    .get<string[]>(`/assets/reference-data/healthid-schemes.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  readonly tarrifMedicalTypeCodes$ = this.httpClient
    .get<{ SpecialityCode: number, Description: string, Type: string }[]>(`/assets/reference-data/medical_type.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  public readonly dosageNumbersOptions = [0.25, 1, 0.5, 2, 3, 4, 5, 10, 20];
  public readonly siUnitsOptions = ['μg / mcg', 'mg', 'g', 'ml'];

  public readonly routeOptions = [
    { description: 'orally', value: 'PO' },
    { description: 'per the rectum', value: 'PR' },
    { description: 'per the vagina', value: 'PV' },
    { description: 'under tongue', value: 'SL' },
    { description: 'into muscle', value: 'IM' },
    { description: 'under skin', value: 'SC' },
  ];

  public readonly routeOptionsPerCategory = [
    {
      category: 'Administration',
      options: [
        { description: 'PO (orally)', value: 'PO' },
        { description: 'PR (per rectum)', value: 'PR' },
        { description: 'PV (per vagina)', value: 'PV' },
        { description: 'SL (under tongue)', value: 'SL' },
      ],
    },
    {
      category: 'Injection',
      options: [
        { description: 'IM (into muscle)', value: 'IM' },
        { description: 'SC (under skin)', value: 'SC' },
      ],
    },
    {
      category: 'Ear',
      options: [
        { description: 'Left ear', value: 'Left ear' },
        { description: 'Right ear', value: 'Right ear' },
        { description: 'Both ears', value: 'Both ears' },
      ],
    },
    {
      category: 'Eye',
      options: [
        { description: 'Left eye', value: 'Left eye' },
        { description: 'Right eye', value: 'Right eye' },
        { description: 'Both eyes', value: 'Both eyes' },
      ],
    },
    {
      category: 'Nose',
      options: [{ description: 'In nose', value: 'In nose' }],
    },
  ];

  public readonly frequencyOptions = [
    {
      order: 0,
      category: 'Per day',
      periodType: 'day',
      options: [
        { display: 'Q1D (1 x per day)', FrequencyUnits: 1, PeriodUnits: 1, PeriodType: 'day' },
        { display: 'BD (2 x per day)', FrequencyUnits: 2, PeriodUnits: 1, PeriodType: 'day' },
        { display: 'TDS (3 x per day)', FrequencyUnits: 3, PeriodUnits: 1, PeriodType: 'day' },
        { display: 'QID (4 x per day)', FrequencyUnits: 4, PeriodUnits: 1, PeriodType: 'day' },
      ],
    },
    {
      order: 2,
      category: 'Hours',
      periodType: 'hour',
      options: [
        { display: 'Q4h (every 4 hours)', FrequencyUnits: 1, PeriodUnits: 4, PeriodType: 'hour' },
        { display: 'Q6H (every 6 hours)', FrequencyUnits: 1, PeriodUnits: 6, PeriodType: 'hour' },
        { display: 'Q8H (every 8 hours)', FrequencyUnits: 1, PeriodUnits: 8, PeriodType: 'hour' },
        { display: 'Q12H (every 12 hours)', FrequencyUnits: 1, PeriodUnits: 12, PeriodType: 'hour' },
      ],
    },
    {
      order: 1,
      category: 'Weeks',
      periodType: 'week',
      options: [
        { display: 'Once weekly', FrequencyUnits: 1, PeriodUnits: 1, PeriodType: 'week' },
        { display: 'Twice weekly', FrequencyUnits: 2, PeriodUnits: 1, PeriodType: 'week' },
        { display: 'Thrice weekly', FrequencyUnits: 3, PeriodUnits: 1, PeriodType: 'week' },
      ],
    },
    {
      order: 3,
      category: 'Month',
      periodType: 'month',
      options: [{ display: 'Monthly', FrequencyUnits: 1, PeriodUnits: 1, PeriodType: 'month' }],
    },
  ].sort((a, b) => a.order - b.order);

  public readonly durationUnitOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public readonly durationUnitTypeOptions = ['days', 'weeks', 'months'];

  public readonly repeatOptions = [0, 1, 2, 3, 4, 5, 6];

  public readonly additionalOptionsFull = [
    { label: 'A.M.', meaning: 'morning' },
    { label: 'P.M.', meaning: 'evening' },
    { label: 'ac', meaning: 'before meals' },
    { label: 'pc', meaning: 'after meals' },
    { label: 'mane', meaning: 'in the morning' },
    { label: 'ap', meaning: 'before dinner' },
    { label: 'BT', meaning: 'bedtime' },
    { label: 'achs', meaning: 'before meals and at bedtime' },
    { label: 'qn', meaning: 'nightly or at bedtime' },
    { label: 'noct', meaning: 'in the night' },
    { label: 'PRN', meaning: 'PRN' },
    { label: 'QOD', meaning: 'every other day' },
    { label: 's.o.s', meaning: 'if necessary' },
    { label: 'STAT', meaning: 'STAT' },
    { label: 'Allow generics', meaning: 'Allow generics' },
    { label: 'No generics', meaning: 'No generics' },
    { label: 'q.p.m', meaning: 'in the afternoon' },
  ];

  public readonly additionalOptionsFullGenerics = [
    { label: 'A.M.', meaning: 'morning' },
    { label: 'P.M.', meaning: 'evening' },
    { label: 'ac', meaning: 'before meals' },
    { label: 'pc', meaning: 'after meals' },
    { label: 'mane', meaning: 'in the morning' },
    { label: 'ap', meaning: 'before dinner' },
    { label: 'BT', meaning: 'bedtime' },
    { label: 'achs', meaning: 'before meals and at bedtime' },
    { label: 'qn', meaning: 'nightly or at bedtime' },
    { label: 'noct', meaning: 'in the night' },
    { label: 'PRN', meaning: 'PRN' },
    { label: 'QOD', meaning: 'every other day' },
    { label: 's.o.s', meaning: 'if necessary' },
    { label: 'STAT', meaning: 'STAT' },
    { label: 'Allow for substitutes', meaning: 'Allow for substitutes' },
    { label: 'No substitutes', meaning: 'No substitutes' },
    { label: 'q.p.m', meaning: 'in the afternoon' },
  ];

  /**************** OTHER ****************/

  readonly languages$ = this.httpClient.get<Language[]>(`/assets/reference-data/languages.json?v=${VERSION.version}`).pipe(shareReplay(1));

  readonly maritalStatuses$ = this.httpClient
    .get<string[]>(`/assets/reference-data/marital-statuses.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  readonly surgeries$ = this.httpClient.get<ISurgery[]>(`/assets/reference-data/surgeries.json?v=${VERSION.version}`).pipe(shareReplay(1));

  readonly clinicalMetrics$ = this.httpClient
    .get<IMetricSchema[]>(`/assets/reference-data/metrics.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  readonly lifestyle$ = this.httpClient
    .get<QuestionBase[]>(`/assets/reference-data/lifestyle.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  readonly familyHistory$ = this.httpClient
    .get<QuestionBase[]>(`/assets/reference-data/family-history.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  //getVisitReasonQuestions

  readonly womenHealthQuestions$ = this.merakiClient.getVisitReasonQuestions('womens-health').pipe(
    map(data => data.map(m => m.Question)),
    shareReplay(1));

  // this.httpClient
  //   .get<QuestionBase[]>(`/assets/reference-data/women-health.json?v=${VERSION.version}`)
  //   .pipe(shareReplay(1));

  public PlaceOfServiceCodes: Array<PlaceOfService> = [
    { name: 'Consulting Rooms', code: '11' },
    { name: 'Inpatient Hospital', code: '21' },
    { name: 'Day Clinic', code: '24' },
    { name: 'Telehealth', code: '02' },
  ];
  countries$ = this.merakiClient.getCountries().pipe(shareReplay(1));

  public readonly planOutcomes = [
    { Title: 'Path request', Type: PlanOutcomeTypes.PathRequest },
    { Title: 'Follow-up visit', Type: PlanOutcomeTypes.FollowUp },
    { Title: 'Referred', Type: PlanOutcomeTypes.Referred },
    { Title: 'Ultrasound', Type: PlanOutcomeTypes.Ultrasound },
    { Title: 'Radiology', Type: PlanOutcomeTypes.Radiology },
    { Title: 'Admitted to hospital', Type: PlanOutcomeTypes.AdmitToHospital },
    { Title: 'Discharged from hospital', Type: PlanOutcomeTypes.DischargeFromHospital },
  ] as EncounterPlanOutcomeVo[];

  readonly myPatientFilesCategories$ = this.httpClient
    .get<MyPatientFilesCategories>(`/assets/reference-data/mypatientfiles-categories.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  readonly proceduresNotTelehealtSpecific = ['0130', 'VCONM', 'VCOMS', 'VCOMSC'];

  readonly peadsMeasurementMetricNames = ['Weight', 'Height', 'Head-circumference'];

  readonly randomColors$ = this.httpClient
    .get<UiColor[]>(`/assets/reference-data/random-colors.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  /**
   * Metrics read from metrics.json that must be displayed in the General Screening table by default
   */
  readonly screeningMetrics$: Observable<IMetricSchemaItem[]> = this.httpClient
    .get<IMetricSchema[]>(`/assets/reference-data/metrics.json?v=${VERSION.version}`)
    .pipe(
      map(schemas => {
        const Items = schemas
          .flatMap(s => s.items)
          .filter(item => item.usage?.some(usage => usage === MetricUsages.all || usage === MetricUsages.screening));

        return Items as IMetricSchemaItem[];
      }),
      shareReplay(1),
    );

  /**
   * Metrics read from metrics.json that can be added to the General Screening table but are not displayed by default.
   */
  readonly screeningCommonMetrics$: Observable<IMetricSchemaItem[]> = this.httpClient
    .get<IMetricSchema[]>(`/assets/reference-data/metrics.json?v=${VERSION.version}`)
    .pipe(
      map(schemas => {
        const Items = schemas.flatMap(s => s.items).filter(item => item.usage?.some(usage => usage === MetricUsages.commonScreening));

        return Items as IMetricSchemaItem[];
      }),
      shareReplay(1),
    );

  readonly allergens$ = this.httpClient
    .get<AllergenVo[]>(`/assets/reference-data/allergens.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  readonly genericMedicines$ = this.httpClient
    .get<GenericMedicine>(`/assets/reference-data/generic-medicine.json?v=${VERSION.version}`)
    .pipe(shareReplay(1));

  public readonly SpecialityCodesForEnhancedHospitalConsultReasons = [
    '032', //Paediatrician
  ];

  // note: keep in mind to update server side if add new values
  public readonly SpecialPlaceholders = [
    { text: 'Patient title', value: '#PatientTitle' },
    { text: 'Patient fullname', value: '#PatientFullname' },
    { text: 'Patient first name', value: '#PatientFirstname' },
    { text: 'Patient surname', value: '#PatientSurname' },
    { text: 'Patient ID number', value: '#PatientIdentityNo' },
    { text: 'Patient email address', value: '#PatientEmailAddress' },
    { text: 'Patient mobile number', value: '#PatientMobileNumber' },
    { text: 'Membership number', value: '#MembershipNumber' },
    { text: 'Account Number', value: '#AccountNo' },
    { text: 'Medical aid name', value: '#MedicalAidName' },
    { text: 'Medical aid plan', value: '#MedicalAidPlan' },
    { text: 'Medical aid option', value: '#MedicalAidOption' },
    { text: 'Patient allergies', value: '#PatientAllergies' },
    { text: 'ICD10 code(s)', value: '#ICD10Codes' },
    { text: 'Practice name', value: '#PracticeName' },
    { text: 'Practice office number', value: '#PracticeOfficeNumber' },
    { text: 'Practice mobile number', value: '#PracticeMobileNumber' },
    { text: 'Provider name', value: '#ProviderName' },
    { text: 'Provider signature', value: '#ProviderSignature' },
    { text: 'Report date', value: '#ReportDate' },
  ];


  /**************** METHODS ****************/

  constructor(
    private patientClient: PatientClient,
    private store: ReferenceDataStore,
    private query: ReferenceDataQuery,
    private authQuery: AuthQuery,
    private httpClient: HttpClient,
    private algoliaRestService: AlgoliaRestService,
    private merakiClient: MerakiClientService,
  ) {
  }

  getSymptomQuestions(symptomId) {
    return this.httpClient
      .get<SymptomQuestion[]>(`/assets/reference-data/symptoms/${symptomId}-symptom-questions.json?v=${VERSION.version}`)
      .pipe(shareReplay(1));
  }

  getQuestionsBySymptom(symptomId) {
    return this.httpClient
      .get<QuestionBase[]>(`/assets/reference-data/symptoms/${symptomId}-questions.json?v=${VERSION.version}`)
      .pipe(shareReplay(1));
  }

  getQuestions() {
    return this.httpClient.get<QuestionBase[]>(`/assets/reference-data/symptoms/questions.json?v=${VERSION.version}`).pipe(shareReplay(1));
  }

  getCountries() {
    return this.merakiClient.getCountries();
  }

  getPaedsData(metric: string, gender: string) {
    return this.httpClient
      .get<any[]>(`/assets/reference-data/paeds/${gender.toLowerCase()}/${metric.toLowerCase()}.json?v=${VERSION.version}`)
      .pipe(map(data => this.paedsToDefault(data)));
  }

  paedsToDefault(data: any[]): ChartDefaultData[] {
    if ('Month' in data[0]) {
      return data.map(
        point =>
        ({
          ...point,
          XValue: point.Month,
        } as ChartDefaultData),
      );
    }

    if ('Length' in data[0]) {
      return data.map(
        point =>
        ({
          ...point,
          XValue: point.Length,
        } as ChartDefaultData),
      );
    }

    if ('Day' in data[0]) {
      const result: ChartDefaultData[] = [];
      for (let index = 0; index < 60; index++) {
        const dataDay = data[index * 30];
        result.push({
          ...dataDay,
          XValue: index,
        } as ChartDefaultData);
      }
      return result;
    }
    return [];
  }

  getMedicineCategories() {
    return this.algoliaRestService.getAlgoliaMedicineCategories().pipe(shareReplay(1));
  }

  limitType(limit: LimitAvailable) {
    switch (limit.Limit) {
      case 'GP_VISIT15':
        return 'GP visit/s';

      case 'AFT_VISIT':
        return 'After-hours consultation/s';

      case 'PATH_VISIT':
        return 'Pathology visit/s';

      case 'RAD_VISIT':
        return 'Radiology visit/s';

      case 'GP_VISIT':
        return 'Out-of-network visit/s';

      case 'PHAR_VISIT':
        return 'Pharmacy visit/s';

      default:
        return limit.LimitDescription;
    }
  }

  filterPatientCriteriaByAge(f: QuestionBase, age: number): boolean {
    return !f.Filter || (f.Filter?.Older && f.Filter?.Older < age) || (f.Filter?.Younger && f.Filter?.Younger > age);
  }
}
