import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientsService } from '@app/core/services';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface PatientSaveEmailInputData {
  emailAddressOptions: string[],
  patientId: string,
  tenantId: string,
}

@Component({
  selector: 'app-patient-save-email-dialog',
  templateUrl: './patient-save-email-dialog.component.html',
  styleUrls: ['./patient-save-email-dialog.component.scss']
})
export class PatientSaveEmailDialogComponent implements OnInit {

  form: FormGroup = this.fb.group({
    patientEmailAddress: ['', Validators.required],
  });

  emailAddressOptions$ = new BehaviorSubject<string[]>(null);

  constructor(
    private patientsService: PatientsService,
    @Inject(MAT_DIALOG_DATA) public data: PatientSaveEmailInputData,
    public dialogRef: MatDialogRef<PatientSaveEmailDialogComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.emailAddressOptions$.next(this.data.emailAddressOptions);
  }

  saveEmailAddress(form, saveButton) {
    saveButton.disabled = true;
    this.patientsService.updatePatient(this.data.tenantId, { PatientId: this.data.patientId, PatientDetails: { EmailAddress: form.controls.patientEmailAddress.value } })
      .pipe(
        catchError(() => of({ Sucess: false })),
        tap(result => {
          const snackMessage = result.Sucess ? "Patient email has been updated" : "Patient email could not be updated";
          this.snackbar.open(snackMessage, 'OK', { duration: 10000 });
          this.dialogRef.close();
        })
      ).subscribe();
  }

}
