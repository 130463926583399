import { NgModule } from '@angular/core';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';

@NgModule({
  imports: [
    AkitaNgDevtools.forRoot()
  ],
  exports: [
  ]
})
export class StateModule {}
