import { EntityState, StoreConfig, EntityStore, EntityUIStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';

export interface ClinicalLettersUIViewModel {
}

export interface Letter {
  Id: string;
  BodyHtml: string;
  UrlPdf: string;
}

export interface ClinicalLetters {
  PatientId: string;
  communications: Letter[];
}

export interface ClinicalLettersState extends EntityState<ClinicalLetters> { }
export interface ClinicalLettersUIState extends EntityState<ClinicalLettersUIViewModel> { }

@Injectable({ providedIn: StateModule })
@StoreConfig({ name: 'encounter-letters', idKey: 'PatientId', resettable: true })
export class EncounterLettersStore extends EntityStore<ClinicalLettersState, ClinicalLetters> {

  ui: EntityUIStore<ClinicalLettersUIState>;

  constructor() {
    super();
    this.createUIStore().setInitialEntityState({

    } as ClinicalLettersUIViewModel);
  }

  addLetters(patientId, letters: Partial<ClinicalLetters>) {
    this.add({
      ...letters,
      PatientId: patientId
    } as ClinicalLetters);
  }
}
