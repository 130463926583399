import { Query, selectPersistStateInit } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { UiStateStore, UiState } from './ui-state.store';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UiStateQuery extends Query<UiState> {
  uiState$ = this.select();
  displayProgressBar$ = this.select(store => store.displayProgressBar);
  displayProgressSpinner$ = this.select(store => store.displayProgressSpinner);
  displayProgressSpinnerLoaded$ = combineLatest([this.uiState$, selectPersistStateInit()])
    .pipe(
      tap(([store, loading]) => store.displayProgressSpinner)
    );
  lastPatientUpdated$ = this.select(store => store.lastPatientUpdated);
  online$ = this.select(store => store.online);
  currentPatientTabs$ = this.select(store => store.currentPatientTabs);
  leftPanelOpened$ = this.select(store => store.leftPanelOpened);
  practiceProviders$ = this.select(store => store.practiceProviders);

  constructor(protected store: UiStateStore) {
    super(store);
  }
}
