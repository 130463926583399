import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { IisCompatibleUrlSerializer } from './core/services/iis-compatible-url-serializer.service';
import { UrlSerializer } from '@angular/router';

export let InjectorInstance: Injector;
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    // ServiceWorkerModule.register('./OneSignalSDKUpdaterWorker.js', { enabled: true }),
  ],
  providers: [
    { provide: UrlSerializer, useClass: IisCompatibleUrlSerializer },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
