<div class="whiteboard-container">
  <div class="draw-top-toolbar">
    <mat-icon class="draw-logo-icon" svgIcon="whiteboard_draw"></mat-icon>
    <span class="title">HB Draw</span>
    <div class="divider"></div>
    <app-color-picker-basic class="color-picker" (selectedColor$)="onColorChange($event)"></app-color-picker-basic>
    <div class="divider"></div>
    <app-line-thickness-picker (selectedLineThickness$)="onLineThicknessChanged($event)"></app-line-thickness-picker>
    <div class="divider"></div>
    <button mat-flat-button class="flat-button-styled" (click)="clearCanvas()">Clear</button>
    <span *ngIf="savingImage" class="save-image-label"><mat-spinner diameter="24"></mat-spinner></span>
    <div class="pull-right">

      <mat-icon class="draw-logo-icon template-small" (click)="toggleTemplateVisibility()">subject</mat-icon>
      <button mat-flat-button class="flat-button-styled template-big" (click)="toggleTemplateVisibility()">Add
        Templates</button>

      <button mat-flat-button class="flat-button-styled" (click)="cancelClick()">Cancel</button>
      <button mat-flat-button color="primary" (click)="saveDiagram()">Save</button>
    </div>
  </div>

  <div class="content-layout">
    <div class="iconContainer">
      <mat-icon class="tools-mat-icon" [ngClass]="{ 'selected' : (this.textAnnotationEnabled$ | async) === false }"
        (click)="selectPenDrawing()">edit</mat-icon>
      <mat-icon class="tools-mat-icon" (click)="undoDraw()">undo</mat-icon>
      <mat-icon class="tools-mat-icon" (click)="redoDraw()">redo</mat-icon>
      <mat-icon class="tools-mat-icon" (click)="deleteDrawComponent()">delete</mat-icon>
      <mat-icon class="tools-mat-icon" [ngClass]="{ 'selected' : (this.textAnnotationEnabled$ | async) === true }"
        (click)="selectTextDrawing()">text_fields</mat-icon>
    </div>

    <mat-tab-group class="tab-group-container" animationDuration="0ms" dynamicHeight
      (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedTabIndex$ | async">
      <mat-tab *ngFor="let drawingTemplate of selectedTemplates$ | async">
        <ng-template mat-tab-label>
          <span class="tab-label" title="{{ drawingTemplate.TemplateName }}">{{ drawingTemplate.TemplateName }}</span>
          <mat-icon class="tab-remove-icon" (click)="deleteDrawTemplate(drawingTemplate.TemplateId)">clear</mat-icon>
        </ng-template>
        <app-drawing-component #drawingComponent [textAnnotationEnabled]="textAnnotationEnabled$ | async"
          [drawColor]="selectedDrawColor$ | async" [lineWidth]="selectedLineWidth$ | async"
          [drawTemplate]="drawingTemplate"></app-drawing-component>
      </mat-tab>
    </mat-tab-group>
    <app-draw-template-selector #drawTemplateSelector [preloadedImages]="preloadedImages"
      (selectedTemplatesChanged)="onSelectedTemplatesChanged($event)" (templateAddedAtIndex)="goToTabIndex($event)">
    </app-draw-template-selector>
    <div class="lite" *ngIf="isLite$ | async">
      <div class="icon-lite-container">
        <span [matTooltip]="'Requires HbC Full'" [matTooltipPosition]="'left'" class="material-icons icon-lite"> lock
        </span>
      </div>
    </div>
  </div>

</div>
