import { QueryEntity, EntityUIQuery } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {
  ClinicalEncountersStore,
  ClinicalEncountersState,
  ClinicalEncounterUIState,
  ClinicalEncounterUIViewModel
} from './clinical-encounter.store';
import { EncounterVo } from 'api-clinician-app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClinicalEncountersQuery extends QueryEntity<ClinicalEncountersState, EncounterVo> {
  ui: EntityUIQuery<ClinicalEncounterUIState>;
  inProgressEncounters$ = this.selectAll();

  constructor(protected store: ClinicalEncountersStore) {
    super(store);
    this.createUIQuery();
  }

  patientHasInProgressEncounter$(patientId: string): Observable<boolean> {
    return this.selectEntity(patientId, enc => !!enc);
  }

  patientInProgressEncounter$(patientId: string): Observable<EncounterVo> {
    return this.selectEntity(patientId);
  }

  encounterUiState$(patientId: string): Observable<ClinicalEncounterUIViewModel> {
    return this.ui.selectEntity(patientId);
  }

  getPatientEncounterId(patientId: string) {
    return this.getEntity(patientId)?.EncounterId || null;
  }
}
