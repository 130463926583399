import { Injectable, OnDestroy } from '@angular/core';
import { interval } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';

interface CacheValues<T> {
  TTL: number;
  value: T;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService implements OnDestroy {

  private cache = new Map();

  constructor() {
    this.setupCacheCleanerTimer();
  }

  private setupCacheCleanerTimer() {
    interval(5000).pipe(
      untilDestroyed(this)
    ).subscribe({
      next: () => {
        Object.keys(this.cache).forEach(key => {
          const cacheVal = this.cache[key] as CacheValues<any>;
          if (cacheVal?.TTL && (new Date()).getTime() > cacheVal.TTL) {
            this.cache[key] = null;
          }
        });
      }
    });
  }

  Get<T>(key: string): T {
    const cacheVal = this.cache[key] as CacheValues<T>;
    if (!!cacheVal) {
      if (!cacheVal.TTL) {
        // cache hit, no TTL
        return cacheVal.value as T;
      } else if ((new Date()).getTime() < cacheVal.TTL) {
        // cache hit, TTL no yet expired
        return cacheVal.value as T;
      }
      // cache hit, but TTL has expired
      this.cache[key] = null;
      return null as T;
    }
    // cache miss
    return null as T;
  }

  Set<T>(key: string, value: T, options?: { TTL: number }) {
    this.cache[key] = {
      TTL: options?.TTL ? (new Date().getTime()) + options.TTL : null,
      value,
    } as CacheValues<T>;
  }

  ngOnDestroy(): void {
  }
}
