import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConsultationDialogComponent } from '../nora-consultation-dialog/nora-consultation-dialog.component';
import { AudioRecorderService } from './audio-recorder.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogRef: MatDialogRef<ConsultationDialogComponent>;
  private isResizedSubject = new BehaviorSubject<boolean>(true);
  public isResized$ = this.isResizedSubject.asObservable();

  constructor(private audioRecorderService: AudioRecorderService, private dialogManagementService: DialogManagementService) {}

  closeConsultationDialog() {
    this.audioRecorderService.setProcessingNotesStatus(false);
    this.dialogManagementService.closeDialog();
  }

  isNoraDialogClosed(): boolean {
    return this.dialogManagementService.isNoraDialogClosed();
  }

  cancelRecordingSession() {
    try {
      this.audioRecorderService.stopRecording();
    } catch (error) {
      console.error('Error stopping recording:', error);
    }
  }

  stopRecordingStatus() {
    this.audioRecorderService.setRecordingStatus(false);
  }

  getRecordingStatus(): boolean {
    return this.audioRecorderService.getRecordingStatus();
  }

  setResized(isResized: boolean): void {
    this.isResizedSubject.next(isResized);
  }

  getIsResized(): boolean {
    return this.isResizedSubject.value;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DialogManagementService {
  private dialogRef: MatDialogRef<any>;
  private isSpeechToNotesActiveSubject = new BehaviorSubject<boolean>(false);
  public isSpeechToNotesActive$ = this.isSpeechToNotesActiveSubject.asObservable();

  constructor(private dialog: MatDialog) {}

  openDialog(component: any, data: any): MatDialogRef<any> {
    this.dialogRef = this.dialog.open(component, {
      width: '450px',
      height: '400px',
      disableClose: true,
      hasBackdrop: false,
      data: data,
    });
    this.setIsSpeechToNotesActive(true);
    return this.dialogRef;
  }

  isNoraDialogClosed(): boolean {
    if (this.dialogRef) {
      return this.dialogRef.getState() === 0;
    } else {
      return false;
    }
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.setIsSpeechToNotesActive(false);
    }
  }

  setIsSpeechToNotesActive(isActive: boolean): void {
    this.isSpeechToNotesActiveSubject.next(isActive);
  }

  getIsSpeechToNotesActive(): boolean {
    return this.isSpeechToNotesActiveSubject.value;
  }
}
