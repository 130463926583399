import { EventEmitter, Injectable } from '@angular/core';
import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
import { TokenProvidersClient } from './api-client.service';
import { tap, distinctUntilChanged, filter, withLatestFrom, switchMap, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppInsightTags, ApplicationInsightsService } from './application-insights.service';
import { ProviderService } from './provider.service';
import { PathologyService } from './pathology.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientsService } from './patients.service';
import { ChatRoomStatusVo } from './state/provider/provider.store';
import { BehaviorSubject, Subscription, fromEvent, timer } from 'rxjs';
import * as moment from 'moment';

export enum SignalRMessageTypes {
  WaitingRoomUpdate = 'WaitingRoomUpdated',
  WaitingRoomUpdatedForPractice = 'WaitingRoomUpdatedForPractice',
  NotificationsUpdated = 'NotificationsUpdated',
  EncounterTemplateUpdated = 'EncounterTemplateUpdated',
  PathReportReceived = 'PathReportReceived',
  ChatRoomUpdated = 'ChatRoomUpdated',
}

export interface ISignalRMessage {
  Date: Date;
  Type: SignalRMessageTypes;
  Scope: string;
  Context: string;
  Value: any;
}

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  connection: HubConnection = null;
  private lastActive$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  private events: string[] = ['keydown', 'click', 'wheel', 'mousemove'];
  wsSubscription: Subscription;
  eventEmitterUpdate$ = new EventEmitter<string>();

  constructor(
    private tokenClient: TokenProvidersClient,
    private authService: AuthService,
    private providerService: ProviderService,
    private patientService: PatientsService,
    private pathologyService: PathologyService,
    private appInsightsService: ApplicationInsightsService,
    private snackbar: MatSnackBar,
  ) {
    this.setupAuthMonitorTracking();
  }

  setupAuthMonitorTracking() {
    this.connectWS();

    this.events.forEach(event =>
      fromEvent(document, event).subscribe(_ => this.recordLastActiveDate())
    );
    // every 1min check if user is active or not, after 2h stop WS connection
    // after 2min of being active connect to WS again
    timer(30 * 1000, 60 * 1000).pipe(
      withLatestFrom(this.lastActive$, this.providerService.isForge$),
      //tap(c => console.warn('timer', c)),
      tap(([_, last, forge]) => {
        if (forge) {
          // ignore signal-r for nova practices
          return;
        }

        const duration = moment.duration(moment().diff(moment(last))).asMinutes() || 0;
        if (duration > 2 * 60 && this.connection != null) {
          console.log('close socket due to inactivity.');
          this.close();
        }
        if (duration < 2 && this.connection == null) {
          console.log('setup WS connection as user is active again');
          this.connectWS();
        }
      })
    ).subscribe();

    this.eventEmitterUpdate$.pipe(
      switchMap((s) => this.providerService.getWaitingList()),
      untilDestroyed(this, 'destroy'),
    ).subscribe();

  }

  private connectWS() {
    if (this.wsSubscription != null) {
      this.wsSubscription.unsubscribe();
    }
    this.wsSubscription = this.providerService.provider$.pipe(
      untilDestroyed(this, 'destroy'),
      distinctUntilChanged(),
      withLatestFrom(this.providerService.isForge$)
    ).subscribe({
      next: ([provider, forge]) => {
        if (forge) {
          return;
        }
        if (provider) {
          this.connect(provider.PracticeId);
        } else {
          this.close();
        }
      },
    });
  }

  private recordLastActiveDate() {
    var currentDate = new Date();
    this.lastActive$.next(currentDate);
  }

  connect(providerId: string) {
    this.tokenClient
      .getTokenByType(providerId, 'practice_signalr')
      .pipe(
        tap({
          error: err => {
            console.error(err);
          },
        }),
        filter(token => !!token) // when API doesn't return appropriate response, suppres it
      )
      .subscribe(token => {
        this.close();

        this.connection = new HubConnectionBuilder()
          //.configureLogging(LogLevel.Trace)
          .withUrl(token.ServiceUrl, { accessTokenFactory: () => token.Token })
          .build();

        this.connection.serverTimeoutInMilliseconds = 360000;
        this.connection.keepAliveIntervalInMilliseconds = 30000;

        this.connection.onclose((error: Error) => {
          console.error('SignalR WS disconnected', error);
          if (!error || error?.stack?.includes('timeout') || error?.stack?.includes('1006')) {
            if (this.connection != null) {
              console.log('SignalR WS starting after timeout');
              this.connection.start();
            }
          }
        });

        this.connection.on('processMessage', (message: ISignalRMessage) => {
          this.appInsightsService.trackEvent(AppInsightTags.SIGNALR_PROCESS_MESSAGE, {
            serviceUrl: token.ServiceUrl,
            providerId,
            messageType: message.Type,
            messageDate: message.Date.toString(),
            messageValue: message.Value,
          });

          // console.log('receive SignalR', message);

          switch (message.Type) {
            case SignalRMessageTypes.ChatRoomUpdated:
              const status: ChatRoomStatusVo = JSON.parse(message.Value);
              this.providerService.addChatStatus(status);
              break;
            case SignalRMessageTypes.WaitingRoomUpdate:
            case SignalRMessageTypes.WaitingRoomUpdatedForPractice:
              // todo can be improved to re-load only waiting room for specific provider (message.Context)
              this.eventEmitterUpdate$.next(message.Type);
              break;
            case SignalRMessageTypes.PathReportReceived:
              // this.pathologyService.getReportsUnactionedForToday(providerId).subscribe();
              break;
            // case SignalRMessageTypes.NotificationsUpdated:
            //     this.providerService.getNotifications(providerId).subscribe();
            default:
              console.warn('SignalR Message NotYetImplemented', message.Type);
              break;
          }
        });
        this.connection.start();
      });
  }

  async close() {
    if (this.connection) {
      this.connection.off('processMessage');
      this.connection.stop();
      this.connection = null;
    }
  }

  destroy() {
    this.close();
  }
}
