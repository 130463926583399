import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { UiStateService } from '../services/ui-state.service';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import * as urlParse from 'url-parse';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarInterceptor implements HttpInterceptor {

  constructor(private uiStateService: UiStateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoreUrls = [
      'dc.services.visualstudio.com', // don't log AppInsights
    ];
    const url = urlParse(req.url, false);
    if (ignoreUrls.find(i => i.toLocaleString().includes(url.hostname))) {
      return next.handle(req);
    }
    if (req.headers.has('X-Skip-Progress-Bar') || ignoreUrls.find(i => i.toLocaleString().includes(url.hostname))) {
      // Clone the request and remove the custom header before passing it to the next handler
      const clonedRequest = req.clone({
        headers: req.headers.delete('X-Skip-Progress-Bar')
      });
      return next.handle(clonedRequest);
    }

    this.showProgressBar();

    return next.handle(req).pipe(
      finalize(() => this.hideProgressBar()),
    );
  }

  private showProgressBar(): void {
    this.uiStateService.showProgressBar();
  }

  private hideProgressBar(): void {
    this.uiStateService.hideProgressBar();
  }

}
