import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

export enum DialogResponse {
  YES = 'YES',
  NO = 'NO',
  CANCEL = 'CANCEL',
  DISCARD = 'DISCARD',
  CONTINUE = 'CONTINUE',
  OK = 'OK',
  EDIT = 'EDIT',
  CHANGE_PLAN = 'CHANGE_PLAN',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export const defaultDialogActions: { [key: string]: ConfirmDialogAction } = {
  YES: { key: DialogResponse.YES, value: 'Yes', color: 'primary' },
  NO: { key: DialogResponse.NO, value: 'No', color: 'basic' },
  CANCEL: { key: DialogResponse.CANCEL, value: 'Cancel', color: 'basic', displayFlat: true },
  OK: { key: DialogResponse.OK, value: 'Ok', color: 'primary' },
  DELETE: { key: DialogResponse.DELETE, value: 'Delete', color: 'primary'}
};

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    dialogRef.disableClose = true;
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
    if (!this.data.actions) {
      this.data.actions = [
        defaultDialogActions.NO,
        defaultDialogActions.YES
      ];
    }
  }

  renderAction(action: ConfirmDialogAction): void {
    this.dialogRef.close(action);
  }
}

export interface ConfirmDialogModel {
  title: string;
  message: string;
  actions?: ConfirmDialogAction[];
}

export interface ConfirmDialogAction {
  key: DialogResponse;
  value: string;
  color: string;
  displayFlat?: boolean;
}


export const saveDialogModel = {
  title: 'Unsaved changes',
  message: 'Save changes before you navigate away?',
  actions: [
    defaultDialogActions.CANCEL,
    defaultDialogActions.NO,
    { key: DialogResponse.YES, value: 'Save', color: 'primary' }
  ]
} as ConfirmDialogModel;
