<h2 mat-dialog-title>Important Message</h2>
<mat-dialog-content>
  <div class="content">
    <h2>We've updated our Privacy Policy in our Terms & Conditions</h2>
    <img src="assets/images/privacypolicy.png" />
    <p>They cover important information on how we use and protect your personal data. Please familiarise yourself with our updated policy on our login page.</p>
  </div>
  <mat-error *ngIf="message">{{message}}</mat-error>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="agree()" [disabled]="saving">
    <div class="save-content">
      <mat-spinner *ngIf="saving" diameter="20"></mat-spinner><span>{{ saving ? 'Please wait' : 'I Understand' }}</span>
    </div>
  </button>
</mat-dialog-actions>