import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PatientAllergiesVo, PatientsService } from '@app/core/services';
import { distinctUntilChanged } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-patient-allergy-warning',
  templateUrl: './patient-allergy-warning.component.html',
  styleUrls: ['./patient-allergy-warning.component.scss'],
})
export class PatientAllergyWarningComponent implements OnInit, OnDestroy {
  @Input() tenantId!: string;
  @Input() patientId!: string;

  allergies$: Observable<PatientAllergiesVo>;

  constructor(private patientsService: PatientsService) {}

  ngOnInit(): void {
    this.allergies$ = this.patientsService
      .patientAllergiesById$(this.tenantId, this.patientId)
      .pipe(distinctUntilChanged(_.isEqual), untilDestroyed(this));
  }

  ngOnDestroy(): void {}
}
