import { Observable } from "rxjs";
import { SYSTEM_ACCESS_CODE } from "./access.models";

export interface Address {
  Line1?: string;
  Line2?: string;
  Line3?: string;
  Code?: string;
}

export interface BankDetails {
  AccountHolderName?: string;
  AccountNo?: string;
  AccountType?: BANK_ACCOUNT_TYPE;
  Bank?: BANK_NAME;
  BranchCode?: string;
}

export interface AdditionalEmails {
  Description1?: string;
  Description2?: string;
  Description3?: string;
  Address1?: string;
  Address2?: string;
  Address3?: string;
}

export interface AdditionalPhones {
  Description1?: string;
  Description2?: string;
  Description3?: string;
  Phone1?: string;
  Phone2?: string;
  Phone3?: string;
}

export interface Country {
  Name?: string;
  Alpha2?: string;
  Alpha3?: string;
}

export interface BaseModel {
  UpdatedAt?: Date;
  UpdatedBy?: string;
  CreatedAt?: Date;
  CreatedBy?: string;
}

export interface Auditable {
  Audits?: Audit[];
}

export interface Audit {
  Date?: Date;
  TriggeredBy?: string;
  TriggeredFrom?: string;
}

export interface ChangeAudit extends Audit {
  Changes?: FieldChangeAudit[];
};

export interface FieldChangeAudit {
  FieldName?: string;
  ValueFrom?: any;
  ValueTo?: any;
}

export interface SidebarItem {
  label: string;
  icon?: string;
  url?: string;
  disabled?: boolean;
  counter?: number;
  access?: Observable<boolean>;
  click?: (e: any) => void;
}

export interface SidebarGroup {
  header?: string;
  items: SidebarItem[];
  hasItems$?: Observable<boolean>
}

export interface NexBaseResponse<T> {
  data?: {
    payload?: T;
    correlationId: string;
  };
  error?: {
    message?: string;
    correlationId: string;
  }
}

export interface Person {
  Title?: string;
  Name?: string;
  Surname?: string;
  Initials?: string;
  Gender?: string;
  IdentityNo?: string;
  DateOfBirth?: Date;
  Contact?: Contact;
  FileNo?: string;
}

export interface Contact {
  PostalAddress?: Address;
  PhysicalAddress?: Address;
  TelephoneWork?: string;
  TelephoneHome?: string;
  Cellphone?: string;
  Email?: string;
}

export interface Entity {
  _id?: PropertyKey; // case sensitive, used for entity store.
}

export interface Balance {
  Outstanding?: number;
  PatientLiable?: number;
  MedicalAidLiable?: number;
  Date?: Date;
}

export enum BANK_NAME {
	POST_BANK = 'Post Bank',
	INVESTEC_BANK = 'Investec Bank',
	MERCANTILE_BANK = 'Mercantile Bank',
	FNB = 'FNB',
	CAPITEC = 'Capitec',
	STANDARD_BANK = 'Standard Bank',
	BANK_OF_AHTENS = 'Bank of Athens',
	OTHER = 'Other',
	NEDBANK = 'Nedbank',
	ABSA = 'ABSA',
	BIDVEST = 'Bidvest',
	ALBARAKA_BANK = 'Albaraka Bank',
	SASFIN_BANK = 'Sasfin Bank',
	AFRICAN_BANK = 'African Bank',
	FIRSTRAND = 'FirstRand',
	IMPERIAL = 'Imperial',
	TEBA = 'Teba',
	DISCOVERY_BANK = 'Discovery Bank',
}

export enum BANK_ACCOUNT_TYPE {
	CHEQUE = 'Cheque',
  SAVINGS = 'Savings',
  TRANSMISSION = 'Transmission',
}

export enum VISIT_TYPE {
  REGULAR_CONSULTATION = 'Regular consultation',
  MEDICAL_INSURANCE = 'Medical insurance',
  VIDEO_CONSULT = 'Video consult',
  TELEPHONIC_CONSULT = 'Telephonic consult',
  NO_CHARGE = 'No charge (follow-up)',
  OUT_OF_ROOMS = 'Out of rooms',
  CUSTOM = 'Custom'
}

export class Speciality {
  static GP_014 = '014';
  static GP_015 = '015';
  static OPTOMETRIST = '070';
}

export enum BENEFIT_CHECK_STATUS {
  NOT_TRIGGERED = "No BC triggered",
  UNKNOWN_RESPONSE = "Unknown response",
  CALL_CENTRE_UNAVAILABLE = "Call centre unavailable",
  FULLY_COVERED = "Fully covered",
  PART_COVERED = "Partially covered",
  NOT_COVERED ="Not covered",
  REJECTED = "Rejected",
  AWAITING_RESPONSE = "Awaiting response",
  FAULT = "Request fault",
  NOT_SUPPORTED = "Not supported",
  INFORMATION_AVAILABLE = "Information available",
  INVALID_PATIENT_DATA = "Invalid patient data"
}


export const DEFAULT_VISIT_TYPE_COLOURS = {
  [VISIT_TYPE.MEDICAL_INSURANCE]: '#00B286',
  [VISIT_TYPE.NO_CHARGE]: '#D82C0D',
  [VISIT_TYPE.OUT_OF_ROOMS]: '#8C9196',
  [VISIT_TYPE.REGULAR_CONSULTATION]: '#2C6ECB',
  [VISIT_TYPE.TELEPHONIC_CONSULT]: '#F7941C',
  [VISIT_TYPE.VIDEO_CONSULT]: '#FFCE00'
}

export const DEFAULT_BENEFIT_CHECK_COLOURS = {
  [BENEFIT_CHECK_STATUS.AWAITING_RESPONSE]: '#8C9196',
  [BENEFIT_CHECK_STATUS.CALL_CENTRE_UNAVAILABLE]: '#202223',
  [BENEFIT_CHECK_STATUS.FAULT]: '#202223',
  [BENEFIT_CHECK_STATUS.FULLY_COVERED]: '#00B286',
  [BENEFIT_CHECK_STATUS.INFORMATION_AVAILABLE]: '#2C6ECB',
  [BENEFIT_CHECK_STATUS.INVALID_PATIENT_DATA]: '#202223',
  [BENEFIT_CHECK_STATUS.NOT_COVERED]: '#F7941C',
  [BENEFIT_CHECK_STATUS.NOT_SUPPORTED]: '#2C6ECB',
  [BENEFIT_CHECK_STATUS.NOT_TRIGGERED]: '#2C6ECB',
  [BENEFIT_CHECK_STATUS.PART_COVERED]: '#FFCE00',
  [BENEFIT_CHECK_STATUS.REJECTED]: '#D82C0D',
  [BENEFIT_CHECK_STATUS.UNKNOWN_RESPONSE]: '#202223'
}

// Order of colours is priority
export const PROVIDER_COLOURS = [
  '#2C6ECB',
  '#FFCE00',
  '#008060',
  '#FC9738',
  '#624290',
  '#E0225C',
  '#27B5E1',
  '#ADBB47',
  '#F14F36',
  '#3DB59E',
  '#5A2768',
  '#7A9F32',
  '#BF3468',
  '#283BA0',
  '#FF6C99',
  '#8AD99B',
  '#A359AF',
  '#008DA6',
];

// Order of colours is priority
export const VISIT_TYPE_COLOURS = [
  '#2C6ECB',
  '#FFCE00',
  '#008060',
  '#FC9738',
  '#624290',
  '#E0225C',
  '#27B5E1',
  '#ADBB47',
  '#F14F36',
  '#3DB59E'
];

export enum CALENDAR_EVENT_VIEW {
  DETAILED = 1,
  COMPACT = 2,
  DENSE = 3
};

export enum CALENDAR_INTERVAL_SIZE {
  "10M",
  "15M",
  "20M",
  "30M"
};

export enum PROVIDER_SELECTION_TYPE {
  MULTI,
  SINGLE
};

