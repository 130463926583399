import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ReportsState, ReportsStore } from './reports.store';
import { PowerBIReport } from 'api-clinician-app';

@Injectable({
  providedIn: 'root'
})
export class ReportsQuery extends QueryEntity<ReportsState, PowerBIReport> {
  constructor(protected store: ReportsStore) {
    super(store);
  }
}
