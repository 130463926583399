import { IEnvironment, StorageType } from './IEnvironment';

export const environment: IEnvironment = {
  production: false,

  apiUrl: '/api',
  merakiApiGateway: 'https://meraki-api-gateway-dev-b6kbpmzq.ew.gateway.dev',
  merakiCacheLoadBalancer: 'https://dev.meraki.healthbridge.co.za',
  applicationInsightsKey: 'ac0dcf7d-83f2-467a-8b3d-2f66fa40e7f1',
  videoChatUrl: 'https://healthbridge-videochat.web.app',
  clientId: '178dccca-a27b-49d8-81ee-ff670ccdc64a',
  googleCaptchaSiteKey: '',
  oneSignalAppId: '',
  bridgeitUrl: 'http://102.133.232.178/Views/Login.html',
  settings: {
    playgroundEnabled: true,
    styleGuideEnabled: true,
    checkVersion: true,
    verboseLogging: true,
    videoConsultFeatureEnabled: true,
    voiceToTextNotesTranscribeEnabled: true,
    smartTemplatesV2: true,
  },
  meiliSearchAccount: {
    host: "https://flexhunt.dev",
    key: "b2876f88942f1f6c6da4c1b42aa0ee9fd57803daf1e80b7f1d502b92e7ead014",
  },
  meiliSearchLookup: {
    host: "https://flexhunt.dev",
    key: "b2876f88942f1f6c6da4c1b42aa0ee9fd57803daf1e80b7f1d502b92e7ead014",
  },
  offlineSync: {
    enabled: false,
    delayBeforeStart: 10000,
    delayBetweenPatients: 2000,
    delayBetweenChecks: 300000
  },
  algoliaSettings: {
    apiKey: '24bc34ccb16e4197e04b93c7ed014102',
    appId: '7YZ5D9RL1C',
    genericMedicineIndexName: 'test_SmartMeds',
    medicineIndexName: 'test_MEDICINES_stmaster',
    diagnosisIndexName: 'prod_DIAGNOSES',
    defaultDebounce: 500,
  },
  smartGenericsSettings: {
    Url: '/v1/clinical/cli/smartGenericMedicine',
    FsCollection: 'smarttemplates-smartmedsV1',
    BaseGroupName: 'gp_base_test',
    CommunityName: 'suburban',
    AdsDisplayUrl: 'https://europe-west1-hbsmarttemplate.cloudfunctions.net/hbst_serveAdwords_eu_test'
  },
  keepAliveTesting: {
    enabled: true,
    delayBetweenChecks: 30000
  },
  localStorage: {
    type: StorageType.IndexedDB,
    version: 3
  },
  firebase: {
    apiKey: 'AIzaSyDunXBb27ggYIgxKyA8qznR2jwJw77WfDA',
    authDomain: 'healthbridge-meraki-dev.firebaseapp.com',
    projectId: 'healthbridge-meraki-dev',
    storageBucket: 'healthbridge-meraki-dev.appspot.com',
    messagingSenderId: '876295494566',
    appId: '1:876295494566:web:7489e1a480de5bbdef8d67',
    measurementId: 'G-RRWEP7G93N'
  },
  nora: {
    noraApiToken: 'hbUM76fC3L7aSOYVqrR3c4kosMIFiO2D6420w8c5',
    noraApiHostUrlV1: 'https://3zjx22dig8.execute-api.af-south-1.amazonaws.com/prod',
    noraApiHostUrlV2: 'https://3zjx22dig8.execute-api.af-south-1.amazonaws.com/prod',
  },
  sandbox: {
    cloudFunctionBaseUrl: 'https://us-central1-healthbridge-meraki-dev.cloudfunctions.net/',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

import 'zone.js/dist/zone-error';  // Included with Angular CLI

