/**
 * Inverts the camera preview box, by default the PWA camera elements
 * adds a style to the video element to invert, this function
 * will clear out that transform. Due to the way the pwa element
 * is built, we need to check if the component is available before
 * clearing out the style. It will be done with the setInterval for retries
 */
export const InvertPreviewCamera = () => {
  let numberOfTries = 0;
  let maxNumberOfTries = 5;
  const timerHandle = setInterval(() => {
    let didChangeCameraTransform: boolean = false;

    const modalInstance: any = document.querySelector("pwa-camera-modal-instance");
    if (modalInstance !== undefined && !!modalInstance) {
      const camera = modalInstance.shadowRoot.querySelector("pwa-camera");
      if (camera !== undefined && !!camera) {
        const video = camera.shadowRoot.querySelector(".camera-wrapper .camera-video video");
        if (video !== undefined && !!video ) {
          video.style.transform = null;
          didChangeCameraTransform = true;
        }
      }
    }

    numberOfTries++;
    if (didChangeCameraTransform === true || numberOfTries === maxNumberOfTries) {
      clearInterval(timerHandle);
    }
  },
  200);
}
