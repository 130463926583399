import { Injectable, OnDestroy } from '@angular/core';
import { NoraAPIService } from '../services/nora-api.service';
import { BehaviorSubject, combineLatest, from, Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { distinctUntilChanged, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AuthService, UiStateService } from '@app/core/services';
import { ClinicalEncounterService } from '@app/core/services/clinical-encounter.service';
import { NoraManagementService } from './nora-management.service';
/// <reference types="dom-mediacapture-record" />

@Injectable({
  providedIn: 'root',
})
export class AudioRecorderService implements OnDestroy {
  practiceId: string | null = null;
  encounterId: string | null = null;
  practiceTenantId: string | null = null;

  micUsed: string | null = null;
  recordingDuration: number = 0;
  dictationMode: string = '';
  durationInterval: any = null;

  mediaRecorder!: MediaRecorder;
  audioChunks: Blob[] = [];
  mediaStream!: MediaStream;
  file: File | null = null;
  presignedUrl: string | null = null;

  params$ = combineLatest([this.authService.currentTenantId$, this.uiStateService.currentPatientId$]).pipe(
    map(([tenantId, patientId]) => ({ tenantId, patientId })),
    distinctUntilChanged()
  );

  encounter$ = this.params$.pipe(
    switchMap(params => this.clinicalEncounterService.encounterInProgress$(params.tenantId, params.patientId)),
    untilDestroyed(this),
    shareReplay(1)
  );

  private handleAudioDataSubject = new Subject<Blob>();

  private recordingSubject = new BehaviorSubject<boolean>(false);
  recording$ = this.recordingSubject.asObservable();

  private pausedSubject = new BehaviorSubject<boolean>(false);
  paused$ = this.pausedSubject.asObservable();

  private isProcessingNotesSubject = new BehaviorSubject<boolean>(false);
  isProcessingNotes$ = this.isProcessingNotesSubject.asObservable();

  // Add a new subject to track component visibility
  private componentVisibleSubject = new BehaviorSubject<boolean>(true);
  componentVisible$ = this.componentVisibleSubject.asObservable();

  // Add new subjects for UI state
  private recordingDurationSubject = new BehaviorSubject<number>(0);
  recordingDuration$ = this.recordingDurationSubject.asObservable();

  private durationIntervalSubject = new BehaviorSubject<any>(null);
  durationInterval$ = this.durationIntervalSubject.asObservable();

  private patientIdSubject = new BehaviorSubject<string>('');
  patientId$ = this.patientIdSubject.asObservable();

  private selectedDeviceSubject = new BehaviorSubject<{id: string, label: string}>({ id: '', label: '' });
  selectedDevice$ = this.selectedDeviceSubject.asObservable();

  private showRecordingPlanLimitReachedSubject = new BehaviorSubject<boolean>(false);
  showRecordingPlanLimitReached$ = this.showRecordingPlanLimitReachedSubject.asObservable();

  constructor(
    private noraAPIService: NoraAPIService,
    private noraManagementService: NoraManagementService,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private clinicalEncounterService: ClinicalEncounterService,
    private uiStateService: UiStateService
  ) {
    this.handleAudioDataSubject.subscribe(blob => this.handleAudioData(blob));

    // Monitor duration and subscription limits
    this.recordingDuration$.pipe(
      withLatestFrom(this.noraManagementService.getSubscriptionLevel()),
      tap(([duration, subscriptionLevel]) => {
        if (
          subscriptionLevel in this.noraManagementService.limits &&
          duration >= this.noraManagementService.limits[subscriptionLevel]
        ) {
          this.pauseRecording();
          this.showRecordingPlanLimitReachedSubject.next(true);
        }
      })
    ).subscribe();
  }

  async getAudioInputDevices(): Promise<MediaDeviceInfo[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(device => device.kind === 'audioinput');
  }

  async startRecording(deviceId: string) {
    this.audioChunks = [];
    const constraints = {
      audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
    };

    await navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        this.mediaStream = stream;
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = async event => {
          this.audioChunks.push(event.data);
        };
        this.mediaRecorder.start();
        this.setRecordingStatus(true);
        // this.snackbar.open('Recording started. Reopen Speech To Notes on Clinical Notes tab.', 'Close', { duration: 5000 });
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
      });
  }

  pauseRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.pause();
      this.setPausedStatus(true);
      this.stopDurationTimer();
    }
  }

  resumeRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state === 'paused') {
      this.mediaRecorder.resume();
      this.setPausedStatus(false);
      this.setRecordingStatus(true);
    }
  }

  stopRecording(): Promise<Blob> {
    return new Promise(resolve => {
      if (this.mediaRecorder) {
        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
          this.stopMediaTracks();
          resolve(audioBlob);
          this.setRecordingStatus(false);
        };
        this.mediaRecorder.stop();
      }
    });
  }

  resetRecording() {
    if (this.mediaRecorder) {
      if (this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
      }
      this.mediaRecorder = null as any;
    }
    this.audioChunks = [];
    this.setRecordingStatus(false);
    this.stopMediaTracks();
  }

  deleteAudio() {
    if (!this.componentVisibleSubject.getValue()) {
      return; // Don't delete if component isn't visible
    }
    this.resetRecording();
    this.audioChunks = [];
    this.setRecordingStatus(false);
    this.stopMediaTracks();
  }

  private stopMediaTracks() {
    if (!this.mediaStream) return;
    this.mediaStream.getTracks().forEach(track => track.stop());
    this.mediaStream = null as any;
  }

  private async uploadToStorageBucket(audioData: Uint8Array) {
    try {
      this.getTenantEncounterIDs()
        .pipe(
          take(1),
          tap(async data => {
            const fileName = `${this.practiceId}_${this.encounterId}.wav`;
            const response = await fetch('https://us-central1-hbclinicalgenai.cloudfunctions.net/audio-stream', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/octet-stream',
                'X-Filename': fileName,
              },
              body: audioData,
            });

            if (!response.ok) {
              throw new Error(`Error uploading audio chunk: ${response.statusText}`);
            }
          })
        )
        .subscribe();
    } catch (error) {
      console.error('Error uploading audio chunk:', error);
    }
  }

  private uploadToApi(audioData: Uint8Array) {
    this.getTenantEncounterIDs()
      .pipe(
        take(1),
        switchMap(data => {
          // console.log(this.practiceId, this.encounterId);
          const fileName = `${this.practiceId}_${this.encounterId}.wav`;
          const fileToSend = new File([audioData], fileName, { type: 'audio/wav' });
          const contentType = fileToSend.type || 'application/octet-stream';

          return this.noraAPIService
            .getPresignedUrl(
              this.practiceId,
              this.encounterId,
              fileName,
              contentType,
              this.micUsed,
              this.recordingDuration,
              this.dictationMode
            )
            .pipe(
              switchMap(response => {
                this.presignedUrl = response.url;
                return from(
                  fetch(this.presignedUrl, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': contentType,
                    },
                    body: fileToSend,
                  })
                );
              }),
              tap(result => {
                if (result.status === 200) {
                  console.log('Successfully uploaded audio to Nora!');
                } else {
                  console.error('Failed to upload audio:', result.statusText);
                }
              })
            );
        })
      )
      .subscribe({
        error: error => console.error('An error occurred:', error),
      });
  }

  private async handleAudioData(blob: Blob) {
    const arrayBuffer = await this.blobToArrayBuffer(blob);
    const audioData = new Uint8Array(arrayBuffer);

    // applying concurrency to upload the audio streams all at the same time
    await Promise.all([this.uploadToStorageBucket(audioData), this.uploadToApi(audioData)]);
  }

  private blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    // Convert blob to array buffer
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  public notifyHandleAudioData(micUsed: string, recordingDuration: number, dictationMode: string) {
    this.recordingDuration = recordingDuration;
    this.micUsed = micUsed;
    this.dictationMode = dictationMode;
    const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
    this.handleAudioDataSubject.next(audioBlob);
    this.audioChunks = [];
  }

  getTenantEncounterIDs(): Observable<{ patientId: string; practiceTenantId: string; practiceId: string; encounterId: string }> {
    return this.encounter$.pipe(
      take(1), // Take only the first emission and complete
      withLatestFrom(this.params$), // Combine the latest value from params$
      map(([encounter, params]) => {
        // console.log('encounter', encounter);
        // console.log('params', params);
        // Transform the values to the desired data object
        // console.log(params.tenantId, encounter.EncounterId);
        return {
          patientId: params.patientId,
          practiceTenantId: encounter.PracticeId,
          practiceId: params.tenantId,
          encounterId: encounter.EncounterId,
        };
      })
    );
  }

  getPracticeId(): string | null {
    return this.practiceId;
  }

  getEncounterId(): string | null {
    return this.encounterId;
  }

  setPracticeId(practiceId: string | null): void {
    this.practiceId = practiceId;
  }

  setEncounterId(encounterId: string | null): void {
    this.encounterId = encounterId;
  }

  setPracticeTenantId(practiceTenantId: string | null): void {
    this.practiceTenantId = practiceTenantId;
  }

  getPracticeTenantId(): string | null {
    return this.practiceTenantId;
  }

  setRecordingStatus(isRecording: boolean): void {
    this.recordingSubject.next(isRecording);
  }

  setPausedStatus(isPaused: boolean): void {
    this.pausedSubject.next(isPaused);
  }

  getRecordingStatus(): boolean {
    return this.recordingSubject.getValue();
  }

  setProcessingNotesStatus(isProcessing: boolean): void {
    this.isProcessingNotesSubject.next(isProcessing);
  }

  getProcessingNotesStatus(): boolean {
    return this.isProcessingNotesSubject.getValue();
  }

  // Add method to update visibility
  setComponentVisibility(isVisible: boolean): void {
    this.componentVisibleSubject.next(isVisible);
  }

  setRecordingDuration(duration: number): void {
    this.recordingDurationSubject.next(duration);
  }

  setDurationInterval(interval: any): void {
    this.durationIntervalSubject.next(interval);
  }

  getDurationInterval(): any {
    return this.durationIntervalSubject.getValue();
  }

  getRecordingDuration(): number {
    return this.recordingDurationSubject.getValue();
  }

  setPatientId(patientId: string): void {
    this.patientIdSubject.next(patientId);
  }

  getPatientId(): string {
    return this.patientIdSubject.getValue();
  }

  startDurationTimer() {
    this.stopDurationTimer();
    this.showRecordingPlanLimitReachedSubject.next(false);
    
    this.durationInterval = setInterval(() => {
      const currentDuration = this.recordingDurationSubject.getValue();
      this.recordingDurationSubject.next(currentDuration + 1);
    }, 1000);
  }

  stopDurationTimer() {
    if (this.durationInterval) {
      clearInterval(this.durationInterval);
      this.durationInterval = null;
    }
  }

  resetDuration() {
    this.stopDurationTimer();
    this.recordingDurationSubject.next(0);
    this.showRecordingPlanLimitReachedSubject.next(false);
  }

  setSelectedDevice(deviceId: string, deviceLabel: string) {
    this.selectedDeviceSubject.next({ id: deviceId, label: deviceLabel });
  }

  getSelectedDevice() {
    return this.selectedDeviceSubject.getValue();
  }

  ngOnDestroy(): void {
    // Only cleanup if we're actually stopping everything
    if (this.mediaRecorder && !this.recordingSubject.getValue()) {
      this.deleteAudio();
    }
  }
}
