import { Injectable } from '@angular/core';
import { NoraAPIService } from './nora-api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
/// <reference types="dom-mediacapture-record" />

@Injectable({
  providedIn: 'root'
})
export class AudioRecorderService {
  mediaRecorder!: MediaRecorder;
  audioChunks: Blob[] = [];
  mediaStream!: MediaStream;
  file: File | null = null;
  presignedUrl: string | null = null;

	private handleAudioDataSubject = new Subject<Blob>();

  private recordingSubject = new BehaviorSubject<boolean>(false);
  recording$ = this.recordingSubject.asObservable();

  private pausedSubject = new BehaviorSubject<boolean>(false);
  paused$ = this.pausedSubject.asObservable();

  private isProcessingNotesSubject = new BehaviorSubject<boolean>(false);
  isProcessingNotes$ = this.isProcessingNotesSubject.asObservable();

  constructor(
    private noraAPIService: NoraAPIService,
    private snackbar: MatSnackBar
  ) {
		this.handleAudioDataSubject.subscribe(blob => this.handleAudioData(blob));
	}

  async getAudioInputDevices(): Promise<MediaDeviceInfo[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(device => device.kind === 'audioinput');
  }

  async startRecording(deviceId: string) {
    // this.audioChunks = [];
    const constraints = {
      audio: { deviceId: deviceId ? { exact: deviceId } : undefined }
    };

    await navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        this.mediaStream = stream;
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = async event => {
          this.audioChunks.push(event.data);
          // if (event.data.size > 0) {
          //   await this.handleAudioData(event.data);

          //   // await this.uploadAudioChunk(event.data)
          // }
        };
        this.mediaRecorder.start();
        this.setRecordingStatus(true);
        this.snackbar.open('Recording started. Reopen Speech To Notes on Clinical Notes tab.', 'Close', { duration: 5000 });
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
      });
  }

  pauseRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.pause();
      this.setPausedStatus(true);
      console.log('Recording paused.');
    }
  }

  resumeRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state === 'paused') {
      this.mediaRecorder.resume();
      this.setPausedStatus(false);
      this.setRecordingStatus(true);
      console.log('Recording resumed.');
    }
  }

  stopRecording(): Promise<Blob> {
    return new Promise(resolve => {
      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
        console.log(audioBlob)
        this.stopMediaTracks();
        resolve(audioBlob);
        console.log('Recording stopped and audio resolved.');
        this.setRecordingStatus(false);
      };
      this.mediaRecorder.stop();
    });
  }

  resetRecording() {
    if (this.mediaRecorder) {
      if (this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
      }
      this.mediaRecorder = null as any;
    }
    this.audioChunks = [];
    this.stopMediaTracks();
  }

  private stopMediaTracks() {
    if(!this.mediaStream) return;
    this.mediaStream.getTracks().forEach(track => track.stop());
    this.mediaStream = null as any;
  }

  private async uploadToStorageBucket(audioData: Uint8Array) {
    try {
			const fileName = `speechToNotesAudio-${this.noraAPIService.passData().encounterId}.wav`;
			const response = await fetch('https://us-central1-hbclinicalgenai.cloudfunctions.net/audio-stream', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/octet-stream',
					'X-Filename': fileName
				},
				body: audioData
			});

			if (!response.ok) {
				throw new Error(`Error uploading audio chunk: ${response.statusText}`);
			}

			console.log('Audio chunk uploaded successfully:', response);
		} catch (error) {
			console.error('Error uploading audio chunk:', error);
		}
  }

  private async uploadToApi(audioData: Uint8Array) {
    console.log(this.noraAPIService.passData().practiceId)
    var fileName = `${this.noraAPIService.passData().practiceId}_${this.noraAPIService.passData().encounterId}.wav`;
    const fileToSend = new File([audioData], fileName, { type: 'audio/wav' });
    const contentType = fileToSend.type || 'application/octet-stream';
    console.log(fileToSend)

		this.noraAPIService.getPresignedUrl(fileName, contentType)
		.subscribe(async response => {
				this.presignedUrl = response.url;
				const result = await fetch(this.presignedUrl, {
						method: 'PUT',
						headers: {
							'content_type': contentType,
						},
						body: fileToSend
					});
				//   console.log(result)
				if(result.status === 200){
					console.log("Successfully uploaded audio to Nora!")
				}
			},
			(error) => {
					console.log(error)
			}
		);
  }

  private async handleAudioData(blob: Blob) {
    const arrayBuffer = await this.blobToArrayBuffer(blob);
    const audioData = new Uint8Array(arrayBuffer);
    this.setProcessingNotesStatus(true);
    // applying concurrency to upload the audio streams all at the same time
    await Promise.all([
      this.uploadToStorageBucket(audioData),
      this.uploadToApi(audioData)
    ]);
  }

  private blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    // Convert blob to array buffer
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

	public notifyHandleAudioData() {
    const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
    this.handleAudioDataSubject.next(audioBlob);
    this.audioChunks = [];
  }

  setRecordingStatus(isRecording: boolean): void {
    this.recordingSubject.next(isRecording);
  }

  setPausedStatus(isPaused: boolean): void {
    this.pausedSubject.next(isPaused);
  }

  getRecordingStatus(): boolean {
    return this.recordingSubject.getValue();
  }

  setProcessingNotesStatus(isProcessing: boolean): void {
    this.isProcessingNotesSubject.next(isProcessing);
  }

  getProcessingNotesStatus(): boolean {
    return this.isProcessingNotesSubject.getValue();
  }
}
