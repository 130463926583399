import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ProviderConfigurationKey, ProviderOptionsEnum, ProviderService, UiStateService } from '../services';
import { AppInsightTags, ApplicationInsightsService } from '../services/application-insights.service';
import { SidebarLocations } from '../services/state/ui-state/ui-state.store';

@Component({
  selector: 'app-guided-tour',
  template: '',
})
export class GuidedTourComponent implements OnInit, AfterViewInit {
  params$ = this.route.paramMap.pipe(
    map(m => ({ tenantId: m.get('tenantId'), patientId: m.get('patientId') })),
    distinctUntilChanged(_.isEqual)
  );

  constructor(
    private shepherdService: ShepherdService,
    private uiStateService: UiStateService,
    private router: Router,
    private route: ActivatedRoute,
    private providerService: ProviderService,
    private appInsightsService: ApplicationInsightsService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => this.StartShepherdTour(), 1000);
  }

  StartShepherdTour() {
    this.uiStateService.activeGuidedTour$
      .pipe(
        take(1),
        withLatestFrom(
          this.router.events.pipe(
            filter(f => f instanceof NavigationEnd),
            map(m => m as NavigationEnd),
            startWith({} as NavigationEnd)
          )
        ),
        tap(([tour, nav]) => {
          if (!tour) {
            return;
          }

          let tourConfigSteps = this.GetTourConfigSteps(tour.id, nav);

          if (!tourConfigSteps) {
            return;
          }

          this.shepherdService.requiredElements = [
            {
              selector: '.header_search',
              message: 'Header section present?',
              title: 'Header is not present',
            },
          ];

          this.shepherdService.defaultStepOptions = tourConfigSteps.DefaultStepOptions;
          this.shepherdService.addSteps(tourConfigSteps.Steps);
          this.shepherdService.start();
        })
      )
      .subscribe();
  }

  GetTourConfigSteps(id: string, nav: NavigationEnd) {
    switch (id) {
      case 'tour-consult-navigation':
        // if (nav.url.includes('consult')) {
        //   return null;
        // }
        return this.TourConsultNavigation;

      case 'tour-calendar-navigation':
        return this.TourCalendarNavigation;

      default:
        return null;
    }
  }

  get TourCalendarNavigation(): { DefaultStepOptions: any; Steps: any } {
    return {
      DefaultStepOptions: {},
      Steps: [],
    };
  }

  get TourConsultNavigation() {
    return {
      DefaultStepOptions: {
        tourName: 'tour-consult-navigation',
        classes: 'hbc-sheperd-tour',
        scrollTo: { behavior: 'smooth', block: 'center' },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Previous',
          },
          {
            action() {
              return this.next();
            },
            classes: 'shepherd-button-primary',
            text: 'Next',
          },
        ],
        when: {
          show: () => {
            const shepherd = this.shepherdService.tourObject;

            const currentStepElement = shepherd.getCurrentStep().getElement();
            const header = currentStepElement.querySelector('.shepherd-header');
            const progressStep = document.createElement('span');
            progressStep.innerText = `${shepherd.steps.indexOf(shepherd.getCurrentStep()) + 1}/${shepherd.steps.length}`;
            header.insertBefore(progressStep, currentStepElement.querySelector('.shepherd-cancel-icon'));

            const progress = document.createElement('p');
            progress.style['border'] = '1px solid #F7941C';
            progress.style['width'] = `${((shepherd.steps.indexOf(shepherd.getCurrentStep()) + 1) / shepherd.steps.length) * 100}%`;
            currentStepElement.querySelector('.shepherd-content').append(progress);
          },
          canel: () => {
            console.log('when cancel triggered');
          },
          complete: () => {
            console.log('when complete triggered');
          },
        },
      },
      Steps: [
        {
          id: '1-tour-symptoms-tab',
          attachTo: {
            element: '.mat-tab-labels .mat-tab-label:nth-child(1)',
            on: 'bottom-end',
          },
          highlightClass: 'highlight',
          title: 'Symptoms tab',
          text: [
            '<p>The top tabs guide you through the consultation process.</p><br /><p>The symptoms tab lets you capture symptom information. This can be done via pre-set visit templates, by typing notes, or by using a stylus on your tablet to draw.</p></br>',
          ],
          beforeShowPromise: () =>
            new Promise((resolve: any) => {
              this.uiStateService.setConsultTargetTab(0);
              resolve();
            }),
          buttons: [
            // {
            //   action() {
            //     return this.cancel();
            //   },
            //   classes: 'shepherd-button-secondary',
            //   text: 'Exit',
            // },
            {
              action() {
                return this.next();
              },
              classes: 'shepherd-button-primary',
              text: 'Next',
            },
          ],
        },
        {
          id: '2-tour-examination-tab',
          attachTo: {
            element: '.mat-tab-labels .mat-tab-label:nth-child(2)',
            on: 'bottom-end',
          },
          highlightClass: 'highlight',
          title: 'Examination tab',
          text: ['<p>The examination tab allows you to capture vitals and metrics, and relevant examination information.</p><br />'],
          beforeShowPromise: () =>
            new Promise((resolve: any) => {
              this.uiStateService.setConsultTargetTab(1);
              resolve();
            }),
        },
        {
          id: '3-tour-diagnosis-prescription-tab',
          attachTo: {
            element: '.mat-tab-labels .mat-tab-label:nth-child(3)',
            on: 'bottom-end',
          },
          highlightClass: 'highlight',
          title: 'Diagnosis and prescription tab',
          text: [
            '<p>The diagnose & prescribe tab lets you capture your diagnoses, medicines, billing information and generate a claim.</p><br />',
          ],
          beforeShowPromise: () =>
            new Promise((resolve: any) => {
              this.uiStateService.setConsultTargetTab(2);
              resolve();
            }),
        },
        {
          id: '4-tour-plan-tab',
          attachTo: {
            element: '.mat-tab-labels .mat-tab-label:nth-child(4)',
            on: 'bottom-end',
          },
          highlightClass: 'highlight',
          title: 'Plan tab',
          text: [
            '<p>The Plan tab is the final step in the consultation process.</p><br /><p>Here you will find medical certificates, referral letters and outcomes for the visit.</p><br />',
          ],
          beforeShowPromise: () =>
            new Promise((resolve: any) => {
              this.uiStateService.setConsultTargetTab(3);
              resolve();
            }),
        },
        {
          id: '5-tour-patient-file',
          attachTo: {
            element: '[data-tour="patient-file"]',
            on: 'auto',
          },
          highlightClass: 'highlight',
          title: 'Patient file menu',
          text: [
            '<p>Down the left hand side, you will find the Patient File menu.</p><br /><p>Information saved in these sections will be displayed in the Patient File.</p><br />',
          ],
          beforeShowPromise: () =>
            new Promise((resolve: any) => {
              this.uiStateService.setConsultTargetTab(0);
              resolve();
            }),
        },
        {
          id: '6-tour-executive-summary',
          attachTo: {
            element: '[data-tour="executive-summary"]',
            on: 'right',
          },
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 250] } }],
          },
          highlightClass: 'highlight',
          title: 'Patient executive summary',
          text: [
            '<p>The Patient Executive Summary allows you to view important patient information while you are in the consultation.</p><br />',
          ],
          beforeShowPromise: () =>
            new Promise((resolve: any) => {
              this.uiStateService.navigateSidebar(SidebarLocations.EXECUTIVE_SUMMARY);
              resolve();
            }),
        },
        {
          id: '7-tour-consultation-summary',
          attachTo: {
            element: '[data-tour="consultation-summary"]',
            on: 'right',
          },
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 250] } }],
          },
          highlightClass: 'highlight',
          title: 'Consultation summary',
          text: [
            `<p>The Consultation Summary keeps track of everything you've added during the consult, such as clinical notes and billing information. </p><br />`,
          ],
          beforeShowPromise: () =>
            new Promise((resolve: any) => {
              this.uiStateService.navigateSidebar(SidebarLocations.ENCOUNTER_SUMMARY);
              resolve();
            }),
        },
        {
          id: '8-tour-sconsultation-note',
          attachTo: {
            element: '[data-tour="consultation-note"]',
            on: 'right',
          },
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 250] } }],
          },
          highlightClass: 'highlight',
          title: 'Consultation note',
          text: ['<p>Should you wish to capture notes in one place during a consult, the Consultation Note tab can be used.</p><br /><p>'],
          beforeShowPromise: () =>
            new Promise((resolve: any) => {
              this.uiStateService.navigateSidebar(SidebarLocations.QUICK_NOTES);
              this.uiStateService.currentTenantId$
                .pipe(
                  take(1),
                  switchMap(tenantId =>
                    forkJoin([
                      this.providerService.updateProviderConfigurationItemsByKey(
                        tenantId,
                        ProviderConfigurationKey.GuidedTourConsultNavigation,
                        ['complete']
                      ),
                      this.providerService.updateOption(String(ProviderOptionsEnum.IsRequireToShowConsultNavigationTips), String(false)),
                    ])
                  ),
                  tap(() => this.uiStateService.setGuidedTour(null)),
                  tap(() => this.appInsightsService.trackEvent(AppInsightTags.TOUR_CONSULT_NAV_COMPLETE))
                )
                .subscribe();
              resolve();
            }),
          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Previous',
            },
            {
              action() {
                return this.complete();
              },
              classes: 'shepherd-button-primary',
              text: 'Continue Consult',
            },
          ],
        },
      ],
    };
  }
}
