<div class="start-recording" *ngIf="!recording && !audioBlob">
    <mat-form-field style="width: 150%; margin-top: 20px;">
      <mat-label>Select Microphone</mat-label>
      <mat-select [(ngModel)]="selectedDeviceId">
        <mat-option *ngFor="let device of devices" [value]="device.deviceId">{{ device.label || 'Microphone' }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button (click)="startRecording()" style="margin-top: 20px; background-color: #FF1655; color: white;">
      <mat-icon>mic</mat-icon> Start Recording
    </button>
  </div>
  <div *ngIf="recording" style="display: flex; flex-direction: column; align-items: center;">
    <h2 style="color: #FF1655;"> <mat-icon>timer</mat-icon> {{ recordingDuration }} seconds</h2>
    <button mat-raised-button *ngIf="!isPaused" (click)="pauseRecording()" style="margin-top: 20px;"><mat-icon>pause_circle</mat-icon>Pause Recording</button>
    <button mat-raised-button *ngIf="isPaused" (click)="resumeRecording()" style="margin-top: 20px;"><mat-icon>play_circle</mat-icon>Resume Recording</button>
    <button mat-raised-button (click)="stopRecording()" style="margin-top: 20px; color: white; background-color: #FF1655;">
      <mat-icon>stop_circle</mat-icon> Stop Recording
    </button>
  </div>
  <div *ngIf="audioBlob && !recording" style="display: flex; flex-direction: column; align-items: center;">
    <!-- <audio [src]="getAudioUrl()" controls style="width: 90%; max-width: 400px;"></audio> -->
    <button mat-stroked-button (click)="startRecording()" [disabled]="isUploading" style="margin-top: 20px; border-color: #FF1655; color: #FF1655;">
      <mat-icon>play_circle</mat-icon> Resume Recording
    </button>
  <button mat-raised-button [disabled]="isUploading" *ngIf="audioBlob && !recording" (click)="uploadAudio()" style="margin-top: 20px; color: white; background-color:#2EB67D;"><mat-icon>upload</mat-icon>{{isUploading ? 'Audio uploaded' : 'Upload'}}</button>
  <button mat-button [disabled]="isUploading" *ngIf="audioBlob" (click)="deleteAndRestart()" style="margin-top: 20px; margin-bottom: 15px;"><mat-icon>delete</mat-icon>Delete and Restart</button>
  </div>
  