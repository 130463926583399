import { Input, Directive, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[sharedDisableControl]',
})
export class DisableReactiveFormControlDirective implements OnChanges {

  @Input() sharedDisableControl;

  constructor(private ngControl: NgControl) {}

  ngOnChanges(changes) {
    if (changes['sharedDisableControl']) {
      const action = this.sharedDisableControl ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
  }

}
