import * as moment from "moment";
import {
  ClinicalMetricVo, EncounterLineItemVo, EncounterStatusVo, MedicationConditionVo, MedicationPrescription, PatientChronicConditionsVo, PatientVo
} from "../api-client.service";
import firebase from 'firebase/app';
import 'firebase/firestore';
import Timestamp = firebase.firestore.Timestamp;

export function convertFirestoreToCondition(item: PatientChronicConditionsVo | any): PatientChronicConditionsVo {
  return {
    ...item,
    OtherMedications: item?.OtherMedications?.map(s => convertFirestoreToMedication(s)) || [],
    Conditions:
      item?.Conditions?.map(condition => ({
        ...condition,
        Medications: condition.Medications?.map(s => convertFirestoreToMedication(s)) || [],
        CapturedDate: fromTimestamp(condition.CapturedDate),
        Condition: {
          ...condition.Condition,
          TestDate: fromTimestamp(condition.Condition.TestDate),
          DateOnset: fromTimestamp(condition.Condition.DateOnset),
        },
      })) || [],
    Patient: (item?.Patient && convertFirestoreToPatient(item?.Patient)) || null,
  };
}

export function convertConditionToFirestore(item: PatientChronicConditionsVo): PatientChronicConditionsVo | any {
  return {
    ...item,
    IcdCodes: item.Conditions?.map(s => s.Condition.DiagnosisCode),
    Conditions: item.Conditions?.map(condition => ({
      ...condition,
      CapturedDate: toTimestamp(condition.CapturedDate),
      Condition: {
        ...condition.Condition,
        TestDate: toTimestamp(condition.Condition.TestDate),
        DateOnset: toTimestamp(condition.Condition.DateOnset),
        CommonForSpecialties: null // remove this useless data artifact from ui component (cause whole screen to misbehave: medicine not deleted/discontinued)
      },
      Medications: condition.Medications?.map(s => convertMedicationToFirestore(s)) || []
    })),
    OtherMedications: item.OtherMedications?.map(s => convertMedicationToFirestore(s)) || [],
    Patient: convertPatientToFirestore(item?.Patient),
  };
}

export function convertFirestoreToLineItem(item: EncounterLineItemVo | any): EncounterLineItemVo {
  // please note: florence convert decimal to string, as firestore doesn't support decimal
  return {
    ...item,
    DateOfService: item.DateOfService?.toDate() || null,
    Amount: item.Amount && parseFloat(item.Amount) || null,
    TotalExcVat: item.TotalExcVat && parseFloat(item.TotalExcVat) || null,
    TotalIncVat: item.TotalIncVat && parseFloat(item.TotalIncVat) || null,
    UnitPrice: item.UnitPrice && parseFloat(item.UnitPrice) || null,
    VatAmount: item.VatAmount && parseFloat(item.VatAmount) || null
  };
}

export function convertFirestoreToMedication(item: MedicationConditionVo | any): MedicationConditionVo {
  return {
    ...item,
    DateStarted: fromTimestamp(item.DateStarted),
    DateEnded: fromTimestamp(item.DateEnded)
  };
}

export function convertMedicationToFirestore(item: MedicationConditionVo | any): MedicationConditionVo {
  return {
    ...item,
    DateStarted: toTimestamp(item.DateStarted),
    DateEnded: toTimestamp(item.DateEnded)
  };
}

export function convertLineItemToFirestore(item: EncounterLineItemVo | any): EncounterLineItemVo {
  // please note: florence convert decimal to string, as firestore doesn't support decimal
  return {
    ...item,
    DateOfService: toTimestamp(item.DateOfService),
    Amount: (item.Amount && item.Amount.toString()) || null,
    TotalExcVat: (item.TotalExcVat && item.TotalExcVat.toString()) || null,
    TotalIncVat: (item.TotalIncVat && item.TotalIncVat.toString()) || null,
    UnitPrice: (item.UnitPrice && item.UnitPrice.toString()) || null,
    VatAmount: (item.VatAmount && item.VatAmount.toString()) || null,
    Parameters: item.Parameters || null
  };
}

export function toTimestamp(dateInput: Date | string | null) {
  return dateInput && Timestamp.fromDate(moment(dateInput).toDate()) || null;
}

export function fromTimestamp(dateInput: Timestamp | any) {
  // compatibility fix if for some reason date were saved not as timestamp but as complex object
  // or just as string but suppose to be Timestamp
  return dateInput != null && dateInput.hasOwnProperty('seconds')
    ? new Timestamp(+dateInput.seconds, +dateInput.nanoseconds).toDate()
    : typeof dateInput === 'string' ? moment(dateInput).toDate() : dateInput?.toDate() || null;
}

export function convertEncounterToFirestore(encounter: EncounterStatusVo): any {
  return {
    ...encounter,
    PatientEventDetails: {
      ...encounter.PatientEventDetails,
      CheckInTime: toTimestamp(encounter.PatientEventDetails.CheckInTime),
      ScheduledTime: toTimestamp(encounter.PatientEventDetails.ScheduledTime),
      Patient: convertPatientToFirestore(encounter.PatientEventDetails.Patient),
    },
    EncounterLineItems: {
      ...encounter.EncounterLineItems,
      EncounterHeader: {
        ...encounter.EncounterLineItems.EncounterHeader,
        DateOfService: toTimestamp(encounter.EncounterLineItems.EncounterHeader.DateOfService),
        DischargeDateTime: toTimestamp(encounter.EncounterLineItems.EncounterHeader.DischargeDateTime),
        AdmissionDateTime: toTimestamp(encounter.EncounterLineItems.EncounterHeader.AdmissionDateTime)
      },
      LineItems: [...encounter.EncounterLineItems.LineItems.map(s => convertLineItemToFirestore(s))],
    },
    MedicalCertificate: encounter.MedicalCertificate && {
      ...encounter.MedicalCertificate,
      FromDate: toTimestamp(encounter.MedicalCertificate.FromDate),
      ToDate: toTimestamp(encounter.MedicalCertificate.ToDate),
      CreatedDate: toTimestamp(encounter.MedicalCertificate.CreatedDate),
      ExaminationDate: toTimestamp(encounter.MedicalCertificate.ExaminationDate),
      FollowupDate: toTimestamp(encounter.MedicalCertificate.FollowupDate),
      ReturnDate: toTimestamp(encounter.MedicalCertificate.ReturnDate),
      AccompaniedFromDate: toTimestamp(encounter.MedicalCertificate.AccompaniedFromDate),
      AccompaniedToDate: toTimestamp(encounter.MedicalCertificate.AccompaniedToDate),
      Patient: convertPatientToFirestore(encounter.MedicalCertificate.Patient)
    } || null,
    ClinicalMetrics: encounter.ClinicalMetrics?.map(s => convertClinicalMetricToFirestore(s)) || [],
    EncounterFollowUp: encounter.EncounterFollowUp && {
      ...encounter.EncounterFollowUp,
      DueDateForFollowUp: toTimestamp(encounter.EncounterFollowUp.DueDateForFollowUp)
    } || null,
    MedicationsPrescriptions: encounter.MedicationsPrescriptions?.map(s => convertPrescriptionToFirestore(s))
  };
}

export function convertFirestoreToEncounter(encounter: EncounterStatusVo | any): EncounterStatusVo {
  return {
    ...encounter,
    PatientEventDetails: {
      ...encounter.PatientEventDetails,
      CheckInTime: encounter.PatientEventDetails.CheckInTime?.toDate() || null,
      ScheduledTime: encounter.PatientEventDetails.ScheduledTime?.toDate() || null,
      Patient: convertFirestoreToPatient(encounter.PatientEventDetails.Patient),
    },
    EncounterLineItems: {
      ...encounter.EncounterLineItems,
      EncounterHeader: {
        ...encounter.EncounterLineItems.EncounterHeader,
        DateOfService: encounter.EncounterLineItems.EncounterHeader.DateOfService?.toDate() || null,
        DischargeDateTime: encounter.EncounterLineItems.EncounterHeader.DischargeDateTime?.toDate() || null,
        AdmissionDateTime: encounter.EncounterLineItems.EncounterHeader.AdmissionDateTime?.toDate() || null
      },
      LineItems: [...encounter.EncounterLineItems.LineItems.map(s => convertFirestoreToLineItem(s))]
    },
    MedicalCertificate: encounter.MedicalCertificate && {
      ...encounter.MedicalCertificate,
      FromDate: fromTimestamp(encounter.MedicalCertificate.FromDate),
      ToDate: fromTimestamp(encounter.MedicalCertificate.ToDate),
      CreatedDate: fromTimestamp(encounter.MedicalCertificate.CreatedDate),
      ExaminationDate: fromTimestamp(encounter.MedicalCertificate.ExaminationDate),
      FollowupDate: fromTimestamp(encounter.MedicalCertificate.FollowupDate),
      ReturnDate: fromTimestamp(encounter.MedicalCertificate.ReturnDate),
      Patient: convertFirestoreToPatient(encounter.MedicalCertificate.Patient),
      AccompaniedFromDate: fromTimestamp(encounter.MedicalCertificate.AccompaniedFromDate),
      AccompaniedToDate: fromTimestamp(encounter.MedicalCertificate.AccompaniedToDate)
    } || null,
    ClinicalMetrics: encounter.ClinicalMetrics?.map(s => convertFirestoreToClinicalMetric(s)) || [],
    EncounterFollowUp: encounter.EncounterFollowUp && {
      ...encounter.EncounterFollowUp,
      DueDateForFollowUp: fromTimestamp(encounter.EncounterFollowUp.DueDateForFollowUp)
    } || null,
    MedicationsPrescriptions: encounter.MedicationsPrescriptions?.map(s => convertFirestoreToPrescription(s)) || null
  };
}

export function convertFirestoreToClinicalMetric(item: ClinicalMetricVo | any): ClinicalMetricVo {
  return {
    ...item,
    NextScreeningDate: fromTimestamp(item.NextScreeningDate),
    TestDate: fromTimestamp(item.TestDate)
  };
}

export function convertClinicalMetricToFirestore(item: ClinicalMetricVo | any): ClinicalMetricVo {
  return {
    ...item,
    NextScreeningDate: toTimestamp(item.NextScreeningDate),
    TestDate: toTimestamp(item.TestDate)
  };
}

export function convertFirestoreToPrescription(item: MedicationPrescription | any): MedicationPrescription {
  return {
    ...item,
    MedicationPrescriptionLines: item.MedicationPrescriptionLines?.map(s => {
      const dosage = typeof s.DosageUnits === 'string'
        ? +(s?.DosageUnits?.replace(',', '.') || '0')
        : s.DosageUnits;
      return ({ ...s, DosageUnits: dosage });
    }) || [],
    IssuedDate: fromTimestamp(item.IssuedDate),
    FutureDate: fromTimestamp(item.FutureDate),
    Patient: item.Patient && convertFirestoreToPatient(item.Patient) || null,
  };
}

export function convertPrescriptionToFirestore(item: MedicationPrescription | any): MedicationPrescription {
  return {
    ...item,
    IssuedDate: toTimestamp(item.IssuedDate),
    FutureDate: toTimestamp(item.FutureDate),
    Patient: convertPatientToFirestore(item.Patient),
  };
}

export function convertPatientToFirestore(patient: PatientVo): any {
  return {
    ...patient,
    PatientDetails: {
      ...patient.PatientDetails,
      DateOfBirth: toTimestamp(patient.PatientDetails.DateOfBirth),
    },
    PatientAccountDetails: {
      ...patient.PatientAccountDetails,
      MedicalAidMainMemberDetails: {
        ...patient.PatientAccountDetails.MedicalAidMainMemberDetails,
        DateOfBirth: toTimestamp(patient.PatientAccountDetails.MedicalAidMainMemberDetails.DateOfBirth),
      },
    },
  };
}

export function convertFirestoreToPatient(patient: any): PatientVo {
  return {
    ...patient,
    PatientDetails: {
      ...patient?.PatientDetails,
      DateOfBirth: patient?.PatientDetails.DateOfBirth?.toDate(),
    },
    PatientAccountDetails: {
      ...patient.PatientAccountDetails,
      MedicalAidMainMemberDetails: {
        ...patient.PatientAccountDetails.MedicalAidMainMemberDetails,
        DateOfBirth: patient.PatientAccountDetails.MedicalAidMainMemberDetails.DateOfBirth?.toDate(),
      },
    },
  };
}
