import { HttpBackend, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import {
  PatientChronicConditionsVo,
  PatientFileGenericInformationVo,
  ProviderConfigurationVo,
  Symptom,
  SymptomConfiguration,
  SymptomQuestion,
} from '.';
import { MerakiAuthService } from './meraki-auth.service';
import {
  EmailRequest,
  NexResponse,
  PathologyReportFirestoreVo,
  PpeCode,
  PriceResponse,
  SendEmailResponsePayload,
  SinglePriceRequest,
  TariffPriceRequest,
} from './meraki-models/meraki-models';
import { HttpClientMeraki, MERAKI_API_GATEWAY, MERAKI_CACHE_LB } from './meraki-nexus.providers';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Timestamp = firebase.firestore.Timestamp;
import { GenericMedicine } from './view-models/generic-medicine-view-model.utils';
import {
  AddressBookEntryVo,
  PatientConfigurationVo,
  ReminderVo,
  RestApiResultOfBoolean,
  RestApiResultOfGuid,
  UpdateAddressBookEntry,
  PatientLifestyleVo,
  PatientSurgicalHistoryVo,
  NotificationStatusView,
  NotificationVo,
  ChronicConditionVo,
  PatientClinicalNotesVo,
  PatientAllergiesVo,
  ConditionFilterVo,
  CommunicationTemplateVo,
  EncounterVo,
  EncounterStatusVo,
  SpecialityRuleVo,
  ClinicalMetricVo,
  RestApiResultOfEncounterVo,
  EncounterHeaderVoPlaceOfService,
  CalendarEventVo,
  PatientInWaitingRoom,
  PatientEventVo,
  PatientEventVoStatus,
  ProviderBranchConfigurationVo,
  BranchProviderInfoVo,
  SupportingProviderVo,
  TemplateSummaryVo,
  EncounterLineItemVo,
  DiagnosisVo2,
  RestApiResultOfCheckinPatientVisitResult,
  PatientEventsByDateVo,
  PatientEventView,
  MedicalInsurerVo,
  RestApiResultOfCompleteEncounterResult,
  PatientVo,
  RestApiResultOfString,
} from './api-client.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfigService } from './config.service';
import {
  convertClinicalMetricToFirestore,
  convertConditionToFirestore,
  convertEncounterToFirestore,
  convertFirestoreToClinicalMetric,
  convertFirestoreToCondition,
  convertFirestoreToEncounter,
  convertPatientToFirestore,
  fromTimestamp,
} from './meraki-models/meraki-model-util';
import { KahunAppointment } from './view-models/kahun-view-model.utils';
import * as uuidV4 from 'uuid/v4';
import * as guidByString from 'uuid-by-string';
import {
  CalendarEvent,
  CALENDAR_EVENT_TYPE,
  CALENDAR_EVENT_STATUS,
  CalendarEventStatusHistory,
  CLINICAL_CALENDAR_EVENT_STATUS,
  VisitInfo,
} from './meraki-models/calendar.models';
import { Branch, Practice } from './meraki-models/practice.models';
import { Address, Country, VISIT_TYPE } from './meraki-models/general.models';
import { PracticeProvider } from './meraki-models/provider.models';
import { InvoiceTemplate, TemplateLine } from './meraki-models/invoice-template.models';
import { Insurer } from './meraki-models/insurer.models';
import { EncounterType, PatientEventType } from './state/clinical-encounter/clinical-encounter.model';
import { BaseInvoice, INVOICE_STATUS, INVOICE_SUBTYPE, INVOICE_TYPE, InvoiceLine, MEDICINE_TYPE, PLACE_OF_SERVICE, SaveInvoiceRequest } from './meraki-models/invoice.models';
import { ACCOUNT_TYPE, Account } from './meraki-models/account.models';
import { AccountMember, IDENTIFICATION_TYPE, PatientAccount, PatientAccountResponse } from './meraki-models/member.models';
import { ERROR_CODE } from './meraki-models/error-models';
import { AdImpression } from './view-models/smartmeds-ads-models';
import { environment } from '@env/environment';
import { SlicePipe } from '@app/shared/pipes/slice.pipe';
import { b64toBlob, blobToFile } from '@app/shared/functions/b64ToBlob';
import { ApplicationInsightsService } from './application-insights.service';
import { EncounterTemplateVo } from './api-client.service';

export interface VisitReasonViewModel extends Symptom, SymptomConfiguration {
}

export interface VisitReasonQuestionViewModel extends SymptomQuestion {
  Active: boolean;
}

export interface CommonChronicConditionsVo extends ChronicConditionVo {
  CommonForSpecialties: Array<string>;
}

export interface GenericMedicineRequest {
  FsCollection: string;
  BaseGroupName: string;
  CommunityName: string;
  CohortName: string;
  BPN_TPN: string;
  ATC5Code: string;
  DrugFamilyGroup: string;
  NAPPICode7: string;
  PatientInfo: any;
  View: string;
}

export interface VaccinatedStatusVo {
  // Pfizer: Array<string> | null;
  // JohnsonAndJohnson: Array<string> | null;
  // Other: Array<string> | null;
  Notes: string | null;
  VaccineStatus: string | null;
}

const PRACTICE_ROOT = 'ClinicalPractice';
const PROVIDER_ROOT = 'ClinicalProvider';
const ENCOUNTER_ROOT = 'Encounter';
const CHRONIC_CONDITION_ROOT = 'PatientConditions';
const SMARTMEDS_AD_IMPRESSIONS_ROOT = 'SmartMedsAdImpression';
const KAHUN_PRACTICE = 'KahunPractice';
const METRICS_THRESHOLD = 700;

export enum PathReportFollowuActions {
  InTaskList = 'InTaskList',
  SmsSent = 'SmsSent',
  EmailSent = 'EmailSent',
}

@Injectable({
  providedIn: 'root',
})
export class MerakiClientService {


  private http: HttpClientMeraki;
  private baseUrl: string;
  private loadBalancerUrl: string;

  constructor(
    private httpHandler: HttpBackend, // Bypass Auth interceptor since it overrides with florence auth token.
    @Inject(HttpClientMeraki) http: HttpClientMeraki,
    @Inject(MERAKI_API_GATEWAY) baseUrl: string,
    @Inject(MERAKI_CACHE_LB) cacheLbUrl: string,
    private authService: MerakiAuthService,
    private firestore: AngularFirestore,
    private slicePipe: SlicePipe,
    private configService: ConfigService,
    private appInsightService: ApplicationInsightsService,
  ) {
    this.http = http;
    //this.http = new HttpClient(httpHandler);

    this.baseUrl = baseUrl ? baseUrl : '';
    this.loadBalancerUrl = cacheLbUrl;
  }

  getDefaultConfigurationByType(): Observable<any> {
    return this.firestore
      .collection('Configuration')
      .doc('AppointmentType')
      .get()
      .pipe(
        map(doc => doc.data()),
        first(),
      );
  }

  getVisitReasons(): Observable<VisitReasonViewModel[]> {
    return this.firestore
      .collection('ClinicalVisitReason')
      .doc('VisitReasons')
      .valueChanges()
      .pipe(
        take(1),
        map(data => Object.values(data)),
      );
  }

  getVisitReasonsByPractice(bpn: string): Observable<VisitReasonViewModel[]> {
    return this.firestore
      .collection(`Practice/${bpn}/VisitReason`)
      .doc('VisitReasons')
      .valueChanges()
      .pipe(
        take(1),
        map(data => Object.values(data || [])),
        tap(question => console.log({ question })),
      );
  }

  getVisitReasonQuestions(id: string): Observable<VisitReasonQuestionViewModel[]> {
    return this.firestore
      .collection('ClinicalVisitReason')
      .doc(id)
      .valueChanges()
      .pipe(
        take(1),
        map(data => Object?.values(data || [])),
      );
  }

  getCountries(): Observable<Country[]> {
    return this.firestore
      .collection('Countries')
      .valueChanges<string>({ idField: 'Alpha2' })
      .pipe(
        take(1),
        catchError(err => { console.error('error', err); return of([]); }),
      );
  }

  updateSpeechToNotes(tenantId: string, encounterId: string, isGood: boolean, updatedNotes: string) {
    // console.log(encounterId);
    // console.log(tenantId);
    const speechToNotesCollection = this.firestore
      .collection('ClinicalPractice')
      .doc(tenantId)
      .collection('Encounter')
      .doc(encounterId)
      .collection('SpeechToNotes')
      .doc(encounterId)
      .update({
        Rating: isGood == null ? null : (isGood ? 'Good' : 'Not Good'),
        UpdatedSpeechToNotesSummary: updatedNotes,
      });

    return from(speechToNotesCollection);
  }

  updateSpeechToNotesNoteVersion(tenantId: string, encounterId: string, noteVersion: number) {
    const speechToNotesCollection = this.firestore
      .collection('ClinicalPractice')
      .doc(tenantId)
      .collection('Encounter')
      .doc(encounterId)
      .collection('SpeechToNotes')
      .doc(encounterId)
      .update({ NoteVersion: noteVersion });

    return from(speechToNotesCollection);
  }

  createSpeechToNotesCollection(tenantId: string, encounterId: string, recordingDuration: number, micDevice: string) {
    const speechToNotesCollection = this.firestore
      .collection('ClinicalPractice')
      .doc(tenantId)
      .collection('Encounter')
      .doc(encounterId)
      .collection('SpeechToNotes')
      .doc(encounterId);

    return from(speechToNotesCollection.set({
      RecordingDuration: recordingDuration,
      MicrophoneDeviceUsed: micDevice
    }));
  }

  listenForTranscription(id: string, practiceTenantId: string): Observable<string | null> {
    return this.firestore
      .collection('ClinicalPractice')
      .doc(practiceTenantId)
      .collection('Encounter')
      .doc(id)
      .collection('SpeechToNotes')
      .doc(id)
      .valueChanges()
      .pipe(
        map(data => (data ? (data['SpeechToNotesSummary'] ? data['UpdatedSpeechToNotesSummary'] : '') : null)),
        tap(res => { }) // Map to transcription field directly
      );
  }

  getRecentCompletedEncounterId(practiceId: string, patientId: string): Observable<any> {
    // Fetch the most recent encounters without filtering by patientId
    const encounterSnapshot = this.firestore
      .collection("ClinicalPractice")
      .doc(practiceId)
      .collection("Encounter", ref => 
        ref.orderBy("EncounterLineItems.EncounterHeader.DateOfService", "desc")
           .limit(10) // Fetch more documents to filter in memory
      )
      .get();

    // Filter encounters by patientId in memory
    const filteredEncounters = encounterSnapshot.pipe(
      map(snapshot => snapshot.docs
        .map(doc => doc.data())
        .filter(encounter => 
          encounter.PatientEventDetails.Patient.PatientId === patientId &&
          encounter.Status === "Completed"
        )
      )
    );

    // filteredEncounters.pipe(
    //   take(1),
    //   tap(encounters => console.log({ encounters: encounters[0] }))
    // )
    // .subscribe();

    return filteredEncounters.pipe(
      map(encounters => encounters.length > 0 ? encounters[0].EncounterId : null)
    );
  }

  listenForLetterGeneration(practiceId: string, practiceTenantId: string, patientId: string): Observable<any> {
    return this.getRecentCompletedEncounterId(practiceTenantId, patientId)
    .pipe(
      switchMap(previousEncounterId => {
        if (!previousEncounterId) {
          return of(null);
        }

        return this.firestore
          .collection('Sandbox')
          .doc(practiceId)
          .collection('PatientLetters')
          .doc(practiceId)
          .collection('Encounters')
          .doc(previousEncounterId)
          .valueChanges()
          .pipe(
            take(1),
            map(data => (data ? data['letterHtml'] : null))
          );
      })
    );
  }

  submitSandboxUserFeedback(feedback: string, rating: string, practiceId: string, encounterId: string) {
    const patientLetterCollection = this.firestore
      .collection('Sandbox')
      .doc(practiceId)
      .collection('PatientLetters')
      .doc(practiceId)
      .collection('Encounters')
      .doc(encounterId);

    return from(patientLetterCollection.update({
      userFeedback: {
        Feedback: feedback,
        Rating: rating
      }
    }));
  }

  getSandboxProjectVotes(projectName: string): Observable<any> {
    return this.firestore
      .collection('Sandbox')
      .doc('project_votes')
      .valueChanges()
      .pipe(
        map(data => data?.[projectName] || { upvotes: 0, downvotes: 0 })
      );
  }

  updateSandboxProjectVotes(projectName: string, vote: 'up' | 'down') {
    return this.firestore
      .collection('Sandbox')
      .doc('project_votes')
      .update({
        [projectName]: {
          upvotes: vote === 'up' ? firebase.firestore.FieldValue.increment(1) : firebase.firestore.FieldValue.increment(0),
          downvotes: vote === 'down' ? firebase.firestore.FieldValue.increment(1) : firebase.firestore.FieldValue.increment(0)
        }
      });
  }

  isPracticeEnabledForSpeechToNotes(bpn: string, hpcsaNumber: string): Observable<boolean> {
    if (bpn == null) {
      return of(false);
    }
    return this.firestore
      .doc(`Practice/${bpn}`)
      .get()
      .pipe(
        map(data => {
          if (!data.exists) {
            return false;
          }
          const practice = data.data() as Practice;
          var isSpeechToNotesEnabled = practice?.Settings?.ClinicalCoPilotSettings?.SpeechToNotes?.Enabled == true;
          var isEnabledDoctorsEmpty = practice?.Settings?.ClinicalCoPilotSettings?.SpeechToNotes?.EnabledDoctors?.length == 0;

          if (isSpeechToNotesEnabled) {
            return isEnabledDoctorsEmpty
              ? true
              : practice?.Settings?.ClinicalCoPilotSettings?.SpeechToNotes?.EnabledDoctors?.includes(hpcsaNumber);
          }
        })
      );
  }

  isPracticeEnabledForSandboxProjects(bpn: string, hpcsaNumber: string): Observable<boolean> {
    if (bpn == null) {
      return of(false);
    }
    return this.firestore
      .doc(`Practice/${bpn}`)
      .get()
      .pipe(
        map(data => {
          if (!data.exists) {
            return false;
          }
          const practice = data.data() as Practice;
          var isSandboxEnabled = practice?.Settings?.SandboxSettings?.Enabled == true;
          var isEnabledDoctorsEmpty = practice?.Settings?.SandboxSettings?.EnabledDoctors?.length == 0;

          if (isSandboxEnabled) {
            return isEnabledDoctorsEmpty
              ? true
              : practice?.Settings?.SandboxSettings?.EnabledDoctors?.includes(hpcsaNumber);
          }
          return false;
        })
      );
  }

  getVisitReasonQuestionsByPractice(bpn: string, id: string): Observable<VisitReasonQuestionViewModel[]> {
    return this.firestore
      .collection(`Practice/${bpn}/VisitReason`)
      .doc(id)
      .valueChanges()
      .pipe(
        take(1),
        map(data => Object?.values(data || [])),
        tap(questions => console.log({ questions }))
      );
  }

  isPracticeEnabledForPatientAI(bpn: string): Observable<boolean> {
    if (bpn == null) {
      return of(false);
    }
    return this.firestore
      .doc(
        `Practice/${bpn}`
      ).get().pipe(
        map(data => {
          if (!data.exists) {
            return false;
          }
          const practice = data.data() as Practice;
          // support both typo version and expected version
          return practice?.Settings?.ClinicalCoPilotSettings?.PatientHistorySummary?.Enabled == true ||
            practice?.Settings?.ClincialCoPilotSettings?.PatientHistorySummary?.Enabled == true;
        }),
      );
  }

  getPatientClinicalSummary(practiceTenantId: string, patientId: string): Observable<any> {
    return this.firestore
      .collection(
        `ClinicalPractice/${practiceTenantId}/PatientClinicalSummary/${patientId}/CheckInSummary`,
        ref =>
          ref
            .orderBy('CreatedAt', 'desc') // Apply ordering within the query function
            .limit(1) // Limit to the most recent document
      )
      .valueChanges<string>({ idField: 'id' })
      .pipe(
        /* map(querySnapshot => {
          // Extract the first document from the query snapshot
          const doc = querySnapshot.docs[0];
          const summaryDoc: any = doc.data();
          summaryDoc.id = doc.id;
          return doc ? summaryDoc : null;
        }), */
        first(),
        map(doc => doc.length > 0 && doc[0]),
        map(s => s && { ...s, CreatedAt: fromTimestamp(s.CreatedAt) })
      );
  }


  logClinicalSummaryOpenEvent(
    practiceTenantId: string,
    patientId: string,
    docId: string,
  ): Observable<any> {

    // Reference to the document to update
    const documentRef = this.firestore
      .collection(`ClinicalPractice/${practiceTenantId}/PatientClinicalSummary/${patientId}/CheckInSummary`)
      .doc(docId);

    // Reference to the events collection
    const eventsCollectionRef = this.firestore.collection(
      `ClinicalPractice/${practiceTenantId}/PatientClinicalSummary/${patientId}/CheckInSummary/${docId}/Events`
    );

    return from(eventsCollectionRef.add({ openTime: new Date() })).pipe(
      switchMap(docRef => from(documentRef.update({ OpenWindow: false })).pipe(map(() => ({ success: true, eventId: docRef.id })))),
      catchError(error => {
        throw error;
      })
    );
  }


  logClinicalSummaryCloseEvent(
    practiceId: string,
    patientId: string,
    feedback: string,
    thumbState: string,
    docId: string,
    hideSummaryPreference: boolean,
    eventId: string
  ): Observable<any> {
    // Reference to the document to update
    const documentRef = this.firestore.collection(`ClinicalPractice/${practiceId}/PatientClinicalSummary/${patientId}/CheckInSummary`).doc(docId);

    // Reference to the specific event document
    const eventDocumentRef = this.firestore.collection(`ClinicalPractice/${practiceId}/PatientClinicalSummary/${patientId}/CheckInSummary/${docId}/Events`).doc(eventId);

    return from(documentRef.update({ OpenWindow: false })).pipe(
      switchMap(() =>
        from(eventDocumentRef.update({ feedback, thumbState, closeTime: new Date(), hideSummaryPreference }))
      ),
      map(() => {
        return { success: true };
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  sendEmail(request: EmailRequest): Observable<NexResponse<SendEmailResponsePayload>> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .post<NexResponse<SendEmailResponsePayload>>(`${this.baseUrl}/v1/communication/email/sendEmail`, request, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
          ),
      ),
    );
  }

  searchProcedure(query: string = '', year: number = new Date().getFullYear(), type: string = '10', page: number = 0, pageLimit: number = 10): Observable<NexResponse<any>> {
    // todo speciality code is missing
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .post<NexResponse<any>>(`${this.baseUrl}/v1/medicine/search/searchProcedure`, {
            query,
            year,
            field: 'Code',
            type,
            page,
            pageLimit,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
          ),
      ),
    );
  }

  requestPatientSummary(provider: ProviderConfigurationVo, patientEvent: PatientEventVo) {
    var request = {
      "BPN": provider.PracticeNumber,
      "TPN": provider.TreatingPracticeNumber,
      "PracticeTenantId": provider.PracticeTenantId,
      "PracticeId": provider.PracticeId,
      "PatientId": patientEvent.Patient.PatientXRef,
      "PatientUUID": patientEvent.Patient.PatientId,
      "EventId": patientEvent.PatientEventId,
      "UpdatedDate": firebase.firestore.FieldValue.serverTimestamp(),
    };
    return this.firestore
      // .collection('Practice')
      // .doc(provider.PracticeNumber)
      .collection('ClinicalPractice')
      .doc(provider.PracticeTenantId)
      .collection('CalendarEventCheckin')
      .doc(patientEvent.PatientEventId)
      .set(request, { merge: true });

    /* return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http // todo base url via gateway
          //.post<any>(`${this.baseUrl}/clinicialai-create-patient-timeline-summary-http`,
          .post<any>(`https://europe-west1-healthbridge-meraki-dev.cloudfunctions.net/clinicialai-create-patient-timeline-summary-http`,
            request,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Skip-Progress-Bar': 'true', // skip progress bar as call is long and we don't want to show annoying loader
              },
            })
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
          ),
      ),
    ); */
  }

  loadBenefitCheckReport(bpn: string, referenceNum: string): Observable<string> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .get<NexResponse<any>>(`${this.baseUrl}/v1/calendar/bcpdf/${bpn}/${referenceNum}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
            map(response => response.text),
            map(response => response?.replace('data:application/pdf;base64,', "")),
            map(response => URL.createObjectURL(blobToFile(b64toBlob(response, 'application/pdf;base64'), referenceNum + '.pdf')))
          ),
      ),
    );
  }

  /* getAccountFinancialInformation(bpn: string, accountXRef: string): Observable<AccountInfoVo> {
    return this.firestore.collection('Practice')
      .doc(bpn)
      .collection('Account')
      .doc(accountXRef)
      .valueChanges()
      .pipe(
        take(1),
        map(s => ({
          AccountLiable: s.AccountBalance.PatientLiable,
          LastBenefitCheckStatus: 'todo',
          LastBenefitCheckReportUrl: 'todo',
          LastBenefitCheckDate: null,
          LastBcContractData: 'todo'
        } as AccountInfoVo))
      );
  } */

  getAllMedicalInsurers() {
    return this.firestore
      .collection('Configuration')
      .doc('SchemeLookup')
      .valueChanges()
      .pipe(
        first(),
        map((item: any) => item.MedicalInsurers),
        map((insurers: { [key: string]: any }) =>
          Object.keys(insurers)
            .reduce((result, insurerCode) => {
              result.push({ ...insurers[insurerCode], Code: insurerCode, Id: insurerCode } as Insurer);
              return result;
            }, [])
            .sort((i1, i2) => i1?.Name?.localeCompare(i2.Name)),
        ),
        map((items: Insurer[]) => items.map(item => ({
          Name: item.Name,
          Email: item.Email,
          AccountNo: item.Code,
          Id: item.Code,
          InsurerXRef: item.Id,
          Phone1: item.Phone,
        } as MedicalInsurerVo))),
      );
  }

  getAllMedicalAids() {
    return this.firestore
      .collection('SchemePlanOption')
      //    .valueChanges<string>({ idField: 'Id' })
      .valueChanges()
      .pipe(
        take(1),
      );
  }

  loadCompletedInvoices(bpn: string, patientXref: string): Observable<BaseInvoice[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Invoice', r =>
        r.where('Patient.Id', '==', patientXref)
          //.where('Source', '!=', INVOICE_SOURCE.CLINICAL) // todo can be improved but need index
          .where('Status', '==', INVOICE_STATUS.OPEN),
      ).valueChanges<string>({ idField: 'Id' }).pipe(
        take(1),
        map(s => s.filter(t => t.Source != 'Clinical').map(s => ({
          ...s,
          DateOfService: fromTimestamp(s.DateOfService),
          InvoiceDate: fromTimestamp(s.InvoiceDate),
        }))),
      );
  }

  getPracticeCalendarAppointments(bpn: string, providerIds: string[], from: Date, to?: Date): Observable<CalendarEventVo[]> {
    if (providerIds.length == 0) {
      return of([]);
    }
    const ignoreVisitTypes = [CALENDAR_EVENT_STATUS.DELETED, CALENDAR_EVENT_STATUS.CANCELLED, CALENDAR_EVENT_STATUS.NO_SHOW];

    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent', r =>
        r.where('TreatingProvider', 'in', providerIds)
          //.where('Branch', '==', '') // todo get branch? or create other index
          .where('EndTime', '>=', from)
          .where('EndTime', '<=', to),
      )
      .valueChanges<string>({ idField: 'Id' }).pipe(
        // take(1) // todo confirm if we want to listen
        map(events => events
          .filter(item => !ignoreVisitTypes.includes(item.Status))
          .map((item: CalendarEvent | any) => ({
            PatientName: item.VisitInfo?.PatientInfo?.Name || '',
            PatientSurname: item.VisitInfo?.PatientInfo?.Surname || '',
            AppointmentType: item.VisitInfo?.VisitType, // todo confirm types matching
            AppointmentReason: item.VisitInfo?.VisitReasonDescription || '',
            AppointmentTitle: '',
            // nova save dates in firestore as local time (later each practice will have own timezone configuration)
            StartDate: moment(item.StartTime?.toDate()).add(-moment().utcOffset(), 'minutes').toDate(),
            EndDate: moment(item.EndTime?.toDate()).add(-moment().utcOffset(), 'minutes').toDate(),
            AppointmentStatus: item.Status, // todo confirm statuses
            BenefitCheckStatus: item.VisitInfo?.BenefitCheckStatus,
            CalendarEventXRef: item.Id?.toString(), // get id from document ref
            PatientId: item.VisitInfo?.PatientInfo?.UUID || guidByString(item.VisitInfo?.PatientInfo?.PatientId?.toString() || '', 5), // todo confirm convert
            PatientXRef: item.VisitInfo?.PatientInfo?.PatientId,
            Invoiced: item.VisitInfo?.Invoiced,
            PracticeId: item.TreatingProvider, // set hpcsaNo, on parent level will be changed to PracticeId
            Type: item.EventType == 'Doctor leave' ? 'Doctor unavailable'
              : 'Appointment', // map to mymps type
          } as CalendarEventVo))),
      );
  }

  checkinInPatient(bpn: string, patient: PatientVo, provider: ProviderConfigurationVo): Observable<RestApiResultOfCheckinPatientVisitResult> {
    const patientVisit = {
      ClinicalStatus: CALENDAR_EVENT_STATUS.CHECKED_IN,
      // AD intentionally don't set Status to keep visit hidden for nova user
      //Status: CALENDAR_EVENT_STATUS.CHECKED_IN,
      TreatingProvider: provider.HPCSANumber,
      Id: uuidV4(),
      StartTime: moment().toDate(),
      // EndTime: visit.CheckInTime ,//todo add 5min time
      //IsWalkIn: true,
      EventType: CALENDAR_EVENT_TYPE.PATIENT_VISIT,
      VisitInfo: this.buildPatientInfo(patient),
    } as CalendarEvent;

    return from(this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent')
      .doc(patientVisit.Id)
      .set(this.dropNullUndefined(patientVisit), { merge: true }),
    ).pipe(
      map(s => ({ Sucess: true, Data: {} } as RestApiResultOfCheckinPatientVisitResult)),
    );
  }


  checkinInProgressPatient(bpn: string, visit: PatientEventVo, provider: ProviderConfigurationVo): Observable<RestApiResultOfCheckinPatientVisitResult> {
    // TODO check if endpoint should be called to create visit
    const patientVisit = {
      ClinicalStatus: CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS,
      // AD intentionally don't set Status to keep visit hidden for nova user
      //Status: CALENDAR_EVENT_STATUS.CHECKED_IN,
      TreatingProvider: provider.HPCSANumber,
      Id: visit.PatientEventXRef,
      StartTime: visit.CheckInTime,
      // EndTime: visit.CheckInTime ,//todo add 5min time
      IsWalkIn: true,
      EventType: CALENDAR_EVENT_TYPE.PATIENT_VISIT,
      VisitInfo: {
        ...this.buildPatientInfo(visit.Patient),
        VisitType: this.toVisitType(visit.Type),
        IsWalkIn: true, //exists on both levels
      },
    } as CalendarEvent;

    return from(this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent')
      .doc(visit.PatientEventXRef)
      .set(this.dropNullUndefined(patientVisit), { merge: true }),
    ).pipe(
      map(s => ({ Sucess: true, Data: {} } as RestApiResultOfCheckinPatientVisitResult)),
    );
  }

  private buildPatientInfo(patient: PatientVo): VisitInfo {
    return {
      //CheckInTime: moment().toDate(),
      PatientInfo: {
        UUID: patient.PatientId,
        Name: patient.PatientDetails.FirstName,
        Surname: patient.PatientDetails.Surname,
        Cellphone: patient.PatientDetails.ContactNo,
        DateOfBirth: patient.PatientDetails.DateOfBirth,
        Email: patient.PatientDetails.EmailAddress,
        FileNumber: patient.FileNo,
        IdentityNo: patient.PatientDetails.IdentityNo,
        PatientId: patient.PatientXRef,
        DependantCode: patient.PatientAccountDetails.MedicalAidDependentCode,
      },
      AccountInfo: {
        AccountId: patient.PatientAccountDetails.AccountId,
        AccountNo: patient.PatientAccountDetails.AccountNo,
        SchemeName: patient.PatientAccountDetails.MedicalAidName,
        PlanName: patient.PatientAccountDetails.MedicalAidPlan,
        //OptionName: visit.Patient.PatientAccountDetails.MedicalAidPlanOption
      },
      CashAccount: patient.PatientAccountDetails.IsCashAccount,
      VisitType: VISIT_TYPE.REGULAR_CONSULTATION,
    };
  }

  toVisitType(type: string): VISIT_TYPE {
    if (type == PatientEventType.MedicalInsuranceType) {
      return VISIT_TYPE.MEDICAL_INSURANCE;
    }
    if (type == PatientEventType.RegularConsultationType) {
      return VISIT_TYPE.REGULAR_CONSULTATION;
    }
    if (type == PatientEventType.NoChargeType) {
      return VISIT_TYPE.NO_CHARGE;
    }
    if (type == PatientEventType.OutOfRoomsType) {
      return VISIT_TYPE.OUT_OF_ROOMS;
    }

    return VISIT_TYPE.CUSTOM;
  }

  markVisitInProgress(bpn: string, visit: PatientEventVo): Observable<RestApiResultOfCheckinPatientVisitResult> {
    return from(this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent')
      .doc(visit.PatientEventXRef)
      .set({ ClinicalStatus: CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS }, { merge: true }),
    ).pipe(
      map(s => ({ Sucess: true, Data: {} } as RestApiResultOfCheckinPatientVisitResult)),
    );
  }

  completeVisit(bpn: string, visit: PatientEventVo): Observable<RestApiResultOfCheckinPatientVisitResult> {
    return from(this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent')
      .doc(visit.PatientEventXRef)
      .set({ ClinicalStatus: CALENDAR_EVENT_STATUS.CHECKED_OUT }, { merge: true }),
    ).pipe(
      map(s => ({ Sucess: true, Data: {} } as RestApiResultOfCheckinPatientVisitResult)),
    );
  }

  cancelPatientVisit(bpn: string, hpcsaNo: string, xref: string): Observable<RestApiResultOfGuid> {
    return from(this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent')
      .doc(xref)
      // todo confirm if we okay to cancel only doctor's visit
      .set({ ClinicalStatus: CALENDAR_EVENT_STATUS.CANCELLED }, { merge: true }),
    ).pipe(
      map(s => ({ Sucess: true } as RestApiResultOfGuid)),
    );
  }

  /* getTemplatePage(bpn: string, hpcsaNo, templateType: string, pageSize: number, pageNo: number, search: string): Observable<TemplateSummaryVo[]> {
    // adjust type to not overcomplicate tweaking logic on higher level
    let type = templateType;
    if (templateType == 'Template') {
      type = 'Invoice';
    }
    return this.firestore.collection('Practice').doc(bpn)
      .collection('Provider').doc(hpcsaNo).collection('InvoiceTemplate', r => r.where('Type', '==', type))
      .valueChanges().pipe(
        tap(c => console.warn('templates', c)),
        map((templates: InvoiceTemplate[]) => templates.flatMap(template =>
          template.IsGroup
            ? [...template.Templates.map(t => ({
              Caption: t.Caption,
              Code: t.Description,// confirm
              Description: t.Description,
              SortOrder: t.SortOrder,
              TemplateType: templateType,
              TemplateXRef: t.Id || t.Caption,
              GroupInvoiceTemplateXRef: t.Id,// todo confirm
            } as TemplateSummaryVo))]
            : [({
              Caption: template.Caption,
              Code: template.Description,// confirm
              Description: template.Description,
              SortOrder: template.SortOrder,
              TemplateType: templateType,
              TemplateXRef: template.Id || template.Caption,
              GroupInvoiceTemplateXRef: template.Id,// todo confirm
            } as TemplateSummaryVo)])
        )
      )
  } */

  createTemplateGroup(bpn: string, hpcsaNo: string, templateType: string, request: TemplateSummaryVo) {
    return from(this.firestore.collection('Practice').doc(bpn)
      .collection('Provider').doc(hpcsaNo)
      .collection('InvoiceTemplate').doc()
      .set(({
        Caption: request.Caption,
        Description: request.Description,
        IsGroup: true,
        IsActive: true,
        SortOrder: 0,
        Type: templateType == 'Template' ? 'Invoice' : templateType,
      } as InvoiceTemplate)));
  }

  deleteTemplate(bpn: string, hpcsaNo: string, xref: string): Observable<RestApiResultOfBoolean> {
    const templateRef = this.firestore.collection('Practice').doc(bpn)
      .collection('Provider').doc(hpcsaNo)
      .collection('InvoiceTemplate');

    return from(templateRef.doc(xref).set({ 'IsActive': false }, { merge: true })).pipe(mapTo({
      Sucess: true,
      Data: true,
    }));
  }

  deleteTemplateFromGroup(bpn: string, hpcsaNo: string, groupXRef: string, caption: string): Observable<RestApiResultOfBoolean> {
    const templateRef = this.firestore.collection('Practice').doc(bpn)
      .collection('Provider').doc(hpcsaNo)
      .collection('InvoiceTemplate');

    return templateRef.doc(groupXRef)
      .valueChanges()
      .pipe(
        take(1),
        switchMap(group =>
          from(templateRef.doc(groupXRef)
            .set({
              ...group,
              Templates: [
                ...(group?.Templates || []).filter(s => s.Caption != caption),
              ],
            }, { merge: true })),
        ),
        catchError((err) => {
          console.error('saving error', err);
          return of(null);
        }),
        mapTo({ Sucess: true, Data: true }),
      );
  }

  updateTemplate(bpn: string, hpcsaNo: string, template: EncounterTemplateVo, existingTemplates: TemplateSummaryVo[]): Observable<RestApiResultOfString> {
    const request = this.dropNullUndefined(this.fromEncounterTemplate(template));

    const templateRef = this.firestore.collection('Practice').doc(bpn)
      .collection('Provider').doc(hpcsaNo)
      .collection('InvoiceTemplate');
    // adding to group if caption contains '|'
    // (following old drapp communication principle to not refactor other components)

    if (template.Caption.includes('|')) {
      const groupRef = template.Caption.split('|')[0];
      const caption = template.Caption.split('|')[1];

      if (!template.TemplateXref && existingTemplates.filter(s => s.Caption == caption && s.GroupInvoiceTemplateXRef == groupRef).length > 0) {
        return of({
          Data: '',
          Sucess: false,
          ResponseMessage: 'Name of favorite is DUPLICATE',
        } as RestApiResultOfString);
      }

      return templateRef.doc(groupRef)
        .valueChanges()
        .pipe(
          take(1),
          switchMap(group =>
            from(templateRef.doc(groupRef)
              .set({
                ...group,
                Templates: [
                  ...group?.Templates || [],
                  {
                    ...request,
                    Caption: template.Caption.split('|')[1],
                  }],
              }, { merge: true })),
          ),
          mapTo({ Sucess: true }),
          catchError((err) => {
            console.error('saving error', err);
            return of(null);
          }),
        );
    }

    if (!template.TemplateXref && existingTemplates.filter(s => s.Caption == template.Caption && s.GroupInvoiceTemplateXRef == null).length > 0) {
      return of({ Data: '', Sucess: false, ResponseMessage: 'Name of favorite is DUPLICATE' } as RestApiResultOfString);
    }

    return (template.TemplateXref
      ? from(templateRef.doc(template.TemplateXref).set(request, { merge: true }))
      : from(templateRef.doc().set(request))
    ).pipe(
      mapTo({ Sucess: true }),
    );
  }

  getInvoiceTemplateGroup(bpn: string, hpcsaNo: string, templateType: string): Observable<TemplateSummaryVo[]> {
    return this.firestore.collection('Practice').doc(bpn)
      .collection('Provider').doc(hpcsaNo).collection('InvoiceTemplate',
        r => r.where('Type', '==', templateType).where('IsGroup', '==', true).where('IsActive', '==', true))
      .valueChanges<string>({ idField: 'Id' }).pipe(
        //take(1), // todo confirm if easy to make real time
        map(templates => templates.map((t: InvoiceTemplate) =>
        ({
          Caption: t.Caption,
          Code: t.Description,// confirm
          Description: t.Description,
          SortOrder: t.SortOrder,
          TemplateType: templateType,
          TemplateXRef: t.Id || t.Caption,
          GroupInvoiceTemplateXRef: t.Id,
        } as TemplateSummaryVo))),
        map(list => _.chain(list).orderBy('Caption', 'asc').value()),
      );
  }

  getInvoiceTemplateGroupContent(bpn: string, hpcsaNo: string, templateType: string, groupXref: string): Observable<TemplateSummaryVo[]> {
    return this.firestore.collection('Practice').doc(bpn)
      .collection('Provider').doc(hpcsaNo).collection('InvoiceTemplate').doc(groupXref).get().pipe(
        take(1),
        map(s => s.data() as InvoiceTemplate),
        map(s => s.Templates.map(t => ({
          Caption: t.Caption,
          Code: t.Description,// confirm
          Description: t.Description,
          SortOrder: t.SortOrder,
          TemplateType: templateType,
          TemplateXRef: t.Id || t.Caption,
          GroupInvoiceTemplateXRef: groupXref,
          OriginalTemplate: t,
        } as TemplateSummaryVo))),
      );
  }

  getInvoiceTemplates(bpn: string, hpcsaNo, templateType: string, pageSize: number, pageNo: number, search: string): Observable<EncounterTemplateVo[]> {
    // adjust type to not overcomplicate tweaking logic on higher level
    let type = templateType;
    if (templateType == 'Template') {
      type = 'Invoice';
    }
    return this.firestore.collection('Practice').doc(bpn)
      .collection('Provider').doc(hpcsaNo).collection('InvoiceTemplate', r => r.where('Type', '==', type).where('IsActive', '==', true))
      .valueChanges<string>({ idField: 'Id' }).pipe(
        //take(1), // todo we can't set otherwise only load local cache version and no server call
        map((templates: InvoiceTemplate[]) => templates.flatMap(template =>
          template.IsGroup
            ? [...template.Templates?.map(t => ({ ...this.toEncounterTemplate(t, templateType, template.Id) })) || []]
            : [this.toEncounterTemplate(template, templateType, null)]),
        ),
        map(templates => search && templates.filter(t => t.Caption?.toLocaleLowerCase().includes(search.toLowerCase())) || templates),
        map(templates => templates.slice(pageNo * pageSize, pageNo * pageSize + pageSize)),
        map(list => _.chain(list).orderBy('Caption', 'asc').value()),
      )
  }

  private fromEncounterTemplate(template: EncounterTemplateVo): InvoiceTemplate {
    return ({
      Caption: template.Caption,
      Description: template.Description,
      IsGroup: false,
      IsActive: true,
      SortOrder: 0,
      Type: template.TemplateType == 'MyTemplate' ? 'Invoice' : template.TemplateType,
      Diagnosis: template.EncounterLineItems.EncounterHeader.Diagnosis.map((s, index) => ({
        Code: s.DiagnosisCode,
        Description: s.DiagnosisDescription,
        OrderNo: index,
      })),
      Lines: [...template.EncounterLineItems.LineItems.map(s => ({
        Diagnoses: s.Diagnosis.map((d, index) => ({
          Code: d.DiagnosisCode,
          Description: d.DiagnosisDescription,
          OrderNo: index,
        })),
        ChargeCode: s.ChargeCode,
        Description: s.ChargeDesc,
        Quantity: s.ChargeQuan,
        LineType: s.LineType,
        LineNumber: s.LineNum,
        NappiCode: s.NappiCode,
        DosageType: s.DosageType,
        FrequencyType: s.FrequencyType,
        DurationType: s.DurationType,
        DurationUnit: s.DurationUnit,
        DosageUnit: s.DosageUnit,
        PeriodType: s.PeriodType,
        PeriodUnit: s.PeriodUnit,
        Repeat: s.Repeat,
        ChronicIndicator: s.ChronicIndicator,
        Route: s.Parameters?.RouteOfAdministration,
        FrequencyUnit: s.FrequencyUnit,
        AdditionalInstructions: s.Parameters?.InformationText,
        Instructions: s.Parameters?.AdditionalInstructionSelections?.split(', '),
        // new field in model, forge doesn't know about it
        DosageDescription: s.Parameters?.DosageDescription,
        Dispense: s.LineType == 'Medicine',

      } as TemplateLine)),
      ...template.MedicationPrescriptionLines.map(s => ({
        Diagnoses: s.Diagnosis.map((d, index) => ({
          Code: d.DiagnosisCode,
          Description: d.DiagnosisDescription,
          OrderNo: index,
        })),
        LineType: 'Medicine',
        Description: s.MedicationDescription,
        MedicationDescription: s.MedicationDescription,
        Dispense: false,
        Quantity: s.Quantity,
        LineNumber: s.LineNum,
        NappiCode: s.NappiCode,
        DosageType: s.DosageType,
        FrequencyUnit: s.FrequencyUnits,
        DurationType: s.DurationType,
        DurationUnit: s.DurationUnit,
        DosageUnit: s.DosageUnits,
        PeriodType: s.PeriodType,
        PeriodUnit: s.PeriodUnit,
        Repeat: s.Repeat,
        ChronicIndicator: s.ChronicIndicator,
        DispensingInstructions: s.DispensingInstructions,
        AdditionalInstructions: s.Parameters?.InformationText,
        Instructions: s.Parameters?.AdditionalInstructionSelections?.split(', '),
        // new field in model, forge doesn't know about it
        DosageDescription: s.Parameters?.DosageDescription,
      } as TemplateLine)),
      ],
      // (AD) prescriptions model of template service NOT used here same as via mymps integration
      // instead used Dispense flag to diff between dispense/prescribe so then we could quickly prescribe dispensed med and vice versa
      /* Prescriptions: template.MedicationPrescriptionLines.map(s => ({
        Description: s.Description,
        Quantity: s.Quantity,
        LineNumber: s.LineNum,
        NappiCode: s.NappiCode,
        DosageType: s.DosageType,
        FrequencyUnit: s.FrequencyUnits,
        DurationType: s.DurationType,
        DurationUnit: s.DurationUnit,
        DosageUnit: s.DosageUnits,
        PeriodType: s.PeriodType,
        PeriodUnit: s.PeriodUnit,
        Repeat: s.Repeat,
        ChronicIndicator: s.ChronicIndicator,
        DispensingInstructions: s.DispensingInstructions,
        MedicationDescription: s.MedicationDescription,
      } as InvoicePrescription)) */
    } as InvoiceTemplate);
  }

  toEncounterTemplate(t: InvoiceTemplate, templateType: string, groupId?: string): EncounterTemplateVo {
    return {
      Caption: t.Caption,
      Code: t.Description,
      Description: t.Description,
      SortOrder: t.SortOrder,
      TemplateType: templateType,
      TemplateXref: t.Id || t.Caption,
      TemplateId: t.Id || t.Caption, // todo templates in group doesn't have id
      GroupInvoiceTemplateXRef: groupId,
      EncounterLineItems: {
        EncounterHeader: {
          Diagnosis: t.Diagnosis?.map(d => ({
            DiagnosisCode: d.Code,
            DiagnosisDescription: d.Description,
          } as DiagnosisVo2)) || [],
        },
        LineItems: t.Lines.map((s: TemplateLine | any) => ({
          ChargeCode: s.ChargeCode,
          ChargeDesc: s.Description,
          ChargeQuan: s.Quantity,
          LineType: s.LineType,
          LineNum: s.LineNumber,
          NappiCode: s.NappiCode,
          DosageType: s.DosageType || s.UserDefinedDosageType,
          FrequencyType: null, //todo
          DurationType: s.DurationType,
          DurationUnit: s.DurationUnit,
          DosageUnit: s.DosageUnit,
          PeriodType: s.PeriodType,
          PeriodUnit: s.PeriodUnit,
          Repeat: s.Repeat,
          ChronicIndicator: s.ChronicIndicator,
          FrequencyUnit: s.FrequencyUnit,
          Parameters: s.LineType == 'Medicine' && {
            RouteOfAdministration: s.Route,
            AdditionalInstructionSelections: s.AdditionalInstructionSelections || '',
            InformationText: s.AdditionalInstructions,
            DosageDescription: s.DosageDescription,
            MedicineType: s.Dispense ? 'Dispense' : 'Prescribe',
          } || null,
        } as EncounterLineItemVo)),
      },
    } as EncounterTemplateVo;
  }

  getSupportingProviders(bpn: string): Observable<SupportingProviderVo[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Provider')
      .valueChanges()
      .pipe(
        first(),
        map((providers: PracticeProvider[]) => providers
          // todo check how to get assisting/referring provider
          // .filter(p => p.Type == ProviderType.EXTERNAL)
          .map(p => ({
            Name: p.Name,
            FriendlyName: `${p.Title ?? ''} ${p.Name} ${p.Surname}`.trim(),
            HpcsaNumber: p.HPCSANumber,
            Surname: p.Surname,
            Title: p.Title,
            TreatingPracticeNumber: p.TreatingPracticeNumber,
            Type: p.Type, // todo check what types exist
            SupportingProviderXRef: p.HPCSANumber,
          } as SupportingProviderVo))),
      );
  }

  getPracticeBranches(bpn: string): Observable<ProviderBranchConfigurationVo[]> {
    return forkJoin([
      this.firestore
        .collection('Practice')
        .doc(bpn)
        .valueChanges<string>({ idField: 'Id' })
        .pipe(first()),
      this.firestore
        .collection('Practice')
        .doc(bpn)
        .collection('Provider')
        .valueChanges<string>({ idField: 'Id' })
        .pipe(first()),
    ]).pipe(
      map(([practice, providers]: [Practice, PracticeProvider[]]) =>
        // TODO: depend on what data forge gonna include in provider object we might need to use branch info (addresses mainly)
        !practice.IsMultiBranch
          ? []
          : practice.Branches.map((b: Branch) => ({
            BranchName: b.Name,
            BranchId: guidByString(b.Name, 5),
            BranchXRef: b.Name,
            IsMainBranch: b.IsMainBranch,
            Enabled: b.Active,
            PhoneNumber: b.ContactDetails.OfficeNo,
            PhysicalAddress: this.addressToString(b.PhysicalAddress),
            PostalAddress: this.addressToString(b.PostalAddress),
            ProviderInfos: providers
              ?.flatMap(d => d.Common?.AssignedBranches
                .filter(provider => provider.BranchName == b.Name)
                .map(s => ({
                  IsDefaultBranch: s.IsDefault,
                  DispensingLicenseNumber: s.DispensingLicenceNo,
                  IsDispensing: s.IsDispensing,
                  ProviderXRef: d.Id,
                } as BranchProviderInfoVo) || []))
              .filter(s => !!s),
          } as ProviderBranchConfigurationVo),
          )));
  }

  addressToString(address: Address): string {
    return `${address.Line1}\n${address.Line2}\n${address.Line3}\n${address.Code}`;
  }

  getPatientVisitHistory(bpn: string, hpcsaNo: string): Observable<PatientEventsByDateVo[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent', r => r
        .where('TreatingProvider', '==', hpcsaNo)
        .where('StartTime', '>', moment().add(-21, 'day').toDate())
      )
      .valueChanges<string>({ idField: 'Id' }).pipe(
        map(events => events
          .map((calendar: CalendarEvent | any) =>
          ({
            PatientEvent: this.toPatientEvent(calendar, null),
            BranchName: calendar.Branch,
            EncounterType: 'todo'
          } as PatientEventView))
        ),
        map(list => _.chain(list).filter(s => [
          PatientEventVoStatus._1,
          PatientEventVoStatus._2,
          PatientEventVoStatus._3,
          PatientEventVoStatus._4
        ].includes(s.PatientEvent.Status)).value()),
        map(list => _.chain(list)
          .groupBy(s => moment(s.PatientEvent.CheckInTime).format('YYYY-MM-DD'))
          .map(values => ({
            Date: values[0].PatientEvent.CheckInTime,
            PatientEvents: _.chain(values).orderBy(it => it?.PatientEvent.CheckInTime, 'desc').value(),
          }))
          .orderBy(s => s.Date, 'desc')
          .value(),
        ),
      );
  }

  getPatientDetails(bpn: string, id: string) {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Patient')
      .doc(id).get().pipe(
        take(1),
        map(ref => {
          if (!ref.exists) {
            return null;
          }
          const data = ref.data();

          return ({
            ...data,
            Id: ref.id,
            DateOfBirth: fromTimestamp(data['DateOfBirth'])
          } as AccountMember);
        })
      );
  }

  getAccountDetails(bpn: string, accountId: string) {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('Account')
      .doc(accountId).get().pipe(
        take(1),
        map(ref => {
          if (!ref.exists) {
            return null;
          }
          const data = ref.data();
          return ({
            ...data,
            Id: ref.id,
            LastBenefitCheckDate: fromTimestamp(data['LastBenefitCheckDate'])
          } as Account);
        })
      );
  }

  getWaitingRoomsForPractice(bpn: string, providers: ProviderConfigurationVo[]): Observable<PatientInWaitingRoom[]> {
    return this.firestore
      .collection('Practice')
      .doc(bpn)
      .collection('CalendarEvent', r => r.where('ClinicalStatus', 'in', [CALENDAR_EVENT_STATUS.CHECKED_IN, CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS]))
      .valueChanges<string>({ idField: 'Id' }).pipe(
        //take(1), // todo confirm if we can have active listener
        map(events => events
          // filter out events if they cancelled on nova and there is no active encounter on our side
          .filter((d: CalendarEvent) => d.ClinicalStatus == CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS || d.Status == CALENDAR_EVENT_STATUS.CHECKED_IN || d.ClinicalStatus == CALENDAR_EVENT_STATUS.CHECKED_IN)
          .map((d: CalendarEvent | any) => ({
            PatientEvent: this.toPatientEvent(d, providers.find(p => p.HPCSANumber == d.TreatingProvider)?.PracticeId),
          }))),
        map(list => _.chain(list).orderBy(it => it?.PatientEvent?.CheckInTime, 'asc').value()),
      );
  }

  private toPatientEvent(calendar: CalendarEvent | any, practiceId): PatientEventVo {

    const visitType = this.toPatientEventType(calendar.VisitInfo?.VisitType);
    const patientVisit = {
      PatientEventId: calendar.Id && guidByString(calendar.Id, 5),
      PracticeId: practiceId,
      BenefitCheckStatus: calendar.VisitInfo?.BenefitCheckStatus,
      BenefitCheckPdfLink: null, // todo no link on nova
      CheckInTime: calendar.StartTime?.toDate(),
      CompletedTime: this.getVisitDateForStatus(calendar.Statuses, CALENDAR_EVENT_STATUS.CHECKED_OUT),
      ScheduledTime: this.getVisitDateForStatus(calendar.Statuses, CALENDAR_EVENT_STATUS.BOOKED),
      PatientEventXRef: calendar.Id,
      VideoChatLink: null, // todo not supported yet
      Status: this.toPatientStatus(calendar.ClinicalStatus),
      Type: visitType,
      Cancelled: calendar?.ClinicalStatus == CALENDAR_EVENT_STATUS.CANCELLED,
      CheckedIn: calendar?.ClinicalStatus == CALENDAR_EVENT_STATUS.CHECKED_IN,
      Completed: calendar?.ClinicalStatus == CALENDAR_EVENT_STATUS.CHECKED_OUT,
      ProviderNote: calendar.VisitInfo?.NoteToProvider,
      MedicalInsurance: visitType == PatientEventType.MedicalInsuranceType && {
        Broker: calendar.VisitInfo.PatientInfo.InsuranceBroker,
        MedicalInsurer: {
          Id: calendar.VisitInfo.PatientInfo.Insurer,
          // todo fill
        },
        PolicyNo: calendar.VisitInfo.PatientInfo.InsurancePolicyNo,
        PatientIdentityNo: calendar.VisitInfo.PatientInfo.IdentityNo,
      } || null,
      BranchName: calendar.Branch,
      BranchXRef: calendar.Branch, // branch name is id in nova
      IsClinicalVisit: !calendar.Status, // if status not provided means we created this visit
      Patient: {
        PatientId: calendar.VisitInfo?.PatientInfo?.UUID || guidByString(calendar.VisitInfo?.PatientInfo?.PatientId || 's', 5) || null,
        PatientXRef: calendar.VisitInfo?.PatientInfo?.PatientId,
        PatientDetails: {
          FirstName: calendar.VisitInfo?.PatientInfo?.Name,
          Surname: calendar.VisitInfo?.PatientInfo?.Surname,
          ContactNo: calendar.VisitInfo?.PatientInfo?.Cellphone,
        },
      },
    } as PatientEventVo;
    return patientVisit;
  }

  getVisitDateForStatus(statuses: CalendarEventStatusHistory[], status: CALENDAR_EVENT_STATUS): Date {
    return (statuses?.find((st) => st.Status == status) as any)?.AppliedAt?.toDate();
  }

  toPatientEventType(visitType: VISIT_TYPE): string {
    if (VISIT_TYPE.MEDICAL_INSURANCE == visitType) {
      return PatientEventType.MedicalInsuranceType;
    }
    if (VISIT_TYPE.NO_CHARGE == visitType) {
      return PatientEventType.NoChargeType;
    }
    if (VISIT_TYPE.REGULAR_CONSULTATION == visitType) {
      return PatientEventType.RegularConsultationType;
    }
    if (VISIT_TYPE.OUT_OF_ROOMS == visitType) {
      return PatientEventType.OutOfRoomsType;
    }
    // todo not all types are supported

    return;
  }

  toPatientStatus(status: CALENDAR_EVENT_STATUS | CLINICAL_CALENDAR_EVENT_STATUS): PatientEventVoStatus {
    /** 0 = Scheduled, 1 = CheckedIn, 2 = InProgress, 3 = Completed, 4 = Canceled, 5 = Deleted */
    if (status == CALENDAR_EVENT_STATUS.BOOKED) {
      return PatientEventVoStatus._0;
    }
    if (status == CALENDAR_EVENT_STATUS.CHECKED_IN) {
      return PatientEventVoStatus._1;
    }
    // own status
    if (status == CLINICAL_CALENDAR_EVENT_STATUS.IN_PROGRESS) {
      return PatientEventVoStatus._2;
    }
    if (status == CALENDAR_EVENT_STATUS.CHECKED_OUT) {
      return PatientEventVoStatus._3;
    }
    if (status == CALENDAR_EVENT_STATUS.CANCELLED) {
      return PatientEventVoStatus._4;
    }
    if (status == CALENDAR_EVENT_STATUS.DELETED) {
      return PatientEventVoStatus._5;
    }
  }

  priceEncounter(practiceid: string, encounterId: string, encounterVo: EncounterVo, provider: ProviderConfigurationVo): Observable<RestApiResultOfEncounterVo> {
    // todo private rate is not part of
    /** 0 = Uknown, 2 = Telehealth, 11 = ConsultingRooms, 21 = InPatientHospital, 24 = DayClinic */
    const placeOfServiceCode = encounterVo.EncounterLineItems.EncounterHeader.PlaceOfService;
    let placeOfService = 'OH';
    if (placeOfServiceCode == EncounterHeaderVoPlaceOfService._21) {
      placeOfService = 'IH';
    } else if (placeOfServiceCode == EncounterHeaderVoPlaceOfService._24) {
      placeOfService = 'DC';
    }
    return forkJoin([...encounterVo.EncounterLineItems.LineItems.map(line => {
      const year = moment(encounterVo.EncounterLineItems.EncounterHeader.DateOfService).year().toString();
      const optionCode = encounterVo.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidOptionCode;

      if (line.LineType === 'Procedure') {
        const request = {
          type: 'Procedure',
          placeOfService: placeOfService,
          date: encounterVo.EncounterLineItems.EncounterHeader.DateOfService,
          disciplineCode: provider.SpecialityCode,
          planOptionCode: optionCode,
          priceCode: line.ChargeCode,
          practiceId: provider.PracticeNumber,
          providerId: provider.HPCSANumber,
          quantity: line.ChargeQuan,
          isMedicalAid: encounterVo.EncounterType == EncounterType.MedicalAidInvoice,
        } as SinglePriceRequest;
        return this.getSinglePrice(request).pipe(
          map(s => this.addPricingFields(line, s)),
          catchError((err) => throwError(`Unable to price ${line.LineType} line item: ${line.ChargeCode}, ${line.ChargeDesc} `)),
        );
      }
      if (line.LineType === 'Medicine' || line.LineType === 'Consumable') {
        const request = {
          type: line.LineType,
          placeOfService: placeOfService,
          date: encounterVo.EncounterLineItems.EncounterHeader.DateOfService,
          disciplineCode: provider.SpecialityCode,
          planOptionCode: optionCode,
          priceCode: line.NappiCode,
          practiceId: provider.PracticeNumber,
          providerId: provider.HPCSANumber,
          quantity: line.ChargeQuan,
          isMedicalAid: encounterVo.EncounterType == EncounterType.MedicalAidInvoice,
        } as SinglePriceRequest;
        return this.getSinglePrice(request)
          .pipe(
            map(s => this.addPricingFields(line, s)),
            catchError((err) => throwError(`Unable to price ${line.LineType} line item: ${line.NappiCode}, s ${this.slicePipe.transform(line.ChargeDesc, 0, 70)} `)),
          );
      }
      return of(line);
    })])
      .pipe(
        map(lines => ({
          Sucess: true,
          Data: ({
            ...encounterVo,
            EncounterLineItems: {
              ...encounterVo.EncounterLineItems,
              LineItems: lines,
            },
          }),
        })),
        catchError(err => {
          return of({
            Sucess: false,
            ResponseCode: 400,
            ResponseMessage: err,
          });
        }),
      );
  }

  addPricingFields(line: EncounterLineItemVo, s: NexResponse<PriceResponse>): EncounterLineItemVo {
    const totalPrice = parseFloat(Number(s.data.payload.result.price).toFixed(2));
    return {
      ...line,
      Amount: totalPrice,
      TotalIncVat: totalPrice,
      //UnitPrice: s.data.payload.result.price
    };
  }

  getSinglePrice(priceRequest: SinglePriceRequest): Observable<NexResponse<PriceResponse>> {
    const params = {
      type: priceRequest.type,
      placeOfService: priceRequest.placeOfService,
      disciplineCode: priceRequest.disciplineCode,
      planOptionCode: priceRequest.planOptionCode || null,
      priceCode: priceRequest.priceCode,
      date: priceRequest.date.toISOString(),
      isMedicalAid: String(priceRequest.isMedicalAid),
      quantity: priceRequest.quantity,
      /*isMultiBranch: String(priceRequest.isMultiBranch) */
    };
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.get<NexResponse<PriceResponse>>(`${this.loadBalancerUrl}/v1/pricing/single/${priceRequest.practiceId}/${priceRequest.providerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              rejectUnauthorized: 'false',
            },
            params: {
              ...this.dropNullUndefined(params),
            },
          }),
      ),
    );
  }

  getTariffPrice(priceRequest: TariffPriceRequest): Observable<NexResponse<any>> {
    // todo extract load balancer (responsible for caching) to env config
    // please note atm private rates not returned by endpont

    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.get<NexResponse<any>>(`${this.loadBalancerUrl}/v1/pricing/tariff/${priceRequest.practiceId}/${priceRequest.providerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              rejectUnauthorized: 'false',
            },
            params: {
              year: priceRequest.year,
              tariffCode: priceRequest.tariffCode,
              disciplineCode: priceRequest.disciplineCode,
              placeOfService: priceRequest.placeOfService,
              planOptionCode: priceRequest.planOptionCode,
            },
          }),
      ),
    );
  }

  getMedicinePrice(bpn: string, nappiCode: string, planOptionCode, quantity, year): Observable<NexResponse<any>> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.get<NexResponse<any>>(`${this.loadBalancerUrl}/v1/pricing/${bpn}/medicine`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              rejectUnauthorized: 'false',
            },
            params: {
              nappiCode: nappiCode,
              planOptionCode: planOptionCode,
              quantity: quantity,
              year: year,
            },
          }),
      ),
    );
  }

  getConsumablePrice(bpn: string, nappiCode: string, planOptionCode, quantity, year): Observable<NexResponse<any>> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.get<NexResponse<any>>(`${this.loadBalancerUrl}/v1/pricing/${bpn}/consumable`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              rejectUnauthorized: 'false',
            },
            params: {
              nappiCode: nappiCode,
              planOptionCode: planOptionCode,
              quantity: quantity,
              year: year,
            },
          }),
      ),
    );
  }

  getPatientFileWomensHealth(practiceId: string, patientId: string): Observable<PatientFileGenericInformationVo> {
    const ref = this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceId)
      .collection('PatientFileWomenHealth', r => r.where('PatientId', '==', patientId));

    return ref.valueChanges().pipe(
      map(doc => {
        if (doc.length > 0) {
          const womenHealth = doc.map((data: any) => ({
            ...data,
            CapturedDate: data.CapturedDate?.toDate(),
          }));
          // guarantee we pickup last edited doc
          return _.orderBy(womenHealth, d => d.CapturedDate, 'desc');
        }
        return [{}] as PatientFileGenericInformationVo[];
      }),
      take(1),
      map(womenHealths => womenHealths[0]),
    );
  }

  updatePatientFileWomensHealth(
    practiceId: string,
    patientId: string,
    womensHealth: PatientFileGenericInformationVo,
  ): Observable<RestApiResultOfGuid> {
    const ref = this.patientWomensHealthRef(practiceId)
      .doc(womensHealth.PatientFileGenericInformationId)
      .set({
        ...womensHealth,
        CapturedDate: womensHealth.CapturedDate && Timestamp.fromDate(womensHealth.CapturedDate),
      });

    return from(ref).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  removePatientGenericInformation(practiceId: string, patientId: string, womensHealthId: string): Observable<RestApiResultOfBoolean> {
    const ref = this.patientWomensHealthRef(practiceId).doc(womensHealthId).delete();

    return from(ref).pipe(map(() => ({ Sucess: true } as RestApiResultOfBoolean)));
  }

  patientWomensHealthRef(practiceId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceId).collection('PatientFileWomenHealth');
  }

  getPatientConfigurations(practiceTenantId: string, patientId: string): Observable<PatientConfigurationVo> {
    return this.patientConfigurationCollectionRef(practiceTenantId)
      .doc(patientId)
      .valueChanges()
      .pipe(
        map(data => (data && { ...data, CapturedDate: data.CapturedDate.toDate() }) || null),
        first(),
      );
  }

  revisePatientClinicalNotes(practiceTenantId: string, patientId: string, data: PatientClinicalNotesVo) {
    return from(
      this.patientClinicalNoteCollectionRef(practiceTenantId)
        .doc(patientId)
        .set({
          ...this.dropNullUndefined(data),
          CapturedDate: data.CapturedDate && Timestamp.fromDate(data.CapturedDate),
        }),
    ).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  /**
   * Take a note, the following observable is real-time listener intentionally to propagate change (and update akita)
   */
  getPatientClinicalNotes(practiceId: string, patientId: string): Observable<PatientClinicalNotesVo> {
    return this.patientClinicalNoteCollectionRef(practiceId)
      .doc(patientId)
      .valueChanges()
      .pipe(
        map((data: any) => ({ ...data, CapturedDate: data?.CapturedDate?.toDate() })),
        distinctUntilChanged(_.isEqual),
        catchError(() => of(null)),
      );
  }

  getBasicCommunicationTemplates() {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc('00000000-0000-0000-0000-000000000000')
      .collection('CommunicationTemplate')
      .valueChanges()
      .pipe(
        map(data => data.map(d => ({ ...d, LastUpdated: d.LastUpdated.toDate() }) as CommunicationTemplateVo)),
        first(),
      );
  }

  removeCommunicationTemplate(practiceTenantId: string, templateId: string) {
    // todo check if we want to permanently delete, no cqrs history to revert that
    return from(this.communicationTemplateCollectionRef(practiceTenantId).doc(templateId).delete());
  }

  updateCommunicationTemplate(template: CommunicationTemplateVo) {
    return from(this.communicationTemplateCollectionRef(template.PracticeId)
      .doc(template.TemplateId)
      .set(({ ...template, LastUpdated: firebase.firestore.FieldValue.serverTimestamp() }), { merge: true }))
      .pipe(
        map(s => ({ Sucess: true } as RestApiResultOfGuid)),
      );
  }

  getCommunicationTemplates(practiceTenantId: string, templateTypes: string[]) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId)
      .collection('CommunicationTemplate', ref => ref.where('TemplateType', 'in', templateTypes))
      .valueChanges()
      .pipe(
        map(data => data.map(d => ({ ...d, LastUpdated: d.LastUpdated?.toDate() || null }) as CommunicationTemplateVo)),
        first(),
      );
  }

  getPatientAllergies(practiceTenantId: string, patientId: string): Observable<PatientAllergiesVo> {
    return this.patientAllergiesCollectionRef(practiceTenantId)
      .doc(patientId)
      .valueChanges()
      .pipe(
        map((data: any) => ({
          ...data,
          Patient: convertPatientToFirestore(data?.Patient),
          PatientAllergies: data?.PatientAllergies?.map(s => ({ ...s, CapturedDate: s?.CapturedDate?.toDate() })),
          NoAllergies: data.NoAllergies || null,
        })),
        distinctUntilChanged(_.isEqual),
        first(),
        catchError(() => of(null)),
      );
  }

  revisePatientAllergies(practiceTenantId: string, patientId: string, allergies: PatientAllergiesVo): Observable<RestApiResultOfGuid> {
    return from(
      this.patientAllergiesCollectionRef(practiceTenantId)
        .doc(patientId)
        .set({
          ...allergies,
          Patient: this.dropNullUndefined(convertPatientToFirestore(allergies?.Patient)),
          PatientAllergies: allergies.PatientAllergies?.map(s => ({
            ...s,
            CapturedDate: s.CapturedDate && Timestamp.fromDate(s.CapturedDate),
          })),
        }),
    ).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  updatePatientConfiguration(practiceTenantId: string, patientId: string, configuration: PatientConfigurationVo) {
    return from(
      this.patientConfigurationCollectionRef(practiceTenantId)
        .doc(patientId)
        .set({
          ...configuration,
          CapturedDate: configuration.CapturedDate && Timestamp.fromDate(configuration.CapturedDate),
        }),
    ).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  getAddressBookAll(practiceId: string): Observable<AddressBookEntryVo[]> {
    const ref: AngularFirestoreCollection<AddressBookEntryVo> = this.addressBookCollectionRef(practiceId);
    return ref.valueChanges().pipe(first());
  }

  getAddressBookEntryById(practiceId: string, addressBookEntryId: string): Observable<AddressBookEntryVo> {
    return this.addressBookCollectionRef(practiceId).doc(addressBookEntryId).valueChanges().pipe(first());
  }

  updateAddressBook(
    practiceId: string,
    addressBookEntryId: string,
    addressBookEntry: UpdateAddressBookEntry,
  ): Observable<RestApiResultOfGuid> {
    return from(
      this.addressBookCollectionRef(practiceId)
        .doc(addressBookEntryId)
        .set({
          ...addressBookEntry.AddressBookEntry,
        }),
    ).pipe(
      map(s => ({ Sucess: true } as RestApiResultOfGuid)), // todo
    );
  }

  removeAddressBook(practiceId: string, addressBookEntryId: string): Observable<RestApiResultOfGuid> {
    return from(this.addressBookCollectionRef(practiceId).doc(addressBookEntryId).delete()).pipe(
      map(s => ({ Sucess: true } as RestApiResultOfGuid)), // todo
    );
  }

  getReminders(providerId: string): Observable<ReminderVo[]> {
    const ref: AngularFirestoreCollection<ReminderVo> = this.taskCollectionRef(providerId);
    return ref.valueChanges().pipe(
      map(s =>
        s.map((data: any) => {
          // todo need common approach to convert/use timestamp, for now to not change existing c# model use conversion on fly
          return {
            ...data,
            DueDate: data.DueDate?.toDate(),
            UpdatedDate: data.UpdatedDate?.toDate(),
            CreatedDate: data.CreatedDate?.toDate(),
          };
        }),
      ),
      distinctUntilChanged(_.isEqual), // atm after update this fires twice, might be angularfire bug
    );
  }

  updateReminder(providerId: string, reminderId: string, reminder: ReminderVo): Observable<RestApiResultOfGuid> {
    return from(
      this.taskCollectionRef(providerId)
        .doc(reminderId)
        .set({
          ...reminder,
          DueDate: reminder.DueDate && Timestamp.fromDate(reminder.DueDate),
          UpdatedDate: firebase.firestore.FieldValue.serverTimestamp(),
          CreatedDate: reminder.CreatedDate && Timestamp.fromDate(reminder.CreatedDate),
          Description: reminder.Description || '',
        }),
    ).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)), catchError(() => of({ Sucess: false })));
  }

  deleteReminder(providerId: string, reminderId: string): Observable<RestApiResultOfGuid> {
    return from(this.taskCollectionRef(providerId).doc(reminderId).delete()).pipe(map(s => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  getPatientSurgicalHistory(practiceTenantId: string, patientId: string): Observable<PatientSurgicalHistoryVo> {
    const ref = this.patientSurgicalHistoryRef(practiceTenantId).doc(patientId);

    return ref.valueChanges().pipe(first());
  }

  getAllPatientClinicalMetrics(practiceTenantId: string, patientId: string): Observable<ClinicalMetricVo[]> {
    return this.patientMetricRef(practiceTenantId, patientId).valueChanges().pipe(
      take(1),
      switchMap(s => s?.ClinicalMetrics == null
        ? this.patientMetricRef(practiceTenantId, patientId).collection('ClinicalMetrics').valueChanges().pipe(take(1))
        : of(s.ClinicalMetrics),
      ),
      map((metrics: ClinicalMetricVo[]) => metrics.map(t => convertFirestoreToClinicalMetric(t)) || []),
    );
  }

  saveClinicalMetrics(practiceTenantId: string, patientId: string, allMetrics, addMetrics: ClinicalMetricVo[], editScreening: boolean) {
    return of(allMetrics).pipe(
      switchMap(existingMetrics => {
        const metricId = uuidV4();
        const metrics = addMetrics
          .map(s => ({ ...s, ReferenceId: s.ReferenceId || metricId }));

        const updatingMetrics =
          (editScreening ? _.unionWith(metrics, existingMetrics, (a, b) => this.getMetricUniqueKey(a) === this.getMetricUniqueKey(b)) : [...existingMetrics, ...metrics])
            .map(s => convertClinicalMetricToFirestore(this.dropNullUndefined(s)) as any);

        // put in multiple documents if we reach threshold
        if (updatingMetrics.length >= METRICS_THRESHOLD) {
          // add only new metrics as standalone documents as we reached threshold
          return forkJoin([
            // due to firestore batch limit 500 split into chunks
            ..._.chunk(updatingMetrics, 500).map(group => {
              const batch = this.firestore.firestore.batch();
              group.forEach(item => {
                const id = this.getMetricId(item);
                var ref = this.patientMetricRef(practiceTenantId, patientId).collection('ClinicalMetrics').doc(id).ref;
                batch.set(ref, item);
              });
              return from(batch.commit());
            }),
            from(this.patientMetricRef(practiceTenantId, patientId).set({
              PracticeId: practiceTenantId,
              PatientId: patientId,
              ClinicalMetrics: null,
            })),
          ]).pipe(mapTo(metrics));
        }

        return from(this.patientMetricRef(practiceTenantId, patientId).set({
          PracticeId: practiceTenantId,
          PatientId: patientId,
          ClinicalMetrics: updatingMetrics,
        })).pipe(mapTo(metrics));
      }),
      catchError((err) => { console.error('save metric', err); return of(null) }),
    );
  }

  private getMetricId(item: any) {
    return item.Group + '-' + item.Name + '-' + moment(item.TestDate.toDate()).format('YYYY-MM-DD');
  }

  private getMetricUniqueKey(item: any) {
    return item.Group + '-' + item.Name + '-' + moment(item.TestDate).format('YYYY-MM-DD');
  }

  removePatientClinicalMetrics(practiceTenantId: string, patientId: string, allMetrics: ClinicalMetricVo[], referenceId: string, allowedTypes: string[]) {
    // todo use key: group + name + referenceId
    const deleteMetrics = allMetrics.filter(s => s.ReferenceId === referenceId && allowedTypes.includes(s.Name));
    const updateMetrics = allMetrics.filter(s => !_.includes(deleteMetrics, s));

    if (allMetrics.length >= METRICS_THRESHOLD) {
      // add only new metrics as standalone documents as we reeched threshold
      return forkJoin([
        // due to firestore batch limit 500 split into chunks
        ..._.chunk(deleteMetrics, 500).map(group => {
          const batch = this.firestore.firestore.batch();
          group.forEach(item => {
            batch.delete(
              this.patientMetricRef(practiceTenantId, patientId).collection('ClinicalMetrics').doc(this.getMetricId(item)).ref,
            );
          });
          return batch.commit();
        }),
      ]).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
    }

    return from(this.patientMetricRef(practiceTenantId, patientId).set({
      PracticeId: practiceTenantId,
      PatientId: patientId,
      ClinicalMetrics: updateMetrics,
    })).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  updatePatientSurgicalHistory(practiceTenantId: string, patientId: string, hospitalizations: PatientSurgicalHistoryVo) {
    const ref = this.patientSurgicalHistoryRef(practiceTenantId).doc(patientId).set(hospitalizations);

    return from(ref).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  getPatientLifestyle(practiceid: string, patientId: string): Observable<PatientLifestyleVo> {
    const ref = this.patientLifestyleCollectionRef(practiceid).doc(patientId);

    return ref.valueChanges().pipe(first());
  }

  updatePatientLifestyle(practiceid: string, patientId: string, lifestyle: PatientLifestyleVo) {
    const ref = this.patientLifestyleCollectionRef(practiceid).doc(patientId).set(lifestyle);

    return from(ref).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  getSmartGenericMedicine(request: GenericMedicineRequest): Observable<GenericMedicine> {
    return this.authService.getFirebaseToken().pipe(
      take(1),
      switchMap(token =>
        this.http.post<GenericMedicine>(
          `${this.baseUrl}${this.configService.config.smartGenericsSettings.Url}`,
          { message: request },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
      ),
    );
  }

  getTopNotifications(providerId: string): Observable<NotificationStatusView[]> {
    const ref = this.notificationCollectionRef(providerId);
    return ref.valueChanges().pipe(
      map(s =>
        s.map((data: any) => {
          return {
            ...data,
            Date: data.Date?.toDate(),
          } as NotificationStatusView;
        }),
      ),
      map(results => results.splice(0, 5)),
      take(1),
    );
  }

  viewNotification(providerId: string, notificationId: string): Observable<RestApiResultOfGuid> {
    const ref = this.notificationCollectionRef(providerId).doc(notificationId).update({
      Status: 'viewed',
    });

    return from(ref).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  createBulkNotification(notifications: NotificationVo[]) {
    const batch = this.firestore.firestore.batch();

    notifications.forEach(notification => {
      const ref = this.notificationCollectionRef(notification.ProviderId).doc(notification.NotificationId).ref;

      const document = {
        ...notification,
        Date: notification.Date && Timestamp.fromDate(notification.Date),
      };

      batch.set(ref, document);
    });

    return batch.commit();
  }

  getProviders(): Observable<ProviderConfigurationVo[]> {
    const ref: AngularFirestoreCollection<ProviderConfigurationVo> = this.providerCollectionRef();

    return ref.valueChanges().pipe(
      take(1),
      map(s =>
        s.map((data: any) => ({
          ...data,
          BillingEndDate: data.BillingEndDate?.toDate(),
          BillingStartDate: data.BillingStartDate?.toDate(),
          LastUpdatedDate: data.LastUpdatedDate?.toDate(),
        })),
      ),
      distinctUntilChanged(_.isEqual), // atm after update this fires twice, might be angularfire bug
    );
  }

  private providerCollectionRef() {
    return this.firestore.collection(PROVIDER_ROOT);
  }

  private notificationCollectionRef(providerId: string) {
    return this.firestore
      .collection(PROVIDER_ROOT)
      .doc(providerId)
      .collection('Notification', ref => ref.orderBy('Date', 'desc'));
  }

  private patientSurgicalHistoryRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientHospitalization');
  }

  private patientMetricRef(practiceTenantId: string, patientId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientClinicalMetric').doc(patientId);
  }

  private taskCollectionRef(providerId: string) {
    const reminderRef = this.firestore.collection(PROVIDER_ROOT).doc(providerId).collection('Task');
    return reminderRef;
  }

  private addressBookCollectionRef(practiceTenantId: string) {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection<AddressBookEntryVo>('AddressBook', ref => ref.orderBy('Name'));
  }

  private patientClinicalNoteCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientClinicalNote');
  }

  private communicationTemplateCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('CommunicationTemplate');
  }

  private patientAllergiesCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientAllergies');
  }

  private patientLifestyleCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PatientLifestyle');
  }

  private patientConfigurationCollectionRef(practiceTenantId: string) {
    return this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('FlexibleDataStore');
  }

  getChronicConditions(): Observable<CommonChronicConditionsVo[]> {
    const ref: AngularFirestoreCollection<CommonChronicConditionsVo> = this.firestore.collection('ClinicalChronicConditions');
    return ref.valueChanges().pipe(first());
  }

  filterTrackingConditions(practiceTenantId: string, filter: ConditionFilterVo): Observable<PatientChronicConditionsVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(CHRONIC_CONDITION_ROOT, r => r.where('IcdCodes', 'array-contains-any', filter.IcdCodes))
      .valueChanges()
      .pipe(
        first(),
        map((data: any[]) => data.map(item => convertFirestoreToCondition(item))),
        // filter by from / to / gender in memory
        map(data =>
          data
            .filter(s => (filter.FromAge || 0) <= moment().diff(s.Patient.PatientDetails.DateOfBirth, 'years'))
            .filter(s => (filter.ToAge || 150) >= moment().diff(s.Patient.PatientDetails.DateOfBirth, 'years'))
            .filter(s => (filter.Gender && filter?.Gender === s.Patient.PatientDetails.Gender) || !filter?.Gender),
        ),
        // use case insensitive sort, otherwise in some cases order might be not obvious (e.g. iPad user will be last)
        map(data => _.orderBy(data, d => d.Patient?.PatientDetails?.FirstName?.toLocaleLowerCase())),
      );
  }

  revisePatientChronicConditions(
    practiceTenantId: string,
    patientId: string,
    conditions: PatientChronicConditionsVo,
  ): Observable<RestApiResultOfGuid> {
    return from(
      this.firestore
        .collection(PRACTICE_ROOT)
        .doc(practiceTenantId)
        .collection(CHRONIC_CONDITION_ROOT)
        .doc(patientId)
        .set({ ...convertConditionToFirestore(this.dropNullUndefined(conditions)) }),
    ).pipe(map(() => ({ Sucess: true } as RestApiResultOfGuid)));
  }

  removeTrackingConditions(practiceTenantId: string, patientId: string, icdCodes: string[]): Observable<RestApiResultOfGuid> {
    return this.getPatientChronicConditions(practiceTenantId, patientId).pipe(
      map(s => ({
        ...s,
        Conditions: s.Conditions.filter(d => !icdCodes.includes(d.Condition.DiagnosisCode)),
      })),
      switchMap(conditions => this.revisePatientChronicConditions(practiceTenantId, patientId, conditions)),
    );
  }

  getPatientChronicConditions(practiceTenantId: string, patientId: string): Observable<PatientChronicConditionsVo> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(CHRONIC_CONDITION_ROOT)
      .doc(patientId)
      .valueChanges()
      .pipe(
        first(),
        map(data => convertFirestoreToCondition(data)),
      );
  }

  isBillingInvoiceRequired(encounter: EncounterVo) {
    return encounter.EncounterLineItems.LineItems.length > 0;
  }

  completeEncounter(bpn: string, encounter: EncounterVo, requireManualProcessing: string[]): Observable<RestApiResultOfCompleteEncounterResult> {
    if (!this.isBillingInvoiceRequired(encounter)) {
      return of({ Sucess: true, Data: { EncounterId: encounter.EncounterId } });
    }

    const dosSameAsVisit = moment(encounter.PatientEventDetails.CheckInTime).format('YYYY-MM-DD') == moment(encounter.EncounterLineItems.EncounterHeader.DateOfService).format('YYYY-MM-DD');
    const invoice: BaseInvoice = {
      //Id: uuidV4(),
      SourceInvoiceId: encounter.EncounterId,
      Type: this.getInvoiceType(encounter.EncounterType),
      Subtype: this.getInvoiceSubtype(encounter.EncounterType),
      PlaceOfService: this.getPlaceOfService(encounter.EncounterLineItems.EncounterHeader.PlaceOfService),
      DateOfService: encounter.EncounterLineItems.EncounterHeader.DateOfService,
      AdmissionDate: encounter.EncounterLineItems.EncounterHeader.AdmissionDateTime,
      DischargeDate: encounter.EncounterLineItems.EncounterHeader.DischargeDateTime,
      AuthorizationNo: encounter.EncounterLineItems.EncounterHeader.ClaimAuthorizationNumber,
      ReferringProvider: encounter.ReferringDoctorPracticeNumber && {
        TreatingPracticeNumber: encounter.ReferringDoctorPracticeNumber,
        FullName: encounter.ReferringDoctorName,
      },
      AssistingProvider: encounter.AssistingDoctorPracticeNumber && {
        TreatingPracticeNumber: encounter.AssistingDoctorPracticeNumber,
        FullName: encounter.AssistingDoctorName,
      },
      InvoiceDate: moment().toDate(),
      IdentityNo: encounter.PatientEventDetails.Patient.PatientDetails.IdentityNo,
      Branch: encounter.BranchXRef,
      HeaderDiagnosisCodes: encounter.EncounterLineItems.EncounterHeader.Diagnosis.map(s => s.DiagnosisCode),
      // don't set linked appointment if it's visit created by us
      LinkedAppointment: encounter.PatientEventDetails.IsClinicalVisit /* || !dosSameAsVisit  */ ? null : encounter.PatientEventDetails.PatientEventXRef,
      TreatingProvider: {
        TreatingPracticeNumber: encounter.Provider.TreatingDoctorPracticeNumber,
        BillingPracticeNumer: encounter.Provider.PracticeNumber,
        PracticeName: encounter.Provider.PracticeName,
        HPCSANumber: encounter.Provider.HPCSANumber,
      },
      PolicyNo: encounter.MedicalInsurance?.PolicyNo,
      Broker: encounter.MedicalInsurance?.Broker,
      MedicalInsurer: encounter.MedicalInsurance && {
        Name: encounter.MedicalInsurance.MedicalInsurer.Name,
        //Code: encounter.MedicalInsurance.MedicalInsurer.
        Phone: encounter.MedicalInsurance.MedicalInsurer.Phone1,
        Email: encounter.MedicalInsurance.MedicalInsurer.Email,
        Id: encounter.MedicalInsurance.MedicalInsurer.Id,
        //Url:
      },
      Account: {
        Id: encounter.PatientEventDetails.Patient.PatientAccountDetails.AccountId,
        AccountNo: encounter.PatientEventDetails.Patient.PatientAccountDetails.AccountNo,
        MemberNo: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidMembershipNumber,
        SchemeName: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidName,
        SchemeCode: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidSchemeCode,
        PlanCode: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidPlanCode,
        PlanName: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidPlan,
        OptionName: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidPlanOption,
        OptionCode: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidOptionCode,
      },
      MainMember: {
        Id: encounter.PatientEventDetails.Patient.PatientAccountDetails.MainMemberXRef,
      },
      Patient: {
        Id: encounter.PatientEventDetails.Patient.PatientXRef,
        Name: encounter.PatientEventDetails.Patient.PatientDetails.FirstName,
        Surname: encounter.PatientEventDetails.Patient.PatientDetails.Surname,
        DateOfBirth: encounter.PatientEventDetails.Patient.PatientDetails.DateOfBirth,
        IdentityNo: encounter.PatientEventDetails.Patient.PatientDetails.IdentityNo,
        FileNo: encounter.PatientEventDetails.Patient.FileNo,
        DependantCode: encounter.PatientEventDetails.Patient.PatientAccountDetails.MedicalAidDependentCode,
        Contact: {
          Cellphone: encounter.PatientEventDetails.Patient.PatientDetails.ContactNo,
        },
      },

      //todo add other fields
      Lines: encounter.EncounterLineItems.LineItems.map(s => ({
        Description: s.ChargeDesc,
        LineType: s.LineType,
        LineNumber: s.LineNum,
        NappiCode: s.NappiCode,
        TariffCode: s.ChargeCode,
        Quantity: s.ChargeQuan,
        DiagnosisCodes: s.Diagnosis.map(t => t.DiagnosisCode),
        AmountBilled: encounter.EncounterType == EncounterType.ZeroInvoice ? 0 : Math.round(s.Amount * 100),
        PriceOverride: s.PriceOverride,
        //Balance
        MedicineAdditionalInfo: {
          // todo map types
          DosageType: s.DosageType,
          DosageUnit: s.DosageUnit,
          DurationType: s.DurationType,
          DurationUnit: s.DurationUnit,
          FrequencyUnit: s.FrequencyUnit,
          MedicineType: s.ChronicIndicator ? MEDICINE_TYPE.CHRONIC : MEDICINE_TYPE.ACUTE,
          PeriodType: s.PeriodType,
          PeriodUnit: s.PeriodUnit,
          Repeats: s.Repeat,
        },
      } as InvoiceLine)),
    };

    var claimNote = requireManualProcessing.length > 0
      ? `${encounter.Note ?? ''} [Note: Invoice deferred for manual processing due to ${requireManualProcessing.join(', ')}]`
      : encounter.Note;

    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.post<NexResponse<{ invoiceId: string }>>(
          `${this.baseUrl}/v1/invoice/claim/saveInvoice`,
          {
            BillingPracticeNumber: bpn,
            Note: claimNote,
            Source: 'Clinical',
            Invoice: invoice,
            ProcessingContext: {
              Completed: requireManualProcessing.length == 0,
              IgnoreLinkedAppointmentOnDosMismatch: true,
            },
          } as SaveInvoiceRequest,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
          .pipe(
            switchMap(s => {
              if (s.success) {
                return of(({ Sucess: true, Data: { EncounterId: s.data.payload.invoiceId } }));
              }
              var errorMessage = (s.error.code == ERROR_CODE.DUPLICATE_REQUEST) ? 'Consultation already completed. Use cancel option to close consultation.' : s.error.message;

              return throwError({
                Data: '',
                Sucess: false,
                Code: s.error.code,
                ResponseMessage: errorMessage,
              } as RestApiResultOfCompleteEncounterResult);
            }), //todo ucomment
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return throwError(httpError.error);
              }
              return throwError(httpError);
            }),
          ),
      ),
    );

  }

  updatePatient(bpn: string, patientVo: PatientVo, accountXref?: string, mainMemberXRef?: string): Observable<RestApiResultOfGuid> {

    var patientDetails: AccountMember = {
      Title: patientVo.PatientDetails.Title,
      Name: patientVo.PatientDetails.FirstName,
      Surname: patientVo.PatientDetails.Surname,
      PreferredName: patientVo.KnownAs,
      IdentificationType: patientVo.PatientDetails.IdentityNo ? IDENTIFICATION_TYPE.SAID : IDENTIFICATION_TYPE.PASSPORT,
      IdentityNo: patientVo.PatientDetails.IdentityNo,
      Country: patientVo.PatientDetails.Country,
      PassportNumber: patientVo.PatientDetails.PassportNo,
      Gender: patientVo.PatientDetails.Gender,
      Occupation: patientVo.Occupation,
      Employer: patientVo.Employer,
      Contact: {
        Cellphone: patientVo.PatientDetails.ContactNo,
        Email: patientVo.PatientDetails.EmailAddress,
        PhysicalAddress: patientVo.PhysicalAddress,
        PostalAddress: patientVo.PostalAddress,
      },
      DateOfBirth: patientVo.PatientDetails.DateOfBirth,
      FileNo: patientVo.FileNo,
      DependantCode: patientVo.PatientAccountDetails.MedicalAidDependentCode,
    };

    const accountDetails = {
      Id: accountXref,
      MainMemberDependantCode: patientVo.PatientAccountDetails.MedicalAidDependentCode,
      MemberNo: patientVo.PatientAccountDetails.MedicalAidMembershipNumber,
      AccountNo: patientVo.PatientAccountDetails.AccountNo,

      AccountType: patientVo.PatientAccountDetails.IsCashAccount ? ACCOUNT_TYPE.PRIVATE : ACCOUNT_TYPE.MEDICAL_AID,

      Scheme: patientVo.PatientAccountDetails.MedicalAidSchemeCode,
      Plan: patientVo.PatientAccountDetails.MedicalAidPlanCode,
      Option: patientVo.PatientAccountDetails.MedicalAidOptionCode,
      MainMember: mainMemberXRef,
    };

    var mainMemberDetails: AccountMember = {
      Id: mainMemberXRef,
      Title: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.Title,
      Name: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.FirstName,
      Surname: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.Surname,
      DateOfBirth: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.DateOfBirth,
      /* IdentificationType: IDENTIFICATION_TYPE.SAID,
      IdentityNo: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo, */
      IdentificationType: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo ? IDENTIFICATION_TYPE.SAID : IDENTIFICATION_TYPE.PASSPORT,
      IdentityNo: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo,
      PassportNumber: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.PassportNo,
      Country: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.Country,

      Gender: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.Gender,
      //PreferredName: "todo",
      Contact: {
        Cellphone: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.ContactNo,
        Email: patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.EmailAddress,
      },
    };
    /*   if (patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo) {
        mainMemberDetails.IdentityNo = patientVo.PatientAccountDetails.MedicalAidMainMemberDetails.IdentityNo;
        mainMemberDetails.IdentificationType = IDENTIFICATION_TYPE.SAID;
      } */

    // accountXref if not null, means we add new patient or edit
    var request = {} as PatientAccount;

    if (patientVo.PatientXRef != null) {
      // 1. edit patient (means editing account or patient or main member)
      request.Patient = patientDetails;
      request.Patient.Id = patientVo.PatientXRef;
      request.Account = accountDetails;
      request.Account.Id = patientVo.PatientAccountDetails.AccountId;
      request.MainMember = mainMemberDetails;
      request.MainMember.Id = patientVo.PatientAccountDetails.MainMemberXRef;
    } else if (accountXref == null) {
      // 2. new account + main member
      request.Account = accountDetails;
      request.MainMember = patientDetails;

    } else {
      // 3. add new patient to existing account
      request.Patient = patientDetails;
      request.Account = { Id: accountXref };
      request.MainMember = { Id: mainMemberXRef };
      // todo as part of adding patient we can update account info (not obvious way)
    }

    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http.post<NexResponse<PatientAccountResponse>>(
          `${this.baseUrl}/v1/account/patient/${bpn}`,
          request,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ).pipe(
          switchMap(s => {
            if (s.success) {
              return of(({ Sucess: true, Data: s.data?.payload.PatientGuid }));
            }
            var errorMessage = s.error.message;

            return of({
              Data: '',
              Sucess: false,
              Code: s.error.code,
              ResponseMessage: errorMessage,
            } as RestApiResultOfGuid);
          }),
          catchError(httpError => {
            if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
              return throwError(httpError.error.error.message);
            }
            return throwError(httpError);
          }),
        ),
      ),
    );
  }

  getPlaceOfService(placeOfService: EncounterHeaderVoPlaceOfService): PLACE_OF_SERVICE {
    /** 0 = Uknown, 2 = Telehealth, 11 = ConsultingRooms, 21 = InPatientHospital, 24 = DayClinic */
    if (placeOfService == EncounterHeaderVoPlaceOfService._2) {
      return PLACE_OF_SERVICE.TELEMEDICINE;
    }
    if (placeOfService == EncounterHeaderVoPlaceOfService._11) {
      return PLACE_OF_SERVICE.CONSULTING_ROOM;
    }
    if (placeOfService == EncounterHeaderVoPlaceOfService._21) {
      return PLACE_OF_SERVICE.INPATIENT_HOSPITAL;
    }
    if (placeOfService == EncounterHeaderVoPlaceOfService._24) {
      return PLACE_OF_SERVICE.DAY_CLINIC_HOSPITAL;
    }
    return undefined;
  }

  getInvoiceType(type: string): INVOICE_TYPE {
    if (type == EncounterType.MedicalAidInvoice) {
      return INVOICE_TYPE.MEDICAL_AID;
    } else if (type == EncounterType.CashInvoice) {
      return INVOICE_TYPE.CASH;
    } else if (type == EncounterType.MedicalInsurance) {
      return INVOICE_TYPE.MEDICAL_AID;
    } else if (type == EncounterType.ZeroInvoice) {
      return INVOICE_TYPE.MEDICAL_AID;
    }
    //return type;
  }

  getInvoiceSubtype(type: string): INVOICE_SUBTYPE {
    if (type == EncounterType.ZeroInvoice) {
      return INVOICE_SUBTYPE.NO_CHARGE;
    } else if (type == EncounterType.MedicalInsurance) {
      return INVOICE_SUBTYPE.MEDICAL_INSURER;
    }
    return INVOICE_SUBTYPE.NONE;
  }

  updateEncounterStatus(practiceTenantId: string, encounterId: string, status: 'Created' | 'Updated' | 'Saved' | 'Completed' | 'Cancelled'): Observable<any> {
    return from(this.encounterRef(practiceTenantId, encounterId).set({ Status: status }, { merge: true }));
  }

  dropNullUndefined(d) {
    return Object.entries(d)
      .reduce((acc, [k, v]) => (
        v == null ? acc :
          {
            ...acc,
            [k]: (_.isPlainObject(v) ? this.dropNullUndefined(v) : _.isArray(v) ? v.map(s => this.dropNullUndefined(s)) : v),
          }
      ), {});
  }

  saveEncounter(providerId: string, patientId: string, encounter: EncounterStatusVo): Observable<RestApiResultOfGuid> {
    // threshold limits: 120: SymptomQuestions, 240: SymptomQuestionAnswers, 50: EncounterLineItems.LineItems,
    let questions$ = of(null);
    let encounterForUpdate = { ...this.dropNullUndefined(encounter), Status: 'Saved' } as EncounterStatusVo;

    encounterForUpdate = convertEncounterToFirestore(encounterForUpdate);

    const encounterRootRef = this.encounterRef(encounterForUpdate.PracticeId, encounterForUpdate.EncounterId);

    if (encounterForUpdate.SymptomQuestions.length >= 120) {
      // symptom questions is more informational data, no need to merge
      questions$ = forkJoin([
        // due to firestore batch limit 500 split into chunks
        ..._.chunk(encounterForUpdate.SymptomQuestions, 500).map(group => {
          const batch = this.firestore.firestore.batch();
          group.forEach(item => {
            batch.set(
              encounterRootRef.collection('SymptomQuestions').doc(item.QuestionKey.replace('/', '_')).ref,
              item,
            );
          });
          return from(batch.commit());
        }),
      ]);
      encounterForUpdate = { ...encounterForUpdate, SymptomQuestions: null };
    }

    let answers$ = of(null);
    if (encounterForUpdate.SymptomQuestionAnswers.length >= 240) {
      answers$ = forkJoin([
        // due to firestore batch limit 500 split into chunks
        ..._.chunk(encounterForUpdate.SymptomQuestionAnswers, 500).map(group => {
          const batch = this.firestore.firestore.batch();
          group.forEach(item => {
            batch.set(
              encounterRootRef.collection('SymptomQuestionAnswers').doc(item.QuestionKey.replace('/', '_')).ref,
              item,
            );
          });
          return from(batch.commit());
        }),
      ]);
      encounterForUpdate = { ...encounterForUpdate, SymptomQuestionAnswers: null };
    }

    let prescriptions$ = of(null);
    if (encounterForUpdate.MedicationsPrescriptions?.length > 0) {
      const batch = this.firestore.firestore.batch();
      encounterForUpdate.MedicationsPrescriptions.forEach(item => {
        batch.set(
          encounterRootRef.collection('MedicationsPrescriptions').doc(item.MedicationPrescriptionId).ref,
          item,
        );
      });
      prescriptions$ = from(batch.commit()).pipe(catchError(s => of(null)));
      encounterForUpdate = { ...encounterForUpdate, MedicationsPrescriptions: null };
    }

    let lines$ = of(null);
    if (encounterForUpdate.EncounterLineItems?.LineItems.length >= 50) {
      lines$ = forkJoin([
        // due to firestore batch limit 500 split into chunks
        ..._.chunk(encounterForUpdate.EncounterLineItems.LineItems, 500).map(group => {
          const batch = this.firestore.firestore.batch();
          group.forEach(item => {
            batch.set(
              encounterRootRef.collection('EncounterLineItems.LineItems').doc(`${item.LineType}-${item.NappiCode || '00'}-${item.ChargeCode}`).ref,
              item,
            );
          });
          return from(batch.commit());
        }),
      ]);

      encounterForUpdate = {
        ...encounterForUpdate,
        EncounterLineItems: { ...encounterForUpdate.EncounterLineItems, LineItems: [] },
      };
    }

    return forkJoin([
      lines$,
      questions$,
      answers$,
      prescriptions$,
      from(encounterRootRef.set(encounterForUpdate)),
    ])
      .pipe(
        map(() => ({ Sucess: true } as RestApiResultOfGuid)),
        catchError(s => {
          console.warn(s);
          this.appInsightService.trackException(s, 'save-encounter', { encounterVo: JSON.stringify(encounter) });
          return of({ Sucess: false, ResponseMessage: s.message });
        }),
      );
  }

  private encounterRef(practiceid: string, encounterId: string) {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceid)
      .collection(ENCOUNTER_ROOT)
      .doc(encounterId);
  }

  getEncountersRxHistory(practiceTenantId: string, patientId: string, type?: 'dispensed' | 'prescribed' | 'all' | null): Observable<EncounterStatusVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(ENCOUNTER_ROOT, r => r
        .where('Status', 'in', ['Completed', 'Finalised', 'Corrected'])
        .where('PatientEventDetails.Patient.PatientId', '==', patientId))
      .valueChanges()
      .pipe(
        first(),
        switchMap((encounters: EncounterStatusVo[]) =>
          encounters.length > 0 ?
            forkJoin([...encounters.map(encounter => this.loadFullEncounter(encounter, practiceTenantId))])
            : [],
        ),
        map(encounters => encounters.map(encounter => convertFirestoreToEncounter(encounter))),
        map(encounters => encounters.filter((encounter: EncounterStatusVo) =>
          encounter.EncounterLineItems.LineItems.find(s => s.LineType == 'Medicine' || s.LineType == 'Consumable') != null ||
          encounter.MedicationsPrescriptions?.find(s => s.MedicationPrescriptionLines.length > 0 != null),
        )),
        map(encounters => {
          if (type === 'dispensed') {
            return encounters.filter((encounter: EncounterStatusVo) =>
              encounter.EncounterLineItems.LineItems.find(s => s.LineType == 'Medicine' || s.LineType == 'Consumable'));
          } else if (type === 'prescribed') {
            return encounters.filter((encounter: EncounterStatusVo) => encounter.MedicationsPrescriptions?.find(s => s.MedicationPrescriptionLines.length > 0 != null));
          } else {
            return encounters;
          }
        }),
      );
  }

  getActiveEncounterForPatient(practiceTenantId: string, providerId: string, patientId: string, visit?: PatientEventVo): Observable<EncounterVo> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(ENCOUNTER_ROOT, r => r
        .where('Status', 'in', ['Created', 'Updated', 'Saved'])
        .where('PatientEventDetails.Patient.PatientId', '==', patientId))
      .valueChanges()
      .pipe(
        first(),
        map(encounters => encounters?.filter(e => e.PatientEventDetails.PracticeId === providerId) || []),
        map(encounters => _.orderBy(encounters, s => fromTimestamp(s.PatientEventDetails?.CheckInTime), 'desc')),
        map(encounters => visit != null
          ? encounters.filter(e => e.PatientEventDetails.PatientEventXRef == visit.PatientEventXRef || e.PatientEventDetails.PatientEventId == visit.PatientEventId)
          : encounters),
        map(encounters => encounters.length > 0 && encounters[0] || null),
        // load full encounter (in case if contains sub-collections)
        switchMap((encounter: EncounterStatusVo) => this.loadFullEncounter(encounter, practiceTenantId)),
        map(encounter => encounter && convertFirestoreToEncounter(encounter) || null),
      );
  }

  checkIfEncounterStillActive(practiceTenantId: string, patientId: string, encounterId: string) {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection(ENCOUNTER_ROOT, r => r
        .where('Status', 'in', ['Created', 'Updated', 'Saved'])
        .where('PatientEventDetails.Patient.PatientId', '==', patientId))
      .valueChanges()
      .pipe(
        first(),
        map(encounters => encounters?.filter(e => e.EncounterId === encounterId) || []),
        map(encounters => encounters.length > 0),
      );
  }

  loadFullEncounter(encounter: EncounterStatusVo, practiceTenantId: string): any {
    return encounter && this.loadEncounterSubCollections(encounter, practiceTenantId).pipe(
      map(([symptomQuestions, symptomQuestionAnswers, lineItems, prescriptions]) => ({
        ...encounter,
        SymptomQuestions: symptomQuestions || encounter.SymptomQuestions,
        SymptomQuestionAnswers: symptomQuestionAnswers || encounter.SymptomQuestionAnswers,
        EncounterLineItems: {
          ...encounter.EncounterLineItems,
          LineItems: lineItems || encounter.EncounterLineItems.LineItems,
        },
        MedicationsPrescriptions: prescriptions || encounter.MedicationsPrescriptions,
      })),
    ) || of(null);
  }

  startCapturingEncounter(practiceId: string, patientId: string, encounter: EncounterStatusVo) {
    return this.saveEncounter(practiceId, patientId, encounter).pipe(
      mapTo(encounter));
  }

  loadEncounterSubCollections(encounter: EncounterStatusVo, practiceTenantId: string): any {
    // threshold limits: 120: SymptomQuestions, 240: SymptomQuestionAnswers, 50: EncounterLineItems.LineItems,
    return forkJoin([
      of(encounter).pipe(switchMap(s => {
        if (s.SymptomQuestions == null || s?.SymptomQuestions?.length >= 120) {
          return this.encounterRef(practiceTenantId, s.EncounterId)
            .collection('SymptomQuestions').valueChanges().pipe(first());
        }
        return of(null);
      })),
      of(encounter).pipe(
        switchMap(s => {
          if (s.SymptomQuestionAnswers == null || s.SymptomQuestionAnswers?.length >= 240) {
            return this.encounterRef(practiceTenantId, s.EncounterId)
              .collection('SymptomQuestionAnswers').valueChanges().pipe(first());
          }
          return of(null);
        }),
      ),
      of(encounter).pipe(
        switchMap(s => {
          if (s.EncounterLineItems.LineItems == null || s.EncounterLineItems.LineItems.length >= 50) {
            return this.encounterRef(practiceTenantId, s.EncounterId)
              .collection('EncounterLineItems.LineItems').valueChanges().pipe(first());
          }
          return of(null);
        }),
      ),
      of(encounter).pipe(
        switchMap(s => {
          if (s.MedicationsPrescriptions === null) {
            return this.encounterRef(practiceTenantId, s.EncounterId)
              .collection('MedicationsPrescriptions').valueChanges().pipe(first());
          }
          return of(null);
        }),
      ),
    ]);
  }

  getSpecialityRule(practiceId: string, code: string): Observable<SpecialityRuleVo> {
    return this.firestore.collection('SpecialityRule').doc(code).valueChanges().pipe(first());
  }

  getPpeCodes(optionCode: string, specialityCode: string): Observable<PpeCode> {
    return this.firestore.collection('PPECode').doc(`${optionCode}_${specialityCode.replace(/^0+/, '')}`).valueChanges().pipe(first());
  }

  getUndeliveredPathologyReports(practiceTenantId: string, dateFrom: Date, dateTo: Date): Observable<PathologyReportFirestoreVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport', q => {
        const query = q.where('DeliveryStatus', '==', 'Undelivered');

        if (dateFrom && dateTo) {
          return query.where('DeliveryDate', '>=', Timestamp.fromDate(dateFrom)).where('DeliveryDate', '<=', Timestamp.fromDate(dateTo)).orderBy('DeliveryDate', 'desc');
        } else if (dateFrom && !dateTo) {
          return query.where('DeliveryDate', '>=', Timestamp.fromDate(dateFrom)).orderBy('DeliveryDate', 'desc');
        } else if (!dateFrom && dateTo) {
          return query.where('DeliveryDate', '<=', Timestamp.fromDate(dateTo)).orderBy('DeliveryDate', 'desc');
        }
        return query;
      })
      .valueChanges()
      .pipe(
        map((data: any[]) =>
          data.map(item => ({
            ...this.toPathReportObject(item),
            DeliveryDate: item.DeliveryDate?.toDate() || null,
          })),
        ),
        // map(data => data.filter(d => d.DeliveryStatus === 'Undelivered') || data)
      );
  }

  getHtmlBodyFromStorage(billingPracticeNo: string, path: string, communicationId: string) {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .get<{ success: boolean; contents: string }>(
            `${this.baseUrl}/v2/files/practices/${billingPracticeNo}?file=${path}/${communicationId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .pipe(
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
          ),
      ),
      map(s => s.contents),
    );
  }

  updateHtmlToStorage(billingPracticeNo: string, path: string, id: string, content: string) {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .post<{ success: boolean }>(
            `${this.baseUrl}/v1/files/practices/${billingPracticeNo}?file=${path}/${id}`,
            {
              content,
              contentType: 'text/html',
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .pipe(
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
          ),
      ),
      map(s => ({ Data: content, Sucess: true })),
    );
  }

  getPathologyReportXml(billingPracticeNo: string, pathologyReportId: string) {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .get<{ success: boolean; contents: string }>(
            `${this.baseUrl}/v1/files/practices/${billingPracticeNo}/PathologyReports/${pathologyReportId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
          ),
      ),
      map(s => s.contents),
    );
  }

  getPathologyReportPdf(billingPracticeNo: string, pathologyReportId: string) {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token =>
        this.http
          .get<{ success: boolean; contents: string }>(
            `${this.baseUrl}/v2/files/practices/${billingPracticeNo}?file=PathologyReports/${pathologyReportId}.pdf`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .pipe(
            // TODO: Standardize Nexus function error handling. Interceptor on the custom httpclient maybe?
            catchError(httpError => {
              if (httpError instanceof HttpErrorResponse && httpError.error?.error) {
                return of(httpError.error);
              }
              return throwError(httpError);
            }),
          ),
      ),
      map(s => s.contents),
    );
  }

  getPathologyReportWithXml(practiceTenantId: string, pathologyReportId: string): Observable<PathologyReportFirestoreVo> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport')
      .doc(pathologyReportId)
      .valueChanges()
      .pipe(
        map(s => this.toPathReportObject(s)),
        switchMap(pathologyReport => pathologyReport.Source === 'cloud'
          ? this.getPathReportStructuredXml(practiceTenantId, pathologyReport)
            .pipe(map(path => ({ ...pathologyReport, PathologyReport: path })))
          : of(pathologyReport)),
        distinctUntilChanged(_.isEqual),
      );
  }

  getPathologyReport(practiceTenantId: string, pathologyReportId: string): Observable<PathologyReportFirestoreVo> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport')
      .doc(pathologyReportId)
      .valueChanges()
      .pipe(
        map(s => this.toPathReportObject(s)),
        distinctUntilChanged(_.isEqual),
      );
  }

  getPathReportStructuredXml(practiceTenantId: string, pathologyReport: PathologyReportFirestoreVo) {
    if (pathologyReport.XmlReference != null) {
      return this.firestore
        .doc(pathologyReport.XmlReference)
        .collection('xml')
        .doc(pathologyReport.PathologyReportId)
        .valueChanges(first());
    }
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport')
      .doc(pathologyReport.PathologyReportId)
      .collection('xml')
      .doc(pathologyReport.PathologyReportId)
      .valueChanges(first());
  }

  getPathologyReportsByPatientIds(practiceTenantId: string, patientIds: string[]): Observable<PathologyReportFirestoreVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport', r => r.where('DeliveryStatus', '==', 'Delivered').where('PatientIds', 'array-contains-any', patientIds))
      .valueChanges()
      .pipe(
        // first(),
        map((data: any[]) =>
          data.map(item => ({
            ...this.toPathReportObject(item),
            DeliveryDate: item.DeliveryDate?.toDate() || null,
          })),
        ),
      );
  }

  getPathologyReports(
    practiceTenantId: string,
    primaryProviderId: string,
    dateFrom: Date,
    dateTo: Date,
    reportStates: string[],
    followupActions: string[],
  ): Observable<PathologyReportFirestoreVo[]> {
    return this.firestore
      .collection(PRACTICE_ROOT)
      .doc(practiceTenantId)
      .collection('PathologyReport', q => {
        const query = q.where('DeliveryStatus', '==', 'Delivered');
        if (dateFrom && dateTo) {
          return query.where('ReceivedDate', '>=', Timestamp.fromDate(dateFrom)).where('ReceivedDate', '<=', Timestamp.fromDate(dateTo)).orderBy('ReceivedDate', 'desc');
        } else if (dateFrom && !dateTo) {
          return query.where('ReceivedDate', '>=', Timestamp.fromDate(dateFrom)).orderBy('ReceivedDate', 'desc');
        } else if (!dateFrom && dateTo) {
          return query.where('ReceivedDate', '<=', Timestamp.fromDate(dateTo)).orderBy('ReceivedDate', 'desc');
        }
        /*
        // todo need complex index to make it work, for now filter in memory
        if (!!primaryProviderId) {
          return query != null
            ? query.where('PrimaryProviderId', '==', primaryProviderId)
            : q.where('PrimaryProviderId', '==', primaryProviderId);
        } */
        return query;
      })
      .valueChanges()
      .pipe(
        map((data: any[]) => data.map(item => this.toPathReportObject(item))),
        map(data => (reportStates.length > 0 && data.filter(d => reportStates.includes(d.StatusType))) || data),
        map(data =>
          (followupActions.length > 0 && data.filter(d => d.FollowupActions?.every(s => !followupActions?.includes(s.Type)) || false))
          || data,
        ),
      );
  }

  private toPathReportObject(item: any): PathologyReportFirestoreVo {
    return {
      ...item,
      ReceivedDate: item.ReceivedDate?.toDate() || null,
      DeliveryDate: item.DeliveryDate?.toDate() || null,
      // handle use case when during merging we didn't find workflow for path report and status is not set
      StatusType: item.StatusType && item.StatusType !== 'Urgent' ? item.StatusType : 'Read',
      FollowupActions: item.FollowupActions?.map(s => ({ ...s, Date: s.Date && s.Date.toDate() })) || [],
      PatientInfoFromReport: item?.PatientInfoFromReport && convertPatientToFirestore(item?.PatientInfoFromReport),
      PatientContactNo: item?.PatientInfoFromReport?.PatientAccountDetails.MedicalAidMainMemberDetails.ContactNo || null,
      PathologyReport: null, // ignore xml info to lightweight object, ideally ignore even before reading from firestore
      ContentTypes: item?.ContentTypes || [],
    };
  }

  linkPathReportToPatient(practiceTenantId: string, primaryProviderId: string, pathologyReportId: string, patientIds: string[]) {
    return from(
      this.firestore.collection(PRACTICE_ROOT).doc(practiceTenantId).collection('PathologyReport').doc(pathologyReportId).update({
        PatientIds: patientIds,
        StatusType: 'Unread',
        DeliveryStatus: 'Delivered',
        DeliveryDate: Timestamp.now(),
        PrimaryProviderId: primaryProviderId,
      }),
    ).pipe(map(s => patientIds));
  }

  addFollowuActionForPathReport(practiceTenantId: any, pathologyReport: any, action: { Type: string; Date: Date }) {
    return from(
      this.firestore
        .collection(PRACTICE_ROOT)
        .doc(practiceTenantId)
        .collection('PathologyReport')
        .doc(pathologyReport.PathologyReportId)
        .update({ FollowupActions: [...(pathologyReport.FollowupActions || []), action] }),
    );
  }

  updatePathologyReportStatus(practiceTenantId: string, pathologyReportId: string, status: string) {
    return from(
      this.firestore
        .collection(PRACTICE_ROOT)
        .doc(practiceTenantId)
        .collection('PathologyReport')
        .doc(pathologyReportId)
        .update({ StatusType: status }),
    );
  }

  updatePathologyReportStatuses(group: PathologyReportFirestoreVo[], status: string) {
    const batch = this.firestore.firestore.batch();
    group.forEach(s => {
      batch.update(
        this.firestore.firestore.collection(PRACTICE_ROOT).doc(s.PracticeId).collection('PathologyReport').doc(s.PathologyReportId),
        { StatusType: status },
      );
    });
    return from(batch.commit());
  }

  updatePathologyDeliveryStatus(practiceTenantId: string, pathologyReportId: string, status: string) {
    return from(
      this.firestore
        .collection(PRACTICE_ROOT)
        .doc(practiceTenantId)
        .collection('PathologyReport')
        .doc(pathologyReportId)
        .update({ DeliveryStatus: status }),
    );
  }

  getFeatureAddOnConfig(id: string): Observable<any> {
    return this.authService.getFirebaseToken().pipe(
      switchMap(token => this.http.get<any>(`${this.baseUrl}/v1/clinical/config/getFeatureAddOnConfiguration?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).pipe(
        map(data => ({
          ...data.config,
          DateNow: new Date(data.config.DateNow),
          BeginDate: new Timestamp(data.config.BeginDate._seconds, data.config.BeginDate._nanoseconds).toDate(),
          EndDate: new Timestamp(data.config.EndDate._seconds, data.config.EndDate._nanoseconds).toDate(),
        })))),
    );
  }

  getKahunPatientSummary(practice: string, appointmentXRef: string): Observable<KahunAppointment> {
    if (appointmentXRef) {
      return this.firestore
        .collection(KAHUN_PRACTICE)
        .doc(practice)
        .collection('Appointment')
        .doc(appointmentXRef)
        .valueChanges()
        .pipe(
          map(data => data as KahunAppointment),
          distinctUntilChanged(_.isEqual),
        );
    }
    return of(null);
  }

  isKahunPractice(provider: any) {
    return this.firestore.collection('Configuration').doc('KahunPractice').valueChanges().pipe(
      first(),
      map((data: any) => {
        const BPN = provider.PracticeNumber;
        const TPN = provider.TreatingPracticeNumber;
        let isKahunPractice = false;
        if (data?.billingNumbers.hasOwnProperty(BPN)) {
          isKahunPractice = data?.billingNumbers[BPN].length ? data?.billingNumbers[BPN].includes(TPN) : true;
        }
        return isKahunPractice;
      }),
      catchError(e => of(false)),
    );
  }

  public saveAdImpressions(impressions: AdImpression[]) {
    try {
      const fsImpressions = impressions.filter(i => !!i.EncounterID);

      const batch = this.firestore.firestore.batch();

      fsImpressions.forEach(impression => {
        batch.set(this.firestore.firestore.collection(`${SMARTMEDS_AD_IMPRESSIONS_ROOT}`).doc(), impression);
      });

      batch.commit();

    } catch (err) {
      console.error(`An error occurred while saving ad impressions. ${err}`);
    }
  }

  public async loadAd(sponsorInfo: any) {
    try {
      const baseUrl = environment.smartGenericsSettings.AdsDisplayUrl;
      const result = await this.http.post(baseUrl, {
        message: {
          SponsorInfo: sponsorInfo,
        },
      }, {
        withCredentials: false,
        observe: 'body',
      }).toPromise();
      return result;
    } catch (err) {
      console.error(err);
    }
    return null;
  }
}

