import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityUIStore, EntityStore } from '@datorama/akita';
import {
  ProviderConfigurationVo,
  AddressBookEntryVo,
  PatientInWaitingRoom,
  SpecialityRuleVo,
  PathologyReportDeliveryVo,
  PathologyReportWorkflowVo,
  PatientChronicConditionsVo,
  ProviderBranchConfigurationVo,
  AllergenVo,
} from 'api-clinician-app';
import { ProviderConfigurationViewModel } from '../../view-models/providerConfiguration-view-model.utils';
import { CalendarEventViewModel } from '../../view-models/calendarEvent-view-model.utils';
import {
  CommunicationTemplateVo,
  EncounterTemplateVo,
  MedicalInsurerVo,
  NotificationStatusView,
  PatientConfigurationVo,
  PatientEventsByDateVo,
  ReminderVo,
  TemplateSummaryVo,
} from '../../api-client.service';
import { PathologyReportFirestoreVo } from '../../meraki-models/meraki-models';
import { Moment } from 'moment';

export interface ChatRoomStatusVo {
  DisplayName: string;
  ChatRoomId: string;
  Status: string | 'joined' | 'left';
}

export interface ProviderViewState {
  providerId: string;
  details: ProviderConfigurationVo;
  addressbook: AddressBookEntryVo[];
  calendar: CalendarEventViewModel[];
  calendarView: 'day' | 'week';
  waitingRoom: PatientInWaitingRoomViewModel[];
  chatStatuses: ChatRoomStatusVo[];
  lastChecked: Date;
  communicationTemplates: CommunicationTemplateVo[];
  specialityRule: SpecialityRuleVo;
  undeliveredReports: PathologyReportDeliveryVo[];
  patientReports: PathologyReportWorkflowVo[];
  pathReports: { groupName: string; data: PathologyReportFirestoreVo[] }[];
  followupReports: PathologyReportWorkflowVo[];
  urgentReports: PathologyReportWorkflowVo[];
  todayReports: PathologyReportFirestoreVo[];
  weekReports: PathologyReportWorkflowVo[];
  pathologyReports: PathologyReportViewModel[];
  trackingConditions: PatientChronicConditionsVo[];
  tasks: ReminderVo[];
  allergens: AllergenVo[];
  practiceProviders: ProviderConfigurationViewModel[];
  branches: ProviderBranchConfigurationVo[];
  templates: TemplateSummaryVo[];
  templateGroups: TemplateSummaryVo[];
  visitHistory: PatientEventsByDateVo[];
  notifications: NotificationStatusView[];
  providerConfiguration: PatientConfigurationVo;
  medicalInsurers: MedicalInsurerVo[];
  appointmentTypes: string[];
  invoiceTemplates: EncounterTemplateVo[];
}

export interface PathologyReportViewModel extends PathologyReportWorkflowVo, PathologyReportDeliveryVo {
  ReportId: string;
  Date: Date;
  DateFrom: string;
  Type: string;
}
export interface PathCentreFilter {
  DateRange: 'LastWeek' | 'LastMonth' | 'Custom' | null;
  FromDate: Moment | null;
  ToDate: Moment | null;
  ReadState: boolean | null;
  UnreadState: boolean | null;
  InTaskListState: boolean | null;
  SmsSentState: boolean | null;
  EmailSentState: boolean | null;
  MyPatientsState: boolean | null;
}

export interface ProviderStateUIModel {
  pathCentreFilter: PathCentreFilter;
}

export interface ProviderUIState extends EntityState<ProviderStateUIModel> {}

export interface PatientInWaitingRoomViewModel extends PatientInWaitingRoom {
  PatientId: string;
}

export function createInitialState(): ProviderViewState {
  return {
    providerId: null,
    details: null,
    addressbook: [],
    calendar: [],
    calendarView: 'day',
    waitingRoom: [],
    communicationTemplates: [],
    chatStatuses: [],
    lastChecked: null,
    specialityRule: null,
    undeliveredReports: [],
    patientReports: [],
    followupReports: [],
    urgentReports: [],
    todayReports: [],
    allergens: [],
    weekReports: [],
    pathologyReports: [],
    tasks: [],
    trackingConditions: [],
    practiceProviders: [],
    branches: [],
    templates: [],
    templateGroups: [],
    visitHistory: [],
    notifications: [],
    providerConfiguration: null,
    medicalInsurers: [],
    appointmentTypes: [],
    pathReports: [],
    invoiceTemplates: []
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'provider', resettable: true })
export class ProviderStore extends EntityStore<ProviderViewState> {
  ui: EntityUIStore<ProviderUIState, ProviderStateUIModel>;

  constructor() {
    super(createInitialState());
    this.createUIStore().setInitialEntityState({
      pathCentreFilter: {
        DateRange: 'LastWeek',
        UnreadState: true,
      },
    });
  }
}
