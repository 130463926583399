import { Component, OnInit, HostBinding, Input, forwardRef, ContentChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { validationErrorAnimation } from '@app/shared/animations/validation';
import { InputDirective } from '@app/shared/directives/input.directive';

@Component({
  selector: 'ui-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  animations: [ validationErrorAnimation ],
})
export class FormInputComponent implements OnInit, AfterViewInit {
  @Input() label = '';
  @Input() fieldWidth = null;
  @Input() errorMessage = 'There was a problem with this field';
  @Input() suppressOptional  = false;

  get showError() {
    return this.input.invalid && (this.input.dirty || this.input.touched);
  }
  get showOptional() {
    return !this.input.required && !this.suppressOptional;
  }



  @ContentChild(InputDirective) input: InputDirective;

  constructor( private cdr: ChangeDetectorRef ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.cdr.detach();
    if (this.fieldWidth) {
      setTimeout(() => {
        this.input.width = this.fieldWidth;
      });
    }
  }
}
