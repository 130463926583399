import { QueryEntity } from '@datorama/akita';
import { DocumentsStore, DocumentsState } from './documents.store';
import { PatientDocumentVo } from 'api-clinician-app';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';

@Injectable({ providedIn: StateModule })
export class DocumentsQuery extends QueryEntity<DocumentsState, PatientDocumentVo> {
  constructor(protected store: DocumentsStore) {
    super(store);
  }
}
