import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes a string with multiple line breaks and truns them into single breaklines.
 * input:
 * This is a sentance.
 *
 *
 * Second sentance.
 *
 * output:
 * This is a sentance.
 * Second setance.
 */
@Pipe({ name: 'singlebreak' })
export class SingleBreakPipe implements PipeTransform {
  transform(value: string) {
    return value?.replace(/\n\s*\n/g, '\n');
  }
}
