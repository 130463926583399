<h1 mat-dialog-title>
  {{ title }}
  <mat-icon mat-dialog-close>clear</mat-icon>
</h1>

<mat-dialog-content>
  <p [innerHtml]="message"></p>
</mat-dialog-content>

<mat-dialog-actions>
    <ng-container *ngFor="let action of data.actions">
        <ng-container *ngIf="!action.displayFlat">
            <button mat-raised-button  [color]="action.color"  (click)="renderAction(action)">
                {{ action.value }}
              </button>
        </ng-container>
        <ng-container *ngIf="!!action.displayFlat">
            <button mat-flat-button [color]="action.color"  (click)="renderAction(action)">
                {{ action.value }}
              </button>
        </ng-container>
    </ng-container>


</mat-dialog-actions>
