<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="over" [(opened)]="opened" class="mat-sidenav">
        <mat-nav-list>
            <a mat-list-item href="#">Home</a>
            <a mat-list-item href="#">Consults</a>
            <a mat-list-item href="#">Settings</a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <!-- Add any content here if needed -->
    </mat-sidenav-content>
</mat-sidenav-container>
