<div class="template-container"
     [ngClass]="{'hidden': !isOpen}">
  <div class="template-item" [ngClass]="{'selected': isTemplateSelected(drawingTemplate)}" *ngFor="let drawingTemplate of availableTemplates$ | async" title="{{drawingTemplate.Name}}" (click)="selectTemplate(drawingTemplate)">
    <div class="template-item-header">
      <span class="template-name">{{drawingTemplate.TemplateName}}</span>
    </div>
    <div class="template-item-content">
      <canvas [ngStyle]="{'background-image': 'url(' + drawingTemplate.TemplateSource + ')'}" class="canvas-overlay-image-layer1" *ngIf="drawingTemplate.Type === 'image'"></canvas>
      <div class="canvas-overlay-svg-layer1" *ngIf="drawingTemplate.Type === 'raw-svg'" [innerHTML]="transformSafeHtml(drawingTemplate.TemplateSource)"></div>
      <div class="canvas-overlay-svg-layer1" *ngIf="drawingTemplate.Type === 'link-svg'">
        <img src="{{drawingTemplate.TemplateSource}}" height="auto" />
      </div>
    </div>
  </div>
</div>




