<div class="container">
  <div class="header" *ngIf="authenticated$ | async">
    <ng-container [ngSwitch]="currentArea$ | async">
      <ng-container *ngSwitchCase="'patient'">
        <!-- Patient context header -->
        <div class="patient-name" *ngIf="patient$ | async as patient">
          <button mat-icon-button [routerLink]="[currentTenantId$ | async, 'home', 'mobile']">
            <mat-icon>arrow_back</mat-icon>
          </button>
          {{ patient.PatientDetails.FirstName }}
          {{ patient.KnownAs !== patient.PatientDetails.FirstName ? patient.KnownAs && '(' + patient.KnownAs + ')' : '' }}
          {{ patient.PatientDetails.Surname }}
        </div>
        <!-- <button mat-icon-button (click)="expandConsultation()">
          <mat-icon>open_in_full</mat-icon>
        </button> -->
      </ng-container>

      <ng-container *ngSwitchCase="'pathology'">
        <!-- Pathology context header -->
        <div class="patient-name">
          <button mat-icon-button [routerLink]="[currentTenantId$ | async, 'home', 'mobile']">
            <mat-icon>arrow_back</mat-icon>
          </button>
          Pathology
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <!-- Default context header -->
        <div class="left">
          <button mat-button [routerLink]="[currentTenantId$ | async, 'home', 'mobile']">
            <img src="/assets/images/mobile-logo.png" />
          </button>
        </div>
        <div class="right">
          <div>
            <button
              mat-icon-button
              [routerLink]="[currentTenantId$ | async, 'home', 'mobile-path-centre']"
              class="nav large"
              routerLinkActive="active"
              *ngIf="(browserWidth$ | async) > 425"
            >
              <mat-icon svgIcon="pathology"></mat-icon>
            </button>
            <button
              mat-icon-button
              [routerLink]="[currentTenantId$ | async, 'patient', 'mobile-search']"
              class="nav large"
              routerLinkActive="active"
            >
              <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button class="nav large" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="[currentTenantId$ | async, 'home', 'mobile-path-centre']"
              *ngIf="(browserWidth$ | async) < 425">
              <mat-icon svgIcon="pathology"></mat-icon>
              <span>Pathology</span>
            </button>
            <button mat-menu-item (click)="setDesktopOverride()">
              <mat-icon>desktop_windows</mat-icon>
              <span>Desktop Site</span>
            </button>
            <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
