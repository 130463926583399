import { Query, QueryEntity, EntityUIQuery, selectPersistStateInit } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ProviderViewState, ProviderStore, ProviderUIState, ProviderStateUIModel, PathCentreFilter } from './provider.store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProviderQuery extends QueryEntity<ProviderViewState> {
  ui: EntityUIQuery<ProviderUIState, ProviderStateUIModel>;

  provider$ = this.select(p => p.details);
  addressbook$ = this.select(p => p.addressbook);
  calendar$ = this.select(p => p.calendar);
  waitingRoom$ = this.select(p => p.waitingRoom);
  chatStatuses$ = this.select(p => p.chatStatuses);
  specialityRule$ = this.select(p => p.specialityRule);
  undeliveredReports$ = this.select(p => p.undeliveredReports);
  patientReports$ = this.select(p => p.patientReports);
  followupReports$ = this.select(p => p.followupReports);
  urgentReports$ = this.select(p => p.urgentReports);
  todayReports$ = this.select(p => p.todayReports);
  weekReports$ = this.select(p => p.weekReports);
  pathologyReports$ = this.select(p => p.pathologyReports);
  trackingConditions$ = this.select(p => p.trackingConditions);
  tasks$ = this.select(p => p.tasks);
  allergens$ = this.select(p => p.allergens);
  practiceProviders$ = this.select(p => p.practiceProviders);
  branches$ = this.select(p => p.branches);
  invoiceTemplates$ = this.select(p => p.invoiceTemplates);
  templates$ = this.select(p => p.templates);
  templateGroups$ = this.select(p => p.templateGroups);
  visitHistory$ = this.select(p => p.visitHistory);
  communicationTemplates$ = this.select(p => p.communicationTemplates);
  notifications$ = this.select(p => p.notifications);
  providerConfiguration$ = this.select(p => p.providerConfiguration);
  medicalInsurers$ = this.select(p => p.medicalInsurers);
  appointmentTypes$ = this.select(p => p.appointmentTypes);
  pathReports$ = this.select(p => p.pathReports);

  constructor(protected store: ProviderStore) {
    super(store);
    this.createUIQuery();
  }

  providerUiState$(): Observable<PathCentreFilter> {
    return combineLatest([this.ui.select(p => p.pathFilters), selectPersistStateInit()]).pipe(map(([filters, init]) => filters));
  }
}
