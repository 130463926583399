<header *ngIf="{ tenantId: currentTenantId$ | async, auth: authenticated$ | async } as params">
  <div class="logo_area">
    <button mat-icon-button [routerLink]="[params.tenantId, 'home']" routerLinkActive="active">
      <img src='assets/nav/header_logo_new.png' />
    </button>
  </div>

  <div id="e2e_patient_tabs" class="patient_tabs">
    <button id="e2e_home_button" mat-button class="home_icon" [routerLink]="[params.tenantId, 'home']"
      routerLinkActive="active">
      <mat-icon>home</mat-icon>
    </button>

    <button mat-button class="patient_tab" *ngFor="let patient of activePatients$ | async"
      [routerLink]="[params.tenantId, 'patient', patient.patientId]" routerLinkActive="active">
      <div class="patient_name">
        <!-- Dev note: Locator class -->
        <span class="loc-tab-patient-name">{{ patient.patientName | titlecase }}</span>
        <!-- Dev note: Locator class -->
        <mat-icon class="recording-icon" 
        *ngIf="(audioRecorderService.recording$ | async) === true && audioRecorderService.getPatientId() === patient.patientId">
          radio_button_checked
        </mat-icon>
        <mat-icon class="loc-tab-patient-close" [ngClass]="{'orange-border': inProgressEncounter(params.tenantId, patient.patientId)}" routerLinkActive="active"
          (click)="$event.stopPropagation(); removeActivePatient(params.tenantId, patient.patientId)">cancel</mat-icon>
      </div>
    </button>
  </div>

  <div class="header_search">
    <button id="e2e_patient_search_button" mat-button (click)="focusSearchInput()">
      <mat-icon class="searchIcon" fontSet="material-icons-outlined">search</mat-icon>
    </button>
    <input id="e2e_patient_search_box" type="text" #searchInput placeholder="Patient search"
      [formControl]="searchControl" [matAutocomplete]="auto" class="search_box" (keydown.escape)="searchInput.blur()" />
    <mat-autocomplete #auto="matAutocomplete" panelWidth="600px" (optionSelected)="selectPatient($event.option.value)">
      <mat-option *ngFor="let patient of filteredPatients$ | async" [value]="patient.PatientId"
        class="header-search-result">
        <span class="text">
          {{ patient.PatientDetails.FirstName }} {{ patient.KnownAs && '(' + patient.KnownAs + ')' }} {{
          patient.PatientDetails.Surname }} -
          {{ patient.PatientDetails.DateOfBirth | date: 'mediumDate' }} -
          {{ (!patient.PatientAccountDetails.IsCashAccount ? patient.PatientAccountDetails.MedicalAidName : null) ||
          'CASH' }}
        </span>
        <mat-icon matTooltip="Add patient to waiting room"
          (click)="$event.stopPropagation(); addToWaitingRoom(patient.PatientId, params.tenantId)">add</mat-icon>
      </mat-option>
    </mat-autocomplete>
    <mat-icon class="addIcon clickable" fontSet="material-icons-outlined"
      [routerLink]="[params.tenantId, 'patient', 'add']">
      person_add
    </mat-icon>
  </div>

  <div class="doctor_bio">
    <div class="doctor_name">
      <a [routerLink]="[params.tenantId, 'provider']">
        <h4 class="doctor-name-title">{{ (currentProvider$ | async)?.TreatingDoctorName }}</h4>
      </a>
    </div>
    <!-- <div class="doctor_avatar">
      <img src="/assets/images/drstrange.png" alt="{{ (currentProvider$ | async)?.TreatingDoctorName }}" />
    </div> -->
  </div>
</header>
<mat-progress-bar color="primary" mode="indeterminate" *ngIf="displayProgressBar$ | async"> </mat-progress-bar>
