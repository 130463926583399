import { Directive, HostBinding, Self, OnInit, OnDestroy } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[uiInput]'
})
export class InputDirective {
  @HostBinding('style.width')
  get elementWidth() {
    return this.fixedWidth || null;
  }

  get name() {
    return this.ngControl.name;
  }

  get required() {
    const f = this.ngControl.control.validator;
    if (f) {
      const validator = f({} as AbstractControl);
      return (validator && validator.required);
    } else {
      return false;
    }
  }

  fixedWidth = null;
  set width(width: string) {
    if (width === '') {
      this.fixedWidth = null;
    } else {
      this.fixedWidth = width;
    }
  }

  get invalid() {
    return this.ngControl.invalid;
  }
  get dirty() {
    return this.ngControl.dirty;
  }
  get touched() {
    return this.ngControl.touched;
  }

  constructor(@Self() private ngControl: NgControl) {
  }
}
