import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PatientsService } from '@app/core/services';
import { ClinicalEncounterService } from '@app/core/services/clinical-encounter.service';
import { PatientWhiteboardDialogComponent } from '@app/modules/patient/patient-whiteboard/patient-whiteboard-dialog/patient-whiteboard-dialog.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { delay, distinctUntilChanged, map, mapTo, mergeAll, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'shared-whiteboard-icon',
  templateUrl: './whiteboard-icon.component.html',
  styleUrls: ['./whiteboard-icon.component.scss']
})
export class WhiteboardIconComponent implements OnInit, OnDestroy {

  @Input() shouldUploadOnComplete = false;
  @Input() type = '';
  @Input() uploadOnSave = false;

  params$ = this.route.paramMap.pipe(
    map(param => ({ tenantId: param.get('tenantId'), patientId: param.get('patientId') })),
    distinctUntilChanged()
  );

  diagrams$ = this.params$.pipe(
    take(1),
    switchMap(p => this.clinicalEncounterService.encounterInProgress$(p.tenantId, p.patientId)),
    map(encounter => (encounter.EncounterDiagrams?.filter(diagram => diagram.Type === this.type)) || []),
  );

  hasDiagram$ = this.diagrams$.pipe(
    map(diagrams => diagrams?.length > 0 || 0)
  );

  constructor(
    private route: ActivatedRoute,
    private clinicalEncounterService: ClinicalEncounterService,
    private patientsService: PatientsService,
    private dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    if (!this.type) {
      throw new Error(`No type provided for: ${this.constructor.name}`);
    }
  }

  ngOnDestroy(): void { }

  onClick() {
    this.params$.pipe(
      take(1),
      switchMap(params => {
        const dialogRef = this.dialog.open(PatientWhiteboardDialogComponent, {
          data: {
            type: this.type,
            uploadOnSave: this.uploadOnSave,
            shouldUploadOnComplete: this.shouldUploadOnComplete,
            tenantId: params.tenantId,
            patientId: params.patientId
          },
          height: 'calc(100vh - 40px)',
          width: 'calc(100vw - 40px)',
          maxWidth: 'calc(100vw - 40px)',
          disableClose: true,
          panelClass: 'clear-padding-dialog'
        })
        return dialogRef.afterClosed().pipe(
          take(1),
          untilDestroyed(this),
          delay(300),
          switchMap(result => {
            return result?.event === PatientWhiteboardDialogComponent.RESULT.UPLOADED ? this.patientsService.getPatientDashboard(params.tenantId, params.patientId) : of(null)
          })
        );
      }),
      untilDestroyed(this)
    ).subscribe();
  }

}
