export interface IEnvironment {
  production: boolean;
  apiUrl: string;
  merakiApiGateway: string;
  merakiCacheLoadBalancer: string;
  applicationInsightsKey: string;
  clientId: string;
  videoChatUrl: string;
  googleCaptchaSiteKey: string;
  oneSignalAppId: string;
  bridgeitUrl: string;
  meiliSearchLookup: {
    host: string;
    key: string;
  },
  meiliSearchAccount: {
    host: string;
    key: string;
  },
  algoliaSettings: {
    apiKey: string;
    appId: string;
    medicineIndexName: string;
    genericMedicineIndexName: string;
    diagnosisIndexName: string;
    defaultDebounce: number;
  };
  smartGenericsSettings: {
    Url: string;
    FsCollection: string;
    BaseGroupName: string;
    CommunityName: string;
    AdsDisplayUrl: string;
  };
  settings?: { [key: string]: boolean };
  offlineSync: {
    enabled: boolean;
    delayBeforeStart: number;
    delayBetweenPatients: number;
    delayBetweenChecks: number;
  };
  keepAliveTesting: {
    enabled: boolean;
    delayBetweenChecks: number;
  };
  localStorage: {
    type: StorageType;
    version: number;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  nora: {
    noraApiToken: string;
    noraApiHostUrlV1: string;
    noraApiHostUrlV2: string;
  },
  sandbox: {
    cloudFunctionBaseUrl: string;
  }
}

export enum StorageType {
  LocalStorage = 'LOCALSTORAGE',
  IndexedDB = 'INDEXEDDB',
}
