<input
  #fileSelection
  class="file-input"
  type="file"
  style="display: none"
  (change)="changeListener($event)"
  [accept]="accept || 'image/gif, image/jpeg, image/png, .pdf'"
  multiple>
<span class="btn-file-input" style="display: flex;">
  <button
    #btn
    mat-raised-button
    color="primary"
    type="button"
    (click)="addFilesClick()">
    <ng-content></ng-content>
  </button>
  {{ !!files?.length && displayFilename ? files[0].name : '' }}
</span>
