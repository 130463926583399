import { Pipe, PipeTransform } from '@angular/core';

/**
 * Formats a phone number into the typciall phone number format. The follow are the only formats currently catered for:
 *
 * Input: 0821234567
 * Output: 082 123 4567
 *
 * Input: 27821234567
 * Output: 27 82 123 4567
 *
 * All other input will be output as-is, without change
 */
@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: string) {
    if (phoneNumber?.length > 9) {
      if (phoneNumber.startsWith('0')) {
        return phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3, 6) + ' ' + phoneNumber.slice(6);
      } else {
        return phoneNumber.slice(0, 2) + ' ' + phoneNumber.slice(2, 4) + ' ' + phoneNumber.slice(4, 7) + ' ' + phoneNumber.slice(7);
      }
    }
    return phoneNumber;
  }
}
