<div>
  <ng-container *ngFor="let category of questionGroup">
    <ng-container [ngSwitch]="category.Category">
      <ng-container *ngSwitchCase="'Smoking'">
        <h4 *ngIf="!!category.Category">{{ category.Category }}</h4>
          <div>
            <div class="row">
              <shared-dynamic-form-question class="smoking-question" [question]="getQuestionByQuestionKey('Smoker_Status', category.Questions)" [form]="form"></shared-dynamic-form-question>
              <shared-dynamic-form-question class="smoking-question" [question]="getQuestionByQuestionKey('Smoker_Severity', category.Questions)" [form]="form"></shared-dynamic-form-question>
            </div>
            <div>
              <shared-dynamic-form-question class="smoking-question" [question]="getQuestionByQuestionKey('Smoker_YearsDuration', category.Questions)" [form]="form"></shared-dynamic-form-question>
              <shared-dynamic-form-question class="smoking-question" [question]="getQuestionByQuestionKey('Smoker_AverageQuantity', category.Questions)" [form]="form"></shared-dynamic-form-question>
            </div>
            <div class="grid-row">
              <mat-label class="label"></mat-label>
              <p class="smoking-exposure question full-row-span" *ngIf="SmokingExposure !== 0">
                Your smoking exposure is
                <strong
                  >{{ SmokingExposure }} pack-years
                  <mat-icon
                    matTooltip="1 pack-year is equal to smoking 20 cigarettes (1 pack) per day for 1 year or 40 cigarettes per day for half a year, and so on"
                    >info</mat-icon
                  >
                </strong>
              </p>
            </div>
            <div>
              <shared-dynamic-form-question class="smoking-question" [question]="getQuestionByQuestionKey('Smoker_Notes', category.Questions)" [form]="form"></shared-dynamic-form-question>
            </div>
          </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <h4 *ngIf="!!category.Category">{{ category.Category }}</h4>
        <div *ngFor="let group of category.Questions" [ngClass]="{ 'sub-category': category.Category }">
          <h4 *ngIf="!!group.Subcategory">{{ group.Subcategory }}</h4>
          <div *ngFor="let question of group.Questions" class="question">
            <shared-dynamic-form-question [question]="question" [form]="form"></shared-dynamic-form-question>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
