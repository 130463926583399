import { EntityState, StoreConfig, EntityStore, EntityUIStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';
import { EncounterStatusVo, MedicineInteractionVo, EncounterVo, SmartCategories2, Consumables } from '../../api-client.service';
import { KahunAppointment } from '../../view-models/kahun-view-model.utils';
import { AdImpression } from '../../view-models/smartmeds-ads-models';

export const DEFAULT_CATEGORIES = 2;

export type SymptomQuestionSection = 'history' | 'examination' | 'plan' | 'diagnosis';

export enum MedicineInteractionStatus {
    None = 0,
    Loading = 1,
    Failure = 2
}

export interface ClinicalEncounterUIViewModel {
    expansionSections: SectionExpanded[];
    categoriesToShow: number;
    subCategoriesToDisplay: number;

    // smart template caching
    diagnosisSuggestions: {
        [token: string]: SmartCategories2
    };
    consumableSuggestionsPerCategory: {
        [token: string]: Consumables
    };
    sectionUsage: string[];
    medicineInteractions: MedicineInteractionVo[];
    medicineInteractionStatus: MedicineInteractionStatus;

    // kahun patient summary notes caching
    kahunAppointment?: KahunAppointment | null;
    kahunConsultationNotesSelection?: string[]

    // smartmeds ad impressions
    adImpressions: AdImpression[];
}

export interface MedicineFilterModel {
    filter: string;
    flag: string;
    selected: boolean;
}

export interface SectionExpanded {
    section: SectionEnum;
    expanded: boolean;
}

export enum SectionEnum {
    ReasonToVisit,
    Symptoms,
    Examination,
    Diagnosis,
    Medicines,
    Plan,
    InvoiceLines,
    Review,
}

export const defaultExpansionSections: SectionExpanded[] = [
    { section: SectionEnum.ReasonToVisit, expanded: true },
    { section: SectionEnum.Symptoms, expanded: true },
    { section: SectionEnum.Examination, expanded: true },
    { section: SectionEnum.Diagnosis, expanded: true },
    { section: SectionEnum.Medicines, expanded: true },
    { section: SectionEnum.Plan, expanded: true },
    { section: SectionEnum.InvoiceLines, expanded: true },
    { section: SectionEnum.Review, expanded: true },
];

export interface ClinicalEncountersState extends EntityState<EncounterVo> { }
export interface ClinicalEncounterUIState extends EntityState<ClinicalEncounterUIViewModel> { }


@Injectable({ providedIn: StateModule })
@StoreConfig({ name: 'clinical-encounters', idKey: 'PatientId', resettable: true })
export class ClinicalEncountersStore extends EntityStore<ClinicalEncountersState, EncounterVo> {

    ui: EntityUIStore<ClinicalEncounterUIState>;

    constructor() {
        super();
        this.createUIStore().setInitialEntityState({
            expansionSections: defaultExpansionSections,
            categoriesToShow: DEFAULT_CATEGORIES,
            subCategoriesToShow: DEFAULT_CATEGORIES,
            diagnosisSuggestions: {},
            consumableSuggestionsPerCategory: {},
            sectionUsage: [],
            medicineInteractions: [],
            medicineInteractionsStatus: MedicineInteractionStatus.None,
            kahunAppointment: null,
            kahunConsultationNotesSelection: [],
            adImpressions: []
        });
    }

    addEncounter(patientId, encounter: EncounterStatusVo) {
        this.add({
            ...encounter,
            DiagnosisTokenItems: encounter.DiagnosisTokenItems || [],
            PatientId: patientId,
            Communications: encounter.Communications || [],
            MedicationsPrescriptions: encounter.MedicationsPrescriptions || [],
            MedicationsPrescribed: null,
            Diagnosis: encounter.Diagnosis || [],
            ClinicalMetrics: encounter.ClinicalMetrics || [],
            Symptoms: encounter.Symptoms || [],
            SymptomQuestions: encounter.SymptomQuestions || [],
            SymptomQuestionAnswers: encounter.SymptomQuestionAnswers || [],
            EncounterDiagrams: encounter.EncounterDiagrams || [],
            EncounterLineItems: {
                ...encounter.EncounterLineItems,
                LineItems: [...encounter.EncounterLineItems.LineItems.map(l => ({ ...l, Diagnosis: l.Diagnosis || [] }))],
            },
        } as EncounterVo);
    }
}
