import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { TenantsState, TenantsStore } from './tenants.store';
import { TenantVo } from 'api-clinician-app';

@Injectable({
  providedIn: 'root'
})
export class TenantsQuery extends QueryEntity<TenantsState, TenantVo> {
  constructor(protected store: TenantsStore) {
    super(store);
  }
}
