<mat-toolbar>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
    </button>
    <span>Nora</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with resize icon" (click)="resizeDialog()">
        <mat-icon>aspect_ratio</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" (click)="closeDialog()">
        <mat-icon>close_small</mat-icon>
    </button>
</mat-toolbar>
