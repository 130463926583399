import { Injectable } from '@angular/core';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';

export enum USER_ROLE {
  Doctor = 'doctor',
  FrontOfficeUser = 'front-office-user',
  SystemAdmin = 'system-admin',
  Receptionist = 'receptionist',
  PracticingNurse = 'practicing-nurse',
  TriageNurse = 'triage-nurse',
}

export enum CONSULT_PERMISSION {
  All = '**',
  Capture = 'canCaptureConsult',
  Complete = 'canCompleteConsult',
  Cancel = 'canCancelConsult',
  Save = 'canSaveConsult'
}

export enum FRONT_DESK_PERMISSION {
  DenyViewPatientFile = 'denyViewPatientfile',
  DenyViewConditionTracker = 'denyViewConditionTracker',
  DenyViewPathology = 'denyViewPathology',
  DenyViewSettings = 'denyViewSettings'
}

@Injectable({
  providedIn: 'root',
})
export class RoleManagerService {

  constructor(private permissionsService: NgxPermissionsService, private rolesService: NgxRolesService) { }

  public setRoleAndPermissions(role: USER_ROLE) {

    let permissions: string[] = [];

    switch (role) {
      case USER_ROLE.Doctor:
        permissions = this.ToArray(CONSULT_PERMISSION);

        break;
      case USER_ROLE.PracticingNurse:
        permissions = this.ToArray(CONSULT_PERMISSION);

        break;
      case USER_ROLE.TriageNurse:
        permissions = this.ToArray(CONSULT_PERMISSION).filter(f => f === CONSULT_PERMISSION.All || f === CONSULT_PERMISSION.Save);

        break;
      case USER_ROLE.SystemAdmin:
        permissions = ['*', ...this.ToArray(CONSULT_PERMISSION)];

        break;
      case USER_ROLE.FrontOfficeUser:
      case USER_ROLE.Receptionist:
        permissions = this.ToArray(FRONT_DESK_PERMISSION);

        break;
    }

    this.rolesService.addRole(role, permissions);
    this.permissionsService.addPermission(permissions);

  }

  public flushRolesAndPermissions() {
    this.permissionsService.flushPermissions();
    this.rolesService.flushRoles();
  }

  private ToArray(enumme) {
    return Object.keys(enumme).map(key => enumme[key]);
  }
}
