
import { trigger, state, style, transition, animate, query, stagger, sequence } from '@angular/animations';

export const flexAnimate =
  trigger('flexAnimate', [
    transition(':enter', [
      style({width: 0}),
      animate(250, style({width: '*'})),
    ]),
    transition(':leave', [
      style({width: '*'}),
      animate(250, style({width: 0})),
    ]),
  ]);
