
<div class="toggle-container" [formGroup]="form">
  <label [for]="clientId + '-first'" class="clickable">{{ No.AnswerValue }}</label>
  <div class="toggle-radio" [ngClass]="{ 'has-value' : hasValue }">
    <input type="radio" class="toggle-option first-toggle" [id]="clientId + '-first'" formControlName="radio" [value]="No.AnswerValue" />
    <input type="radio" class="toggle-option second-toggle" [id]="clientId + '-second'" formControlName="radio" value="null" checked="true"/>
    <input type="radio" class="toggle-option third-toggle" [id]="clientId + '-third'" formControlName="radio" [value]="Yes.AnswerValue" />
    <label [for]="clientId + '-first'"></label>
    <label [for]="clientId + '-second'"></label>
    <label [for]="clientId + '-third'"></label>
    <div class="toggle-option-slider"></div>
  </div>
  <label [for]="clientId + '-third'" class="clickable">{{ Yes.AnswerValue }}</label>
</div>
