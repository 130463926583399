import { mergeMap as _observableMergeMap, catchError as _observableCatch, map, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpEventType } from '@angular/common/http';
import { API_BASE_URL, PatientDocumentVo, RestApiResultOfGuid } from './api-client.service';
import { untilDestroyed } from 'ngx-take-until-destroy';

export interface UploadProgress {
  fileId: string;
  sizeTotal: number;
  sizeUploaded: number;
  percentageUploaded: number;
  isComplete: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : null;
  }

  /**
   * Capture patient document
   * PatientDocumentVo pass via POST headers
   * @return OK
   */
  capturePatientDocument(
    practiceId: string,
    contextId: string | null,
    patientDocumentVo: PatientDocumentVo,
    file: Blob = null,
    pictures: string[] = []
  ) {
    if (practiceId === undefined || practiceId === null) {
      throw new Error('The parameter "practiceId" must be defined.');
    }
    if (contextId === undefined || contextId === null) {
      throw new Error('The parameter "contextId" must be defined.');
    }
    if ((file === undefined || file === null) && (pictures === undefined || pictures === null || pictures.length === 0)) {
      throw new Error('No file/pictures provided');
    }

    const url = `${this.baseUrl}/api/v1/${practiceId}/clinical/patientdocument/${contextId}`;
    const formData: FormData = new FormData();

    if (file) {
      formData.append('Document', file);
    } else {
      for (let i = 0; i < pictures.length; i++) {
        const blob = this.dataURItoBlob(pictures[i]);
        formData.append('Picture' + i, blob);
      }
    }

    const httpHeaders = new HttpHeaders({
      payload: encodeURIComponent(JSON.stringify(patientDocumentVo)),
    });
    httpHeaders.delete('Content-Type');

    const request = this.http.post<RestApiResultOfGuid>(url, formData, {
      headers: httpHeaders,
    }).pipe(
      distinctUntilChanged(), // remove the noise of intermittent events
      untilDestroyed(this, 'destroy'),
    );
    return request;
  }


  /**
   * Capture patient document
   * PatientDocumentVo pass via POST headers
   * @return OK
   */
  capturePatientDocumentWithProgress(
    practiceId: string,
    contextId: string | null,
    patientDocumentVo: PatientDocumentVo,
    file: Blob = null,
    pictures: string[] = []
  ) {
    if (practiceId === undefined || practiceId === null) {
      throw new Error('The parameter "practiceId" must be defined.');
    }
    if (contextId === undefined || contextId === null) {
      throw new Error('The parameter "contextId" must be defined.');
    }
    if ((file === undefined || file === null) && (pictures === undefined || pictures === null || pictures.length === 0)) {
      throw new Error('No file/pictures provided');
    }

    const url = `${this.baseUrl}/api/v1/${practiceId}/clinical/patientdocument/${contextId}`;
    const formData: FormData = new FormData();

    if (file) {
      formData.append('Document', file);
    } else {
      for (let i = 0; i < pictures.length; i++) {
        const blob = this.dataURItoBlob(pictures[i]);
        formData.append('Picture' + i, blob);
      }
    }

    const httpHeaders = new HttpHeaders({
      payload: encodeURIComponent(JSON.stringify(patientDocumentVo)),
    });
    httpHeaders.delete('Content-Type');

    const progress = {
      sizeUploaded: 0,
      sizeTotal: file.size,
      percentageUploaded: 0,
      isComplete: false,
    } as UploadProgress;

    const request = this.http.post<RestApiResultOfGuid>(url, formData, {
      headers: httpHeaders,
      observe: 'events',
      reportProgress: true,
    }).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;

          case HttpEventType.UploadProgress:
            progress.sizeTotal = event.total;
            progress.sizeUploaded = event.loaded;
            const percentDone = Math.round(100 * event.loaded / event.total);
            progress.percentageUploaded = percentDone;
            break;

          case HttpEventType.ResponseHeader:
            progress.sizeUploaded = progress.sizeTotal;
            progress.percentageUploaded = 100;
            break;

          case HttpEventType.Response:
            progress.sizeUploaded = progress.sizeTotal;
            progress.percentageUploaded = 100;
            progress.isComplete = true;
            if (event.body.Sucess) { progress.fileId = event.body.Data; }
            break;

          default:
            break;
        }
        return Object.assign({}, progress); // return fresh version of the object, else distinct() will interfere
      }),
      distinctUntilChanged(), // remove the noise of intermittent events
      untilDestroyed(this, 'destroy'),
    );
    return request;
  }

  private dataURItoBlob(dataURI) {
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  destroy() {}
}
