import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sidenav',
  templateUrl: './nora-sidenav.component.html',
  styleUrls: ['./nora-sidenav.component.css'],
//   standalone: true,
//   imports: [CommonModule, MatSidenavModule, MatListModule]
})
export class SidenavComponent {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  opened = false;

  toggle() {
    this.sidenav.toggle().then(() => {
      console.log('Sidenav toggled, opened:', this.sidenav.opened);
    });
  }
}
