import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFrom'
})
export class DateFromPipe implements PipeTransform {

  transform(value: Date, ...args: any[]): string {

    // const format = !!args[0] ? args[0] : 'hh:mm a';

    const today = new Date().toLocaleDateString();
    const date = new Date(value).toLocaleDateString();

    if (!moment(date).isAfter(today, 'day')) {
      return moment(value).fromNow();
    }

    return moment(date).format('YYYY-MM-DD');
  }

}
