import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { patientAge } from '../functions/patientAge';
@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  transform(value: Date): string {
    const age = patientAge(value);
    return `${age.unit} ${age.unitType.substring(0, 1)}`;
  }
}

@Pipe({
  name: 'humanizedAge'
})
export class HumanizedAgePipe implements PipeTransform {
  transform(value: Date): string {
    const age = patientAge(value);
    return age.longAge;
  }
}
