<h2 mat-dialog-title>Send us your feedback <mat-icon mat-dialog-close>clear</mat-icon></h2>
<mat-dialog-content>
  <mat-label class="label-info">
    {{ (userFeedbackSent$ | async) === false ? titleSubmit : titleDone }}
  </mat-label>
  <form *ngIf="(userFeedbackSent$ | async) === false" [formGroup]="form">
    <mat-radio-group formControlName="feedbackType">
      <mat-radio-button #rbtn class="radio" *ngFor="let feedbackType of feedbackTypes" [value]="feedbackType" >
        {{ feedbackType }}
      </mat-radio-button>
    </mat-radio-group>
    <editor formControlName="feedbackBody" [init]="editorConfig"></editor>
    <div class="attachment-container">
      <input type="file" #fileUpload (change)="fileUploadEvent($event)"/>
      <div class="attachment-items">
        <button mat-raised-button (click)="addAttachmentClick()">
          <mat-icon class="img" fontSet="material-icons-outlined" matTooltip="User Feedback">attach_file</mat-icon>
          Add Attachment
        </button>
        <mat-list class="attachment-list" id="e2e_upload_files_list">
          <mat-list-item *ngFor="let file of uploadFiles$ | async">
            {{ file.fileName }} - {{ file.fileSize }}
            <mat-icon class="remove-icon" color="primary" (click)="removeFile(file)">delete</mat-icon>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </form>
  <div *ngIf="(userFeedbackSent$ | async) === true" class="submit-complete-container">
    <div class="submit-icon-container">
      <div class="submit-item-border"></div>
      <div class="submit-item-background"></div>
      <mat-icon class="submit-success-icon" >check_circle</mat-icon>
    </div>
    <mat-label>Feedback sent successfully.</mat-label>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="(userFeedbackSent$ | async) === false" mat-raised-button cdkFocusInitial color="primary" [disabled]="form.invalid" #sendButton (click)="sendUserFeedback(form, sendButton)">Send feedback</button>
  <button *ngIf="(userFeedbackSent$ | async) === true" mat-raised-button color="primary" mat-dialog-close>Done</button>
</mat-dialog-actions>
