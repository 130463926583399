import { NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'healthbridgesupport.zendesk.com';
  beforePageLoad(zE) {
    zE.setLocale('en');
    zE.hide();
  }
  callback(zE) {
    // You can call every command you want in here
    zE('webWidget', 'hide');
  }
}
