<div *ngIf="imageUrl; else uploadImage">

  <angular-cropper  #angularCropper [cropperOptions]="config" [imageUrl]="imageUrl"></angular-cropper>

  <!-- <mat-slider min="1" max="200" value="100"></mat-slider> -->

  <button mat-button (click)="done()">
    <mat-icon matPrefix>check</mat-icon>
    Done
  </button>
  <button mat-button (click)="cancel()">
    <mat-icon matPrefix>cancel</mat-icon>
    Cancel
  </button>
</div>
<ng-template #uploadImage>
  <input hidden type="file" #imageUpload (change)="imageUploadEvent($event)" accept="image/gif, image/jpeg, image/png"/>
  <button mat-flat-button color="primary" type="button" (click)="imageUpload.click()">
    <mat-icon matPrefix>edit</mat-icon>
    Change
  </button>
</ng-template>



