
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'slice' })
export class SlicePipe implements PipeTransform {
  transform(value: string, start: number, end: number) {
    if (value?.length > end) {
      return value.slice(start, end) + '...';
    } else {
      return value;
    }
  }
}
