<ng-container [ngSwitch]="displayMode">
  <div *ngSwitchCase="'full'" class="container-full">
    <div class="actions">
      <h4>Notes</h4>
      <shared-whiteboard-icon [type]="'Clinical note'" [ngClass]="{ 'white-icon-button-file': !(isMobile$ | async) }">
      </shared-whiteboard-icon>

      <button mat-icon-button aria-label="Take Picture" (click)="takePicture()">
        <mat-icon class="icons">camera_alt</mat-icon>
      </button>

      <button mat-icon-button aria-label="Attach File" (click)="openAddFileDialog()">
        <mat-icon class="icons">attach_file</mat-icon>
      </button>
      
      <!-- <button mat-icon-button aria-label="Consult in progress" *ngIf="(speechToNotesEnabled$ | async) == true" [disabled]="isResized" (click)="updateSpeechToNotesDialogSize(true)">
        <mat-icon class="icons" class="record-consult" 
        [style]="speechToNotesProcessingStatus ? ('color: green; animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;') : (isResized ? (isRecording && isResized ? (isPaused ? 'color: orange' : 'color: red; animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;') : 'color: grey;') : (isRecording ? (isPaused ? 'color: grey' : 'color: red; animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;') : 'color: grey;'))">
         {{speechToNotesProcessingStatus ? 'downloading' : 'radio_button_checked'}}
        </mat-icon>
         {{speechToNotesProcessingStatus ? ('Processing your notes') : (!isResized ? 'Minimised' : (isRecording ? (isPaused ? 'Paused' : 'Recording...') : ''))}}
      </button> -->
    </div>

    <div [formGroup]="clinicalNoteForm" class="clinical-note-field">
      <mat-form-field appearance="outline" floatLabel="always" class="form-field-full-width">
        <textarea matInput formControlName="clinicalNote" class="textarea-auto-resize"
          placeholder="Capture general clinical notes"></textarea>
      </mat-form-field>
      <div class="save-buttons" *ngIf="speechToNotesEnabled$ | async">
        <button mat-stroked-button (click)="updateSpeechToNotes(true)" [disabled]="!isSpeechToNotesDoneProcessing">
            <mat-icon>thumb_up</mat-icon>
            Good and Save
        </button>
        <button mat-stroked-button (click)="updateSpeechToNotes(false)" [disabled]="!isSpeechToNotesDoneProcessing">
            <mat-icon>thumb_down</mat-icon>
            Bad and Save
        </button>
      </div>
    </div>
  </div>

  <ng-template ngSwitchDefault>
    <div class="container">
      <div class="containerBody">
        <ng-template #noEncounter>
          <div class="containerSubBody">
            <div class="notes-card">
              Consultation Note
              <div fxFlex></div>
            </div>
          </div>
        </ng-template>

        <div class="containerSubBody" *ngIf="encounterInProgress$ | async as encounter; else noEncounter">
          <div class="notes-card">
            Consultation Note
            <div fxFlex></div>
            <button mat-icon-button (click)="takePicture()">
              <mat-icon class="icons">camera_alt</mat-icon>
            </button>

            <button mat-icon-button aria-label="Attach File" (click)="openAddFileDialog()">
              <mat-icon class="icons">attach_file</mat-icon>
            </button>
          </div>
          <form [formGroup]="clinicalNoteForm" class="note-form">
            <div class="clinical-note-field">
              <textarea matInput formControlName="clinicalNote" class="textarea-auto-resize"
                placeholder="Capture general clinical notes about the consultation here"></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
