import { Component, OnInit, HostBinding, Input, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl, FormBuilder } from '@angular/forms';
import { distinctUntilChanged, debounceTime, tap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

export interface Values {
  AnswerValue: string;
  NoteValue: string;
}

@Component({
  selector: 'ui-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleComponent),
      multi: true
    }
  ],
})
export class SlideToggleComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @HostBinding('attr.id') externalId = '';
  @Input() label = '';

  @Input() values: Values[];

  @Input() formControlName: string;

  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }

  get clientId() {
    return  this.id || this.formControlName;
  }

  private _ID = '';

  form = this.fb.group({
    radio: ['null', '']
  });

  onChange: any = () => { };
  onTouch: any = () => { };

  get value() {
    return this.form.value.radio;
  }

  constructor(
    private fb: FormBuilder) { }

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(10),
        untilDestroyed(this),
      )
      .subscribe({
        next: formValues => {
          this.onChange(formValues.radio);
          this.onTouch();
        }
      });
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(val) {
    if (val && this.form.value.radio !== val) {
      this.form.patchValue({ radio: val });
    }
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  switch() {
    // this.value = !this.value;
  }

  get hasValue() {

    return this.value !== 'null';
  }

  get Yes() {
    return this.values && this.values.length > 0 ?
      this.values[0] :
      { AnswerValue: 'yes', NoteValue: 'yes' } as Values;
  }

  get No() {
    return this.values && this.values.length > 1 ?
      this.values[1] :
      { AnswerValue: 'no', NoteValue: 'no' } as Values;
  }

  ngOnDestroy(): void { }


}
