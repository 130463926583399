import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AudioRecorderService } from '../nora-services/audio-recorder.service';
import { get } from 'lodash';
import { DialogService } from '../nora-services/nora-dialog.service';

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.css'],
})
export class AudioRecorderComponent implements OnInit {
  @Input() hbdid: string | null = null;
  @Input() hbbid: string | null = null;
  @Input() sessionUUID: string | null = null;

  recording = false;
  showUploadButton = false;
  devices: MediaDeviceInfo[] = [];
  selectedDeviceId: string = '';
  audioBlob: Blob | null = null;
  recordingDuration = 0;
  durationInterval: any;
  isUploading: boolean = false;
  isPaused: boolean = false;

  @Output() onRecorded = new EventEmitter<Blob>();

  constructor(
    private audioRecorderService: AudioRecorderService, 
    private cdr: ChangeDetectorRef, 
    private snackBar: MatSnackBar, 
    private noraDialogService: DialogService
  ) {}

  async ngOnInit() {
    this.initializeAudioRecorder();
  }

  startRecording() {
    if (!this.selectedDeviceId) {
      this.snackBar.open('Please select a microphone before recording.', 'Close', { duration: 3000 });
      return;
    }
    this.recording = true;
    this.isPaused = false;
    this.showUploadButton = false;
    // this.audioBlob = null;
    this.recordingDuration = 0;
    this.durationInterval = setInterval(() => {
      this.recordingDuration++;
      this.cdr.detectChanges();
    }, 1000);
    this.audioRecorderService.startRecording(this.selectedDeviceId);
    this.noraDialogService.setResized(false);
  }

  pauseRecording() {
    this.audioRecorderService.pauseRecording();
    this.isPaused = true;
    clearInterval(this.durationInterval);
  }

  resumeRecording() {
    this.audioRecorderService.resumeRecording();
    this.isPaused = false;
    this.durationInterval = setInterval(() => {
      this.recordingDuration++;
      this.cdr.detectChanges();
    }, 1000);
  }

  async stopRecording() {
    if (this.isPaused) {
      this.isPaused = false; // Ensure it's no longer paused
    }
    this.isUploading = false;
    this.recording = false;
    clearInterval(this.durationInterval);
    this.audioBlob = await this.audioRecorderService.stopRecording();
    if (this.audioBlob) {
      // Ensure audioBlob is not null before emitting
      setTimeout(() => {
        this.onRecorded.emit(this.audioBlob as Blob); // Cast as Blob to satisfy TypeScript
        this.cdr.detectChanges(); // Ensure change detection runs after update
      }, 0);
    }
    this.showUploadButton = true;
    this.cdr.detectChanges(); // Ensure the UI is updated
  }

  getAudioUrl() {
    return this.audioBlob ? URL.createObjectURL(this.audioBlob) : '';
  }

  uploadAudio() {
    this.isUploading = true;
    this.snackBar.open('Audio uploaded and processing. The notes will populate once done processing.', 'Close', { duration: 30000 });
    this.audioRecorderService.notifyHandleAudioData();
  }

  deleteAndRestart() {
    this.audioRecorderService.resetRecording();
    this.recording = false;
    this.isPaused = false;
    this.showUploadButton = false;
    this.audioBlob = null;
    this.recordingDuration = 0;
    clearInterval(this.durationInterval);
    this.cdr.detectChanges();
  }

  async initializeAudioRecorder() {
    // Check if the browser supports the getUserMedia API
    try {
      // Request permission to access the microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // If permission is granted, list audio input devices
      this.devices = await this.audioRecorderService.getAudioInputDevices();

      if (this.devices.length === 0) {
        this.snackBar.open('No microphone devices found. Please connect a microphone.', 'Close', { duration: 3000 });
      } else {
        // Select the first device as the default
        this.selectedDeviceId = this.devices[0].deviceId;
      }

      // Stop all tracks to release the stream (optional)
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        this.snackBar.open('Microphone access denied. Please allow access to the microphone.', 'Close', { duration: 3000 });
      } else if (error.name === 'NotFoundError') {
        this.snackBar.open('No microphone devices found. Please connect a microphone.', 'Close', { duration: 3000 });
      } else {
        this.snackBar.open(`Error accessing microphone: ${error.message}`, 'Close', { duration: 3000 });
      }
    }
  }
}
