import { Component, OnInit, Input, OnDestroy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { ClinicalEncounterService } from '@app/core/services/clinical-encounter.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PatientsService, AuthService, UiStateService, ProviderService } from '@app/core/services';
import { FormBuilder } from '@angular/forms';
import {
  distinctUntilChanged,
  map,
  tap,
  take,
  switchMap,
  mergeAll,
  catchError,
  debounceTime,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { CameraResultType, Camera } from '@capacitor/core';
import { b64toBlob, blobToFile } from '@app/shared/functions/b64ToBlob';
import {
  AddFilesDialogData,
  AddFileDialogComponent,
  AddFilesReturnData,
} from '@app/modules/sidebar/add-file-dialog/add-file-dialog.component';
import { from, combineLatest, of, Subscription } from 'rxjs';
import { PatientDocumentVo } from 'api-clinician-app';
import * as uuidV4 from 'uuid/v4';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppInsightTags, ApplicationInsightsService } from '@app/core/services/application-insights.service';
import * as moment from 'moment';
import { MatTextareaAutosize } from '@angular/material/input';
import * as striptags from 'striptags';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { InvertPreviewCamera } from '@app/shared/functions/camera-invert';
import { MerakiClientService } from '@app/core/services/meraki-client.service';
import { DialogService } from '../nora-component/nora-services/nora-dialog.service';
import { AudioRecorderService } from '@app/modules/sidebar/speech-to-notes/services/audio-recorder.service';
import { switchTap } from '@app/core/functions/switch-tap';
import * as _ from 'lodash';
import { NoraAPIService } from '@app/modules/sidebar/speech-to-notes/services/nora-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'shared-quick-notes',
  templateUrl: './quick-notes.component.html',
  styleUrls: ['./quick-notes.component.scss'],
})
export class QuickNotesComponent implements OnInit, OnDestroy, AfterViewInit {

  isSpeechToNotesDoneProcessing: boolean = false

  @Input('displayMode') displayMode: 'full' | 'sidebar';

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  currentTenantId$ = this.authService.currentTenantId$;
  currentPatientId$ = this.uiStateService.currentPatientId$;

  // Speech to Notes
  isResized: boolean
  isRecording: boolean = false;
  isPaused: boolean = false;
  speechToNotesProcessingStatus: boolean = false;
  private subscriptions: Subscription = new Subscription();

  params$ = this.currentPatientId$.pipe(
    withLatestFrom(this.currentTenantId$),
    distinctUntilChanged(),
    // debounceTime(10),
    map(([patientId, tenantId]) => ({ tenantId, patientId })),
    untilDestroyed(this)
  );

  encounterInProgress$ = this.params$.pipe(
    switchMap(
      params => (params.patientId && this.clinicalEncounterService.encounterInProgress$(params.tenantId, params.patientId)) || of(null)
    )
  );

  clinicalNoteForm = this.fb.group({
    clinicalNote: '',
  }, { updateOn: 'blur' });

  isMobile$ = this.uiStateService.isMobile$;

  speechToNotesEnabled$ = this.providerService.speechToNotesEnabled$;

  constructor(
    private clinicalEncounterService: ClinicalEncounterService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private appInsightsService: ApplicationInsightsService,
    private authService: AuthService,
    private uiStateService: UiStateService,
    private patientsService: PatientsService,
    private merakiService: MerakiClientService,
    private providerService: ProviderService,
    private noraDialogService: DialogService,
    private noraAudioService: AudioRecorderService,
    private noraAPIService: NoraAPIService,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.setupClinicalFormWatchers();

    this.providerService.speechToNotesEnabled$.pipe(
      take(1),
      tap(speechToNotesEnabled => {
        if (speechToNotesEnabled) {
          this.getSpeechToNotes();
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  takePicture() {
    InvertPreviewCamera();
    from(
      Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      })
    )
      .pipe(
        map(image => b64toBlob(image.base64String, image.format)),
        map(blob => blobToFile(blob, `Picture-${moment().format('YYYYMMDD-HHmmss')}.${blob.type}`))
      )
      .subscribe(file => {
        this.openAddFileDialog({ Files: [file] } as AddFilesDialogData);
      });
  }

  openAddFileDialog(dialogData: AddFilesDialogData = {} as AddFilesDialogData) {
    const dialogRef = this.dialog.open(AddFileDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((upload: AddFilesReturnData) => {
      if (!!upload && upload.Files && upload.Files.length > 0) {
        this.params$
          .pipe(
            take(1),
            switchMap(params => {
              const uploads$ = upload.Files.map(file => {
                const documentVo: PatientDocumentVo = {
                  PracticeId: params.tenantId,
                  PatientId: params.patientId,

                  Description: upload.Description,
                  Category: upload.Category,
                  FileName: file.name,
                  CapturedDate: upload.CapturedDate,
                  DocumentDate: upload.DocumentDate,
                  FileSize: file.size,
                  DocumentId: uuidV4(),

                  Notes: upload.Notes,
                  AdditionalData: upload.AdditionalData,

                  CreatedBy: null, // TODO
                  MimeType: null, // TODO

                  ThumbnailUrl: null, //// ignore this field
                  DocumentUrl: null, //// ignore this field
                };
                return this.clinicalEncounterService.uploadEncounterFile(params.tenantId, params.patientId, documentVo, file);
              });

              return uploads$;
            }),
            mergeAll()
          )
          .subscribe();
      }
    });
  }

  setupClinicalFormWatchers() {
    // predefault value from encounter
    this.encounterInProgress$
      .pipe(
        take(1),
        map(e => e.ClinicalNote || ''),
        catchError(() => ''),
        map(notes => {
          if (!!notes && notes.length > 0) {
            // backwards compatibility change to strip out existing HTML tags, if any
            const strippedHtml = striptags(notes.replace('<br>', '\r\n').replace('</p><p>', '\r\n'));
            return strippedHtml;
          } else {
            return notes;
          }
        }),
        tap(note => this.clinicalNoteForm.patchValue({ clinicalNote: note }, { emitEvent: false })),
        debounceTime(250), // add a slight delay here to try and let Angular finish up before resizing contents
        tap(t => this.autosize && this.autosize.resizeToFitContent(true)), // force size refresh
        untilDestroyed(this)
      )
      .subscribe();

    // watch form
    this.clinicalNoteForm
      .get('clinicalNote')
      .valueChanges.pipe(
        withLatestFrom(this.params$, this.encounterInProgress$, this.speechToNotesEnabled$),
        filter(([form, params, encounter, speechEnabled]) => encounter?.EncounterId),
        distinctUntilChanged(),
        tap(([value, params, encounter]) => {
          this.clinicalEncounterService.updateClinicalNote(params.tenantId, params.patientId, value);

          this.appInsightsService.trackEvent(AppInsightTags.CONSULT_QUICK_NOTE, {
            EncounterId: encounter.EncounterId,
            text: value,
          });
        }),
        switchTap(
          ([value, params, encounter, speechEnabled]) =>
            (speechEnabled && this.isSpeechToNotesDoneProcessing &&
              this.clinicalEncounterService.updateSpeechToNotes(encounter.PracticeId, encounter.EncounterId, null, value)) ||
            of(null)
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }

  updateSpeechToNotes(isGood) {
    this.encounterInProgress$.pipe(
      take(1),
      withLatestFrom(this.params$),
      switchMap(([encounter, params]) => {
        if (encounter.EncounterId) {
          const clinicalNote = this.clinicalNoteForm.get('clinicalNote').value;
          
          // Update speech to notes
          return this.clinicalEncounterService.updateSpeechToNotes(encounter.PracticeId, encounter.EncounterId, isGood, clinicalNote).pipe(
            tap(() => {
              this.snackbar.open('Feedback submitted', 'Close', { duration: 3000 });
            }),
            // Call the noraAPIService after updating
            switchMap(() => this.noraAPIService.sendNotesFeedback(params.tenantId, encounter.EncounterId, isGood, clinicalNote))
          );

        }
        return of(null);
      })
    ).subscribe();
  }

  getSpeechToNotes() {
    let initialEncounterId: string;
    
    this.encounterInProgress$.pipe(
      take(1),
      tap(encounter => initialEncounterId = encounter?.EncounterId),
      switchMap(encounter =>
        encounter?.EncounterId
        ? this.merakiService.listenForTranscription(encounter.EncounterId, encounter.PracticeId)
          : of(null)
      ),
      distinctUntilChanged(_.isEqual),
      debounceTime(250),
      withLatestFrom(this.params$, this.encounterInProgress$),
      tap(([transcription, params, currentEncounter]) => {
        // Only process if we have a transcription and it matches the initial encounter
        if (transcription) {
          this.isSpeechToNotesDoneProcessing = true;
          this.speechToNotesProcessingStatus = false;
          this.noraAudioService.setProcessingNotesStatus(false);
          
          if(currentEncounter?.EncounterId === initialEncounterId) {
            var currentValue = this.clinicalNoteForm.value.clinicalNote;
            if (currentValue !== transcription) {
              this.clinicalNoteForm.patchValue({
                clinicalNote: transcription
              }, { emitEvent: false });

            this.clinicalEncounterService.updateClinicalNote(
              params.tenantId,
              params.patientId,
              transcription
              );
            }
          }
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  updateSpeechToNotesDialogSize(newSize: boolean): void {
    this.noraDialogService.setResized(newSize);
  }

  speechToNotesListeners() {
    const isResized$ = this.noraDialogService.isResized$;
    const recording$ = this.noraAudioService.recording$;
    const paused$ = this.noraAudioService.paused$;
    const isSpeechToNotesProcessing$ = this.noraAudioService.isProcessingNotes$;

    this.subscriptions.add(
      combineLatest([isResized$, recording$, paused$, isSpeechToNotesProcessing$]) // combine all the observables
        .pipe(
          tap(([isResized, isRecording, isPaused, isSpeechToNotesProcessing]) => {
            this.isResized = isResized;
            this.isRecording = isRecording;
            this.isPaused = isPaused;
            this.speechToNotesProcessingStatus = isSpeechToNotesProcessing;
          }),
          untilDestroyed(this)
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
