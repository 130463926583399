import { QueryEntity, EntityUIQuery } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {
  ClinicalLetters,
  ClinicalLettersState,
  ClinicalLettersUIState,
  ClinicalLettersUIViewModel,
  EncounterLettersStore
} from './encounter-letters.store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EncounterLettersQuery extends QueryEntity<ClinicalLettersState, ClinicalLetters> {
  ui: EntityUIQuery<ClinicalLettersUIState>;

  constructor(protected store: EncounterLettersStore) {
    super(store);
    this.createUIQuery();
  }

  encounterUiState$(patientId: string): Observable<ClinicalLettersUIViewModel> {
    return this.ui.selectEntity(patientId);
  }
}
