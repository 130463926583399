import { Injectable } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, map, tap, catchError, filter, switchMap, mapTo } from 'rxjs/operators';
import { AuthQuery } from './state/auth/auth.query';
import { AuthStore, Role, UserRole, AuthState } from './state/auth/auth.store';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PatientsStore } from './state/patients/patients.store';
import { resetStores } from '@datorama/akita';
import { AvatarsStore } from './state/mypatientfiles/avatars.store';
import { DocumentsStore } from './state/mypatientfiles/documents.store';
import { ReportsStore } from './state/reports/reports.store';
import { ProviderStore } from './state/provider/provider.store';
import { of } from 'rxjs';
import { AuthApiService } from './api-auth.service';
import { MerakiAuthService } from './meraki-auth.service';
import { RoleManagerService, USER_ROLE } from './role-manager.service';
import '@app/shared/functions/string.extensions';
import { UiStateService } from './ui-state.service';
import { UiStateStore } from './state/ui-state/ui-state.store';

@Injectable()
export class AuthService {
  auth$ = this.authQuery.auth$;
  authenticatedLoaded$ = this.authQuery.authenticatedLoaded$;
  authenticated$ = this.authQuery.authenticated$;
  superAdmin$ = this.authQuery.superAdmin$;
  currentTenantId$ = this.authQuery.currentTenantId$;

  get currentTenantIdSnapshot() {
    return this.authQuery.currentTenantIdSnapshot;
  }

  get authenticated() {
    if (!this.authQuery.getValue().authenticated) {
      return false;
    }
    // check token validity
    const jwtHelper = new JwtHelperService();
    const token = this.authQuery.getValue().token;
    const isExpired = jwtHelper.isTokenExpired(token);
    return !isExpired;
  }

  get superAdmin() {
    return this.authQuery.getValue().superAdmin;
  }

  get token() {
    return this.authenticated ? this.authQuery.getValue().token : null;
  }

  get roles(): USER_ROLE[] {
    return this.authQuery.getValue().roles.map(r => USER_ROLE[r.Role.toProperCase()]);
  }

  constructor(
    private authApi: AuthApiService,
    private authStore: AuthStore,
    private authQuery: AuthQuery,
    private patientsStore: PatientsStore,
    private avatarsStore: AvatarsStore,
    private documentStore: DocumentsStore,
    private providerStore: ProviderStore,
    private reportsStore: ReportsStore,
    private roleManagerService: RoleManagerService,
    private uiStore: UiStateStore,
    private merakiAuthService: MerakiAuthService
  ) {
    this.setupAuthWatcherForNgxPermissions();
  }

  private setupAuthWatcherForNgxPermissions() {
    this.auth$
      .pipe(
        filter(auth => auth.authenticated != null),
        map(auth => auth?.roles || []), // only the userId, then distinct, so that we're not overdoing the setting
        distinctUntilChanged(),
        map(t => t.map(r => r.Role as any as USER_ROLE)),
        tap(roles => roles.forEach(r => this.roleManagerService.setRoleAndPermissions(r))),
        untilDestroyed(this, 'destroy')
      )
      .subscribe();
  }

  public sanitiseStores(sanitiseAll = true) {
    if (sanitiseAll) {
      resetStores({ exclude: ['uiStore'] }); // exclude uiStore!  (config)
    } else {
      this.providerStore.reset();
      this.patientsStore.reset();
      this.avatarsStore.reset();
      this.documentStore.reset();
      this.reportsStore.reset();
      this.uiStore.update(() => ({ currentPatientTabs: [] }));
    }
  }

  public setCurrentTenantId(tenantId: string) {
    this.sanitiseStores(false);
    this.authStore.update({ currentTenantId: tenantId });
  }

  public login(username: string, password: string) {
    const request = this.authApi.login(username, password);
    return request.pipe(
      tap({ error: () => this.authFailed() }),
      switchMap(data => this.merakiAuthService.signIntoFirebase(data.access_token).pipe(map(fs => ({ ...data, fs })))),
      map(data => {
        if (!data || !data.access_token) {
          this.authFailed();
        }
        const jwtHelper = new JwtHelperService();

        const token: string = data.access_token;
        const fsRefreshToken: string = data.fs.user.refreshToken;
        const decodedToken = jwtHelper.decodeToken(token);
        const isExpired = jwtHelper.isTokenExpired(token);
        let superAdmin = false;
        let firstPractice = null;

        const rolesString: Array<string> = decodedToken.role instanceof Array ? decodedToken.role : [decodedToken.role];
        const roles = rolesString.map(role => {
          const r = role.split('/');
          if (r[1] === Role.SystemAdmin && r[0] === '*') {
            superAdmin = true;
            firstPractice = '00000000-0000-0000-0000-000000000001';
          } else {
            firstPractice = r[0];
          }
          return {
            Role: r[1],
            Scope: r[0],
          } as UserRole;
        });

        if (!isExpired) {
          const loginState = {
            authenticated: true,
            currentTenantId: firstPractice,
            token: data.access_token,
            superAdmin,
            roles,
            userId: decodedToken.user_id,
            username,
            fullName: decodedToken.full_name,
            authenticating: false,
            authenticatedFailed: false,
            initialized: true,
            fsRefreshToken
          } as AuthState;
          this.authStore.update(loginState);

          this.roles.forEach(role => this.roleManagerService.setRoleAndPermissions(role));

          return loginState;
        } else {
          return this.tokenExpired();
        }
      }),
      catchError(() =>
        of({
          authenticated: false,
          currentTenantId: null,
          roles: [],
          superAdmin: false,
          token: null,
          userId: null,
          username: null,
          fullName: null,
          fsRefreshToken: null,
        } as AuthState)
      )
    );
  }

  public logout() {
    this.sanitiseStores(); // sanitise all
    this.roleManagerService.flushRolesAndPermissions();
  }

  private authFailed() {
    const newState = {
      authenticated: false,
      token: null,
      superAdmin: null,
      roles: null,
      userId: null,
      username: null,
      authenticatedFailed: true,
      loading: false,
      fsRefreshToken: null,
    };
    this.authStore.update(() => newState);
    return newState;
  }

  private tokenExpired() {
    this.authStore.reset();
    return this.authQuery.auth;
  }

  destroy() { }
}
