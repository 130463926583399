
<mat-nav-list>
  <ng-container *ngIf="!(authenticated$ | async)">
    <mat-list-item routerLink="login" routerLinkActive="active">
      <mat-icon matListIcon>lock</mat-icon>
      <span fxShow.lt-md="false" fxShow="true">Login</span>
    </mat-list-item>
  </ng-container>

  <ng-container *ngIf="authenticated$ | async">
    <ng-container *ngIf="currentTenantId$ | async as tenantId">
      <mat-list-item [routerLink]="[ tenantId, 'home' ]" routerLinkActive="active">
        <mat-icon matListIcon>home</mat-icon>
        <span fxShow.lt-md="false" fxShow="true">Home</span>
      </mat-list-item>
      <mat-list-item [routerLink]="[ tenantId, 'inbox' ]" routerLinkActive="active">
        <mat-icon matListIcon matBadge="15" matBadgePosition="before" matBadgeColor="warn">inbox</mat-icon>
        <span fxShow.lt-md="false" fxShow="true">Inbox</span>
      </mat-list-item>
      <!-- <mat-list-item routerLink="PROVIDERID/patient-consultation" routerLinkActive="active">
        <mat-icon matListIcon>person_outline</mat-icon>
        <span fxShow.lt-md="false" fxShow="true">Consultation</span>
      </mat-list-item> -->
      <mat-list-item [routerLink]="[ tenantId, 'patient-search' ]" routerLinkActive="active">
        <mat-icon matListIcon>person_outline</mat-icon>
        <span fxShow.lt-md="false" fxShow="true">Patients</span>
      </mat-list-item>
      <mat-list-item [routerLink]="[ tenantId, 'reports' ]" routerLinkActive="active">
        <mat-icon matListIcon>show_chart</mat-icon>
        <span fxShow.lt-md="false" fxShow="true">Reports</span>
      </mat-list-item>
      <mat-list-item [routerLink]="[ tenantId, 'settings' ]" routerLinkActive="active">
        <mat-icon matListIcon>settings_applications</mat-icon>
        <span fxShow.lt-md="false" fxShow="true">Settings</span>
      </mat-list-item>
    </ng-container>
    <mat-list-item (click)="logout()" routerLinkActive="active">
      <mat-icon matListIcon>lock_open</mat-icon>
      <span fxShow.lt-md="false" fxShow="true">Logout</span>
    </mat-list-item>
  </ng-container>

  <mat-divider></mat-divider>

  <mat-list-item routerLink="help" routerLinkActive="active">
    <mat-icon matListIcon>help_outline</mat-icon>
    <span fxShow.lt-md="false" fxShow="true">Help</span>
  </mat-list-item>


  <ng-container *ngIf="superAdmin$ | async">
    <mat-divider></mat-divider>

    <mat-list-item routerLink="admin" routerLinkActive="active">
      <mat-icon matListIcon>developer_mode</mat-icon>
      <span fxShow.lt-md="false" fxShow="true">Admin</span>
    </mat-list-item>
  </ng-container>

  <mat-divider></mat-divider>

  <mat-list-item routerLink="style-guide" routerLinkActive="active">
    <mat-icon matListIcon>style</mat-icon>
    <span fxShow.lt-md="false" fxShow="true">Style Guide</span>
  </mat-list-item>
  <mat-list-item routerLink="playground" routerLinkActive="active">
    <mat-icon matListIcon>bug_report</mat-icon>
    <span fxShow.lt-md="false" fxShow="true">Playground</span>
  </mat-list-item>
</mat-nav-list>
