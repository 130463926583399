<div [formGroup]="form" *ngIf="form" [ngClass]="{ hidden: !(visibleQuestion$ | async), 'question-field': true }">
  <div
    [ngSwitch]="question.ControlType"
    class="grid-row dynamic-form-question"
    [ngClass]="{
      'grid-row-2-colum': question.ControlType === 'textbox',
      'long-question': isLongQuestion,
      'grid-row-2-rows form-field-full-width': question.ControlType === 'textarea',
      'priority-1': question.Priority === 1
    }"
  >
    <ng-container *ngSwitchCase="'textbox'">
      <mat-label class="label">{{ question.Label }}</mat-label>
      <mat-form-field class="question" appearance="outline" floatLabel="always">
        <mat-label>{{ question.PlaceholderText }}</mat-label>
        <input
          matInput
          [id]="question.QuestionKey"
          [type]="question.TextType"
          [formControlName]="question.QuestionKey"
          [required]="question.Required"
          [value]="question.Answer"
        />
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'textarea'">
      <div class="whiteboard-icon-label-parent">
        <h4 class="label whiteboard-icon-label">{{ question.Label }}</h4>
        <shared-whiteboard-icon [type]="question.Label" class="whiteboard-icon-label whiteboard-icon"></shared-whiteboard-icon>
      </div>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ question.PlaceholderText }}</mat-label>
        <textarea
          matInput
          #textArea
          [type]="question.TextType"
          [formControlName]="question.QuestionKey"
          [required]="question.Required"
          [value]="question.Answer"
          [readonly]="speechServiceStarted$ | async"
          class="textarea-auto-resize"
        ></textarea>

        <div matSuffix *ngIf="enableSpeech$ | async">
          <mat-icon
            matSuffix
            *ngIf="!(speechServiceStarted$ | async)"
            (click)="$event.stopPropagation(); startListening()"
            class="mic clickable"
            >mic</mat-icon
          >
          <mat-icon
            matSuffix
            *ngIf="speechServiceStarted$ | async"
            (click)="$event.stopPropagation(); stopListening()"
            class="mic clickable"
            >record_voice_over</mat-icon
          >

          <button mat-icon-button [matMenuTriggerFor]="feedbackMenu" *ngIf="showTranscriptionQualityMenu">
            <mat-icon>thumbs_up_down</mat-icon>
          </button>
          <mat-menu #feedbackMenu="matMenu">
            <button mat-menu-item (click)="$event.stopPropagation(); goodQualityTranscription()">
              <mat-icon>thumb_up</mat-icon>
              <span>Transcription was accurate</span>
            </button>
            <button mat-menu-item (click)="$event.stopPropagation(); badQualityTranscription()">
              <mat-icon>thumb_down</mat-icon>
              <span>Transcription was not accurate</span>
            </button>
          </mat-menu>
        </div>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'dropdown'">
      <mat-label class="label">{{ question.Label }}</mat-label>
      <mat-form-field class="question" floatLabel="always">
        <mat-select [formControlName]="question.QuestionKey" [value]="question.Options[0].AnswerValue">
          <mat-option *ngFor="let opt of question.Options" [value]="opt.AnswerValue">{{ opt.AnswerValue }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'radio'">
      <mat-label class="label">{{ question.Label }}</mat-label>
      <mat-radio-group [formControlName]="question.QuestionKey" class="radio-button-group question">
        <mat-radio-button *ngFor="let option of question.Options" [value]="option.AnswerValue">{{ option.AnswerValue }} </mat-radio-button>
      </mat-radio-group>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <mat-label class="label"></mat-label>
      <mat-checkbox class="question" [checked]="isChecked()" (change)="toggleCheck($event)"> {{ question.Label }}</mat-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="'checkboxlist'">
      <mat-label class="label">{{ question.Label }}</mat-label>
      <ui-checkbox-list class="question" [formControlName]="question.QuestionKey" [label]="question.Label" [values]="getStringOptions()">
      </ui-checkbox-list>
    </ng-container>

    <ng-container *ngSwitchCase="'timespan'">
      <mat-label class="label">{{ question.Label }}</mat-label>
      <ui-timespan class="question" [formControlName]="question.QuestionKey" [label]="question.Label"></ui-timespan>
    </ng-container>

    <ng-container *ngSwitchCase="'datepicker'">
      <mat-label class="label"></mat-label>
      <mat-form-field appearance="outline" class="question">
        <mat-label>{{ question.Label }}</mat-label>
        <input readonly matInput [formControlName]="question.QuestionKey" [matDatepicker]="datePicker"/>
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="form.get(question.QuestionKey).invalid">Invalid date</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'timepicker'">
      <mat-label>{{ question.Label }}</mat-label>
      <ui-form-input [label]="question.Label" [errorMessage]="timeErrorMessage" [suppressOptional]="true">
        <input
          uiInput
          [id]="question.QuestionKey"
          [formControlName]="question.QuestionKey"
          [required]="question.Required"
          [ngxTimepicker]="default"
          readonly
        />
        <ngx-material-timepicker #default></ngx-material-timepicker>
      </ui-form-input>
    </ng-container>

    <ng-container *ngSwitchCase="'slidetoggle'">
      <mat-label class="label">{{ question.Label }}</mat-label>
      <ui-slide-toggle class="question" [formControlName]="question.QuestionKey" [values]="question.Options"> </ui-slide-toggle>
    </ng-container>

    <ng-container *ngSwitchCase="'slider'">
      <mat-label class="label"></mat-label>
      <div class="column">
        <mat-slider
          class="question"
          thumbLabel
          min="{{ question.Options[0].AnswerValue }}"
          max="{{ question.Options[question.Options.length - 1].AnswerValue }}"
          [formControlName]="question.QuestionKey"
        >
        </mat-slider>
        <p class="question slider-notes">
          <span>{{ question.Options[0].NoteValue }}</span>
          <span class="left-note">{{ question.Options[question.Options.length - 1].NoteValue }}</span>
        </p>
      </div>
    </ng-container>

    <div
      *ngIf="
        question.ControlType !== 'textbox' &&
        question.ControlType !== 'textarea' &&
        (question.RequiresNote === null || !question.RequiresNote)
      "
      class="note-container"
    >
      <mat-icon class="note-toggle" (click)="toggleNote()" [ngClass]="{ active: showNote$ | async }">note_add</mat-icon>
      <div [hidden]="!(showNote$ | async)" class="note">
        <mat-form-field>
          <input matInput [formControlName]="question.QuestionKey + '-notes'" #notesField />
          <!-- <mat-icon matSuffix (click)="toggleNote()" class="btn-note-toggle">close</mat-icon> -->
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- <div class="optionalMessage"*ngIf="!required">optional</div> -->
  <div class="errorMessage" *ngIf="!isValid">Invalid</div>
</div>
