import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NoraAPIService {
    x: string = ''
    y = ''
	private settingsApiUrl = 'https://wd85sy7ocj.execute-api.af-south-1.amazonaws.com/dev/settings';
	private feedbackApiUrl = 'https://wd85sy7ocj.execute-api.af-south-1.amazonaws.com/dev/feedback';
	private consultationsApiUrl = 'https://wd85sy7ocj.execute-api.af-south-1.amazonaws.com/dev/consults';
    private uploadAudioApiUrl = 'https://wd85sy7ocj.execute-api.af-south-1.amazonaws.com/dev/upload';

  constructor(
		private http: HttpClient
	){}

    getSetDialogData(practiceId: string, encounterId: string): any {
        const payload = {
            practiceId: practiceId,
            encounterId: encounterId
        }

        this.x = payload.practiceId
        this.y = payload.encounterId

        return payload
    }

    passData() {
        const payload = {
            practiceId: this.x,
            encounterId: this.y
        }
        return payload
    }

	getDoctorInfo(hbdid: string): Observable<any> {
		let params = new HttpParams().set('HBDID', hbdid);
		return this.http.get(this.settingsApiUrl, { params });
	}

	submitFeedback(hbdid: string, message: string): Observable<any> {
        const payload = {
        HBDID: hbdid,
        Message: message
        };
        return this.http.post(this.feedbackApiUrl, payload, { headers: { 'Content-Type': 'application/json' } });
    }

	getConsultations(hbdid: string): Observable<any> {
        let params = new HttpParams().set('HBDID', hbdid);
        return this.http.get<any>(this.consultationsApiUrl, { params });
    }

  getPresignedUrl(fileName: string, contentType: string): Observable<any> {
    let params = new HttpParams()
      .set('file_name', fileName)
      .set('content_type', contentType);
    return this.http.get<any>(this.uploadAudioApiUrl, { params });
  }
}
