
import { Pipe, PipeTransform } from '@angular/core';
import { MedicineInfo } from '@app/core/services';
import {
  getMedicineDescriptionFromMedicineInformation
} from '@app/modules/patient/patient-consultation/components/assessment/medicines/models/medicine-view-model.util';
import * as _ from 'lodash';

@Pipe({
  name: 'medicineDescription'
})
export class MedicineDescriptionPipe implements PipeTransform {
  transform(medicineInfo: MedicineInfo): string {
    return getMedicineDescriptionFromMedicineInformation(medicineInfo);
  }
}

@Pipe({
  name: 'medicinePrice'
})
export class MedicinePricePipe implements PipeTransform {
  transform(medicineInfo: any): string {
    return _.chain(medicineInfo.PackInfo)
      .sortBy(x => x.PackSize)
      .first()
      .value()
      .Price;
  }
}

@Pipe({
  name: 'medicineFormulary'
})
export class MedicineFormularyPipe implements PipeTransform {
  transform(medicineInfo: any): string {
    return medicineInfo.FormularyInfo
  }
}
