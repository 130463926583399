import { Injectable } from '@angular/core';
import { UrlTree, UrlSerializer, DefaultUrlSerializer } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IisCompatibleUrlSerializer extends DefaultUrlSerializer {
  constructor() {
    super();
  }

  serialize(tree: UrlTree): string {
    return super.serialize(tree).replace(/:/g, ','); // replace ":" with "," in the url
  }

  parse(url: string): UrlTree {
    return super.parse(url.replace(/,/g, ':')); // replace "," back to ":" and let the original UrlSerializer do it's work
  }
}
