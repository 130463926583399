import { Component, OnInit, HostBinding, Input, forwardRef, ChangeDetectionStrategy, OnDestroy, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, tap, skip, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ui-timespan',
  templateUrl: './timespan.component.html',
  styleUrls: ['./timespan.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimespanComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimespanComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {

  @Input() label = '';

  // availableModelUnits = [ 'Hours', 'Days', 'Weeks', 'Months', 'Years' ];
  availableModelUnits = [ 'Days', 'Weeks', 'Months' ];

  timespanSpanForm = this.fb.group({
    unit: [ null, { validators: [ Validators.min(1), Validators.pattern('^[0-9]*$') ], updateOn: 'blur' }],
    unitType: [ { value: null, updateOn: 'change' }],
  });

  _model: string;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.timespanSpanForm.patchValue({ unitType: 'days'});
    this.timespanSpanForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(10),
        untilDestroyed(this)
      )
      .subscribe({ next: formValues => {
        if (!!formValues.unit && !!formValues.unitType) {
          this._model = moment.duration(formValues.unit, formValues.unitType).toISOString();
        } else {
          this._model = null;
        }
        this.onChange(this._model);
        this.onTouch();
      }});
  }

  ngAfterViewInit(): void {
    // this.timespanSpanForm.patchValue({ unitType: 'days'});
  }

  private onChange: (m: any) => void;
  private onTouch: () => void;

  get model() {
    return this._model;
  }
  writeValue(value: string): void {
    this.set(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  getDurationUnitType(duration: moment.Duration): 'hours' | 'days' | 'weeks' | 'months' | 'years' {
    if (duration.years() > 0 && Number.isInteger(duration.asYears())) { return 'years'; }
    if (duration.months() > 0 && Number.isInteger(duration.asMonths())) { return 'months'; }
    if (duration.weeks() > 0 && Number.isInteger(duration.asWeeks())) { return 'weeks'; }
    if (duration.days() > 0 && Number.isInteger(duration.asDays())) { return 'days'; }
    if (duration.hours() > 0 && Number.isInteger(duration.asHours())) { return 'hours'; }
    return 'days';
  }

  set(value: string) {
    if (value && value.length > 0 && moment.isDuration(moment.duration(value))) {
      // determine the unit we're working with...
      // https://momentjs.com/docs/#/durations/as-iso-string/
      // parse it and work downwards
      const duration = moment.duration(value);
      const unitType = this.getDurationUnitType(duration);

      this.timespanSpanForm.patchValue({
        unit: duration.as(unitType),
        unitType
      }, { emitEvent: false });

      this._model = value;
    }
  }

  increment() {
    let val = this.timespanSpanForm.get('unit').value;
    if (val) {
      val = parseInt(val, 10) + 1;
    } else {
      val = 1;
    }
    this.timespanSpanForm.patchValue({ unit: val });
  }

  decrement() {
    let val = this.timespanSpanForm.get('unit').value;
    if (val) {
      val = parseInt(val, 10) - 1;
    } else {
      val = 1;
    }
    if (val !== 0) { this.timespanSpanForm.patchValue({ unit: val }); }
  }

  ngOnDestroy() { }

}
