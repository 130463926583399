import { Query, selectPersistStateInit } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { AuthStore, AuthState } from './auth.store';
import { filter, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthQuery extends Query<AuthState> {
  auth$ = this.select();
  authenticated$ = this.select(store => store.authenticated && !!store.token);
  authenticatedLoaded$ = combineLatest([this.auth$, selectPersistStateInit()])
    .pipe(
      map(([store, loading]) => store.authenticated && !!store.token)
    );

  superAdmin$ = combineLatest([this.auth$, selectPersistStateInit()]).pipe(
    map(([store, loading]) => store.authenticated && !!store.token && !!store.superAdmin)
  );
  currentTenantId$ = combineLatest([this.auth$, selectPersistStateInit()]).pipe(
    map(([store, loading]) => store.currentTenantId),
    filter(s => !!s)
  );
  currentTenantIdSnapshot = this.getValue().currentTenantId;

  auth = this.getValue();

  constructor(protected store: AuthStore) {
    super(store);
  }
}
