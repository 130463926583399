import { Component, OnInit, HostListener, ElementRef, Input, ViewChild, Inject, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-form-file-selection',
  templateUrl: './form-file-selection.component.html',
  styleUrls: ['./form-file-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormFileSelectionComponent,
      multi: true,
    }
  ],
})
export class FormFileSelectionComponent implements OnInit, ControlValueAccessor {

  // @Input() maxFiles = 1;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() accept: string;
  @Input() displayFilename: boolean;
  @ViewChild('fileSelection') fileSelection;
  @ViewChild('btn') btn;

  files: File[] = [];
  onChange: (files: File[]) => void;

  constructor(
    private host: ElementRef<HTMLInputElement>) {
      // console.warn(this.data);
      // if (!!this.data) {
        // this.files = this.data;
        // this.
      // }
  }

  ngOnInit() {
  }

  writeValue(obj: File[]): void {
    // clear file input
    this.host.nativeElement.value = '';
    this.files = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  addFiles(newfiles: File[]) {
    if (!this.files) { this.files = []; }
    this.files = [
      ...this.files,
      ...newfiles
    ];
    this.onChange(this.files);
  }

  addFilesClick() {
    this.fileSelection.nativeElement.click();
  }

  changeListener(event: Event) {

    const element = event.currentTarget as HTMLInputElement;

    const files = element.files;

    this.addFiles(Array.from(files));

    this.change.emit(event);
  }

}
