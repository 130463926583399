import { Account } from "./account.models";
import { Address, BaseModel, Person } from "./general.models";


export interface PatientAccount {
  Patient?: AccountMember;
  MainMember?: AccountMember;
  Account?: Account;
  SkipErrors?: boolean;
}

export class PatientAccountResponse {
  AccountId?: string;
  MainMemberId?: string;
  MainMemberGuid?: string;
  PatientId?: string;
  PatientGuid?: string;
}

export interface AccountMember extends BaseModel, Person {
  Id?: string;
  UUID?: string; // uuid formal ID to be references by external systems who support only uuid identities, like HBC
  _id?: string;
  PreferredName?: string;
  Initials?: string;
  IdentificationType?: IDENTIFICATION_TYPE;
  IDNumber?: string;
  PassportNumber?: string;
  Country?: string;
  RelationshipToMainMember?: string;
  DependantCode?: string;
  AccountMemberStatus?: MEMBER_STATUS;
  Accounts?: Array<string>;
  StatusUpdateReason?: string;
  PhysicalAddress?: Address;
  PostalAddress?: Address & { SameAsPhysical?: boolean };
  Employer?: string;
  Occupation?: string;
  PatientFileNo?: string;
  TreatingProvider?: string;
  PrimaryProvider?: string;
  SecondaryProvider?: string;
  MemberSettings?: MemberSettings;
  ValidationStatus?: VALIDATION_STATUS;
  LastValidationDate?: Date;
  LastInvoiceDate?: number;
  _Tags?: string[];
}

export interface Contact {
  PostalAddress?: Address;
  PhysicalAddress?: Address;
  TelephoneWork?: string;
  TelephoneHome?: string;
  Cellphone?: string;
  Email?: string;
}

export enum IDENTIFICATION_TYPE {
  SAID = "South African Id no.",
  PASSPORT = "Passport no.",
}

export enum GENDERS {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other"
}

export const TITLES = ["Mr", "Mrs", "Miss", "Dr", "Rev", "Hon"];

export const MEMBER_RELATIONSHIPS = [
  "Brother",
  "Daughter",
  "Father",
  "Friend",
  "Husband",
  "Mother",
  "Other",
  "Self",
  "Sister",
  "Son",
];

export enum MEMBER_STATUS {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}
export interface MemberSettings {
  ConsentToPrivacyPolicy?: CONSENT_TO_PRIVACY_POLICY_SETTING;
  GeneralCommunicationPreferences?: GENERAL_COMMUNICATION_PREFERENCES_SETTING;
  MarketingBulkCommunicationPreferences?: MARKETING_BULK_COMMUNICATION_PREFERENCES_SETTING;
  AppointmentReminderPreferences?: APPOINTMENT_REMINDER_PREFERENCES_SETTING;
}

export enum CONSENT_TO_PRIVACY_POLICY_SETTING {
  YES = "Yes",
  NO = "No",
  NO_RESPONSE = "No Response",
}

export enum GENERAL_COMMUNICATION_PREFERENCES_SETTING {
  SMS = "SMS",
  EMAIL = "Email",
  BOTH = "Both",
}

export enum MARKETING_BULK_COMMUNICATION_PREFERENCES_SETTING {
  SMS = "SMS",
  EMAIL = "Email",
  BOTH = "Both",
  OPT_OUT = "Opt out",
}

export enum APPOINTMENT_REMINDER_PREFERENCES_SETTING {
  SMS = "SMS",
  EMAIL = "Email",
  BOTH = "Both",
  OPT_OUT = "Opt out",
}

export enum VALIDATION_STATUS {
  NOT_DONE = "Not done",
  VALID = "Valid",
  NOT_VALID = "Not valid",
  NOT_SUPPORTED = "Not supported",
  VALIDATING = "Validating",
  OVERDUE = "Overdue",
}

export interface MemberAuthorization extends BaseModel {
  AuthorizationId?: string;
  Type?: string;
  AuthorizationNo?: string;
  TreatingProvider?: string;
  TreatingPracticeNo?: string;
  AuthorizedFrom?: Date;
  AuthorizedTo?: Date;
  AuthorizationStatus?: AUTHORIZATION_STATUS;
}

export enum AUTHORIZATION_STATUS {
  ACTIVE = "Active",
  DELETED = "Deleted",
}
