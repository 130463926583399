<div [formGroup]="form">
  <mat-form-field appearance="outline" style="width: 100%;" class="form-field" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input matInput formControlName="searchString" #searchString *ngIf="!autocomplete" cdkFocusInitial autofocus id="txtAlgSearch"/>
    <input matInput formControlName="searchString" #searchString [matAutocomplete]="autocomplete" *ngIf="!!autocomplete" cdkFocusInitial autofocus id="txtAlgSearch"/>
  </mat-form-field>
  <ng-container *ngIf="showGroupedToggle"> <input type="checkbox" formControlName="groupedResults" /> Grouped Results
  </ng-container>
</div>
