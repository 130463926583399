import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked/lib/marked.umd';

@Component({
  selector: 'app-markdown-renderer',
  template: `<div [innerHTML]="renderedMarkdown"></div>`,
  // styleUrls: ['./markdown-renderer.component.scss'],
})
export class MarkdownRendererComponent implements OnChanges {
  @Input() markdown: string = '';
  renderedMarkdown: SafeHtml = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    this.renderMarkdown();
  }

  private async renderMarkdown(): Promise<void> {
    marked.setOptions({
      gfm: true,
      breaks: true,
      headerIds: true,
      headerPrefix: 'custom-',
      langPrefix: 'hljs-',
      smartLists: true,
      smartypants: true,
      xhtml: false,
    });

    const rawHtml = await marked(this.markdown || '');
    this.renderedMarkdown = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
  }
}
