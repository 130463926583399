import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProviderService } from '@app/core/services';
import { AppInsightTags, ApplicationInsightsService } from '@app/core/services/application-insights.service';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.scss']
})
export class PrivacyPolicyDialogComponent implements OnInit {
  saving = false;
  message = '';

  constructor(
    private providerService: ProviderService,
    private appInsightsService: ApplicationInsightsService,
    private dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { tenantId: string }) { }

  ngOnInit(): void {
  }

  agree() {
    this.saving = true;
    this.providerService.acceptLatestPrivacyPolicy().pipe(
      catchError(err => {
        console.error(err);
        this.message = `${err}`;
        return of(null);
      }),
      tap(res => {
        if (res?.Sucess) {
          this.appInsightsService.trackEvent(AppInsightTags.PRIVACY_POLICY_ACCEPTED, { tenantId: this.data.tenantId });
          this.dialogRef.close();
        } else {
          this.message = res?.ResponseMessage;
        }
      }),
      finalize(() => this.saving = false)
    ).subscribe();
  }

}
