import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from './api-client.service';

export interface LoginResponse {
  access_token: string;
  token_type: string;
  bearer: string;
  expires_in: number;
}

export const CLIENT_ID = new InjectionToken<string>('CLIENT_ID');

@Injectable({
    providedIn: 'root'
})
export class AuthApiService {
  private http: HttpClient;
  private baseUrl: string;
  private clientId: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string,
    @Optional() @Inject(CLIENT_ID) clientId?: string) {
      this.http = http;
      this.baseUrl = baseUrl ? baseUrl : '';
      this.clientId = clientId ? clientId : '';
  }

  public login(username: string, password: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const body =
      encodeURI(`username=${username}&password=${password}&client_id=${this.clientId}&grant_type=password`);
    const request = this.http.post<LoginResponse>(`${ this.baseUrl }/api/v1/authentication/account/login`, body, { headers });
    return request;
  }
}
