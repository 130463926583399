import { Injectable, Optional, Injector } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AppInsights } from 'applicationinsights-js';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import IAppInsights = Microsoft.ApplicationInsights.IAppInsights;
import { VersionService } from './version.service';
import { UiStateQuery } from './state/ui-state/ui-state.query';
import { AuthQuery } from './state/auth/auth.query';
import { ProviderQuery } from './state/provider/provider.query';
import { untilDestroyed } from 'ngx-take-until-destroy';

// Since AI.SeverityLevel isn't working we can just use our own
export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

export class AppInsightsConfig implements Microsoft.ApplicationInsights.IConfig {

  instrumentationKeySetLater?: boolean;
  // Will be deprecated in next major version
  instrumentationKeySetlater?: boolean;
  instrumentationKey?: string;
  endpointUrl?: string;
  emitLineDelimitedJson?: boolean;
  accountId?: string;
  sessionRenewalMs?: number;
  sessionExpirationMs?: number;
  maxBatchSizeInBytes?: number;
  maxBatchInterval?: number;
  enableDebug?: boolean;
  disableExceptionTracking?: boolean;
  disableTelemetry?: boolean;
  verboseLogging?: boolean;
  diagnosticLogInterval?: number;
  samplingPercentage?: number;
  autoTrackPageVisitTime?: boolean;
  disableAjaxTracking?: boolean;
  overridePageViewDuration?: boolean;
  maxAjaxCallsPerView?: number;
  disableDataLossAnalysis?: boolean;
  disableCorrelationHeaders?: boolean;
  correlationHeaderExcludedDomains?: string[];
  disableFlushOnBeforeUnload?: boolean;
  enableSessionStorageBuffer?: boolean;
  isCookieUseDisabled?: boolean;
  cookieDomain?: string;
  isRetryDisabled?: boolean;
  url?: string;
  isStorageUseDisabled?: boolean;
  isBeaconApiDisabled?: boolean;
  sdkExtension?: string;
  isBrowserLinkTrackingEnabled?: boolean;
  appId?: string;
  enableCorsCorrelation?: boolean;
  overrideTrackPageMetrics?: boolean;
}




export enum AppInsightTags {
  EVENT_DIAGRAM_UPLOADED = 'diagram_uploaded',
  REFERRER_ENCOUNTER = 'encounter',
  CUSTOM_LETTER_EDIT = 'custom-letter-edit',
  CUSTOM_LETTER_CREATE = 'custom-letter-create',
  SUPPORT_BRIDGEIT = 'support-bridgeit',
  PROVIDER_IMPERSONATION = 'Provider Impersonation',
  SIGNALR_PROCESS_MESSAGE = 'SignalR.processMessage',
  CONDITION_TRACKER_DELETE = 'condition-tracker-delete',
  CONDITION_TRACKER_UPDATE = 'condition-tracker-update',
  CONDITION_TRACKER_ADD = 'condition-tracker-add',
  CONDITIONS_MEDICATIONS_DISPENSE = 'conditions-medications-dispense',
  CONSUMABLE_TEMPLATE_APPLIED = 'consumable_template_applied',
  CONSULT_CONSUMABLE = 'consult-consumable',
  ALGOLIA_SEARCH_CONSUMABLE_SEARCH = 'AlgoliaSearch-Consumable-Search',
  ALGOLIA_SEARCH_CONSUMABLE_SELECTED = 'AlgoliaSearch-Consumable-Selected',
  DIAGNOSIS_TEMPLATE_APPLIED = 'diagnosis_template_applied',
  CONSULT_ICD = 'consult-ICD',
  CONSULT_DIAGNOSIS_PINNED = 'consult-diagnosis-pinned',
  CONSULT_CONSUMABLE_MEDICINES_EXPAND = 'consult-consumables-medicines-expand',
  CONSULT_MEDICINE_PINNED_FOR_DIAGNOSIS = 'consult-medicine-pinned-for-diagnosis',
  MEDICINE_TEMPLATE_APPLIED = 'medicine_template_applied',
  CONSULT_MEDICINEINFO_HASINGREDIENTSINFO = 'consult-medicineinfo-hasingredientsinfo',
  CONSULT_MEDICINEINFO_NOINGREDIENTSINFO = 'consult-medicineinfo-noingredientsinfo',
  CONSULT_MEDICINE = 'consult-medicine',
  CONSULT_SUMMARY_MEDICINE_INTERACTION_DIALOG_OPENED = 'consult-summary-medicine-warning-opened',
  CONSULT_MEDICINE_INTERACTION_DIALOG_OPENED = 'consult-medicine-warning-opened',
  CONSULT_MEDICINE_INTERACTION_SEVERE = 'consult-medicine warning-L1',
  ALGOLIA_SEARCH_MEDICINE_SEARCH = 'AlgoliaSearch-Medicine-Search',
  ALGOLIA_SEARCH_MEDICINE_SELECTED = 'AlgoliaSearch-Medicine-Selected',
  CONSULT_MEDICINE_FROM_RX_HISTORY_ADDED = 'constult-medicine-from-rx-history-added',
  CONSULT_MODIFIER = 'consult-modifier',
  PROCEDURE_TEMPLATE_APPLIED = 'procedure_template_applied',
  CONSULT_PROCEDURE_CHANGE = 'consult-procedure-change',
  CONSULT_SYMPTOMS = 'consult-symptoms',
  GYNAE_EDIT_IN_CONSULTATION = 'gynae-edit-in-consultation',
  CONSULT_COMPLETE = 'consult-complete',
  CONSULT_SAVE = 'consult-save',
  CONSULT_AUTO_SAVE = 'consult-auto-save',
  CONSULT_CANCEL = 'consult-cancel',
  TEMPLATE_OVERWRITE = 'template_overwrite',
  TEMPLATE_NEW = 'template_new',
  TEMPLATE_APPLIED = 'template_applied',
  ALGOLIA_SEARCH_ICD_SEARCH = 'AlgoliaSearch-Icd-Search',
  ALGOLIA_SEARCH_ICD_SELECTED = 'AlgoliaSearch-Icd-Selected',
  CONSULT_PLAN_REFERRAL = 'consult-plan-referral',
  CONSULT_PLAN_SICKNOTE_EDIT = 'consult-plan-sicknote-edit',
  CONSULT_PLAN_SICKNOTE_PRINT = 'consult-plan-sicknote-print',
  CONSULT_REASON_FOR_VISIT_COMPLAINT_REMOVED = 'consult-reasonForVisitCompaintRemoved',
  CONSULT_REASON_FOR_VISIT_COMPLAINT = 'consult-reasonForVisitCompaint',
  CONSULT_VITALS_REMOVED = 'consult-vitals-removed',
  CONSULT_VITALS = 'consult-vitals',
  PATIENT_FILE_TALING_POINTS_UPDATED = 'patient-file-talking-points-updated',
  CONSULT_START = 'consult-start',
  TIMELINE_QUICK_NOTE_ADDED = 'timeline-quick-note-added',
  TIMELINE_ENCOUNTER_NOTE = 'timeline_encounter_note',
  VIRTUAL_CONSULT_OPEN = 'virtual-consult-open',
  VIRTUAL_CONSULT_OPENTAB = 'virtual-consult-opentab',
  VIRTUAL_CONSULT_MAXIMIZED = 'virtual-consult-maximized',
  VIRTUAL_CONSULT_NORMAL = 'virtual-consult-normal',
  VIRTUAL_CONSULT_CLOSE = 'virtual-consult-close',
  VIRTUAL_CONSULT_MINIMIZE = 'minimize',
  GYNAE_EDIT = 'gynae-edit',
  SCREENING_CUSTOM = 'screening-custom',
  SCREENING_EDIT = 'screening-edit',
  SCREENING = 'screening',
  SCREENING_COMMON = 'screening-common',
  PATIENT_FILE_SURGICAL_INFO_ADDED = 'patient-file-surgical-info-added',
  SIDEBAR_ENCOUNTER_SUMMARY_OPENED = 'sidebar-encounter-summary-opened',
  CUSTOM_ALERT_CLEAR = 'custom-alert-clear',
  CUSTOM_ALERT_ADD = 'custom-alert-add',
  CONSULT_QUICK_NOTE = 'consult-quick-note',
  REMINDER_EDITED = 'reminder-edited',
  REMINDER_ADDED = 'reminder-added',
  STARTED_SPEECH_RECOGNITION = 'started-speech-recognition',
  REVIEW_SPEECH_RECOGNITION = 'review-speech-recognition',
  NOTIFICATION_VIEWED = 'notification-viewed',
  CUSTOM_LETTER_APPLIED = 'custom-letter-applied',
  FAVORITE_CREATED = 'in-consult-favourite-create',
  STYLUS_TEMPLATE_ASSIGN = 'stylus-template-apply',
  PROVIDER_CONFIGURATION_UPDATED = 'provider-configuration-updated',
  PROVIDER_REASON_FOR_VISIT_CONFIGURATION_UPDATED = 'provider-reason-for-visit-configuration-updated',
  PATIENT_SMS = 'patient-sms',
  PATIENT_FILE_CUSTOM_SURGERY_ADDED = 'patient-file-surgical-info-added-custom',
  MEDICINE_PRICE_CHECK = 'medicine-price-check',
  MEDICINE_FORMULARY_CHECK = 'medicine-formulary-check',
  STYLUS_TEMPLATE_DELETED = 'stylus-template-deleted',
  STYLUS_TEMPLATE_UPLOADED = 'stylus-template-uploaded',
  CUSTOM_SMS_EDIT = 'custom-sms-content-edit',
  CUSTOM_SMS_CREATE = 'custom-sms-content-create',
  USER_FEEDBACK = 'feedback',
  TOUR_CONSULT_NAV_SKIP = 'tour-consult-navigation-skip',
  TOUR_CONSULT_NAV_COMPLETE = 'tour-consult-navigation-complete',
  OVERVIEW_EMAIL = 'overview-email',
  SMS_TIP_SHORTCUT = 'sms-tip-shortcut',
  LETTER_TIP_SHORTCUT = 'letter-tip-shortcut',
  CALENDAR_EVENT_INFO_OPENED = 'calendar-info',
  OVERVIEW_MEDICINE_INTERACTION_SEVERE = 'overview-medicine-warning-L1',
  OVERVIEW_MEDICINE_INTERACTION_WARNING = 'overview-medicine-warning-L',
  OVERVIEW_MEDICINE_INTERACTION_DIALOG_OPENED = 'overview-medicine-warning-opened',
  DIAGNOSIS_TEMPLATE_DELETED = 'diagnosis-template-deleted',
  MEDICINE_TEMPLATE_DELETED = 'medicine-template-deleted',
  PROCEDURE_TEMPLATE_DELETED = 'procedure-template-deleted',
  CONSUMABLE_TEMPLATE_DELETED = 'consumable-template-deleted',
  QR_CODE_ENABLED = 'settings-QR-enabled',
  QR_CODE_DISABLED = 'settings-QR-disabled',
  CALENDAR_WORKING_HOURS_SAVED = 'calendar-working-hours-saved',
  PATHOLOG_REPORT_IGNORE = 'pathology-report-ignore',
  PATHOLOG_REPORT_LINK_PATIENT = 'pathology-report-link-patient',
  PRIVACY_POLICY_ACCEPTED = 'policy-understood',
  PATHOLOG_RESULT_MARK_READ = 'pathology-result-mark-as-read',
  PATHOLOG_RESULT_ADD_TASK = 'pathology-result-add-task',
  PATHOLOG_RESULT_FOLLOW_UP_SMS = 'pathology-result-follow-up-sms',
  PATHOLOG_RESULT_EMAIL_REPORT = 'pathology-result-email-report',
  PATHOLOG_RESULT_EMAIL_REPORTS = 'pathology-result-email-reports',
  PATHOLOG_RESULT_DOWNLOAD = 'pathology-result-download-report',
  PATHOLOG_RESULT_PRINT = 'pathology-result-print-report',
  PATHOLOG_RESULT_GO_TIMELINE = 'pathology-result-go-to-timeline',
  PATHOLOG_FILTER_WEEK_UNREAD = 'pathology-filter-week-unread',
  PATHOLOG_FILTER_MONTH_UNREAD = 'pathology-filter-month-unread',
  PATHOLOG_FILTER_MY_PATIENTS = 'pathology-filter-my-patients',
  PATHOLOG_FILTER_UNDELIVERED = 'pathology-filter-undelivered',
  CALENDAR_UPDATE_COLOUR = 'calendar-update-colour',
  CONSULT_VIEW_DISPENSED_MEDICINE = 'consult-medicine-dispensed-view',
  CONSULT_PRINT_DISPENSED_MEDICINE = 'consult-medicine-dispensed-print',
  CONSULT_EMAIL_DISPENSED_MEDICINE = 'consult-medicine-dispensed-email',
  CONSULT_DOWNLOAD_DISPENSED_MEDICINE = 'consult-medicine-dispensed-download',
  CONSULT_TAB_CHANGE = 'consult-main-tabs',
  CONSULT_DIAGNOSE_PRESCRIBE_TAB_CHANGE = 'consult-diagnose-prescribe-tab',
  PLAN_OUTCOME_OPTIONS_UPDATE = 'plan-outcome-options-update',
  SMART_GENERIC_MEDICINE_SELECTED = 'smart-generic-medicine-selected',
  SMART_GENERIC_MEDICINE_SPACE_BAR_SELECT = 'smart-generic-medicine-space-bar-select',
  REMOVE_CONDITION_AS_COMMON = 'remove-condition-as-common',
  ADDED_CONDITION_AS_COMMON = 'add-condition-as-common',
  REMOVE_SURGERY_AS_COMMON = 'remove-surgery-as-common',
  ADDED_SURGERY_AS_COMMON = 'add-surgery-as-common',
  KAHUN_NOTES_ADDED = 'kahu-notes-added'

}

// tslint:disable:variable-name max-line-length no-redundant-jsdoc
@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService implements IAppInsights {
  get context(): Microsoft.ApplicationInsights.ITelemetryContext {
    return AppInsights.context;
  }
  get queue(): Array<() => void> {
    return AppInsights.queue;
  }
  config: AppInsightsConfig;

  constructor(
    @Optional() _config: AppInsightsConfig,
    private _injector: Injector,
    private versionService: VersionService,
    private uiStateQuery: UiStateQuery,
    private authQuery: AuthQuery,
    private providerQuery: ProviderQuery) {
    this.config = _config;
    this.setupAuthChangeWatcherForAppInsights()
  }

  private setupAuthChangeWatcherForAppInsights() {
    // set up subscription on userId in order to notify appInsights
    // of a change, for logging purposes
    this.authQuery.auth$
      .pipe(
        map(auth => (auth ? auth.userId : null)), // only the userId, then distinct, so that we're not overdoing the setting
        distinctUntilChanged()
      )
      .subscribe(userId => {
        if (userId) {
          // console.log('Settings AppInsights context to specific user context: ' + userId);
          this.setAuthenticatedUserContext(userId);
        } else {
          // console.log('Cleared AppInsights auth context');
          this.clearAuthenticatedUserContext();
        }
      });
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackevent
  /**
   * Log a user action or other occurrence.
   * @param   name    A string to identify this event in the portal.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
   */
  trackEvent(eventName: string, eventProperties?: { [name: string]: string }, metricProperty?: { [name: string]: number }) {
    try {
      AppInsights.trackEvent(eventName, eventProperties, metricProperty);
    } catch (ex) {
      console.warn('Angular application insights Error [trackEvent]: ', ex);
    }
  }

  /**
   * Start timing an extended event. Call {@link stopTrackEvent} to log the event when it ends.
   * @param   name    A string that identifies this event uniquely within the document.
   */
  startTrackEvent(name: string): any {
    try {
      AppInsights.startTrackEvent(name);
    } catch (ex) {
      console.warn('Angular application insights Error [startTrackEvent]: ', ex);
    }
  }

  /**
   * Log an extended event that you started timing with {@link startTrackEvent}.
   * @param   name    The string you used to identify this event in startTrackEvent.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
   */
  stopTrackEvent(name: string, properties?: { [p: string]: string }, measurements?: { [p: string]: number }): any {
    try {
      AppInsights.stopTrackEvent(name, properties, measurements);
    } catch (ex) {
      console.warn('Angular application insights Error [stopTrackEvent]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackpageview
  /**
   * Logs that a page or other item was viewed.
   * @param   name  The string you used as the name in startTrackPage. Defaults to the document title.
   * @param   url   String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
   * @param   properties  map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
   * @param   duration number - the number of milliseconds it took to load the page. Defaults to undefined. If set to default value, page load time is calculated internally.
   */
  trackPageView(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
    duration?: number
  ) {
    try {
      AppInsights.trackPageView(name, url, properties, measurements, duration);
    } catch (ex) {
      console.warn('Angular application insights Error [trackPageView]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#starttrackpage
  /**
   * Starts timing how long the user views a page or other item. Call this when the page opens.
   * This method doesn't send any telemetry. Call {@link stopTrackTelemetry} to log the page when it closes.
   * @param   name  A string that idenfities this item, unique within this HTML document. Defaults to the document title.
   */
  startTrackPage(name?: string) {
    try {
      AppInsights.startTrackPage(name);
    } catch (ex) {
      console.warn('Angular application insights Error [startTrackPage]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#stoptrackpage
  /**
   * Logs how long a page or other item was visible, after {@link startTrackPage}. Call this when the page closes.
   * @param   name  The string you used as the name in startTrackPage. Defaults to the document title.
   * @param   url   String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
   * @param   properties  map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
   */
  stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
    try {
      AppInsights.stopTrackPage(name, url, properties, measurements);
    } catch (ex) {
      console.warn('Angular application insights Error [stopTrackPage]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackmetric
  /**
   * Log a numeric value that is not associated with a specific event. Typically used to send regular reports of performance indicators.
   * To send a single measurement, use just the first two parameters. If you take measurements very frequently, you can reduce the
   * telemetry bandwidth by aggregating multiple measurements and sending the resulting average at intervals.
   * @param   name    A string that identifies the metric.
   * @param   average Number representing either a single measurement, or the average of several measurements.
   * @param   sampleCount The number of measurements represented by the average. Defaults to 1.
   * @param   min The smallest measurement in the sample. Defaults to the average.
   * @param   max The largest measurement in the sample. Defaults to the average.
   */
  trackMetric(name: string, average: number, sampleCount?: number, min?: number, max?: number, properties?: { [name: string]: string }) {
    try {
      AppInsights.trackMetric(name, average, sampleCount, min, max, properties);
    } catch (ex) {
      console.warn('Angular application insights Error [trackTrace]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackexception
  /**
   * Log an exception you have caught.
   * @param   exception   An Error from a catch clause, or the string error message.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
   * @param   severityLevel   SeverityLevel | AI.SeverityLevel - severity level
   */
  trackException(
    exception: Error,
    handledAt?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
    severityLevel?: SeverityLevel | AI.SeverityLevel
  ) {
    try {
      if (exception?.message !== "ResizeObserver loop limit exceeded") {
        AppInsights.trackException(exception, handledAt, properties, measurements, severityLevel);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [trackException]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#tracktrace
  // trackTrace(message: string, properties?: {[string]:string}, severityLevel?: SeverityLevel | AI.SeverityLevel)
  // Log a diagnostic event such as entering or leaving a method.
  /**
   * Log a diagnostic message.
   * @param    message A message string
   * @param    properties  map[string, string] - additional data used to filter traces in the portal. Defaults to empty.
   */
  trackTrace(message: string, properties?: { [name: string]: string }, severityLevel?: SeverityLevel | AI.SeverityLevel) {
    try {
      AppInsights.trackTrace(message, properties, severityLevel);
    } catch (ex) {
      console.warn('Angular application insights Error [trackTrace]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackdependency
  /**
   * Log a dependency call (for instance: ajax)
   * @param   id    unique id, this is used by the backend o correlate server requests. Use Util.newId() to generate a unique Id.
   * @param   method    represents request verb (GET, POST, etc.)
   * @param   absoluteUrl   absolute url used to make the dependency request
   * @param   pathName  the path part of the absolute url
   * @param   totalTime total request time
   * @param   success   indicates if the request was sessessful
   * @param   resultCode    response code returned by the dependency request
   * @param   properties    map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   measurements  map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
   */
  trackDependency(
    id: string,
    method: string,
    absoluteUrl: string,
    pathName: string,
    totalTime: number,
    success: boolean,
    resultCode: number,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ) {
    try {
      AppInsights.trackDependency(id, method, absoluteUrl, pathName, totalTime, success, resultCode, properties, measurements);
    } catch (ex) {
      console.warn('Angular application insights Error [trackDependency]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#flush
  // flush()
  // Immediately send all queued telemetry. Synchronous.
  // * You don't usually have to use this, as it happens automatically on window closing.
  flush() {
    try {
      AppInsights.flush();
    } catch (ex) {
      console.warn('Angular application insights Error [flush]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
  /**
   * Sets the authenticated user id and the account id.
   * User auth id and account id should be of type string. They should not contain commas, semi-colons, equal signs, spaces, or vertical-bars.
   *
   * By default the method will only set the authUserID and accountId for all events in this page view. To add them to all events within
   * the whole session, you should either call this method on every page view or set `storeInCookie = true`.
   *
   * @param authenticatedUserId {string} - The authenticated user id. A unique and persistent string that represents each authenticated user in the service.
   * @param accountId {string} - An optional string to represent the account associated with the authenticated user.
   * @param storeInCookie {boolean} - AuthenticateUserID will be stored in a cookie and added to all events within this session.
   */
  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie: boolean = false) {
    try {
      AppInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
    } catch (ex) {
      console.warn('Angular application insights Error [setAuthenticatedUserContext]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#clearauthenticatedusercontext
  /**
   * Clears the authenticated user id and the account id from the user context.
   */
  clearAuthenticatedUserContext() {
    try {
      AppInsights.clearAuthenticatedUserContext();
    } catch (ex) {
      console.warn('Angular application insights Error [clearAuthenticatedUserContext]: ', ex);
    }
  }

  _onerror(message: string): any {
    console.warn('Angular application insights Error [_onerror]: ', message);
  }

  /**
   * Initialize Application Insights for Angular
   * Make sure your config{} has been set
   */
  public init(): void {
    if (this.config) {
      // Deprecation Warning(s)
      if (this.config.instrumentationKeySetlater) {
        console.warn(
          `\n\n
            Warning: [instrumentationKeySetlater] will soon be deprecated.\n
            Use .instrumentationKeySetLater (capital "L" in "L"ater) instead
            to prevent any possible errors in the future!
            \n\n`
        );
      }

      if (this.config.instrumentationKey) {
        try {
          AppInsights.downloadAndSetup(this.config);

          AppInsights.queue.push(() => {
            AppInsights.context.addTelemetryInitializer((envelope) => {

              const telemetryItem = envelope.data.baseData;

              /******** EXCLUDE SOME REQUESTS FROM BEING SENT ********/

              const excludedRequests = [
                'version.json',
                'sockjs-node',
                'infrastructure/network/state'
              ];
              let allow = true;
              excludedRequests.forEach(urlPart => {
                if (telemetryItem.url && telemetryItem.url.includes(urlPart)) {
                  allow = false;
                }
                if (telemetryItem.name && telemetryItem.name.includes(urlPart)) {
                  allow = false;
                }
              });
              if (!allow) { return false; }

              /******** SANITISE THE "PAGE" FIELD ********/

              const santiseRegexs = [
                /([\/]{1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})/,
                /(\(sidebar[,:].+\))/
              ];

              if (telemetryItem.name) {
                santiseRegexs.forEach(pattern => telemetryItem.name = telemetryItem.name.replace(new RegExp(pattern, 'g'), ''));
              }
              if (telemetryItem.properties && telemetryItem.properties.PageName) {
                santiseRegexs.forEach(pattern => telemetryItem.properties.PageName = telemetryItem.properties.PageName.replace(new RegExp(pattern, 'g'), ''));
              }

              /******** ADD CUSTOM PROPERTIES TO EACH REQUEST ********/

              telemetryItem.properties = telemetryItem.properties || {};

              telemetryItem.properties.vehicle = 'Clinician App';
              telemetryItem.properties.clientVersion = this.versionService.currentVersion.version || '';
              telemetryItem.properties.clientVersionDate = this.versionService.currentVersion.date || '';

              if (this.authQuery.getValue()) {
                telemetryItem.properties.practiceId = this.authQuery.getValue().currentTenantId || '';
                telemetryItem.properties.userName = this.authQuery.getValue().userId || '';
              }

              if (this.providerQuery.getValue() && this.providerQuery.getValue().details) {
                telemetryItem.properties.practiceName = this.providerQuery.getValue().details.PracticeName || '';
                telemetryItem.properties.treatingProviderName = this.providerQuery.getValue().details.TreatingDoctorName || '';

                telemetryItem.properties.billingPracticeNumber = this.providerQuery.getValue().details.PracticeNumber || '';
                telemetryItem.properties.treatingPracticeNumber = this.providerQuery.getValue().details.TreatingPracticeNumber || '';
                const practiceXRef = this.providerQuery.getValue().details.PracticeXRef;
                telemetryItem.properties.xref = practiceXRef || '';
                let system = 'myMPS';
                if (practiceXRef?.startsWith('nova')) {
                  system = 'Nova';
                } else if (practiceXRef?.startsWith('iHealth')) {
                  system = 'iHealth';
                } else if (!practiceXRef) {
                  system = 'hbc';
                }
                telemetryItem.properties.system = system;
              }
            });
          });

          // Make sure "router" exists - in case of UIRouterModule where it does not
          if (!this.config.overrideTrackPageMetrics && this.router) {
            this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
              this.startTrackPage(event.url);
            });

            this.router.events
              .pipe(
                filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError)
              )
              .subscribe((event: NavigationEnd) => {
                this.stopTrackPage(event.url);
              });
          }
        } catch (ex) {
          console.warn('Angular application insights Error [downloadAndSetup]: ', ex);
        }
      } else {
        if (!this.config.instrumentationKeySetLater && !this.config.instrumentationKeySetlater) {
          // there is no this.config.instrumentationKey AND no this.config.instrumentationKeySetLater => Add log.
          console.warn('An instrumentationKey value is required to initialize AppInsightsService');
        }
      }
    } else {
      console.warn('You need forRoot on ApplicationInsightsModule, with or instrumentationKeySetLater or instrumentationKey set at least');
    }
  }

  private get router() {
    try {
      return this._injector.get(Router);
    } catch (ex) {
      // @angular/router is not included - App must be utilizing UIRouter
      return null;
    }
  }
}
