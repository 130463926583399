<app-guided-tour *ngIf="guidedTourInstantiated$ | async"></app-guided-tour>
<div class="page-container" [ngClass]="{ unauth: !(authenticated$ | async) }">
  <div class="spinner-container" *ngIf="displayProgressSpinner$ | async">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
  <header *ngIf="authenticated$ | async">
    <app-header></app-header>
  </header>

  <main>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div class="side-content" [hidden]="!(authenticated$ | async)" [ngClass]="{ flyover: sidebarFlyout$ | async }">
      <div #sidebar></div>
    </div>
    <aside *ngIf="authenticated$ | async">
      <div class="side-menu">
        <app-side-nav></app-side-nav>
      </div>
    </aside>
  </main>
</div>
