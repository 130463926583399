import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StateModule } from '../state.module';
import { AvatarsStore, AvatarsState, AvatarViewModel } from './avatars.store';

@Injectable({ providedIn: StateModule })
export class AvatarsQuery extends QueryEntity<AvatarsState, AvatarViewModel> {
  constructor(protected store: AvatarsStore) {
    super(store);
  }
}
