import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MedicalAidVo } from 'api-clinician-app';

export function createInitialState(): ReferenceDataState {
  return {
    lastUpdated: null,
    medicalAids: [],
    // healthIDSSupportedSchemes:
  };
}

export interface ReferenceDataState {
  lastUpdated: Date;
  medicalAids: MedicalAidVo[];
  // healthIDSSupportedSchemes: string[];
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'reference-data', resettable: true })
export class ReferenceDataStore extends Store<ReferenceDataState> {
  constructor() {
    super(createInitialState());
  }
}
