import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import * as algoliasearch from 'algoliasearch'; // When using TypeScript
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { MedicineInfo } from './api-client.service';

export interface MedicineCategory {
  hbATC2Code: string;
  hbDrugClassGroupDescription: string;

  hbATC3Code: string;
  hbDrugClassDescription: string;
}

interface AlgoliaSearchResultHit<T> {
  objectID: number;
  data: T;
}

// interface AlgoliaSearchResultHit<T> {
//   results: {
//     hits: {
//       objectID: number;
//       data: MedicineInfo;
//     // left out other fields for brevity
//     }[]
//   }[];
//   // left out other fields for brevity
// }

@Injectable({
  providedIn: 'root',
})
export class AlgoliaRestService {
  private client: algoliasearch.Client;
  private medicineIndex: algoliasearch.Index;
  private diagnosisIndex: algoliasearch.Index;

  constructor(private configService: ConfigService) {
    this.client = algoliasearch(this.configService.config.algoliaSettings.appId, this.configService.config.algoliaSettings.apiKey);
    this.medicineIndex = this.client.initIndex(this.configService.config.algoliaSettings.medicineIndexName);
    this.diagnosisIndex = this.client.initIndex(this.configService.config.algoliaSettings.diagnosisIndexName);
  }

  // getConsumableInformationByNAPPICodes(nappiCodes: string[]) {
  //   const client = algoliasearch(this.configService.config.algoliaSettings.appId, this.configService.config.algoliaSettings.apiKey);
  //   const index = client.initIndex(this.configService.config.algoliaSettings.medicineIndexName);

  //   return from(index.getObjects(nappiCodes)).pipe(
  //     tap(t => console.warn('algolia', t)),
  //     map(r =>
  //       r.results
  //         ? r.results.filter(t => !!t && !!t['data']).flatMap(t => (t ? ((t as any).data as ConsumableInfo[]) : null))
  //         : ([] as ConsumableInfo[])
  //     ),
  //     tap(t => console.warn('algolia', t)),
  //     map(algoliaConsumables => consumableViewModelsFromAlgoliaResult({ algoliaConsumables })),
  //     tap(t => console.warn('algolia', t))
  //   );
  // }

  getAlgoliaDiagnosisToken(diagnosisCode: string) {
    return from(
      this.diagnosisIndex.search({
        query: diagnosisCode,
        hitsPerPage: 1,
        distinct: 1,
        // attributesToRetrieve: [
        //   'DEPToken',
        // ],
      })
    ).pipe(
      map(t => t.hits.map(hit => hit.data as any)),
      map(records => records.map(record => record.DEPToken as string || null)),
      map(records => _.head(records)),
    );
  }

  async getDiagnosis(diagnosisCode: string) {
    return this.diagnosisIndex.search({
      query: diagnosisCode,
      hitsPerPage: 1,
      distinct: 1
    }).then(t => t.hits.map(hit => hit.data as any)).then(records => _.head(records));
  }

  getAlgoliaMedicineCategories() {
    return from(
      this.medicineIndex.search({
        query: '*',
        hitsPerPage: 1000,
        distinct: 1,
        attributesToRetrieve: [
          'hbATC2Code',
          'hbATC3Code',
          'hbDrugClassDescription',
          'hbDrugClassGroupDescription',
        ],
      })
    ).pipe(
      map(t => t.hits.map(hit => hit._highlightResult.data as any)),
      map(records =>
        records.map(record => ({
          hbATC2Code: record.hbATC2Code.value,
          hbATC3Code: record.hbATC3Code.value,
          hbDrugClassDescription: record.hbDrugClassDescription.value,
          hbDrugClassGroupDescription: record.hbDrugClassGroupDescription.value,
        } as MedicineCategory))
      ),
      map(records => _.sortBy(records, record => ({ a: record.hbDrugClassGroupDescription, b: record.hbDrugClassDescription })))
    );
  }

  // getMedicineInfosByNappis(nappiCodes: string[]): Observable<MedicineInfo[]> {
  // const queries = nappiCodes

  // const filters = nappiCodes.reduce((acc, i) => acc.length > 0 ? ' OR ' : '' + `data.NAPPI:${i}`, '');

  // return from(this.medicineIndex.search<AlgoliaSearchResultHit<MedicineInfo>>({
  //   query: '',
  //   filters: filters
  // })).pipe(
  //   map(r => r.hits.map(h => h.data)),
  //   tap(t => console.warn('algolia medicine info?', t)),
  // );
  // }

  /**
   * Support lookup for nappi codes with 3 forms (10-digit including pack size, 7-digit with leading zero, 6-digit)
   */
  getMedicineInformationByNappi(nappiCode: string): Observable<MedicineInfo> {
    return from(this.medicineIndex.search<AlgoliaSearchResultHit<MedicineInfo>>({
      query: nappiCode,
      // filters: `data.NAPPICode:${}`,
      typoTolerance: 'min',
    })).pipe(
      map(r => r.hits.map(h => h.data)),
      map(lines => lines.find(line => line.PackInfo.map(m => parseInt(m.NAPPICode10)).includes(parseInt(nappiCode))
        || line.NAPPICode === nappiCode || line.NAPPICode7 === nappiCode)
      ),
    );
  }

  getMedicineInfosByFamilyAndForm(medicinefamily: string, medicineForm: string) {
    return from(this.medicineIndex.search<AlgoliaSearchResultHit<MedicineInfo>>({
      query: '',
      filters: `(data.hbDrugFamilyGroup:${medicinefamily} OR data.hbDrugFamilyName:${medicinefamily})`
    })).pipe(
      map(r => r.hits.map(h => h.data)),
    );
  }

}
