<h2 mat-dialog-title>Update patient email address <mat-icon mat-dialog-close>clear</mat-icon></h2>
<mat-dialog-content>
  <h3>Would you like to update the patient's email address?</h3>
  <form [formGroup]="form">
    <mat-radio-group formControlName="patientEmailAddress">
      <mat-radio-button #rbtn class="radio" *ngFor="let emailOption of emailAddressOptions$ | async" [value]="emailOption" >
        {{ emailOption }}
      </mat-radio-button>
    </mat-radio-group>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button cdkFocusInitial color="primary" [disabled]="form.invalid" #saveButton (click)="saveEmailAddress(form, saveButton)">Save</button>
</mat-dialog-actions>
