import { Injectable } from '@angular/core';
import { Params, NavigationExtras, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router) {}

  // TODO implement direct routes in here to allow for easy refactoring
  public navigate(path: any[], extras?: NavigationExtras) {
    // console.warn('TODO implement direct routes in here to allow for easy refactoring', path);
    return this.router.navigate(path, extras);
  }

  // TODO implement direct routes in here to allow for easy refactoring
  public navigateByUrl(url: string | UrlTree, extras?: NavigationExtras) {
    // console.warn('TODO implement direct routes in here to allow for easy refactoring');
    return this.router.navigateByUrl(url, extras);
  }
}
