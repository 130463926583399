import { PackInfo, Dosage, Duration, MedicineInfo } from 'api-clinician-app';

export interface DosageViewModel extends Dosage {
  RouteOfAdministration: string;
  Repeat: number;
  AdditionalInstructionFull: string;
  AdditionalInstructionSelections: string[];
  DosageInstructions?: string;
}

export const MedicineFormsDosageUnitType: { [ key: string]: string } = {
  CAP_TAB: 'tablet',
  CAP: 'capsule',
  TAB: 'tablet',
  SUPPOSITORY: 'suppository',
  DROPS: 'drop',
  SPRAY: 'spray',
  NAS: 'spray',
  INHALER: 'inhaler',
  KIT: 'kit',
  BAND_PLAST: 'plaster',
  IMPLANT: 'implant',
  SAC_: 'sachet',

  INJECTION: 'ml',
  SUSPENSION: 'ml',
  SUS: 'ml',
  SYRUP: 'ml',
  SYR: 'ml',
  LIQUID: 'ml',
  INFUSION: 'ml',

  CREAM_GEL: 'unit',
  PDR_: 'unit',
  POWDER: 'unit',
  SHA_: 'unit',
  MLS_: 'unit',
  OTHER: 'unit',
}

export const MedicineFormsDescriptionsMap: { [key: string]: string } = {
  CAP_TAB: 'Capsule / Tablet',
  SUPPOSITORY: 'Suppository',
  DROPS: 'Drops',
  SPRAY: 'Spray',
  INHALER: 'Inhaler',
  KIT: 'Kit',
  BAND_PLAST: 'Bandage / Plaster',
  IMPLANT: 'Implant',
  SAC_: 'Sachet',
  PDR_: 'Powder',

  INJECTION: 'Injection',
  SUSPENSION: 'Suspension',
  SYRUP: 'Syrup',
  LIQUID: 'Liquid',
  INFUSION: 'Infusion',

  CREAM_GEL: 'Cream / Gel',

  SHA_: 'SHA',
  MLS_: 'MLS',
  OTHER: 'Other',
};

export function formDescription(formName: string) {
  return MedicineFormsDescriptionsMap[formName] || formName;
}

export function formDosageMeasurement(formName: string) {
  return MedicineFormsDosageUnitType[formName] || formName || 'unit';
}

export interface MedicineViewModel {

  // important keys to lookup info
  CategoryCode: string;
  SubCategoryCode: string;
  Token: string;
  NAPPICode: string;

  // information the doctor can change himself

  SelectedDosage: DosageViewModel;
  SelectedDuration: Duration;

  // machine learning information

  Frequency: number;
  Dosage: DosageViewModel[];
  Duration: Duration[];
  ProductUsageCategory: string;

  // contained in MedicineInfo

  MedicineInfo?: MedicineInfo;

  // ManufacturerName: string; // in MedicineInfo
  // ManufacturerCode: string;
  // ProductFamilyCode: string;
  // ProductFamilyDescription: string;
  // ProductUsageCategory: string;
  // RouteOfAdministration: string;
  // ProductName: string;
  // Description: string;
  // StrengthUnit: string; // ordering purposes
  // StrengthDescription: string;
  // PackInfo: PackInfo[];
  // Flags?: string[];
  // MedicineSchedule?: number;
  IsForExistingCondition?: boolean;
}

export interface MedicineFamilyViewModel {

  // important keys to lookup info
  CategoryCode: string;
  SubCategoryCode: string;
  Token: string;

  // Family information
  hbDrugFamilyGroup: string;
  hbDrugFamilyName: string;

  // other information for displaying/filtering...
  Flags: string;
  ProductUsageCategory: string;
  Frequency: number;

  // medicine data
  Medicines: MedicineViewModel[];



}
