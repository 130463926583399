import { ActivatedRoute } from '@angular/router';
import { BaseModel, Audit, Auditable, Balance, Entity, BENEFIT_CHECK_STATUS } from './general.models';
import { AccountMember } from './member.models';
import { CalendarEvent } from "./calendar.models";

export interface Account extends BaseModel, Auditable, Entity {
  Id?: string;
  AccountEntityType?: ACCOUNT_ENTITY_TYPE;
  HomeBranch?: string;
  AccountNo?: string;
  AccountType?: ACCOUNT_TYPE;
  AccountStatus?: ACCOUNT_STATUS;
  Scheme?: string;
  MemberNo?: string;
  Plan?: string;
  Option?: string;
  MainMember?: string;
  MainMemberDependantCode?: string;
  TreatingProvider?: string;
  PrimaryProvider?: string;
  SecondaryProvider?: string;
  CompanyName?: string;
  CompanyRegistrationNo?: string;
  VATNo?: string;
  MemberCount?: number;
  LastFamCheckDate?: Date;
  LastFamCheckSPO?: string;
  Members?: string[];
  Balance?: Balance;
  UnallocatedCredit?: number;
  NextOfKin?: NextOfKin;
  AccountSettings?: AccountSettings;
  ShowNoteModalOnOpen?: boolean;
  LastBenefitCheckStatus?: BENEFIT_CHECK_STATUS;
  LastBenefitCheckReportUrl?: string;
  LastBenefitCheckDate?: Date;
  LastBcContractData?: any;
  LastValidatedScheme?: string;
}

export enum ACCOUNT_ENTITY_TYPE {
  PATIENT = 'Patient',
  COMPANY = 'Company'
};

export enum ACCOUNT_TYPE {
  MEDICAL_AID = 'Medical Aid',
  PRIVATE = 'Private'
}

export enum ACCOUNT_STATUS {
  ACTIVE = 'Active',
  SUSPENDED = 'Suspended',
  FINAL_NOTICE = 'Final notice',
  HANDED_OVER = 'Handed over',
  ARCHIVED = 'Archived'
}

export interface FamCheckResult {
  records?: FamCheckPatientRecord[],
  responseDetails?: FamCheckResponseDetails;
  success?: boolean;
  reason?: string;
}

export interface FamCheckRequest {
  PmaName?: string;
  TreatingPracticeNumber?: string;
  BillingPracticeNumber?: string;
  SchemeCode?: string;
  MembershipNumber?: string;
  IdNumber?: string;
}

export interface FamCheckResponseDetails {
  disclaimer?: string;
  responseDateTime?: string;
  enquiryNumber?: string;
  referenceNumber?: string;
  responseCodes?: FamCheckResponseCode[]
}

export interface FamCheckResponseCode {
  code?: string;
  description?: string;
}

export interface FamCheckPatientRecord {
  benefitStartDate?: string;
  dateOfBirth?: string;
  dependantCode?: string;
  dependencyType?: string;
  firstName?: string;
  gender?: string;
  idNumber?: string;
  initials?: string;
  joinDate?: string;
  lastName?: string;
  leftDate?: string;
  membershipEffectiveDateFrom?: string;
  membershipNumber?: string;
  membershipToDate?: string;
  plan?: string;
  recordDate?: string;
  schemeName?: string;
  status?: string;
  title?: string;
}

export interface AccountStatusChangedAudit extends Audit {
  PreviousStatus?: string;
  NewStatus?: string;
  Reason?: string;
}

export interface Note extends BaseModel {
  Id?: string;
  Note: string;
  Pinned?: boolean;
  OriginType: NOTE_ORIGIN_TYPE;
}

export enum NOTE_ORIGIN_TYPE {
  USER = 'User',
  SYSTEM = 'System',
}

export enum NOTE_FILTER_TYPE {
  ALL_USERS = 'All users',
  ALL_USERS_AND_SYSTEM = 'All users and system',
  SYSTEM = 'System',
}

export interface NoteModalData {
  Type: NOTE_TYPE,
  AccountId: string;
  AccountNo: string;
  Account?: Account;
  Name?: string;
  Surname?: string;
  InvoiceId?: string;
  InvoiceNo?: string;
}

export enum NOTE_TYPE {
  ACCOUNT = 'Account',
  INVOICE = 'Invoice'
}

export interface NoteIdCollectionNest {
  Id: string,
  CollectionName: string,
}

export interface AccountMoveModalData {
  moveMethod?: ACCOUNT_MEMBER_MOVE_METHOD | null,
  member: AccountMember,
  currentAccountId: string,
  route: ActivatedRoute
}

export enum ACCOUNT_MEMBER_MOVE_METHOD {
  NEW = 'New',
  EXISTING = 'Existing',
}

export function instanceOfAccountStatusChangedAudit(object: any): object is AccountStatusChangedAudit {
  return 'NewStatus' in object && 'Reason' in object;
}

export interface NextOfKin {
  ContactPersonOne?: NextOfKinContact;
  ContactPersonTwo?: NextOfKinContact;
}

export interface NextOfKinContact {
  Name?: string;
  CellphoneNo?: string;
  EmailAddress?: string;
  Notes?: string;
}

export interface AccountSettings {
  OptionalCCEmail?: string;
}

export interface AddAccountCalendarContext {
  calendarEvent?: CalendarEvent,
  saveCallback?: Function,
  cancelCallback?: Function
}

export interface AddAccountCalendarCallbackResult {
  accountId?: string,
  checkedInPatientId?: string,
  accountData?: Partial<Account>,
  patientData?: Partial<AccountMember>
}

export interface AccountMemberMoveContext {
  accountId?: string,
  member?: AccountMember,
  saveCallback?: Function,
  cancelCallback?: Function,
  route?: ActivatedRoute,
}

export interface AccountMemberMoveData {
  accountId?: string,
  member?: AccountMember,
  route?: ActivatedRoute,
}
