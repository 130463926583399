import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as urlParse from 'url-parse';
import { ApplicationInsightsService } from '../services/application-insights.service';

@Injectable() export class LoggingInterceptor implements HttpInterceptor {

  constructor(private appInsightsService: ApplicationInsightsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoreUrls = [
      'dc.services.visualstudio.com', // don't log AppInsights
    ];
    const url = urlParse(req.url, false);

    if (ignoreUrls.find(i => i.toLocaleString().includes(url.hostname))) {
      return next.handle(req);
    }

    const start = performance.now();
    return next.handle(req)
      .pipe(
        tap({
          // error: () => {
          //   const duration = (performance.now() - start);
          //   this.appInsightsService.trackPageView(req.url, req.url, {}, {}, duration);
          // },
          complete: () => {
            const duration = (performance.now() - start);
            // this.appInsightsService.trackPageView(url.pathname, url.href, {}, {}, duration);
          }
        })
      );
  }
}
