import { QueryEntity, QueryConfig, EntityUIQuery } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { PatientsStore, PatientsState, PatientStateModel, PatientsUIState, PatientStateUIModel } from './patients.store';
import { Observable } from 'rxjs';

const sortBy = (a: PatientStateModel, b: PatientStateModel, state: PatientsState) => {
  if (a.details && b.details) {
    // surname ASC
    if (a.details.PatientDetails.Surname > b.details.PatientDetails.Surname) {
      return 1;
    } else if (a.details.PatientDetails.Surname < b.details.PatientDetails.Surname) {
      return -1;

      // then firstname ASC
    } else if (a.details.PatientDetails.FirstName > b.details.PatientDetails.FirstName) {
      return 1;
    } else if (a.details.PatientDetails.FirstName < b.details.PatientDetails.FirstName) {
      return -1;
    } else {
      return 0;
    }
  }
};

@Injectable({
  providedIn: 'root',
})
@QueryConfig({ sortBy })
export class PatientsQuery extends QueryEntity<PatientsState, PatientStateModel> {
  ui: EntityUIQuery<PatientsUIState>;

  constructor(protected store: PatientsStore) {
    super(store);
    this.createUIQuery();
  }

  patientUiState$(patientId: string): Observable<PatientStateUIModel> {
    return this.ui.selectEntity(patientId);
  }

}
