import { NavigationItem } from './reference-data.service';


export enum PATIENT_FILE_NAVIGATION {
  PATIENT_FILE = 'patient-file',
  OVERVIEW = 'overview',
  ACCOUNT = 'account',
  GENERAL_SCREENING = 'general-screening',
  CONDITIONS_MEDICATION = 'conditions-medication',
  LIFESTYLE_FAMILY = 'lifestyle-family',
  SURGICAL_HOSPITALISATION = 'surgical-hospitalisation',
  ALLERGIES = 'allergies',
  VITALS_METRICS = 'vitals-metrics',
  GYNAE = 'gynae',
}

export enum CONSULTATION_NAVIGATION {
  CONSULT = 'consult',
  REASON_VISIT = 'reason-visit',
  SYMPTOMS = 'symptoms',
  EXAMINATION = 'examination',
  DIAGNOSIS = 'diagnosis',
  MEDICINES = 'medicines',
  PLAN = 'plan',
  BILLING_LINES = 'billing-lines',
  INVOICE_REVIEW = 'invoice-review'
}


export const navigation: NavigationItem[] = [
  {
    id: PATIENT_FILE_NAVIGATION.PATIENT_FILE,
    title: 'Patient file',
    type: 'group',
    children: [
      {
        id: PATIENT_FILE_NAVIGATION.OVERVIEW,
        title: 'Overview & timeline',
        type: 'item',
        url: '../dashboard',
      },
      {
        id: PATIENT_FILE_NAVIGATION.ACCOUNT,
        title: 'Account & medical aid',
        type: 'item',
        url: '../account',
      },
      {
        id: PATIENT_FILE_NAVIGATION.GENERAL_SCREENING,
        title: 'General screening',
        type: 'item',
        url: '../screening',
      },
      {
        id: PATIENT_FILE_NAVIGATION.CONDITIONS_MEDICATION,
        title: 'Conditions & medication',
        type: 'item',
        url: '../conditions',
      },
      {
        id: PATIENT_FILE_NAVIGATION.LIFESTYLE_FAMILY,
        title: 'Lifestyle & family',
        type: 'item',
        url: '../lifestyle',
      },
      {
        id: PATIENT_FILE_NAVIGATION.SURGICAL_HOSPITALISATION,
        title: 'Surgical & hospitalisation',
        type: 'item',
        url: '../surgical-history',
      },
      {
        id: PATIENT_FILE_NAVIGATION.ALLERGIES,
        title: 'Allergies',
        type: 'item',
        url: '../allergies',
      },
      {
        id: PATIENT_FILE_NAVIGATION.VITALS_METRICS,
        title: 'Vitals & metrics',
        type: 'item',
        url: '../vitals',
      },
      {
        id: PATIENT_FILE_NAVIGATION.GYNAE,
        title: 'Gynae',
        type: 'item',
        url: '../gynae',
        hidden: true
      },
    ]
  },/*
   {
    id: CONSULTATION_NAVIGATION.CONSULT,
    title: 'Consult',
    type: 'group',
    children: [
      {
        id: CONSULTATION_NAVIGATION.CONSULT,
        title: 'Consultation',
        type: 'item',
        url: '../consult',
        fragment: '',
      }
      {
        id: CONSULTATION_NAVIGATION.REASON_VISIT,
        title: 'Reason for visit',
        type: 'item',
        url: '../consult',
        fragment: 'complaints',
      },
       {
        id: CONSULTATION_NAVIGATION.SYMPTOMS,
        title: 'Symptoms',
        type: 'item',
        url: '../consult',
        fragment: 'symptom-history',
      },
      {
        id: CONSULTATION_NAVIGATION.EXAMINATION,
        title: 'Examination',
        type: 'item',
        url: '../consult',
        fragment: 'examination',
      },
      {
        id: CONSULTATION_NAVIGATION.DIAGNOSIS,
        title: 'Diagnosis',
        type: 'item',
        url: '../consult',
        fragment: 'diagnosis',
      },
      {
        id: CONSULTATION_NAVIGATION.MEDICINES,
        title: 'Medicines',
        type: 'item',
        url: '../consult',
        fragment: 'medicines',
      },
      {
        id: CONSULTATION_NAVIGATION.PLAN,
        title: 'Plan',
        type: 'item',
        url: '../consult',
        fragment: 'plan',
      },
      {
        id: CONSULTATION_NAVIGATION.BILLING_LINES,
        title: 'Billing Lines',
        type: 'item',
        url: '../consult',
        fragment: 'invoice-lines',
      },
      {
        id: CONSULTATION_NAVIGATION.INVOICE_REVIEW,
        title: 'Invoice Review',
        type: 'item',
        url: '../consult',
        fragment: 'billing',
      },
    ]
  }*/
];
