<span class="timespan-control" [formGroup]="timespanSpanForm">
  <p class="timespan-unitType">
    <mat-radio-group formControlName="unitType" aria-label="Timespan Unit Type" class="radio-button-group">
      <mat-radio-button *ngFor="let opt of availableModelUnits" [value]="opt.toLocaleLowerCase()">{{ opt }}</mat-radio-button>
    </mat-radio-group>
  </p>
  <p class="timespan-unit">
    <mat-form-field appearance="outline" floatLabel="always" style="width: 100px;">
      <mat-label style="text-transform: capitalize;">{{ timespanSpanForm.get('unitType').value }}</mat-label>
      <!-- <mat-icon (click)="decrement()" matPrefix class="clickable">remove</mat-icon> -->
      <input matInput type="number" formControlName="unit" maxlength="3" />
      <!-- <mat-icon (click)="increment()" matSuffix class="clickable">add</mat-icon> -->
    </mat-form-field>
  </p>
</span>
