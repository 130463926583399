export class Random {
  static randomNum(min, max) {
    return parseInt(Math.random() * (max - min) + min);
  }
  static randomString(length) {
    let randomString = '';
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    for (let i = 0; i < length; i++) {
      randomString += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    return randomString;
  }
  static randomVowel() {
    const vowels = 'aeiou';
    return vowels.charAt(Math.floor(Math.random() * vowels.length));
  }
  static randomConsonant() {
    const consonants = 'bcdfghjklmnpqrstvwyxz';
    return consonants.charAt(Math.floor(Math.random() * consonants.length));
  }
  static selectRandomFrom(letters: string) {
    return letters.charAt(Math.floor(Math.random() * letters.length));
  }
}
