import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'textBodyFormatter',
  pure: true
})
export class TextBodyFormatter implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any): any {
    if (typeof value === 'string') {
      const valueStr = value as string;
      let reg = new RegExp(/[\r|\n]/i);
      const containsLineBreaks = reg.test(valueStr);
      reg = new RegExp(/<[a-z][\s\S]*>/i);
      const containsHtml = reg.test(valueStr);

      if (!containsHtml && containsLineBreaks) {
        return valueStr.replace(/[\r|\n]/ig, '<br>\n');
      } else {
        return valueStr;
      }
    } else {
      return value;
    }
  }
}
