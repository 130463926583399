<mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav mode="over" [(opened)]="opened">
      <mat-nav-list class="sidenav-buttons">
        <div class="button-container">
          <button mat-mini-fab color="primary" aria-label="Home" (click)="switchPage('Home')">
            <mat-icon>home</mat-icon>
          </button>
          <span class="button-title">Home</span>
        </div>
        <div class="button-container">
          <button mat-mini-fab color="primary" aria-label="Consults" (click)="switchPage('Consults')">
            <mat-icon>list</mat-icon>
          </button>
          <span class="button-title">Consults</span>
        </div>
        <div class="button-container">
          <button mat-mini-fab color="warn" aria-label="Settings" (click)="switchPage('Settings')">
            <mat-icon>settings</mat-icon>
          </button>
          <span class="button-title">Settings</span>
        </div>
        <div class="button-container">
          <button mat-mini-fab color="primary" aria-label="Help" (click)="switchPage('Help')">
            <mat-icon>help</mat-icon>
          </button>
          <span class="button-title">Help</span>
        </div>
      </mat-nav-list>
    </mat-sidenav>
  
    <mat-sidenav-content cdkDrag cdkDragRootElement=".cdk-overlay-pane">
      <div class="dialog-container">
        <app-nora-dialog-toolbar (resizeDialogEvent)="resizeDialog()" (sidenavToggle)="onSidenavToggle()" cdkDragHandle></app-nora-dialog-toolbar>
        <h2 mat-dialog-title class="dialog-header">{{ pageConfig[currentPage].title }}</h2>
        <p class="subtitle">{{ subtitle }}</p>
        <mat-divider></mat-divider>
        <mat-dialog-content class="dialog-content" [ngSwitch]="currentPage">
          <div class="consult-ids">
          <ng-container *ngSwitchCase="'Home'">
            <app-audio-recorder class="audio-recorder"
              [hbdid]="data.practiceId" 
              [hbbid]="data.encounterId" 
              [sessionUUID]="data.encounterId"
              (onRecorded)="handleAudioRecorded($event)"
            ></app-audio-recorder>
          </ng-container>
          </div>
          <ng-container *ngSwitchCase="'Consults'">
            <div class="table-container">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="patient">
                  <th mat-header-cell *matHeaderCellDef> Patient </th>
                  <!-- <td mat-cell *matCellDef="let element"> {{element.patient}} </td> -->
                  <td mat-cell *matCellDef="let element"> {{element.ConsultationID}} </td>
                </ng-container>
  
                <ng-container matColumnDef="time">
                  <th mat-header-cell *matHeaderCellDef> Time </th>
                  <!-- <td mat-cell *matCellDef="let element"> {{element.time}} </td> -->
                  <td mat-cell *matCellDef="let element"> {{element.Time}} </td>
                </ng-container>
  
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> Status </th>
                  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'Settings'">
            <div class="settings-card-container">
              <div class="card-wrapper">
                <h4>Accountholder</h4>
                <mat-card>
                  <mat-card-content>
                    <p style="color: blue;">Name</p>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="card-wrapper">
                <h4>Email Address</h4>
                <mat-card>
                  <mat-card-content>
                    <p style="color: blue;">{{email}}</p>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="card-wrapper">
                <h4>Account ID</h4>
                <mat-card>
                  <mat-card-content>
                    <p style="color: blue;">{{accountId}}</p>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </ng-container>        
          <ng-container *ngSwitchCase="'Help'">
            <div class="help-section">
              <div class="help-card-container">
                <mat-card class="help-card">
                  <div class="card-heading">
                    <mat-icon>sms</mat-icon>
                    <p>WhatsApp</p>
                  </div>
                  <mat-card-content>
                    <span class="card-text">
                      <p>
                        <a href="https://wa.me/447893943848" target="_blank" style="color: rgb(51, 51, 234);">+447893943 848</a>
                      </p>
                    </span>
                  </mat-card-content>
                </mat-card>
                <mat-card class="help-card">
                  <div class="card-heading">
                    <mat-icon>mail</mat-icon>
                    <p>Email</p>
                  </div>
                  <mat-card-content>
                    <span class="card-text">
                      <p>
                        <a href="mailto:info@nora-ai.com" style="color: rgb(51, 51, 234);">info&#64;nora-ai.com</a>
                      </p>
                    </span>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="input-container">
                <mat-form-field class="feedback-form">
                  <mat-label>Leave a message</mat-label>
                  <textarea matInput placeholder="Ex. My notes failed to process..." [(ngModel)]="message"></textarea>
                </mat-form-field>
                <button mat-flat-button (click)="onFeedbackSubmit()">Submit</button>
              </div>
            </div>
          </ng-container>
        </mat-dialog-content>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>