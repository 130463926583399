import { trigger, state, style, transition, animate } from '@angular/animations';

export const validationErrorAnimation =
trigger('validationErrorAnimation', [
  state('*', style({
    'overflow-y': 'hidden',
    height: '*'
  })),
  state('void', style({
    height: '0',
    'overflow-y': 'hidden'
  })),
  transition('* => void', animate('250ms ease-out')),
  transition('void => *', animate('250ms ease-in'))
]);
