import { Component, OnInit, Inject, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  PatientCommunicationFollowupVoDeliveryType, TriggerPatientCommunication,
  PatientCommunicationFollowupVoPriority, ProviderVo2, CommunicationAttachment
} from 'api-clinician-app';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProviderService, PatientsService } from '@app/core/services';
import { map, take, distinctUntilChanged, debounceTime, catchError, startWith, tap, withLatestFrom, delay, switchMap, toArray, filter, mapTo, finalize } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, concat, of } from 'rxjs';
import * as uuidV4 from 'uuid/v4';
import * as _ from 'lodash';
import { untilDestroyed } from 'ngx-take-until-destroy';
import '@app/core/validators/emailaddress.validator';
import { shareReplayDestroyed } from '@app/core/functions/share-replay-destroyed';
import { PatientSaveEmailDialogComponent, PatientSaveEmailInputData } from '../patient-save-email-dialog/patient-save-email-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel, defaultDialogActions, DialogResponse } from '../confirm-dialog/confirm-dialog.component';
import { FileSizePipe } from '@app/shared/pipes/file-size.pipe';
import { Random } from 'e2e/src/utilities/Random';
import { MerakiClientService } from '@app/core/services/meraki-client.service';
import { EmailRequest } from '@app/core/services/meraki-models/meraki-models';
import { switchTap } from '@app/core/functions/switch-tap';

export interface PatientCommunicationSendInputData {
  letterText: string;
  routeParams: any;
  attachments: CommunicationAttachmentWithData[];
  subject: string;
  context: string;
  contextId: string;
  provider: ProviderVo2;
  sendToPatient: boolean;
  attachFiles: boolean;
  showFiles: boolean;
  sendWithNexus?: boolean; // Send using nexus function
}

export type CommunicationAttachmentWithData = CommunicationAttachment & {
  data?: string;
};

const TOTAL_SIZE_LIMIT = 8 * 1024 * 1024;

@Component({
  selector: 'app-patient-communication-send-dialog',
  templateUrl: './patient-communication-send-dialog.component.html',
  styleUrls: ['./patient-communication-send-dialog.component.scss'],
})
export class PatientCommunicationSendDialogComponent implements OnInit, OnDestroy {
  @ViewChild('recipientInput') recipientInput: ElementRef;

  recipients: string[] = [];

  form: FormGroup = this.fb.group({
    recipients: ['', Validators.required],
    letterText: [this.data.letterText, Validators.required],
    subject: [this.data.subject, Validators.required],
    attachments: [this.data.attachments],
    files: [],
    tenantEmailAddress: '',
    patientEmailAddress: '',
    sendCopyToTenant: false,
    sendCopyToPatient: false,
    changed: false
  });

  separatorKeysCodes = [ENTER, COMMA];

  provider$ = this.providerService.provider$;

  addressbook$ = combineLatest([
    this.provider$.pipe(switchMap(provider => this.providerService.getAddressbook(provider.PracticeTenantId))),
    this.form.get('recipients').valueChanges.pipe(startWith(''))
  ]).pipe(
    map(([book, recipients]) => book.filter(s => !recipients.includes(s.EmailAddress)))
  );

  filteredAddresses$ = this.form.get('recipients').valueChanges.pipe(
    map(s => s?.toLocaleLowerCase()),
    withLatestFrom(this.addressbook$),
    map(([search, books]) =>
      search?.length > 1
        ? books
          .filter(s => !this.recipients.includes(s.EmailAddress))
          .filter(s => s.EmailAddress.includes(search) || s.Name?.toLocaleLowerCase().includes(search) ||
            s.Surname?.toLocaleLowerCase().includes(search))
        : []
    )
  );

  patient$ = this.patientsService.loadPatientInfo(this.data.routeParams.practiceId, this.data.routeParams.patientId);

  files$ = this.form.get('files').valueChanges;

  constructor(
    private patientsService: PatientsService,
    public dialogRef: MatDialogRef<PatientCommunicationSendDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientCommunicationSendInputData,
    private fb: FormBuilder,
    private providerService: ProviderService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private nexusApi: MerakiClientService
  ) { }

  recipientMail: string;
  editorConfig: any = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins: 'link table preview',
    toolbar: 'undo redo | bold italic | fontsizeselect | bullist numlist outdent indent | alignleft aligncenter alignright | file',
    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
    browser_spellcheck: true,
    contextmenu: false,
    statusbar: false,
    visual: false,
    height: 250,
    valid_children: '+body[style],+body,+head',
    extended_valid_elements: 'body,head',
    elementpath: false,
    branding: false,
    content_css: ['assets/fonts.css'],
    content_css_cors: true,
  };

  sendBusy = false;

  ngOnInit() {
    this.patient$.pipe(
      take(1),
      withLatestFrom(this.provider$),
      untilDestroyed(this),
      map(([patient, provider]) => {
        this.form.patchValue({
          tenantEmailAddress: provider.EmailAddress,
          patientEmailAddress: patient.PatientDetails.EmailAddress
        });

        if (this.data.sendToPatient) {
          this.toggleRecipient(patient.PatientDetails.EmailAddress);
          this.form.patchValue({
            sendCopyToPatient: true
          });
        }
      })).subscribe();

    this.form.valueChanges.pipe(
      distinctUntilChanged(_.isEqual),
      debounceTime(250),
      map(formVal => {

        this.form.patchValue({
          sendCopyToTenant: this.recipients.some(r => r === formVal.tenantEmailAddress),
          sendCopyToPatient: this.recipients.some(r => r === formVal.patientEmailAddress) || this.data.sendToPatient
        });

      }),
      untilDestroyed(this),
    ).subscribe();

    if (!!this.data.sendWithNexus) {
      this.data.attachFiles = false;
    }
  }

  ngOnDestroy() { }

  send(form, sendButton) {
    if (!!this.data.sendWithNexus) {
      this.sendWithNexus();
      return;
    }

    const files = this.form.get('files').value as File[] || [];
    const currentSize = _.chain(files).sumBy(s => s.size) || 0;
    if (currentSize > TOTAL_SIZE_LIMIT) {
      this.warnLimit$(currentSize).subscribe();
      return;
    }

    sendButton.disabled = true;
    const customAttachments$ = files.map(file => {
      return this.uploadPatientAttachment(file);
    });

    concat(...customAttachments$, ...(this.form.value.attachments as CommunicationAttachment[]).map(s => of(s))).pipe(
      toArray(),
      switchMap(attachments => this.patientsService.sendPatientCommunication(this.data.routeParams.tenantId, this.data.routeParams.patientId, {
        SendNow: true,
        PatientCommunication: {
          DeliveryType: PatientCommunicationFollowupVoDeliveryType._0,
          Priority: PatientCommunicationFollowupVoPriority._0,
          // ScheduledDate: values.ScheduleDate,
          Message: form.controls.letterText.value,
          Subject: form.controls.subject.value,
          Destination: form.controls.recipients.value,
          Provider: this.data.provider,
          Context: this.data.context,
          //todo
          ContextId: attachments.length > 0 ?
            `${this.data.contextId},${attachments.map(s => s.DocumentUrl).join(',')}`
            : this.data.contextId,
          Attachments: attachments,
        }
      })),
      catchError(() => of({ Sucess: false })),
      tap(s => sendButton.disabled = s.Sucess),
      tap(s => s.Sucess && this.dialogRef.close(true)),
      withLatestFrom(this.provider$),
      take(1),
      switchMap(([result, provider]) => {
        const snackMessage = result.Sucess ? 'Message scheduled for sending' : 'Your message could not be sent. Please try again later';
        this.snackbar.open(snackMessage, 'OK', { duration: 10000 });
        const availableEmailAddresses = this.recipients.filter(email => email !== provider.EmailAddress);
        if (result.Sucess === true && availableEmailAddresses.length > 0 && (!form.controls.patientEmailAddress.value || form.controls.patientEmailAddress.value === '')) {
          const savePatientEmailDialogData: PatientSaveEmailInputData = {
            emailAddressOptions: availableEmailAddresses,
            patientId: this.data.routeParams.patientId,
            tenantId: this.data.routeParams.tenantId,
          };
          const savePatientEmailDialogRef = this.dialog.open(PatientSaveEmailDialogComponent, {
            data: savePatientEmailDialogData
          });
          return savePatientEmailDialogRef.afterClosed();
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  uploadPatientAttachment(file: File) {
    const document = {
      PracticeId: this.data.routeParams.tenantId,
      PatientId: this.data.routeParams.patientId,
      Description: file.name,
      Category: 'EmailAttachment',
      FileName: `${Random.randomNum(0, 1000)}_${file.name}`,
      CapturedDate: new Date(),
      DocumentDate: new Date(),
      FileSize: file.size,
      DocumentId: uuidV4()
    };
    return this.patientsService.uploadPatientFile(this.data.routeParams.tenantId, this.data.routeParams.patientId, document, file)
      .pipe(
        map(response => response.document),
        map(s => ({ DocumentUrl: s.DocumentUrl, MimeType: s.MimeType, FileName: s.FileName, isCustom: true } as CommunicationAttachment))
      );
  }

  sendWithNexus() {
    const request = {
      To: this.recipients.map(rec => ({
        EmailAddress: rec
      })),
      Body: this.form.controls.letterText.value,
      Subject: this.form.controls.subject.value,
      Attachments: this.form.value.attachments?.map(att => ({
        Data: att.data,
        FileName: att.FileName,
        MimeType: att.MimeType
      })) || []
    } as EmailRequest;

    this.sendBusy = true;
    return this.nexusApi.sendEmail(request).pipe(
      take(1),
      // update timeline with info
      withLatestFrom(this.provider$),
      switchTap(([item, provider]) => this.patientsService.addPatientTimelineEntries(this.data.routeParams.tenantId, this.data.routeParams.patientId, [
        {
          DateTime: new Date(),
          Type: 'PatientEmailSent',
          Code: this.form.controls.recipients.value,
          ValueFlag: 'Email',
          Value: new Date().toDateString(),
          ValueUnits: this.data.context,
          Narrative: this.form.controls.subject.value || '',
          Description: this.form.controls.letterText.value,
          ParentReferenceId: item.data.correlationId,
          PatientId: this.data.routeParams.patientId,
          Thumbnail: this.form?.value.attachments?.length > 0 ?
            `${this.data.contextId},${this.form?.value.attachments.map(s => `${provider.PracticeTenantId}/${this.data.routeParams.patientId}/${s.FileName}`).join(',')}`
            : this.data.contextId,
        }
      ])),
      tap(([res, _]) => {
        if (res.data) {
          this.snackbar.open('Email sent.', 'OK', { duration: 10000 });
          this.dialogRef.close(true);
        } else if (res.error) {
          this.snackbar.open(`${res.error?.message} CorrelationId: ${res.error?.correlationId}`, 'OK');
        } else {
          console.error('Could not send dispensary email', res);
          this.snackbar.open('An unknown error occured while sending the email.', 'OK');
        }
        this.sendBusy = false;
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!value.isValidEmailAddress()) {
      return;
    }

    if (this.recipientIncluded(value)) {
      return;
    }

    if ((value || '').trim()) {
      this.recipients.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.form.get('recipients').setValue(_.uniq(this.recipients).join(', '));
  }

  toggleRecipient(value: string) {
    const recipient = (value || '').trim();

    if (!recipient) {
      return;
    }

    const index = this.recipients.indexOf(recipient);

    if (index > -1) {
      this.recipients.splice(this.recipients.indexOf(recipient));
    } else {
      this.recipients.push(recipient);
    }

    this.form.get('recipients').setValue(_.uniq(this.recipients).join(', '));
  }

  remove(fruit: any): void {
    const index = this.recipients.indexOf(fruit);

    if (index >= 0) {
      this.recipients.splice(index, 1);
    }

    this.form.get('recipients').setValue(_.uniq(this.recipients).join(', '));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.recipients.push(event.option.value);
    this.recipientInput.nativeElement.value = '';
    this.form.get('recipients').setValue(_.uniq(this.recipients).join(', '));
  }

  recipientIncluded(value: string) {
    return this.recipients.map(r => r.toLowerCase())
      .includes((value || '').trim().toLowerCase());
  }

  warnLimit$(currentSize) {
    const dialogData: ConfirmDialogModel = {
      title: 'Note',
      message: `Total file size should be less than 8 MB. Current size is ${new FileSizePipe().transform(currentSize)}.`,
      actions: [defaultDialogActions.OK],
    };

    return this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      height: '190px', // hacky quick way to reduce strange height scrolling
      data: dialogData,
    }).afterClosed();
  }

  removeFile(file) {
    this.form.patchValue({ files: (this.form.get('files').value as File[]).filter(s => s.name !== file.name) });
  }

  removeAttachment(file) {
    this.form.patchValue({ attachments: (this.form.get('attachments').value as CommunicationAttachmentWithData[]).filter(s => s.FileName !== file.FileName) });
  }

}
