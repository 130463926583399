import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StateModule } from '../state.module';
import { PatientDocumentVo } from 'api-clinician-app';

export interface DocumentsState extends EntityState<PatientDocumentVo> { }

@Injectable({ providedIn: StateModule })
@StoreConfig({ name: 'mypatientfiles-documents', idKey: 'DocumentId', resettable: true })
export class DocumentsStore extends EntityStore<DocumentsState, PatientDocumentVo> {
  constructor() {
    super();
  }
}
