import { Component, Output, EventEmitter } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogRef } from '@angular/material/dialog';
import { AudioRecorderService } from '../nora-services/audio-recorder.service';
import { DialogManagementService, DialogService } from '../nora-services/nora-dialog.service';

@Component({
  selector: 'app-nora-dialog-toolbar',
  templateUrl: './nora-dialog-toolbar.component.html',
  styleUrls: ['./nora-dialog-toolbar.component.css'],
//   standalone: true,
//   imports: [MatToolbarModule, MatButtonModule, MatIconModule]
})
export class DialogToolbarComponent {
	@Output() resizeDialogEvent = new EventEmitter<void>();
  @Output() sidenavToggle = new EventEmitter<void>();

  constructor(
    public dialogRef: MatDialogRef<DialogToolbarComponent>,
    private noraDialogService: DialogService,
    private noraAudioRecorderService: AudioRecorderService,
    private dialogManagementService: DialogManagementService
  ) { }

  toggleSidenav() {
    this.sidenavToggle.emit();
  }

  closeDialog(): void {
    if(this.noraAudioRecorderService.getRecordingStatus()) {
      this.noraDialogService.cancelRecordingSession();
    }
    this.dialogManagementService.setIsSpeechToNotesActive(false);
    this.dialogRef.close();
  }

  resizeDialog() {
    this.resizeDialogEvent.emit();
  }
}
