import { Component, OnInit, Input, AfterViewInit, HostBinding, Self, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() value: string;

  onChange: any = () => { };
  onTouch: any = () => { };

  _value;

  set val(val) {

    this._value = val;

    this.onChange(val);
    this.onTouch(val);
  }

  constructor() { }

  writeValue(val: any) {
    this.val = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  isChecked() {
    return !!this._value;
  }

  toggleCheck() {
    this.onChange(!!this._value ? this.value : '');
  }

}
