import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LogoutConfirmationDialogComponent } from '../logout-confirmation-dialog/logout-confirmation-dialog.component';
import { AuthService, NavigationService } from '@app/core/services';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { map, distinctUntilChanged, tap, filter, mergeMap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  authenticated$ = this.authService.authenticated$;
  superAdmin$ = this.authService.superAdmin$;
  currentTenantId$ = this.authService.currentTenantId$;

  menuItems = [
    {
      display: 'auth',
      icon: 'lock',
      text: 'Login',
      routerLink: 'login'
    }
  ];

  tenantId$ =
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      mergeMap(() => this.route.firstChild.paramMap),
      map(params => params.get('tenantId'))
    );

  constructor(
    private authService: AuthService,
    private navService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.tenantId$.
      pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
      ).subscribe(tenantId => {
        if (tenantId) {
          this.authService.setCurrentTenantId(tenantId);
        }
      });
  }

  ngOnDestroy(): void {}

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authService.logout();
        this.navService.navigate(['/auth/login']);
      }
    });
  }

}
