import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from '../application-insights.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightServiceErrorHandler extends ErrorHandler {

  constructor(private appInsightsService: ApplicationInsightsService) {
    super();
  }

  handleError(error: any): void {
    this.appInsightsService.trackException(error);
    super.handleError(error);
  }
}
