import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, TitleCasePipe, DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { ConnectFormDirective } from './directives/connectForm.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { AvatarModule } from 'ngx-avatar';
import { EditableComponent } from './editable/editable.component';
import { FocusableDirective } from './directives/focusable.directive';
import { ViewModeDirective } from './editable/view-mode.directive';
import { EditModeDirective } from './editable/edit-mode.directive';
import { EditableOnEnterDirective } from './editable/editable-on-enter.directive';
import { FilterPipe, GenderAvatarPipe, MyPatientFilesUrlPipe, FileSizePipe, SafePipe, TextBodyFormatter } from './pipes';
import { BirthdayIconComponent } from './components/birthday-icon/birthday-icon.component';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { FileDropDirective } from './directives/fileDrop.directive';
import { FormFileSelectionComponent } from './components/form-file-selection/form-file-selection.component';
import { SubmitIfValidDirective } from './directives/submit-if-valid.directive';
import { ChartsModule } from 'ng2-charts';
import { NgAisModule } from 'angular-instantsearch';
import { DynamicFormBuilderComponent } from './components/dynamic-forms/dynamic-form-builder/dynamic-form-builder.component';
import { DynamicFormQuestionComponent } from './components/dynamic-forms/dynamic-form-question/dynamic-form-question.component';
import { AlgoliaSearchBoxComponent } from './components/algolia-search-box/algolia-search-box.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Capacitor } from '@capacitor/core';
import { DisableReactiveFormControlDirective } from './directives/disable-control.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DateFromPipe } from './pipes/date-from.pipe';
// tslint:disable-next-line: max-line-length
import { PatientCommunicationSendDialogComponent } from './components/patient-communication-send-dialog/patient-communication-send-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { TimespanComponent } from './components/timespan/timespan.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { InputDirective } from './directives/input.directive';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { PowerBiModule } from './powerbi/powerbi.module';
import { ImageUploadCropResizeComponent } from './components/image-upload-crop-resize/image-upload-crop-resize.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { SingleBreakPipe } from './pipes/single-break.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { DialogDraggableTitleDirective } from './directives/dialogDraggableTitle.directive';
import { ThrottleClickDirective } from './directives/throttleClick.directive';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AgePipe, HumanizedAgePipe } from './pipes/age.pipe';
import { MatSortModule } from '@angular/material/sort';
import { SlicePipe } from './pipes/slice.pipe';
import { VarDirective } from './directives/ngVar.directive';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { LimitPipe } from './pipes/limit.pipe';
import { MedicineDescriptionPipe, MedicinePricePipe, MedicineFormularyPipe } from './pipes/medicine.pipe';
import { WhiteboardIconComponent } from './components/whiteboard-icon/whiteboard-icon.component';
import { DateFromStringPipe } from './pipes/date-from-string.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { PatientSaveEmailDialogComponent } from './components/patient-save-email-dialog/patient-save-email-dialog.component';
import { UserFeedbackDialogComponent } from './components/user-feedback-dialog/user-feedback-dialog.component';
import { PrivacyPolicyDialogComponent } from './components/privacy-policy-dialog/privacy-policy-dialog.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { QuickNotesComponent } from './components/quick-notes/quick-notes.component';
import { NoNegativeValuePipe } from './pipes/no-negative-value.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AudioRecorderComponent } from './components/nora-component/audio-recorder/audio-recorder.component';
import { ConsultationDialogComponent } from './components/nora-component/nora-consultation-dialog/nora-consultation-dialog.component';
import { DialogToolbarComponent } from './components/nora-component/nora-dialog-toolbar/nora-dialog-toolbar.component';
import { SidenavComponent } from './components/nora-component/nora-sidenav/nora-sidenav.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { NoraAPIService } from './components/nora-component/nora-services/nora-api.service';
import { MarkdownRendererComponent } from './components/markdown-renderer/markdown-renderer.component';
import { PatientAllergyWarningComponent } from './components/patient-allergy-warning/patient-allergy-warning.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const materialComponents = [
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatMenuModule,
  MatSidenavModule,
  MatCardModule,
  MatDividerModule,
  MatExpansionModule,
  MatListModule,
  MatIconModule,
  MatDialogModule,
  MatBadgeModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatTabsModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatTableModule,
  MatSortModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  NgxMaterialTimepickerModule,
  MatTooltipModule,
  MatRippleModule,
  MatToolbarModule,
  CommonModule,
  MatListModule,
  DragDropModule,
  FormsModule,
  MatOptionModule,
  MatSnackBarModule,
  MatStepperModule,
];

const directives = [
  FocusableDirective,
  ViewModeDirective,
  EditModeDirective,
  EditableOnEnterDirective,
  ConnectFormDirective,
  FileDropDirective,
  SubmitIfValidDirective,
  DisableReactiveFormControlDirective,
  InputDirective,
  DialogDraggableTitleDirective,
  ThrottleClickDirective,
  VarDirective,
];

const components = [
  EditableComponent,
  BirthdayIconComponent,
  FormFileSelectionComponent,
  DynamicFormBuilderComponent,
  DynamicFormQuestionComponent,
  AlgoliaSearchBoxComponent,
  PatientCommunicationSendDialogComponent,
  PatientSaveEmailDialogComponent,
  CheckboxListComponent,
  TimespanComponent,
  FormInputComponent,
  SlideToggleComponent,
  CheckboxComponent,
  UserFeedbackDialogComponent,
  ConfirmDialogComponent,
  ImageUploadCropResizeComponent,
  WhiteboardIconComponent,
  PrivacyPolicyDialogComponent,
  ScrollToTopComponent,
  QuickNotesComponent,
  ConsultationDialogComponent, 
  DialogToolbarComponent, 
  AudioRecorderComponent, 
  SidenavComponent,
  MarkdownRendererComponent,
  PatientAllergyWarningComponent
];

const pipes = [
  FileSizePipe,
  FilterPipe,
  MyPatientFilesUrlPipe,
  GenderAvatarPipe,
  SafePipe,
  TextBodyFormatter,
  DateFromPipe,
  DateFromStringPipe,
  SingleBreakPipe,
  PhonePipe,
  AgePipe,
  HumanizedAgePipe,
  MedicineDescriptionPipe,
  MedicinePricePipe,
  MedicineFormularyPipe,
  SlicePipe,
  LimitPipe,
  NoNegativeValuePipe,
  TruncatePipe,
];

@NgModule({
  entryComponents: [
    // DynamicFormBuilderComponent,
    // DynamicFormQuestionComponent,
    PatientCommunicationSendDialogComponent,
    ConfirmDialogComponent,
  ],
  declarations: [
    ...components,
    ...directives,
    ...pipes,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MomentModule,
    AvatarModule,
    ChartsModule,
    NgAisModule,
    FullCalendarModule,
    EditorModule,
    PowerBiModule,
    AngularCropperjsModule,
    NgxPermissionsModule.forChild(),
    SatPopoverModule,
    NgxPicaModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    ...materialComponents
  ],
  exports: [
    DragDropModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MomentModule,
    AvatarModule,
    ChartsModule,
    NgAisModule,
    EditorModule,
    FullCalendarModule,
    PowerBiModule,
    AngularCropperjsModule,
    NgxPermissionsModule,
    SatPopoverModule,
    NgxPageScrollModule,
    ...components,
    ...directives,
    ...materialComponents,
    ...pipes,
  ],
  providers: [
    MatDatepickerModule,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    NoNegativeValuePipe,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class SharedModule { }
