<ng-container *ngIf="sidebar$ | async as sidebar">
  <div class="top-icons contextual-icons">
    <ng-container *ngIf="isInPatientContext$ | async">
      <button mat-icon-button data-tour="executive-summary"
        [ngClass]="{ active: sidebar === SidebarLocations.EXECUTIVE_SUMMARY }"
        (click)="toggleSidebar($event, SidebarLocations.EXECUTIVE_SUMMARY)">
        <mat-icon matTooltip="Executive summary" class="img exec-icon" fontSet="material-icons-outlined">recent_actors
        </mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="(sandboxEnabled$ | async) == true && (isInPatientContext$ | async)">
      <button class="vip-playground-button" mat-icon-button data-tour="ilab-sandbox"
        [ngClass]="{ active: sidebar === SidebarLocations.ILAB_SANDBOX }"
        (click)="toggleSidebar($event, SidebarLocations.ILAB_SANDBOX)">
        <mat-icon matTooltip="VIP Playground" class="img exec-icon" fontSet="material-icons-outlined">tips_and_updates
        </mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="(speechToNotesEnabled$ | async) == true && (existingConsult$ | async)">
      <button mat-icon-button data-tour="nora-consult-summary"
        [ngClass]="{ active: sidebar === SidebarLocations.NORA_CONSULT_SUMMARY }"
        (click)="toggleSidebar($event, SidebarLocations.NORA_CONSULT_SUMMARY)">
        <mat-icon 
        matTooltip="Record Consult" 
        class="img exec-icon" 
        fontSet="material-icons-outlined"
        *ngIf="!(audioRecorderService.recording$ | async)">
        mic_none
        </mat-icon>
        <mat-icon 
        matTooltip="Recording in progress" 
        class="img exec-icon" 
        style="color: red; animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;" 
        fontSet="material-icons-outlined"
        *ngIf="audioRecorderService.recording$ | async">
        stop_circle
        </mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="existingConsult$ | async">
      <button mat-icon-button data-tour="consultation-summary"
        [ngClass]="{ active: sidebar === SidebarLocations.ENCOUNTER_SUMMARY }"
        (click)="toggleSidebar($event, SidebarLocations.ENCOUNTER_SUMMARY)">
        <mat-icon *ngVar="medicineInteractionMaxServerity$ | async as severity" matTooltip="Consultation summary"
          class="img exec-icon interaction-badge" [class.severe]="severity === 1" [class.warn]="severity > 1"
          fontSet="material-icons-outlined" [matBadge]="severity > 0 ? 'warning' : ''">list_alt</mat-icon>
      </button>

      <button mat-icon-button data-tour="consultation-note" [ngClass]="{ active: sidebar === SidebarLocations.QUICK_NOTES }" *ngIf="!(IsEnabledConsultationNotesTab$ | async)"
        (click)="toggleSidebar($event, SidebarLocations.QUICK_NOTES)">
        <mat-icon matTooltip="Consultation note" class="img exec-icon" fontSet="material-icons-outlined">rate_review
        </mat-icon>
      </button>
      <div class="divider"></div>
    </ng-container>
  </div>
  <div class="bottom-icons global-icons">
    <button mat-icon-button [ngClass]="{ active: sidebar === SidebarLocations.WAITING_ROOM }" class="tour-waiting-room"
      (click)="toggleSidebar($event, SidebarLocations.WAITING_ROOM)">
      <ng-container *ngVar="waitingRoomCount$ | async; let waitingRoomCount">
        <mat-icon matBadge="{{ waitingRoomCount > 0 ? waitingRoomCount : '' }}" [matBadgeColor]="primary" class="img"
         svgIcon="waiting_room" matTooltip="Waiting room"></mat-icon>
      </ng-container>
    </button>
    <button mat-icon-button [ngClass]="{ active: sidebar === SidebarLocations.TASKS }"
      (click)="toggleSidebar($event, SidebarLocations.TASKS)">
      <ng-container *ngVar="overdueTasksCount$ | async; let overdueTasksCount">
        <mat-icon class="img" fontSet="material-icons-outlined" matTooltip="Tasks"
          matBadge="{{ overdueTasksCount > 0 ? overdueTasksCount : '' }}" [matBadgeColor]="primary">
          task_alt</mat-icon>
      </ng-container>
    </button>
    <button mat-icon-button [ngClass]="{ active: sidebar === SidebarLocations.PATHOLOGY }"
      (click)="toggleSidebar($event, SidebarLocations.PATHOLOGY)"
      *ngxPermissionsExcept="[FRONT_DESK_PERMISSION.DenyViewPathology]">
      <ng-container *ngVar="pathologyUnactionedCount$| async; let pathologyUnactionedCount">
        <mat-icon matBadge="{{ pathologyUnactionedCount > 0 ? pathologyUnactionedCount : '' }}"
          [matBadgeColor]="primary" class="img" fontSet="material-icons-outlined" svgIcon="pathology"
          matTooltip="Pathology">pathology</mat-icon>
      </ng-container>
    </button>
   <!--  <button mat-icon-button [ngClass]="{ active: sidebar === SidebarLocations.NOTIFICATIONS }"
      (click)="toggleSidebar($event, SidebarLocations.NOTIFICATIONS)">
      <ng-container *ngVar="notificationsCount$ | async; let notificationsCount">
        <mat-icon matBadge="{{ notificationsCount > 0 ? notificationsCount : '' }}" [matBadgeColor]="primary"
          class="img" fontSet="material-icons-outlined" matTooltip="Notifications">campaign</mat-icon>
      </ng-container>
    </button> -->
    <div class="divider" style="margin-bottom: 80px;"></div>

    <!--
    <button mat-icon-button [ngClass]="{ active: sidebar === SidebarLocations.USER_FEEDBACK }" (click)="openUserFeedback()">
      <mat-icon class="img" fontSet="material-icons-outlined" matTooltip="Give us feedback">emoji_objects</mat-icon>
    </button>
    <button mat-icon-button (click)="navToBridgeIt()" >
      <mat-icon matTooltip="Help">help_outline</mat-icon>
    </button>

     <button mat-icon-button (click)="showHelp()">
      <mat-icon matTooltip="Chat bot">chat</mat-icon>
    </button> -->
  </div>
  <!-- <ng-container *ngIf="sidebarFlyout$ | async">
    <div class="divider"></div>
    <div class="contextualIcons">
      <ul>
        <li (click)="toggleSidebar($event, SidebarLocations.HIDDEN)">
          <mat-icon
            class="img"
            fontSet="material-icons-outlined"
            >exit_to_app</mat-icon
          >
        </li>
      </ul>
    </div>
  </ng-container> -->
</ng-container>
