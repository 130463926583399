<ng-container [ngSwitch]="browserType$ | async">
  <app-mobile-layout *ngSwitchCase="BrowserTypes.Mobile">
    <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
  </app-mobile-layout>
  <app-web-layout *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
  </app-web-layout>
</ng-container>

<ng-template #routerOutlet>
  <router-outlet #outlet="outlet" (activate)="onRouteActivate($event)"></router-outlet>
</ng-template>
