import { Component, ViewChild, AfterViewInit, Inject, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NoraAPIService } from '../nora-services/nora-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../nora-services/nora-dialog.service';
import { AudioRecorderService } from '../nora-services/audio-recorder.service';

type PageKey = 'Home' | 'Consults' | 'Settings' | 'Help';

@Component({
  selector: 'app-nora-consultation-dialog',
  templateUrl: './nora-consultation-dialog.component.html',
  styleUrls: ['./nora-consultation-dialog.component.css']
})
export class ConsultationDialogComponent implements AfterViewInit, OnInit, OnDestroy {
  currentPage: PageKey = 'Home';
  subtitle: string = ''; // Property for subtitle
  opened = false;
  displayedColumns: string[] = ['patient', 'time', 'status']; // Define displayed columns
  dataSource = []; // This should be your data array

	//settings vars
	email: string = ''
	accountId: string = ''

	//feedback vars
	message: string = ''

	//consultations vars
	consultations: any[] = [];

  @ViewChild('sidenav') sidenavComponent!: MatSidenav;

  pageConfig: { [key in PageKey]: { title: string } } = {
    'Home': { title: 'New Consultation' },
    'Consults': { title: 'Previous Consultations' },
    'Settings': { title: 'Settings' },
    'Help': { title: 'Contact Us' }
  };

  @Output() onRecorded = new EventEmitter<Blob>();
  private originalWidth = '450px';
  private originalHeight = '400px';
  private isResized = false;
  private subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ConsultationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
		private http: HttpClient,
		private noraApiService: NoraAPIService,
    private snackBar: MatSnackBar,
    private noraDialogService: DialogService,
    private noraAudioRecorderService: AudioRecorderService

  ) {
    dialogRef.disableClose = true;
    this.updateSubtitle();
  }

	ngOnInit(): void {
    this.noraApiService.getSetDialogData(this.data.practiceId, this.data.encounterId)
        
    this.subscription = this.noraDialogService.isResized$.subscribe(isResized => {
      this.isResized = isResized;
      this.updateDialogSize();
    });

		this.getSettingsData()
		this.getConsulations()
	}

  ngAfterViewInit() {}

  switchPage(page: PageKey) {
    this.currentPage = page;
    this.updateSubtitle();
    this.onSidenavToggle(true);
  }

  updateSubtitle(): void {
    switch (this.currentPage) {
      case 'Home':
        this.subtitle = 'Please select your microphone and start recording your consultation.';
        break;
      case 'Consults':
        this.subtitle = 'View and manage your consultations';
        break;
      case 'Settings':
        this.subtitle = 'View your account details';
        break;
      case 'Help':
        this.subtitle = 'Let us know how we may assist you, and someone from our team will get back to you shortly.';
        break;
      default:
        this.subtitle = '';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  resizeDialog() {
    this.updateDialogSize();
    this.isResized = !this.isResized;
    this.noraDialogService.setResized(this.isResized); // Notify the service
  }

  private updateDialogSize() {
    if (this.isResized) {
      this.dialogRef.updateSize(this.originalWidth, this.originalHeight);
    } else {
      this.dialogRef.updateSize('0px', '0px'); // Minimize/hide the dialog
    }
  }

  onSidenavToggle(close: boolean = false) {
    if (this.sidenavComponent) {
      if (close) {
        this.sidenavComponent.close();
      } else {
        this.sidenavComponent.toggle();
      }
    } else {
      console.error('SidenavComponent is not defined');
    }
  }

  handleAudioRecorded(audioBlob: Blob) {
    console.log('Received recorded audio:', audioBlob);
    this.onRecorded.emit(audioBlob);
  }

	getSettingsData() {
		this.noraApiService.getDoctorInfo(this.data.practiceId)
		.subscribe(res => {
			const jsonResult = JSON.parse(res.body)
			this.email = jsonResult.Email
			this.accountId = jsonResult.DoctorID
		})
	}

	onFeedbackSubmit() {
		// console.log(this.message)
		this.noraApiService.submitFeedback(this.data.practiceId, this.message)
		.subscribe(res => {
			// console.log(res.message);
            if (res.message === 'Feedback submitted successfully!') {
                this.snackBar.open('Feedback submitted successfully.', 'Close', {duration: 2000});
            }
		})
	}

	getConsulations() {
		this.noraApiService.getConsultations(this.data.practiceId)
		.subscribe(res => {
			this.dataSource = res
		})
	}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
