import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';
import { MerakiAuthService } from '../services/meraki-auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private configService: ConfigService, private merakiAuthService: MerakiAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isGateway = req.url.includes(this.configService.config.merakiApiGateway);

    return isGateway && this.authService.authenticated ? this.HandleWithFirebaseAuthHeader(req, next) : this.HandleWithFlorenceAuthHeader(req, next);
  }

  HandleWithFlorenceAuthHeader(req: HttpRequest<any>, next: HttpHandler) {

    const token = this.authService.token;

    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token ? token : ''}`),
      });
    }

    return next.handle(req);
  }


  HandleWithFirebaseAuthHeader(req: HttpRequest<any>, next: HttpHandler) {

    return this.merakiAuthService.getFirebaseToken().pipe(
      switchMap(token => {

        if (token) {
          req = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token ? token : ''}`),
          });
        }

        return next.handle(req);
      })
    )

  }
}
