<div *ngIf="allergies$ | async as allergies">
  <div *ngIf="allergies?.PatientAllergies?.length" class="red-container">
    <mat-icon color="warn" class="warning">warning</mat-icon>

    <div class="allergies">
      <h3>Allergies</h3>
      <div class="flex">
        <div *ngFor="let allergy of allergies.PatientAllergies; let i = index">
          <h4>
            {{ allergy.Allergen.Description }}<span *ngIf="allergy.Criticality"> ({{ allergy.Criticality }})</span
            ><span *ngIf="i !== allergies.PatientAllergies.length - 1">, &nbsp;</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
